import React, { Component } from 'react';

import {logOutUser} from '../LocalStorage/user'
import history from "../History/history";
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";


class  Logout extends Component {


    userFormData;



    componentWillMount(){

        logOutUser()
        this.props.logOut()
        history.push("/");

    }


    render() {


        return <>


        </>

    }

}



const mapStateToProps = state => {
    return {


    };
};

const mapDispachToProps = dispatch => {
    return {

        logOut: () => dispatch(actionCreator.logOut()),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(Logout);





