import React, { Component } from 'react';
import { Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags';

class  Career extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }
    render (){
        return(

            <div className="bg-gray">


                <MetaTags>

                    <title>Make a Career with Us | Happy Weekends </title>
                    <meta name="title" content="Make a Career with Us | Happy Weekends " />
                    <meta name="description" content="If you are looking to make a career that aims to make the world a happier place, you’ve reached the right place. We work with a fantastic bunch of people whose sole ambition is to make weekends awesome for people. Everything that we do is centred around that. Join us today!! " />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content="Make a Career with Us | Happy Weekends " />
                    <meta property="og:description" content="If you are looking to make a career that aims to make the world a happier place, you’ve reached the right place. We work with a fantastic bunch of people whose sole ambition is to make weekends awesome for people. Everything that we do is centred around that. Join us today!! " />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content="Make a Career with Us | Happy Weekends " />
                    <meta property="twitter:description" content="If you are looking to make a career that aims to make the world a happier place, you’ve reached the right place. We work with a fantastic bunch of people whose sole ambition is to make weekends awesome for people. Everything that we do is centred around that. Join us today!! " />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>


                <section className="career-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 how-work-txt text-center">
                                <h2 className="text-center">Careers</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="empower-future-main">
                                    <h2 className="best-family-txt text-center empower-hd">
                                        Before we talk about jobs, let’s talk about<br></br>How we do ours</h2>
                                   
                                    <p className="text-center discover-pg">We work with a fantastic bunch of people whose sole ambition is to make weekends awesome for people. Everything we do is centered around that. If you too, would like to see a happier world, we’d love to sit down and talk with you. Below are the posts we are hiring for, at the moment. Get in touch if what you see interests you.</p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="job-listing">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h2>Open roles</h2>
                            </div>
                            <div className="col-lg-8">
                                <p>At HappyWeekends, the world’s most talented engineers, designers, and thought leaders are shaping the future of weekend experiences.
We’re looking for people to join the team who are as excited as we are to help build the platform that empowers the future generation of creators to be successful online.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="items">
                                    <div className="job-title">Digital Marketing Executive</div>
                                    <div className="job-type">Full time</div>
                                    <div className="job-place">Abu Dhabi</div>
                                    <div className="job-title">
                                        <Link className={"view-job"} to={"/career-detail/visual"}>View Job</Link>
                                    </div>
                                </div>
                                <div className="items d-none">
                                    <div className="job-title">Digital Marketing Executive</div>
                                    <div className="job-type">Full time</div>
                                    <div className="job-place">Abu Dhabi</div>
                                    <div className="job-link">
                                        <Link className={"view-job"} to={"/career-detail/visual"}>View Job</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Career;