import React, { Component } from 'react';
import PartnerShip from '../img/partnership-img.jpg';
import OnlineBooking from '../img/online-booking-img.jpg';
import MIllion from '../img/million-round-img.png';

import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import {Switch, Link} from 'react-router-dom'
import {loginFailed} from "../store/actions/actions";
import axios from "axios/index";
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import MetaTags from 'react-meta-tags';

class  PartnerContact extends Component{


    componentDidMount () {
        window.scrollTo(0, 0)

    }


    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            formSuccess : false,
            formFailed :  false,
            loading : false,
            fields: {},
            errors: {},
            phoneNumberInValid: false,



        }


        this.passwordRef = React.createRef()

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitNew = this.handleSubmitNew.bind(this);

        this.submitRequest = this.submitRequest.bind(this);



        this.handleValidation=this.handleValidation.bind(this)
        this.handleChange=this.handleChange.bind(this)

    }



    handleChange(field, e){

        console.log(field)
        console.log(e)

        let fields = this.state.fields;
        fields[field] = e;
        this.setState({fields});
    }

    handleValidation(){

        // alert("called")
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            // errors["phoneNumber"] = "Invalid phone number!";
            this.setState({

                "phoneNumberInValid" : true
            })
        }



        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValid" : true
            })


        }


        this.setState({errors: errors});
        return formIsValid;
    }






    submitRequest(data){

        this.setState({

            loading : true

        })

        console.log(data)

        axios.post(baseUrl+"host_contacts.json",
            data)
            .then(res => {


        console.log(res.data)

        this.setState({

            formSuccess : true,
            loading : false


        })
            }).catch(error => {

            console.log(error)


            this.setState({

                loading : false,
                formSuccess:false

            })

        });



    }

    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                 validatedForm : true

            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);



                this.setState({

                    passwordError : false
                })

                const name = data.get("name")
                const email = data.get("email")
                const phone = this.state.fields["phoneNumber"]
                const message = data.get("message")
                this.submitRequest({"name":name, "message": message,
                    "email":email,"phone":phone})


        }
    }
    handleSubmitNew = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true

            });
        }

        if (!this.handleValidation()) {

            event.stopPropagation();

            this.setState({

                validatedForm : true

            });
        }





        if(this.handleValidation()&&form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);



            this.setState({

                passwordError : false
            })

            const name = data.get("name")
            const email = data.get("email")
            const phone = data.get("phoneNumber")
            const message = data.get("message")
            this.submitRequest({"name":name, "message": message,
                "email":email,"phone":phone})


        }
    }

    render (){
        return(
            <div className="bg-gray">



                <MetaTags>

                    <title>Start your Journey to Becoming a Weekend Expert | Join us as a Host</title>
                    <meta name="title" content="Start your Journey to Becoming a Weekend Expert | Join us as a Host" />
                    <meta name="description" content="Spending weekends in a unique way is now an in-thing. When you become a partner with Happy Weekends , it will give you direct access to millions of people looking to spend a great weekend. And because there’s nothing like this yet, yours is the door they will knock on. " />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content="Start your Journey to Becoming a Weekend Expert | Join us as a Host" />
                    <meta property="og:description" content=" Spending weekends in a unique way is now an in-thing. When you become a partner with Happy Weekends , it will give you direct access to millions of people looking to spend a great weekend. And because there’s nothing like this yet, yours is the door they will knock on. " />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content="Start your Journey to Becoming a Weekend Expert | Join us as a Host" />
                    <meta property="twitter:description" content=" Spending weekends in a unique way is now an in-thing. When you become a partner with Happy Weekends , it will give you direct access to millions of people looking to spend a great weekend. And because there’s nothing like this yet, yours is the door they will knock on. " />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>
                <section className="section-block become-partner-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="become-partner-txt">
                                    <h2 className="text-center text-white">Become A Host</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="joining-us-sec">
                                    <h2 className="best-family-txt text-center">WE’RE GLAD YOU’RE JOINING US</h2>

                                    <div className="row formobile-reverse">
                                    <div className="col-md-6 partnership-form">
                                            <p className="online-booking-hd">
                                            Best online marketplace for extraordinary weekend experiences.
                                            </p>
                                            <p className="online-pg">
                                                When you become a partner with Happy Weekends, you have direct access to 9 million people looking to spend a great weekend. And because there’s nothing like this yet, when people are looking for beautifully curated experiences, yours is the door they will knock on. Look at it this way. People plan their vacation once or twice a year, and today, it’s become a billion dollar industry. There are fifty two weekends a year. Even if people plan for one weekend a month, the numbers are astronomical.
                                            </p>
                                            <p className="online-booking-hd">
                                            Scale your business with a partnership that works
                                            </p>
                                            <p className="online-pg">
                                            The UAE, unlike most other countries, is an expat country. Which means there are millions of people from all over the world who are living away from their families. When you partner with Happy Weekends, you have access to this giant community of people who are looking to spend a happy weekend.
                                            </p>
                                            <p className="online-booking-hd">Take control of your business’s future</p>
                                            <p className="online-pg">Start your journey to becoming a Happy Weekends Expert today</p>
                                        </div>
                                        <div className="col-md-6 pr-0">
                                            <img src={PartnerShip} className="partnership-img w-100" alt="" />
                                        </div>
                                        <div className="clearfix" />
                                    </div>
                                    <div className="row online-booking-sec">
                                        <div className="col-md-6">
                                            <img src={OnlineBooking} alt="" className="online-booking-img" />
                                        </div>
                                        <div className="col-md-6">
                                            <p className="online-booking-hd">
                                                Join a partnership that takes you and your business to the next level
                                            </p>

                                                <Form  className={"partnership-form-main"} name="host_contact" noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitNew}>

                                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                    <Col sm={{ span: 12 }}>
                                                        <Form.Label> Name</Form.Label>
                                                        <Form.Control  required  name={"name"} type="text" placeholder="" />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                    <Col sm={{ span: 12 }}>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control required  name={"email"} type="email" placeholder="" />
                                                        <Form.Control.Feedback type="invalid">Email Id must be vaild</Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                    <Col sm={{ span: 12 }}>
                                                        <Form.Label>Phone</Form.Label>

                                                        {/*<InputGroup className="phone_no">*/}
                                                            {/*<InputGroup.Prepend>*/}
                                                                {/*<InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>*/}
                                                            {/*</InputGroup.Prepend>*/}

                                                            {/*<Form.Control required minLength={9} maxLength={9}  name={"phone"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                                            {/*<Form.Control.Feedback type="invalid">Phone Number must be 9 digit</Form.Control.Feedback>*/}
                                                        {/*</InputGroup>*/}

                                                        <PhoneInput
                                                            onChange={this.handleChange.bind(this, "phoneNumber")}
                                                            inputClass={this.state.phoneNumberInValid?"is-invalid":""}
                                                            inputProps={{
                                                                name: 'phoneNumber',
                                                                // required: true,
                                                                autoFocus: true,
                                                                defaultErrorMessage:"Invalid",
                                                                // minLength:9,
                                                            }}
                                                            country={'ae'}
                                                        />


                                                        <span className={"invalid-feedback-phone"}>{this.state.errors["phoneNumber"]}</span>

                                                    </Col>
                                                </Form.Group>


                                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                    <Col sm={{ span: 12 }}>
                                                        <Form.Label>Message</Form.Label>


                                                            <Form.Control  as="textarea" rows="7" required  name={"message"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>

                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                    <Col sm={{ span: 12 }}>

                                                        {/*<Button type={"submit"} variant="danger" >*/}
                                                            {/*{ this.state.loading && <Spinner*/}
                                                                {/*as="span"*/}
                                                                {/*animation="grow"*/}
                                                                {/*size="sm"*/}
                                                                {/*role="status"*/}
                                                                {/*aria-hidden="true"*/}
                                                            {/*/> }*/}

                                                            {/*{ this.state.loading ? "Wait.." : "Submit"}*/}


                                                            {/*Your query submitted successfully. Thanks*/}



                                                        {/*</Button>*/}

                                                        <Button  className={"contact-us-submit"}
                                                                 type={"submit"}

                                                        >
                                                            { this.state.loading && <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> }

                                                            {!this.state.formSuccess &&
                                                            <>
                                                                {this.state.loading ?  "Wait" : "Submit" }
                                                            </>

                                                            }

                                                            { this.state.formSuccess &&  "Request Submitted Successfully" }

                                                        </Button>

                                                    </Col>
                                                </Form.Group>

                                            </Form>




                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}



export default PartnerContact;


