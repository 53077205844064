import React, { Component } from 'react';
import './SaveWeekend.css';


class  SaveWeekend extends Component{




    render (){
        return(
            <section className="saveweekend">
                <div className="container">
                    <div className="row title-row justify-content-center">
                        <div className="col-lg-9">
                            <div className="saveweekend-info">
                                <h2 className="font-weight-bold text-white">Save with Weekends Pass</h2>
                                <p>Purchase weekend pass and enjoy your favourite<br/> selected experiences on special prices.</p>
                                {/* <a className="learn-more" href="javascripit">Coming Soon</a> */}
                                <div className="learn-more" href="javascripit">Coming Soon</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         )
    }
}

export default SaveWeekend;