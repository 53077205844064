import React, { Component } from 'react';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import {Switch, Link} from 'react-router-dom'
import { Formik } from 'formik';
import {baseUrl} from "../Util/Constants";
import axios from "axios/index";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

class  CorporateRegister extends Component{


    email
    password
    slug
    componentDidMount() {

        window.scrollTo(0, 0)


        console.log(this.slug)

        this.getCompanyInfo()

    }

    constructor(props) {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            email : null,
            userExists : false,
            emailLoad : false,
            showInfoPopUp : false,
            corporate : null


        }

        this.slug = props.match.params.name

        this.passwordRef = React.createRef()

        this.handleSubmit = this.handleSubmit.bind(this);

        this.checkIfUserExist= this.checkIfUserExist.bind(this)
        this.captchaResponse=this.captchaResponse.bind(this)
        this.showInfo=this.showInfo.bind(this)
        this.getCompanyInfo=this.getCompanyInfo.bind(this)


    }



    showInfo(){

        this.setState({

            showInfoPopUp: !this.state.showInfoPopUp
        })


    }

    captchaResponse(response){

        console.log(response)




    }



    checkIfUserExist(event){



        this.setState({

            email : event.target.value,
            emailLoad : true
        })


        var    url = baseUrl+"customers.json?groups[]=customer&email="+event.target.value+"@"+this.state.corporate.companyEmailDomain;

        console.log(url)




        axios.get(url)
            .then(res => {


                this.setState({

                    emailLoad : false
                })

                this.setState({

                    loadingRefresh : false,
                })


                console.log("user data exits")

                console.log(res.data)


                if (res.data.length>0){


                    this.setState({

                        userExists : true,
                    })

                } else {

                    this.setState({

                        userExists : false,
                    })


                }




            }).catch(error => {

            console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }





    getCompanyInfo(){

        axios.get(baseUrl+"corporates.json?groups[]=corporate&url="+this.props.match.params.name)
            .then(res => {
                const persons = res.data;
                this.setState({

                    corporate : persons[0],

                });


                console.log(persons[0])
            })



    }

    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            if (data.get("password") != data.get("confirmPassword")) {

                // alert("hello")

                this.setState({

                    confirmPasswordError: true
                })


                event.stopPropagation();


            } else {


                this.setState({

                    confirmPasswordError: false
                })

                this.setState({

                    passwordError: false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")+"@"+this.state.corporate.companyEmailDomain
                const phoneNumber = data.get("phoneNumber")
                const password = data.get("password")
                const confirmPassword = data.get("confirmPassword")
                this.props.signUp({
                    "firstName": firstName, "lastName": lastName,
                    "email": email, "phoneNumber": phoneNumber,
                    "password": password,
                    "isCorporate": true,
                    "corporate": "/api/corporates/"+this.state.corporate.id

                })


                // }

            }
        }
    }



    render (){


        return(
            <section className="login signup-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="signup-formtext-center">
                                <div className="signup-form-col text-left">
                                    <form method="post">
                                        <div className="res-row d-flex justify-content-between">
                                            <div className="form-col-banner">
                                            </div>
                                            <div className="form-col-left">
                                                <h3 className="form-heading">Sign Up</h3>

                                                <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>
                                                    <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12 }}>
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control  required  name={"firstName"} type="text" placeholder="" />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12 }}>
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control required  name={"lastName"} type="text" placeholder="" />
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12 }}>

                                                            <Form.Label>Company Email</Form.Label>

                                                            <InputGroup className="mb-3">
                                                            <Form.Control value={this.state.email} onChange={this.checkIfUserExist}  required name={"email"} type="text" placeholder="" />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text id="basic-addon2">@{this.state.corporate&&this.state.corporate.companyEmailDomain}</InputGroup.Text>
                                                            </InputGroup.Append>
                                                            </InputGroup>
                                                            {this.state.emailLoad &&  <Spinner
                                                                className={"email-input-spinner"}
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />}


                                                            <Form.Control.Feedback type="invalid">Email Id must be vaild</Form.Control.Feedback>
                                                        </Col>

                                                    </Form.Group>


                                                    {this.state.userExists &&
                                                    <Form.Group as={Row} controlId="exampleForm.ControlInput1">
                                                        <Col sm={{span: 12}}>


                                                            <div  style={{color : "#EC1C24"}} className="email-exists-block">An account with this email already exists.</div>

                                                            <div className="email-exists-block">Go to <Link  style={{color : "#007dc4", fontWeight: "600"}} to={"/login?email="+this.state.email} className="">Log In</Link> page</div>



                                                        </Col>
                                                    </Form.Group>
                                                    }

                                                    <Form.Group as={Row} className=""  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12 }}>
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <InputGroup className="phone_no">
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                                                </InputGroup.Prepend>

                                                                <Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>
                                                                <Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>
                                                            </InputGroup>

                                                        </Col>
                                                    </Form.Group>


                                                    <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12 }}>
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control  minLength={6} required name={"password"} type="password" placeholder="" />
                                                            <Form.Control.Feedback type="invalid">The password field must be at least 6 characters.
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12 }}>
                                                            <Form.Label>Confirm Password</Form.Label>
                                                            <Form.Control isInvalid={this.state.confirmPasswordError} minLength={6} required  name={"confirmPassword"} type="password" placeholder="" />

                                                            {this.state.confirmPasswordError &&  <Form.Control.Feedback type="invalid">Passwords do not match.
                                                            </Form.Control.Feedback>}

                                                        </Col>
                                                    </Form.Group>


                                                    <InfoPopOver />



                                                    <Form.Group as={Row}  controlId="exampleForm.ControlInput1">

                                                        <Col sm={{ span: 2, offset: 0 }}>

                                                            <Form.Check inline
                                                                        required
                                                                        name={"ambassador"}
                                                                        custom
                                                                        type={"radio"}
                                                                        id={"yes"}
                                                                        label="Yes"
                                                            />

                                                        </Col>
                                                        <Col sm={{ span: 2, offset: 0 }}>


                                                            <Form.Check inline
                                                                        required
                                                                        name={"ambassador"}

                                                                        custom
                                                                        type={"radio"}
                                                                        id={"no"}
                                                                        label="No"
                                                            />
                                                        </Col>

                                                    </Form.Group>


                                                    {!this.state.userExists && <Form.Group as={Row}  className={"justify-content-md-center"} controlId="exampleForm.ControlInput1">


                                                        <Col sm={{ span: 12 }}>
                                                            <div className="d-flex btn-group">

                                                                <button type={"submit"} className="btn btn-signup" >Sign Up</button>

                                                            </div>

                                                        </Col>
                                                    </Form.Group> }

                                                    <Form.Group className={"d-none"}  as={Row}  controlId="exampleForm.ControlInput1">
                                                        <Col sm={{ span: 12, offset: 0 }}>

                                                            <div className="forgot-col mt-3 d-flex aligns-center">
                                                                <Link href="/register">Forgot Password?</Link>
                                                            </div>
                                                        </Col>

                                                    </Form.Group>

                                                </Form>


                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


function InfoPopOver() {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <div>

            <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                <Col sm={{ span: 12, offset: 0 }}>
                    <label className="required">Opt to be the happiness ambassador*</label>
                    <div onClick={handleClick} className="tooltips"> <i style={{fontSize: '16px', cursor :"pointer"}} className="fa fa-info-circle  " />

                    </div>
                </Col>

            </Form.Group>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{maxWidth:"400px"}} className={classes.typography}>Be the happiness ambassador and help us realize the vision of UAE leadership to make the UAE as the happiest place on earth. Simply share the best weekends picture on the #happyweekends hastag. The best pictures get qualified for the annual happy weekends awards and winners will be awarded during the ceremony.</Typography>
            </Popover>
        </div>
    );





}

const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn

    };
};

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item))

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CorporateRegister);