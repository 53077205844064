import React, { Component } from 'react';
//import BollyoodImage1 from '../img/bollywood-1.png';

class  AdminPartnerHeader extends Component{ 
    render (){
        return(
            <section className="admin-haeder blue-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="avatar-upload">
                            <div className="avatar-edit">
                                <label htmlFor="imageUpload" />
                            </div>
                            <div className="avatar-preview">
                            <div id="imagePreview">
                            </div>
                            </div>
                        </div>
                            <div className="user-name">
                                <h5>Customer Account</h5>
                                <h2>Mukesh Dua</h2>
                            </div>
                            <div className="activity-status_info">
                                Dear <strong>First2</strong> <br />Your Email verification is
                                <span className="badge badge-danger">Pending</span>
                                <br />Kindly click on the confirmation link sent to your registered email at the time of registration <br />
                                <a href="javascripit">Resend Email</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>       
        )
    }
}

export default AdminPartnerHeader;