import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import ClintImg from '../img/claint-image2.png';
import {baseUrl,baseImgUrl} from "../Util/Constants";
import axios from "axios/index";
import {saveKey,getKey} from "../LocalStorage/user";
import Moment from 'react-moment';
import Rating from '@material-ui/lab/Rating';
import  {Star} from '@material-ui/icons';

class  CustomerReviews extends Component{



    page=1;
    constructor(props){

        super(props)

        this.state = {
            activities: [],
            page: 1,
            displayLoadMore: false,
            reviews: [],
            verifyPopUp: false,
            verifyId: null,
            error: false,
            loading: false,
            dashboard: {}
        }

        this.loadReviews= this.loadReviews.bind(this)
        this.getActivities= this.getActivities.bind(this)


    }


    getActivities(){


        this.setState({

            reviews:[]
        })

        var    url = baseUrl+"hosts/"+getKey("host").id+".json?groups[]=activities";


        // var    url = baseUrl+"hosts/9.json?groups[]=activities";


        console.log(url)


        axios.get(url)
            .then(res => {

                console.log("host activities ")
                console.log(res.data.activities)

                this.setState({

                    activities: res.data.activities,
                })





                for (var i=0; i<res.data.activities.length;i++){

                    this.loadReviews(res.data.activities[i].id)

                }





            }).catch(error => {


            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }


    checkReviewExist(e){



    }



    loadReviews(id){

        var    url = baseUrl+"activity_reviews.json?groups[]=reviews&activity="+id;

        console.log(url)


        axios.get(url)
            .then(res => {



                console.log("host reviews")
                console.log(res.data)

                var reviews= this.state.reviews


                for (var i=0; i<res.data.length;i++){

                    reviews.push(res.data[i])

                }


                this.setState({

                    reviews : reviews,
                })


            }).catch(error => {

            console.log(error)


        });

    }

componentDidMount(){


        this.getActivities()
}


    render (){
        return(


            <div className="tab-pane fade show active" id="pills-myreview" role="tabpanel"
                 aria-labelledby="pills-myreview-tab">
                    <div className="row mb-4">
                        <div className="col-lg-12 review-saller-title">
                        <h4>Host’s Reviews ({this.state.reviews.length})</h4>
                        {/*<div className="rating">*/}
                            {/*<img src={Star} alt="" />*/}
                            {/*<p></p>*/}
                        {/*</div>*/}
                        </div>
                    </div>

                {this.state.reviews.map((item) =>

                    <div className="row mb-4">
                        <div className="col-lg-9">
                        <div className="review-user-img">
                            <img src={ClintImg} alt="" />
                        </div>
                        <div className="review-user-info">
                            <h5 className="review-user-name">{item.customerName}</h5>
                            <p>
                                <Moment local fromNow>
                                    {item.publishedAt}
                                </Moment>

                            </p>
                            <div className="rating reviewtab">
                                <Rating
                                    readOnly
                                    value={item.rating}
                                    icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                    emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                    name="size-large"  size="large"
                                />

                            </div>
                            {/*<div className="rating">*/}
                            {/*<p>5</p>*/}
                            {/*</div>*/}
                            <p>{item.content} </p>
                        </div>
                        </div>
                        <div className="col-lg-3">
                        <div className="review-blog-img">
                            <img className="d-block w-100" src={baseImgUrl+item.activityImage} alt="First slide" />
                            <h5>{item.activity.title}</h5>
                        </div>
                        </div>
                    </div>
                )}

            </div>

        )
    }
}

export default CustomerReviews;