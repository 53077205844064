import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import { OldSocialLogin as SocialLogin } from 'react-social-login'
import FacebookLogin from 'react-facebook-login';
import {baseUrl,baseImgUrl} from  '../Util/Constants'


class  RecoverPasswordLoginNew extends Component{

    constructor() {

        super();

        this.state = {
            validatedForm: false,
            validatedFormChangePassword: false,

            isChecked: false,
            otpSent: false,
            otpError: false,
            emailError: false,
            btnLoadingEmailCheck : false,
            btnLoadingOtpCheck : false,
            email : null,
            showSubmitRequestSuccess : false,
            emailSubmit : false,
            fields: {},
            errors: {},

        }


        this.handleChangePasswordSubmit = this.handleChangePasswordSubmit.bind(this);
        this.checkEmailSubmit = this.checkEmailSubmit.bind(this);
        // this.handleChangeChecked = this.handleChangeChecked.bind(this);
        this.facebookLogin=this.facebookLogin.bind(this)
        this.googleLogin=this.googleLogin.bind(this)
        this.toggleForgotScreen=this.toggleForgotScreen.bind(this)

        this.handleValidation=this.handleValidation.bind(this)
        this.onChange=this.onChange.bind(this)


    }


    handleValidation(){

        // alert("called")
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;



        if(!fields["password"]){
            // alert("invalid ")
            formIsValid = false;
            // errors["password"] = "Required";
        }

        if(!fields["confirmPassword"]){
            // alert("invalid ")
            formIsValid = false;
            // errors["confirmPassword"] = "Required";

        }

        if (typeof fields["password"] !== "undefined"){


            // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            // const strongRegex = new RegExp("^(?=.*[A-Z])");

            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})");


            console.log(strongRegex.test(fields["password"]))

            if (!strongRegex.test(fields["password"])){

                formIsValid = false;
                errors["password"] = "The password field must be at least 6 characters, one upper case, one numerical charactor, one special character!";

            }


            if (fields["password"].length<6){

                formIsValid = false;
                errors["password"] = "The password field must be at least 6 characters long, must contain one upper case, one numerical characters";

            }


            // console.log(strongRegex.test(fields["password"]))




        }


        this.setState({errors: errors});
        return formIsValid;
    }

    componentDidMount(){

        window.scrollTo(0, 0)
    }



    onChange(field, e){

        console.log(field)
        console.log(e)

        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    checkIfEmailExist(){


        this.setState({

            otpSent : true
        });






    }


    toggleForgotScreen(){
        window.scrollTo(0, 0)


        this.setState({

            showForgotForm : !this.state.showForgotForm
        });


    }



    facebookLogin(){


    }


    googleLogin(){


    }


    checkEmailSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,


            });
        }


        if(form.checkValidity()) {


            const data = new FormData(event.target);


            const email = data.get("email")


            this.setState({

                btnLoadingEmailCheck : true,
                emailError: false
            })



            axios.post(baseUrl+"generate_otps" ,{
                "email": email }).then(res => {


                console.log("pass change email check success ")
                console.log( res.data)



                if (res.data.code==200){


                    this.setState({

                        btnLoadingEmailCheck : false,
                        emailError: false,
                        otpSent : true,
                        email : email
                    })

                }else {



                    this.setState({

                        btnLoadingEmailCheck : false,
                        emailError: true,
                        email : null
                    })


                }



            }).catch(error => {

                console.log("submit request error found "+error)


            });



        }

    }



    handleChangePasswordSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedFormChangePassword : true
            });
        }



        if(form.checkValidity()) {



            const data = new FormData(event.target);



            const email = this.state.email
            const otp = data.get("otp")
            const newPassword = data.get("newPassword")
            // const confirmPassword = data.get("confirmPassword")


            this.setState({

                btnLoadingOtpCheck : true,

            })



            axios.post(baseUrl+"forgot_passwords" ,{

                "email": email , "newPassword": newPassword, "otp": otp }).then(res => {

                console.log("pass change email check success ")
                console.log( res.data)


                if (res.data.code==200){

                    this.setState({

                        btnLoadingOtpCheck : false,
                        otpError : false,
                        showSubmitRequestSuccess: true
                    })

                }else {

                    this.setState({

                        btnLoadingOtpCheck : false,
                        otpError : true,
                        showSubmitRequestSuccess: false

                    })


                }



            }).catch(error => {

                console.log("submit request error found "+error)


            });




        }

    }


    //  handleChangeChecked = event => {
    //     setChecked(event.target.checked);
    // };


    render (){



        return(
            <section className="login signin-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="signup-formtext-center">
                                <div className="signup-form-col text-left">
                                    <div className="res-row d-flex justify-content-between">
                                        <div className="form-col-banner">
                                        </div>

                                            <div className="form-col-left">
                                                <h3 className="form-heading">Recover Your Password </h3>

                                                {this.state.emailError &&
                                                <Alert key={"alert"} variant={"danger"}>
                                                    Invalid Email Address!
                                                </Alert>}


                                                {!this.state.showSubmitRequestSuccess &&
                                                <Form noValidate validated={this.state.validatedForm}
                                                      onSubmit={this.checkEmailSubmit.bind(this)}>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Form.Label column sm={10}>
                                                           Your Registered Email Address
                                                        </Form.Label>
                                                        <Col sm={12}>
                                                            <Form.Control required name={"email"} type="email"
                                                                          placeholder="Email"/>
                                                            <Form.Control.Feedback type="invalid">Email Id must be
                                                                vaild</Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>


                                                    <Form.Group as={Row} className={"justify-content-md-center"}
                                                                controlId="exampleForm.ControlInput1">


                                                        <Col sm={{span: 12}}>
                                                            <div className="d-flex ">

                                                                {/*<button*/}
                                                                    {/*type={"submit"}*/}
                                                                        {/*className="btn btn-no-radius">*/}
                                                                    {/**/}
                                                                    {/*Submit Email*/}
                                                                    {/**/}
                                                                    {/**/}
                                                                {/*</button>*/}


                                                                <Button  type={"submit"}
                                                                         className="btn btn-no-radius"
                                                                         type={"submit"}  >
                                                                    { this.state.btnLoadingEmailCheck && <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    /> }

                                                                    { this.state.btnLoadingEmailCheck  ? "Wait.." : "Submit"}

                                                                </Button>



                                                            </div>

                                                        </Col>
                                                    </Form.Group>


                                                </Form>
                                                }

                                                {this.state.otpSent && !this.state.showSubmitRequestSuccess &&
                                                <Form noValidate validated={this.state.validatedFormChangePassword}
                                                      onSubmit={this.handleChangePasswordSubmit.bind(this)}>

                                                    {this.state.otpError &&
                                                    <Alert key={"alert"} variant={"danger"}>
                                                        Invalid OTP entered!
                                                    </Alert>}



                                                    <Form.Group as={Row} controlId="formHorizontalPassword">
                                                        <Form.Label column sm={12}>
                                                            OTP (Sent to your email )
                                                        </Form.Label>
                                                        <Col sm={12}>
                                                            <Form.Control minLength={4} maxLength={4} required
                                                                          name={"otp"}
                                                                          type="text" placeholder="Enter OTP here.."/>

                                                        </Col>
                                                    </Form.Group>

                                                    {/*<Form.Group as={Row} controlId="formHorizontalPassword" className="">*/}
                                                        {/*<Form.Label column sm={12}>*/}
                                                            {/*OTP (Sent to your email )*/}
                                                        {/*</Form.Label>*/}
                                                        {/*<Col sm={12} className="custom-input-otp">*/}
                                                            {/*<Form.Control minLength={1} maxLength={1} required*/}
                                                            {/*name={"otp"}*/}
                                                            {/*type="text" placeholder="Enter OTP here.."/>*/}
                                                            {/*<Form.Control minLength={1} maxLength={1} required*/}
                                                            {/*name={"otp"}*/}
                                                            {/*type="text" placeholder="Enter OTP here.."/>*/}
                                                            {/*<Form.Control minLength={1} maxLength={1} required*/}
                                                            {/*name={"otp"}*/}
                                                            {/*type="text" placeholder="Enter OTP here.."/>*/}
                                                            {/*<Form.Control minLength={1} maxLength={1} required*/}
                                                            {/*name={"otp"}*/}
                                                            {/*type="text" placeholder="Enter OTP here.."/>*/}

                                                        {/*</Col>*/}
                                                    {/*</Form.Group>*/}


                                                    <Form.Group as={Row} controlId="formHorizontalPassword">
                                                        <Form.Label column sm={12}>
                                                            Set New Password
                                                        </Form.Label>
                                                        <Col sm={12}>
                                                            <Form.Control minLength={6} required name={"newPassword"}
                                                                          type="password" placeholder="New Password"/>
                                                            <span className={"invalid-feedback-phone"}>{this.state.errors["password"]}</span>

                                                        </Col>
                                                        <Col sm={12}>
                                                            <p>Use Minimum password length of 8 or more characters if permitted. Include
                                                                lowercase and uppercase alphabatic characters, numbers and symbols if permitted.
                                                            </p>
                                                        </Col>
                                                    </Form.Group>

                                                    {/*<Form.Group as={Row} controlId="formHorizontalPassword">*/}
                                                        {/*<Form.Label column sm={12}>*/}
                                                            {/*Confirm New Password*/}
                                                        {/*</Form.Label>*/}
                                                        {/*<Col sm={12}>*/}
                                                            {/*<Form.Control minLength={5} required name={"confirmPassword"}*/}
                                                                          {/*type="password" placeholder="Confirm Password"/>*/}
                                                            {/*<Form.Control.Feedback type="invalid">*/}
                                                                {/*The password field must be at least 5 characters.*/}
                                                            {/*</Form.Control.Feedback>*/}
                                                        {/*</Col>*/}
                                                    {/*</Form.Group>*/}


                                                    <Form.Group as={Row} className={"justify-content-md-center"}
                                                                controlId="exampleForm.ControlInput1">


                                                        <Col sm={{span: 12}}>
                                                            <div className="d-flex ">




                                                                <Button  type={"submit"}
                                                                         className="btn btn-no-radius"
                                                                         type={"submit"}  >
                                                                    { this.state.btnLoadingOtpCheck && <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    /> }

                                                                    { this.state.btnLoadingOtpCheck  ? "Wait.." : "Set Password"}

                                                                </Button>



                                                            </div>

                                                        </Col>
                                                    </Form.Group>



                                                </Form>


                                                }


                                                {!this.state.showSubmitRequestSuccess && <Row>
                                                    <Col sm={{span: 12, offset: 0}}>

                                                        <div className="forgot-col reset-block d-flex align-items-start">

                                                            <span className="or w-100 mb-4 text-left">OR</span>


                                                            <div className="d-flex btn-group">

                                                                <Link to={"/login"}  className="text-link">I remember Now</Link>
                                                                - <Link to="/register" className="text-link" >I need an Account</Link>

                                                            </div>

                                                        </div>


                                                    </Col>
                                                </Row>}




                                                {this.state.showSubmitRequestSuccess &&

                                                <div className="text-center" style={{marginTop:"10px"}}>
                                                    <h1 className={"mb-4"} style={{color: "#00adee"}}><i className="fas fa-check-circle"></i></h1>
                                                    <h6 className={"mb-4 mt-4"} style={{color: "#34383b", fontWeight:"500!important"}}>  Your password changed successfully
                                                    </h6>

                                                    <Link to={"/login"}  className="btn btn-no-radius mt-4">Login Now</Link>

                                                </div>
                                                }







                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}



const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed


    };
};

const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item))

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(RecoverPasswordLoginNew);


