import React, { Component } from 'react';
import SportsIMg from '../img/sports-weekends.png';
import LeftArrow from '../img/left-arrow.png';
import ArrowGreen from '../img/arrow-down-green.png';


class  SportsW extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }


    render (){
        return(
           <div>
               <section className="sportsheader-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <img src={SportsIMg} class="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sports-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                            <div className="sports-listing">
                            <ul>
                                <li>
                                <span>THE ISSUE</span>
                                Remember how we grew up? Most of us used to play some form of sport. The weekend matches, the joy of celebrating with the team. And then the unthinkable happened. We grew up, and our priorities changed. We ran out of time, and even though we wanted to get back to a sport, our friends just didn’t seem to have the time.
                                </li>
                                <li>
                                <span>THE SOLUTION</span>
                                Say hello to Happy Sports Weekend. A weekend where you can once again play your favourite sport with people who are as enthusiastic as you. Think about it. Strangers in your city getting together for a game. Finally, you can once again start playing a game, and while you’re at it, you can make some friends too!
                                </li>
                                <li>
                                <span>HOW IT WORKS</span>
                                It’s very simple. You start by nominating the game you’re most interested in. Our supremely smart AI will match you with people who want to play the same game, who have the same proﬁciency level as you. Once the data is in, our team makes the teams and lets you know. Winners of course, receive gift hampers from our sponsors.
                                </li>
                                <li>
                                <span>BE A PART OF IT</span>
                                See that form on the right side? Nominate yourself there. You can nominate yourself for multiple games, but make sure to share your preference, so we can prioritize that. Once the nominations are in, and the teams are made by us, we’ll send you an email seeking your conﬁrmation to attend the event. Typically, you’ll have a few weeks to conﬁrm your attendance. And once you do, you’ll be sent a brieﬁng package with us that’ll give you all the other details about the particular sports event.
                                </li>
                            </ul>
                            </div>
                        </div>
                            <div className="col-lg-6">
                            <div className="sports-form">
                                <h3>nomination Form</h3>
                                <form>
                                <div className="form-row">
                                    <div className="form-group col-lg-6">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label">Name</label>
                                        <div className="col-sm-10"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                                    </div>
                                    </div>
                                    <div className="form-group col-lg-6">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label">Age</label>
                                        <div className="col-sm-10 col-lg-8"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                                    </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-6">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label">Email</label>
                                        <div className="col-sm-10"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                                    </div>
                                    </div>
                                    <div className="form-group col-lg-6">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label">Gender</label>
                                        <div className="col-sm-10 col-lg-8"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                                    </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-6">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label">Phone</label>
                                        <div className="col-sm-10">
                                        <div className="input-group phone_no">
                                            <div className="input-group-prepend">
                                            <div className="input-group-text">+971</div>
                                            </div>
                                            <input type="text" className="field field_full_width" id="inlineFormInputGroup" placeholder />
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-group col-lg-6">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label">Select</label>
                                        <div className="col-sm-10 col-lg-8"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                                    </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <a href="javascripit"><span><img src={LeftArrow} alt="" /></span>GAME AND PROFICIENCY LEVEL</a>
                                </div>
                                </form>
                            </div>
                            <div className="form-drop">
                            <form>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">SPORT/S <img src={ArrowGreen} alt="" width={15} /></label>
          <div className="col-sm-4">
            <label className="col-form-label">PROFICIENCY LEVEL
              <span className="small-text">
                (YPU CAN CHOOSE MULTIPLE OPTIONS)
              </span>
            </label>
          </div>
          <div className="col-sm-4">
            <label className="col-form-label">RANK IN ORDER OF PREFERENCE</label>
          </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">CRICKET</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>                              </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">SOCCAR/FOOTBALL</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>                              </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">TABLE TENNIS</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>
          </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">LAWN TENNIS</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>
          </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>                              </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">SWIMMING</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>
          </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">E-SPORTS</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>
          </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <label className="col-sm-4 col-form-label">ADD MORE</label>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">Beginner</option>
              <option value="saab">Intermediate</option>
              <option value="mercedes">Expert</option>
            </select>                              </div>
          <div className="col-sm-4">
            <select required className="custom-select">
              <option value>select ..</option>
              <option value="volvo">1</option>
              <option value="saab">2</option>
              <option value="mercedes">3</option>
              <option value="volvo">4</option>
              <option value="saab">5</option>
              <option value="mercedes">6</option>
              <option value="volvo">7</option>
              <option value="saab">8</option>
              <option value="mercedes">9</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-3 ml-auto text-right"><a className="btn btn-yellow" href="#">Submit</a></div>
        </div>
      </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
           </div> 
        )
    }
}

export default SportsW;