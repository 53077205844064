import React, { Component,useState } from 'react';
import { Spinner,Button, Col,  Modal,Alert,  Form,  Tab, Tabs} from 'react-bootstrap'
import UploadFileBootStrap from "./UploadFileBootStrap";
import axios from "axios/index";
import {baseImgUrl, baseUrl} from "../Util/Constants";
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Cancel,Check,Error } from '@material-ui/icons';
import {saveKey,getKey} from "../LocalStorage/user";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class  EditActivityPopUp extends Component{


    constructor() {

        super();
        this.state = {

            validatedForm: false,
            isChecked: false,
            file: null,
            files: [],
            userExists : false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            cities : [],
            categories : [],
            days:[],
            timings: [],
            activityPreference:[],
            areas:[],
            formHasError: false,
            images : [],
            priceOptions : [],
            activity : null,
            activityDetail : null,
            fields:[],
            erros:[],
            addCount:[],
            count:1,
            addCountIncluded:[],
            countIncluded:0,
            addCountNotIncluded:[],
            countNotIncluded:0,
            loading:false,
            error:false,
            success: false,



        //    actiivity states


            title: null,
            isOnline: false,
            description: null,

            address: null,
            city: null,
            area: null,
            buildingNo: null,
            poBoxNo: null,
            street: null,
            longitude:null,
            latitude:null,
            validityPeriod: null,
            priceOption: null,

            activityPreferenceState:null,
            category: [],

            included: null,
            notIncluded: null,
            dayState: [],
            timingsState: [],
            duration:null,
            whatsNext:null,
            workingHours:null,
            howToRedeeem:null,
            additionalInformation:null,
            cancellationPolicy:null,
            highlight:null,
            thingsToKeepInMind:null,
            fridayWorkingHours:null,
            thursdayWorkingHours:null,
            saturdayWorkingHours:null,
            otherInformation: null,
            termsAndConditions:null


        }

        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.activitySubmit = this.activitySubmit.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.fetchCities=this.fetchCities.bind(this)
        this.fetchArea=this.fetchArea.bind(this)
        this.hideActivityPopUp=this.hideActivityPopUp.bind(this)
        this.loadActivity=this.loadActivity.bind(this)
        this.fetchDetail=this.fetchDetail.bind(this)
        this.addCount=this.addCount.bind(this)
        this.subtractCount=this.subtractCount.bind(this)

        this.addCountIncluded=this.addCountIncluded.bind(this)
        this.subtractCountIncluded=this.subtractCountIncluded.bind(this)

        this.addCountNotIncluded=this.addCountNotIncluded.bind(this)
        this.subtractCountNotIncluded=this.subtractCountNotIncluded.bind(this)
        this.checkListable=this.checkListable.bind(this)
        this.loadValues=this.loadValues.bind(this)
        this.loadImages=this.loadImages.bind(this)


    }


    loadImages() {


        let images = []


        let currentFiles = []



        for (let k=0;k<this.state.activity.images.length;k++){


            var fileItem={
                status:1,
                id:this.state.activity.images[k].id,
                imgUrl :this.state.activity.images[k].image,
                file:{
                    name: this.state.activity.images[k].id
                }
            }
            // fileItem.status = 1  //success
            // fileItem.id = this.state.item.artifacts[k]._key
            // fileItem.url = this.state.item.artifacts[k].blob_url

            images.push(this.state.activity.images[k].id)

            currentFiles.push(fileItem)



        }





        this.setState({

            files: currentFiles,
        })

        this.setState({

            images: images,
        })





    }


    loadValues(){



        this.fetchArea(this.state.activity.address&&this.state.activity.address.city.id)
        this.setState({





            title: this.state.activity.title,
            isOnline: this.state.activity.isOnline,
            description: this.state.activity.description,
            address: this.state.activity.address&&this.state.activity.address.address,
            city: this.state.activity.address&&this.state.activity.address.city.id,
            area: this.state.activity.address&&this.state.activity.address.area.id,




            buildingNo: this.state.activity.address&&this.state.activity.address.buildingNo,
            poBoxNo:this.state.activity.address&& this.state.activity.address.poBoxNo,
            street: this.state.activity.address&&this.state.activity.address.street,
            longitude:this.state.activity.address&&this.state.activity.address.longitude,
            latitude:this.state.activity.address&&this.state.activity.address.latitude,



            validityPeriod: this.state.activity.validityPeriod,
            priceOption: this.state.activity.priceOption,





            activityPreferenceState:this.state.activity.activityPreference&&this.state.activity.activityPreference.id,


            category: this.state.activityDetail.category,

            included: this.state.activityDetail.included,
            notIncluded: this.state.activityDetail.notIncluded,
            dayState: this.state.activityDetail.day,
            timingsState: this.state.activityDetail.timings,



            duration:this.state.activityDetail.activityTopInformation&&this.state.activityDetail.activityTopInformation.duration,
            whatsNext:this.state.activityDetail.activityTopInformation&&this.state.activityDetail.activityTopInformation.whatsNext,
            workingHours:this.state.activityDetail.activityTopInformation&&this.state.activityDetail.activityTopInformation.workingHours,
            howToRedeeem:this.state.activityDetail.activityTopInformation&&this.state.activityDetail.activityTopInformation.howToRedeeem,

            additionalInformation:this.state.activityDetail.activityMiddleInfo&&this.state.activityDetail.activityMiddleInfo.additionalInformation,
            cancellationPolicy:this.state.activityDetail.activityMiddleInfo&&this.state.activityDetail.activityMiddleInfo.cancellationPolicy,
            highlight:this.state.activityDetail.activityMiddleInfo&&this.state.activityDetail.activityMiddleInfo.highlight,
            thingsToKeepInMind:this.state.activityDetail.activityMiddleInfo&&this.state.activityDetail.activityMiddleInfo.thingsToKeepInMind,


            fridayWorkingHours:this.state.activityDetail.activityBottomInfo&&this.state.activityDetail.activityBottomInfo.fridayWorkingHours,
            thursdayWorkingHours:this.state.activityDetail.activityBottomInfo&&this.state.activityDetail.activityBottomInfo.thursdayWorkingHours,
            saturdayWorkingHours:this.state.activityDetail.activityBottomInfo&&this.state.activityDetail.activityBottomInfo.saturdayWorkingHours,
            otherInformation:this.state.activityDetail.activityBottomInfo&&this.state.activityDetail.activityBottomInfo.otherInformation,
            termsAndConditions:this.state.activityDetail.activityBottomInfo&&this.state.activityDetail.activityBottomInfo.termsAndConditions


        })



        this.loadImages()

    }

    handleSubmit = event => {

        this.setState({

            loading:true,
            error:false,
        })

        event.preventDefault();

        const form = event.currentTarget;



        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,
                formHasError : true
            });
        }


        if(true) {

            this.setState({
                btnLoading: true,
                formHasError : false
            })

            const data = new FormData(event.target);


            console.log("host form data")
            console.log(data)



            this.setState({

                confirmPasswordError: false
            })

            this.setState({

                passwordError: false
            })






            var priceOptopnArray=[]


            for (let i=0;i<this.state.addCount.length;i++){



                priceOptopnArray.push({description:data.get(`description[${i}]`),
                    price:data.get(`price[${i}]`),
                    discountedPrice:data.get(`discountedPrice[${i}]`)
                })

            }



            var includedArray=[]


            for (let i=0;i<this.state.addCountIncluded.length;i++){

                includedArray.push(data.get(`included[${i}]`))

            }



            var notIncludedArray=[]


            for (let i=0;i<this.state.addCountNotIncluded.length;i++){

                notIncludedArray.push(data.get(`notIncluded[${i}]`))

            }



            var catArray=[]


            for (let i=0;i<this.state.categories.length;i++){


                if (data.get(`category[${i}]`))
                    catArray.push(parseInt(data.get(`category[${i}]`)));

            }



            var timingsArray=[]


            for (let i=0;i<this.state.timings.length;i++){


                if (data.get(`timings[${i}]`))
                    timingsArray.push(parseInt(data.get(`timings[${i}]`)));

            }



            var daysArray=[]


            for (let i=0;i<this.state.days.length;i++){


                if (data.get(`day[${i}]`))
                    daysArray.push(parseInt(data.get(`day[${i}]`)));

            }

            var activityData = {

                "activityId": this.state.activity.id,
                "title": data.get("title"),
                "isOnline": this.state.isOnline,
                "description": data.get("description"),
                "address": data.get("address"),
                "city": parseInt(data.get("city")),
                "area": parseInt(data.get("area")),
                "buildingNo": data.get("buildingNo"),
                "poBoxNo": data.get("poBoxNo"),
                "street": data.get("street"),
                "longitude":data.get("longitude"),
                "latitude":data.get("latitude"),
                "activityPreference":parseInt(data.get("activityPreference")),
                "day": daysArray,
                "timings": timingsArray,
                "category": catArray,
                "images": this.state.images,
                "validityPeriod": parseInt(data.get("validityPeriod")),
                // "expiryDate": data.get("expiryDate"),
                "included": includedArray,
                "notIncluded": notIncludedArray,
                "priceOption": priceOptopnArray,

                "duration":data.get("duration"),
                "whatsNext":data.get("whatsNext"),
                "workingHours":data.get("workingHours"),
                "howToRedeeem":data.get("howToRedeeem"),
                "additionalInformation":data.get("additionalInformation"),
                "cancellationPolicy":data.get("cancellationPolicy"),
                "highlight":data.get("highlight"),
                "thingsToKeepInMind":data.get("thingsToKeepInMind"),
                "fridayWorkingHours":data.get("fridayWorkingHours"),
                "thursdayWorkingHours":data.get("thursdayWorkingHours"),
                "saturdayWorkingHours":data.get("saturdayWorkingHours"),
                "otherInformation":data.get("otherInformation"),
                "termsAndConditions":data.get("termsAndConditions")

            };


            console.log(activityData)


            event.preventDefault();



            var url = baseUrl + "activity_edits.json";

            axios.post(url, activityData )
                .then(res => {

                    this.setState({

                        loading : false,
                        error:false,
                        success:true

                    })


                }).catch(error => {

                console.log("activity submit error")
                console.log(error)


                this.setState({

                    loading : false,
                    error:true,
                    success:false
                })

            });

        }
    }



    addCountNotIncluded(){


        var array = this.state.addCountNotIncluded

        array.push(this.state.countNotIncluded+1)

        this.setState({

            addCountNotIncluded:array,
            countNotIncluded:this.state.countNotIncluded +1

        })
    }
    subtractCountNotIncluded() {

        if (this.state.countNotIncluded > 1){


            var array = this.state.addCountNotIncluded

            array.pop()

            if (this.state.countNotIncluded > 1)
                this.setState({

                    addCountNotIncluded:array,
                    countNotIncluded:this.state.countNotIncluded -1

                })


        }
    }

    addCountIncluded(){


        var array = this.state.addCountIncluded

        array.push(this.state.countIncluded+1)

        this.setState({

            addCountIncluded:array,
            countIncluded:this.state.countIncluded +1

        })
    }

    subtractCountIncluded() {

        if (this.state.countIncluded > 1){


            var array = this.state.addCountIncluded

            array.pop()

            if (this.state.countIncluded > 1)
                this.setState({

                    addCountIncluded:array,
                    countIncluded:this.state.countIncluded -1

                })


        }
    }

    subtractCount() {

        if (this.state.count > 1){


            var array = this.state.addCount

            array.pop()

            if (this.state.count > 1)
                this.setState({

                    addCount:array,
                    count:this.state.count -1

                })


        }
    }

    addCount(){


        var array = this.state.addCount

        array.push(this.state.count+1)

        this.setState({

            addCount:array,
            count:this.state.count +1

        })
    }

    checkListable(){


        this.setState({

            isOnline:!this.state.isOnline
        })
    }


    handleChangeFile(event) {




        let files = this.state.files
        // var filesUrl = this.state.filesUrl


        let newFiles = []

        for (var i = 0; i < event.target.files.length; i++) {

            files.push({file:event.target.files[i],status:0,id:null})
            newFiles.push({file:event.target.files[i],status:0,id:null})

        }


        //
        //

        //
        //


        this.setState({
            files: files,
        })


        this.uploadImage(newFiles)



    }


    handleCancel(e) {


        e.preventDefault()

        var index = e.currentTarget.dataset.index;
        var name = e.currentTarget.dataset.name;
        var url = e.currentTarget.dataset.url;





        console.log("before")
        console.log(index)


        console.log("files",this.state.files)
        console.log("images",this.state.images)



        var files= this.state.files
         files = files.filter((item) => item.id !== parseInt(index))
        // var filesUrl = this.state.filesUrl.filter((item) => item.url !== url)





        // var images = this.state.images.filter((item)=> item !==index )

        // var images = this.state.images

        // images.splice(index,1)

        var images = []
        for (let k =0;k<files.length;k++){

            if (files[k].id){

                images.push(files[k].id)
            }

        }


        this.setState({
            images: images
        })




        this.setState({

            files: files,
        })


        console.log("after")
        console.log("images",images)
        console.log("files",files)

    }

    getBase64(file) {


        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    uploadImage(files) {


        if (files.length > 0) {


            for (let i = 0; i < files.length; i++) {



                let imgFile = files[i]




                this.getBase64(imgFile.file).then(

                    data => {


                        axios.post(baseUrl + "upload_images",
                            {
                                imageId:232,  //dummy value
                                image: btoa(data)
                            },
                            {
                                // headers: {
                                //     "Authorization"
                                //         :
                                //     "Bearer " + this.props.userDetail.token
                                // }
                            }

                        ).then(res => {

                            //


                            let images = this.state.images

                            images.push(res.data.image.id)

                            this.setState({

                                images: images,
                            })


                            console.log(images)

                            let currentFiles = this.state.files

                            for (let k=0;k<currentFiles.length;k++){

                                if (currentFiles[k].file.name === imgFile.file.name){

                                    currentFiles[k].status = 1  //success
                                    currentFiles[k].id = res.data.image.id  //success

                                }

                            }


                            this.setState({

                                files: currentFiles,
                            })





                        }).catch(error => {



                            //
                            console.log("image upload error")
                            console.log(error)

                            let currentFiles = this.state.files

                            for (let k=0;k<currentFiles.length;k++){

                                if (currentFiles[k].file.name === imgFile.file.name){

                                    currentFiles[k].status = 2  //failed


                                }

                            }


                            this.setState({

                                files: currentFiles,
                            })

                        })

                    }
                );

            }


        }


    }


    handleChange(field, e) {

        let fields = this.state.fields;

        fields[field] = e.target.value;



        if (field == "title"){


            this.setState({
                title: e.target.value
            })
        }


        if (field == "description"){

            this.setState({
                description: e.target.value
            })
        }

    }

    loadActivity(){


        var url = baseUrl+"activities.json?groups[]=activities&slug="+this.props.editActiviyId

        console.log("detail slug")
        console.log(url)

        axios.get(url)
            .then(res => {
                const persons = res.data;
                console.log(res.data)

                this.setState({

                    activity : persons[0],
                    images : persons[0].images,
                    priceOptions : persons[0].priceOption,
                    title:persons[0].title,
                    description:persons[0].description

                });


                this.fetchDetail(res.data[0].id)

            })

    }


    fetchDetail(id){


        axios.get(baseUrl+"activities/"+id+".json?groups[]=activity-detail")
            .then(res => {
                const persons = res.data;
                // console.log(res.data)
                this.setState({

                    activityDetail: persons

                });


                this.loadValues()
            })
    }

    hideActivityPopUp(e){



        this.props.activityPopUpShow({show:false,activityId:null})


    }




    fetchValues() {

        axios.get(baseUrl+"categories.json?groups[]=list&level=1&isActive=1" )
            .then(res => {
                const persons = res.data;

                this.setState({

                    categories: res.data,

                });


            })


        axios.get(baseUrl+"days.json?groups[]=activity-detail")
            .then(res => {
                this.setState({

                    days: res.data,

                });


            })


        axios.get(baseUrl+"activity_preferences.json?groups[]=detail")
            .then(res => {
                this.setState({

                    activityPreference: res.data,

                });


            })


        axios.get(baseUrl+"timings.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    timings: res.data,

                });


            })

    }
    fetchCities() {

        axios.get(baseUrl + "cities.json?groups[]=detail&isActive=1")
            .then(res => {

                this.setState({

                    cities: res.data,

                });

            })
    }

    fetchArea(e) {


        var cityId;

        if (e.target&&e.target.value){

            cityId = e.target.value
        } else{

            cityId= e
        }



        var url = baseUrl + "areas.json?groups[]=detail&city="+cityId

        console.log(url)

        axios.get(url)
            .then(res => {

                console.log("areas")
                console.log(res.data)

                this.setState({

                    areas: res.data,

                });


            })


    }


    activitySubmit() {




            this.setState({
                btnLoading: true,
                formHasError : false
            })

            // const data = new FormData(event.target);
            //
            //
            // console.log("host form data")
            // console.log(data)
            //


            // this.setState({
            //
            //     confirmPasswordError: false
            // })
            //
            // this.setState({
            //
            //     passwordError: false
            // })
            //
            //


            var activityData = {

                "activityId": this.state.activity.id,
                "host": 18,
                "title": this.state.title,
                "description": this.state.description,


            };


            console.log(activityData)




            var url = baseUrl + "activity_edits.json";

            axios.post(url, activityData )
                .then(res => {

                    this.setState({

                        loading : false
                    })


                    this.hideActivityPopUp()


                }).catch(error => {

                console.log("activity submit error")
                console.log(error)


                this.setState({

                    loading : false
                })

            });


    }




    componentDidMount(){


        this.fetchValues()
        this.fetchCities()
        this.loadActivity()


    }
    
    
    
    render (){
        return(


            <Modal
                // size="lg"

                dialogClassName="modal-80w"
                show={this.props.activityPopUp}

                     onHide={this.hideActivityPopUp}
                     className={"custom-modal-popup edit-activity-popup-form"}
            >
                <button onClick={this.hideActivityPopUp} className="close m-2" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>


                {this.state.activity&&this.state.activityDetail && <div className="container">
                    <div className="row m-1">


                        {/*new code stars here*/}


                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active add-activity-block" id="pills-personal"
                                 role="tabpanel"
                                 aria-labelledby="pills-personal-tab">
                                <Form noValidate validated={this.state.validatedForm} onSubmit={this.handleSubmit}>
                                    <div className="form-block mt-4">
                                        <Form.Row>


                                            <div className="col-12 mt-4">

                                                <Form.Label>Upload Images</Form.Label>


                                                <div className="row camera-grids  mb-5 no-gutters   ">

                                                    <div className="col-12  text-left ">


                                                        <div className={"file-uploader-box"}>

                                                            <div className={"file-uploader-thumbnail-container"}>

                                                                <div className={"file-uploader-thumbnail-container"}>

                                                                    <label className={"label-file-input"}
                                                                           htmlFor="fileInput">
                                                                        <AddPhotoIcon style={{
                                                                            fontSize: 32,
                                                                            color: "#a8a8a8",
                                                                            margin: "auto"
                                                                        }}/>
                                                                    </label>
                                                                    <input accept={"image/jpeg,image/jpg"}
                                                                           style={{display: "none"}} id="fileInput"
                                                                           className={""} multiple type="file"
                                                                           onChange={this.handleChangeFile.bind(this)}/>


                                                                </div>


                                                                {this.state.files && this.state.files.map((item, index) =>

                                                                    <div
                                                                        className={"file-uploader-thumbnail-container"}>

                                                                        <div data-index={index}
                                                                             data-url={item.imgUrl?baseImgUrl+item.imgUrl:URL.createObjectURL(item.file)}

                                                                             className={"file-uploader-thumbnail"}
                                                                             style={{ backgroundImage: "url(" + (item.imgUrl?baseImgUrl+item.imgUrl:URL.createObjectURL(item.file)) + ")" }}

                                                                            // style={{ backgroundImage: "url(" + item.imgUrl?item.imgUrl:""+")"}}

                                                                        >

                                                                            {item.status === 0 && <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                style={{ color: "#cccccc" }}
                                                                                className={"center-spinner"}

                                                                            />}

                                                                            {item.status === 1 &&
                                                                            <Check style={{ color: "#cccccc" }}
                                                                                   className={" file-upload-img-thumbnail-check"}/>}
                                                                            {item.status === 2 && <span
                                                                                className={"file-upload-img-thumbnail-error"}><Error
                                                                                style={{ color: "red" }}
                                                                                className={" "}/>
                                                                            <p>Error!</p>
                                                                            </span>}
                                                                            <Cancel data-name={item.file&&item.file["name"]?item.file["name"]:""}
                                                                                    data-index={item.id}
                                                                                    onClick={this.handleCancel.bind(this)}
                                                                                    className={"file-upload-img-thumbnail-cancel"}/>

                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>


                                            </div>


                                        </Form.Row>
                                        <Form.Row>


                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "title")}
                                                              value={this.state.title} required type="text"
                                                              placeholder="" defaultValue="" name={"title"}/>

                                            </Form.Group>

                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group className={"ml-1"} as={Col} md="12"
                                                        controlId="validationCustom01">


                                                <FormControlLabel
                                                    control={

                                                        <Checkbox
                                                            checked={this.state.isOnline}
                                                            onChange={this.checkListable}
                                                            name="isOnline"

                                                            style={{color: "#00aeef"}}
                                                        />
                                                    }
                                                    label="Tick box if experience you are creating is online"
                                                />

                                                {/*<Col sm={{ span: 6 }}>*/}
                                                {/*<Form.Check label="Check If Experience  is Online ?"  />*/}
                                                {/*</Col>*/}
                                            </Form.Group>
                                        </Form.Row>

                                    </div>
                                    <div className="form-block mt-4 mb-5">
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <h4>Enter Price Options</h4>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <div className="col-md-12 p-0">
                                                <div className="table-responsive">

                                                    <>
                                                        <table className="table mt-3 priceoption-table">

                                                            <tr>
                                                                <th>
                                                                    <Form.Label>Description</Form.Label>
                                                                </th>
                                                                <th>
                                                                    <Form.Label>Price</Form.Label>
                                                                </th>
                                                                <th>
                                                                    <Form.Label>Discounted Price</Form.Label>
                                                                </th>

                                                                <th>
                                                                    <Form.Label>Delete</Form.Label>
                                                                </th>
                                                            </tr>
                                                            {this.state.addCount.map((item, index) =>

                                                                <tr>
                                                                    <td>
                                                                        <Form.Control required type="text"
                                                                                      placeholder="" defaultValue=""
                                                                                      name={`description[${index}]`}/>

                                                                    </td>

                                                                    <td>
                                                                        <Form.Control required type="number"
                                                                                      placeholder="" defaultValue=""
                                                                                      name={`price[${index}]`}/>

                                                                    </td>
                                                                    <td>
                                                                        <Form.Control required type="number"
                                                                                      placeholder="" defaultValue=""
                                                                                      name={`discountedPrice[${index}]`}/>

                                                                    </td>

                                                                    <td>
                                                                        {index > 0 &&
                                                                        <div onClick={this.subtractCount}
                                                                             className="add-delete"><i
                                                                            className="fas fa-trash-alt"></i></div>
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )}


                                                            <tr>
                                                                <td>

                                                                    <button onClick={this.addCount} type="button"
                                                                            className="add_tag_link add-table"><i
                                                                        className="fas fa-plus"></i> ADD
                                                                        NEW
                                                                    </button>
                                                                </td>
                                                            </tr>


                                                        </table>
                                                    </>


                                                </div>
                                            </div>
                                        </Form.Row>


                                        <Form.Row className="form-inlines">
                                            <Form.Group as={Col} md="12" className="mb-0"
                                                        controlId="validationCustom01">
                                                <Form.Label>Select Days</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">

                                                <div className="btn-days btn-group-toggle" data-toggle="buttons">
                                                    {this.state.days.map((item, index) =>


                                                        <SelectionItem selected={this.state.dayState.filter((selctedItem)=> item.id===selctedItem.id).length>0?true:false} name={`day[${index}]`} item={item}/>
                                                    )}
                                                </div>


                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="form-inlines">
                                            <Form.Group as={Col} md="12" className="mb-0"
                                                        controlId="validationCustom01">
                                                <Form.Label>Choose Timings</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">


                                                <div className="btn-days btn-group-toggle" data-toggle="buttons">
                                                    {this.state.timings.map((item, index) =>

                                                        <SelectionItem selected={this.state.timingsState.filter((selctedItem)=> item.id===selctedItem.id).length>0?true:false} name={`timings[${index}]`} item={item}/>
                                                    )}
                                                </div>


                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className="form-inlines">
                                            <Form.Group as={Col} md="12" className="mb-0"
                                                        controlId="validationCustom01">
                                                <Form.Label>Choose one category, best ﬁt for your listing</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">

                                                <div className="btn-days btn-group-toggle" data-toggle="buttons">
                                                    {this.state.categories.map((item, index) =>

                                                        <SelectionItem  selected={this.state.category.filter((selctedItem)=> item.id===selctedItem.id).length>0?true:false} name={`category[${index}]`} item={item}/>
                                                    )}
                                                </div>


                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} md="5" controlId="validationCustom01">
                                                <Form.Label>Enter no of weekends(Validity period from date of
                                                    purchase)</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "title")}
                                                              value={this.state.validityPeriod} type="number"
                                                              placeholder="" name={"validityPeriod"}/>
                                                <p className="small-text">*Leave it blank if not applicable.</p>

                                            </Form.Group>
                                            {false &&
                                            <>
                                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label style={{textAlign: "center"}}>OR</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="5" controlId="validationCustom01">
                                                    <Form.Label>Expiry Date(in case of fixed event date)</Form.Label>
                                                    <Form.Control min={new Date()} type="date" placeholder=""
                                                                  defaultValue="" name={"expiryDate"}/>

                                                </Form.Group>

                                            </>}
                                        </Form.Row>

                                    </div>
                                    <div className="form-block inner-activity mt-5">
                                        <Tabs defaultActiveKey="hours" id="uncontrolled-tab-example">


                                            <Tab eventKey="hours" title="Working Hours">


                                                <Form.Row>

                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>Duration</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "duration")}
                                                            value={this.state.duration} required type="text"
                                                            placeholder="" defaultValue="" name={"duration"}/>

                                                    </Form.Group>

                                                </Form.Row>


                                                <Form.Row>

                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>What's Next</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "whatsNext")}
                                                            value={this.state.whatsNext} required type="text"
                                                            placeholder="" defaultValue="" name={"whatsNext"}/>

                                                    </Form.Group>

                                                </Form.Row>


                                                <Form.Row>

                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>Working Hours</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "workingHours")}
                                                            value={this.state.workingHours}
                                                            required type="text" placeholder=""
                                                                      defaultValue="" name={"workingHours"}/>

                                                    </Form.Group>

                                                </Form.Row>

                                                <Form.Row>

                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>How To Redeem</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "howToRedeeem")}
                                                            value={this.state.howToRedeeem}

                                                            required type="text" placeholder=""
                                                                      defaultValue="" name={"howToRedeeem"}/>

                                                    </Form.Group>

                                                </Form.Row>


                                                <Form.Row>

                                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                        <Form.Label>Thursday Working Hours</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "thursdayWorkingHours")}
                                                            value={this.state.thursdayWorkingHours} required type="text"
                                                            placeholder="" defaultValue=""
                                                            name={"thursdayWorkingHours"}/>

                                                    </Form.Group>

                                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                        <Form.Label>Friday Working Hours</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "thursdayWorkingHours")}
                                                            value={this.state.thursdayWorkingHours} required type="text"
                                                            placeholder="" defaultValue="" name={"fridayWorkingHours"}/>

                                                    </Form.Group>

                                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                        <Form.Label>Saturday Working Hours</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "saturdayWorkingHours")}
                                                            value={this.state.saturdayWorkingHours} required type="text"
                                                            placeholder="" defaultValue=""
                                                            name={"saturdayWorkingHours"}/>

                                                    </Form.Group>

                                                </Form.Row>


                                            </Tab>
                                            <Tab eventKey="included" title="What's Included">

                                                <Form.Row>
                                                    <Form.Group as={Col} md="11" controlId="validationCustom01">

                                                        <Form.Label>What's Included*</Form.Label>

                                                    </Form.Group>
                                                </Form.Row>


                                                {this.state.addCountIncluded.map((item, index) =>


                                                    <Form.Row>


                                                        <>


                                                            <Form.Group as={Col} md="11" controlId="validationCustom01">


                                                                <Form.Control row="2" type="text"
                                                                              name={`included[${index}]`} placeholder=""
                                                                              defaultValue=""/>

                                                            </Form.Group>
                                                            <Form.Group as={Col} md="1" controlId="validationCustom01">


                                                                {index > 0 && <div onClick={this.subtractCountIncluded}
                                                                                   className="add-delete"><i
                                                                    className="fas fa-trash-alt"></i></div>}

                                                            </Form.Group>
                                                        </>


                                                    </Form.Row>
                                                )}

                                                <Form.Row>
                                                    <Form.Group as={Col} md="11" controlId="validationCustom01">


                                                        <button onClick={this.addCountIncluded} type="button"
                                                                className="add_tag_link add-table"><i
                                                            className="fas fa-plus"></i> ADD
                                                            NEW
                                                        </button>
                                                    </Form.Group>
                                                </Form.Row>


                                            </Tab>
                                            <Tab eventKey="notIncluded" title="What's Not Included">

                                                <Form.Row>
                                                    <Form.Group as={Col} md="11" controlId="validationCustom01">

                                                        <Form.Label>What's Not Included*</Form.Label>

                                                    </Form.Group>
                                                </Form.Row>


                                                {this.state.addCountNotIncluded.map((item, index) =>


                                                    <Form.Row>

                                                        <>

                                                            <Form.Group as={Col} md="11" controlId="validationCustom01">


                                                                <Form.Control row="2" type="text"
                                                                              name={`notIncluded[${index}]`}
                                                                              placeholder="" defaultValue=""/>

                                                            </Form.Group>
                                                            <Form.Group as={Col} md="1" controlId="validationCustom01">


                                                                {index > 0 &&
                                                                <div onClick={this.subtractCountNotIncluded}
                                                                     className="add-delete"><i
                                                                    className="fas fa-trash-alt"></i></div>}

                                                            </Form.Group>
                                                        </>


                                                    </Form.Row>
                                                )}

                                                <Form.Row>
                                                    <Form.Group as={Col} md="11" controlId="validationCustom01">
                                                        <button onClick={this.addCountNotIncluded} type="button"
                                                                className="add_tag_link add-table"><i
                                                            className="fas fa-plus"></i>
                                                            ADD NEW
                                                        </button>
                                                    </Form.Group>
                                                </Form.Row>

                                            </Tab>

                                            <Tab eventKey="know" title="Know Before You Go">

                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                        <Form.Label>Description*</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "description")}
                                                            value={this.state.description} as="textarea" row="4"
                                                            required type="file" placeholder="" defaultValue=""
                                                            name={"description"}/>

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                        <Form.Label>Things To Keep In Mind*</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "thingsToKeepInMind")}
                                                            value={this.state.thingsToKeepInMind} as="textarea" row="4"
                                                            type="file" placeholder="" defaultValue=""
                                                            name={"thingsToKeepInMind"}/>

                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                        <Form.Label>Additional Information</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "additionalInformation")}
                                                            value={this.state.additionalInformation} as="textarea"
                                                            row="4" type="file" placeholder="" defaultValue=""
                                                            name={"additionalInformation"}/>

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                        <Form.Label>Other Information*</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "otherInformation")}
                                                            value={this.state.otherInformation} as="textarea" row="4"
                                                            name={"otherInformation"} type="file" placeholder=""
                                                            defaultValue=""/>

                                                    </Form.Group>
                                                </Form.Row>

                                            </Tab>
                                            <Tab eventKey="highlight" title="What’s Special">
                                                <Form.Row>
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>Whats The Highlight</Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "highlight")}
                                                            value={this.state.highlight} as="textarea" row="4"
                                                            name={"highlight"} type="text" placeholder=""
                                                            defaultValue=""/>

                                                    </Form.Group>
                                                </Form.Row>
                                            </Tab>
                                            <Tab eventKey="cancellation" title="Cancellation Policy">
                                                <Form.Row>
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>Add Your Cancellation Policy </Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "cancellationPolicy")}
                                                            value={this.state.cancellationPolicy}
                                                            as="textarea" row="4" name={"cancellationPolicy"}
                                                                      type="text" placeholder="" defaultValue=""/>

                                                    </Form.Group>
                                                </Form.Row>
                                            </Tab>

                                            <Tab eventKey="terms" title="T & C">
                                                <Form.Row>
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>Add Your Terms & Conditions </Form.Label>
                                                        <Form.Control
                                                            onChange={this.handleChange.bind(this, "termsAndConditions")}
                                                            value={this.state.termsAndConditions} as="textarea" row="4"
                                                            name={"termsAndConditions"} type="text" placeholder=""
                                                            defaultValue=""/>

                                                    </Form.Group>
                                                </Form.Row>
                                            </Tab>
                                        </Tabs>
                                    </div>

                                    <div className="form-block mt-4">
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <h4>Google Map</h4>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Longitude</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "longitude")}
                                                              value={this.state.longitude} name={"longitude"}
                                                              type="text" placeholder="" defaultValue=""/>

                                                <Form.Label>Latitude</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "latitude")}
                                                              value={this.state.latitude} name={"latitude"} type="text"
                                                              placeholder="" defaultValue=""/>

                                            </Form.Group>
                                            {/*<Form.Group as={Col} md="6" controlId="validationCustom01">*/}
                                            {/*<Form.Group as={Col} md="6" controlId="validationCustom01">*/}
                                            {/*<Form.Label>Map Preview:</Form.Label>*/}
                                            {/*<Form.Control as="textarea" row="4"   type="file" placeholder="" defaultValue="" />*/}

                                            {/*</Form.Group>*/}
                                        </Form.Row>


                                        {this.state.activityPreference.length > 0 && <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Form.Label>Activity preference</Form.Label>
                                                <div className="row pl-3">


                                                    <Form.Check inline
                                                                required
                                                                name={"activityPreference"}
                                                                value={this.state.activityPreference[0].id}

                                                                custom
                                                                type={"radio"}
                                                                id={"yes"}
                                                                label="Stay At Home"
                                                                checked={this.state.activityPreferenceState == this.state.activityPreference[0].id ? true : false}
                                                    />
                                                    <Form.Check inline
                                                                required
                                                                name={"activityPreference"}
                                                                value={this.state.activityPreference[1].id}
                                                                custom
                                                                checked={this.state.activityPreferenceState == this.state.activityPreference[1].id ? true : false}

                                                                type={"radio"}
                                                                id={"no"}
                                                                label="Out Of Home"
                                                    />
                                                </div>

                                            </Form.Group>
                                        </Form.Row>}
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "address")}
                                                              value={this.state.address}

                                                              type="text"
                                                              placeholder=""
                                                              defaultValue=""
                                                              name={"address"}
                                                />

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Street Name</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "street")}
                                                              value={this.state.street}

                                                              type="text"
                                                              placeholder=""
                                                              defaultValue=""
                                                              name={"street"}
                                                />

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Building </Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "buildingNo")}
                                                              value={this.state.buildingNo}

                                                              type="text"
                                                              placeholder=""
                                                              defaultValue=""
                                                              name={"buildingNo"}
                                                />

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>PO Box No.</Form.Label>
                                                <Form.Control onChange={this.handleChange.bind(this, "poBoxNo")}
                                                              value={this.state.poBoxNo}

                                                              type="text"
                                                              placeholder=""
                                                              defaultValue=""
                                                              name={"poBoxNo"}
                                                />

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control as="select" className="" onChange={this.fetchArea}
                                                              name={"city"}>
                                                    <option value={""}>Choose...</option>
                                                    {this.state.cities.map((item) =>
                                                        <option  selected={this.state.city==item.id?true:false} value={item.id}>{item.name}</option>
                                                    )
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Area</Form.Label>
                                                <Form.Control as="select" className="" name={"area"}>
                                                    <option value={""}>Choose...</option>
                                                    {this.state.areas.map((item) =>
                                                        <option selected={this.state.area==item.id?true:false} value={item.id}>{item.name}</option>
                                                    )
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>

                                    </div>

                                    <div className="form-block mt-3">
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">

                                                {this.state.error && <Alert className={"mt-2"} variant={"danger"}>
                                                    Information provided is not valid or missing!
                                                </Alert>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} md="12">

                                                <Button className="btn btn-red" type="submit">

                                                    {this.state.loading && <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />}

                                                    {this.state.loading ? "Wait.." : "Save"}
                                                </Button>


                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
                }


            </Modal>

        )
    }
}




function SelectionItem(props) {

    const [state, setState] = useState(props.selected?true:false);

    function toggle() {
        setState(!state);
    }



    // if (props.selected){
    //
    //     toggle()
    // }




    return(

        <div  onClick={toggle} className={state? "btn btn-secondary active mt-2":"btn btn-secondary mt-2"}>
            <Form.Control name={props.name} id={props.name+"-"+props.item.id} checked={state}
                          value={props.item.id} type="checkbox"    autoComplete="off" /> {props.item.name}
        </div>
    )
}





function  PriceOptions(props){

        return(

            <>
                <table className="table mt-3 priceoption-table">
                    <tr>
                        <th>
                            <Form.Label>Description</Form.Label>
                        </th>
                        <th>
                            <Form.Label>Price</Form.Label>
                        </th>
                        <th>
                            <Form.Label>Discounted Price</Form.Label>
                        </th>
                        {/*<th>*/}
                            {/*<Form.Label>Budget Range</Form.Label>*/}
                        {/*</th>*/}
                        <th>
                            <Form.Label>Delete</Form.Label>
                        </th>
                    </tr>


                    {props.activity.priceOption.map((item)=>
                    <tr>
                        <td>
                            <Form.Control value={item.description}required type="text" placeholder="" defaultValue="" name={"description"} />

                        </td>

                        <td>
                            <Form.Control value={item.price} required type="number" placeholder="" defaultValue="" name={"price"} />

                        </td>
                        <td>
                            <Form.Control value={item.discountedPrice} required type="number" placeholder="" defaultValue="" name={"discountedPrice"} />

                        </td>
                        {/*<td>*/}
                            {/*<Form.Control as="select" className="custom-select">*/}
                                {/*<option>Choose...</option>*/}
                                {/*<option>...</option>*/}
                            {/*</Form.Control>*/}

                        {/*</td>*/}
                        <td>
                            <div  className="add-delete"><i className="fas fa-trash-alt"></i></div>
                        </td>

                    </tr>
                    )}


                    <tr>
                        <td>

                            <button type="button" className="add_tag_link add-table"><i className="fas fa-plus"></i> ADD
                                NEW
                            </button>
                        </td>
                    </tr>
                </table>
            </>


        )

}




const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        editActiviyId: state.editActiviyId,
        activityPopUp:state.activityPopUp

    };
};

const mapDispachToProps = dispatch => {
    return {

        activityPopUpShow: (data) => dispatch(actionCreator.activityPopUpShow(data)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(EditActivityPopUp);

