import React, {Component} from 'react';
import RelatingBlog from './RealtingBlog';
import axios from "axios/index";
import ReactHtml from 'raw-html-react';
import facebook from '../img/f-round.png';
import insta from '../img/in-round.png';
import LinkDin from '../img/lin-round.png';
import Mail from '../img/mail-round.png';
import Moment from 'react-moment';
import twi from '../img/icon_twitter_circle.png';
import MetaTags from 'react-meta-tags';


import {baseImgUrl, baseUrl} from '../Util/Constants'


class  BlogDetail extends Component{

    slug;
    constructor(props) {
        super(props)

        this.state = {
            blog: {},


        };

        this.slug = props.match.params.id
        console.log(this.slug)
        this.loadBlog=this.loadBlog.bind(this)
        this.updateViews = this.updateViews.bind(this)
        this.whatsAppShare= this.whatsAppShare.bind(this)
        this.facebookShare= this.facebookShare.bind(this)
        this.twitterAppShare= this.twitterAppShare.bind(this)
        this.linkedInShare= this.linkedInShare.bind(this)
        this.instaShare= this.instaShare.bind(this)




    }


    instaShare(e){

        e.preventDefault()


//         Link.open(`whatsapp://send?text=${text}`);
// Link.open("/")

        // window.location.href = "https://www.facebook.com/sharer.php?u=www.happy-weekends.com/activity/"+this.item.slug



        // window.location.href = "https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.item.slug+"&redirect_uri="+window.location.href


        // window.open("https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.state.activity.slug+"&redirect_uri="+window.location.href,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


        // https://www.linkedin.com/shareArticle?mini=true&url={url}&title={title}&summary={text}&source={provider}

        window.open("https://www.instagram.com/happyweekendsuae",'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    linkedInShare(e){

        e.preventDefault()


//         Link.open(`whatsapp://send?text=${text}`);
// Link.open("/")

        // window.location.href = "https://www.facebook.com/sharer.php?u=www.happy-weekends.com/activity/"+this.item.slug



        // window.location.href = "https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.item.slug+"&redirect_uri="+window.location.href


        // window.open("https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.state.activity.slug+"&redirect_uri="+window.location.href,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


        // https://www.linkedin.com/shareArticle?mini=true&url={url}&title={title}&summary={text}&source={provider}

            window.open("https://www.linkedin.com/shareArticle?mini=true&url=www.happy-weekends.com/blog-detail/"+this.state.blog.slug+"&title="+this.state.blog.title,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


            }

    facebookShare(e){

        e.preventDefault()


//         Link.open(`whatsapp://send?text=${text}`);
// Link.open("/")

        // window.location.href = "https://www.facebook.com/sharer.php?u=www.happy-weekends.com/activity/"+this.item.slug



        // window.location.href = "https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.item.slug+"&redirect_uri="+window.location.href


        window.open("https://www.facebook.com/sharer/sharer.php?display=popup&u=https://www.happy-weekends.com/activity/"+this.state.blog.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    twitterAppShare(e){


        e.preventDefault()

        // window.location.href = ("whatsapp://send?text=https://www.happy-weekends.com/activity/"+this.item.slug)



        window.open("https://twitter.com/intent/tweet?url=https://www.happy-weekends.com/blog-detail/"+this.state.blog.slug+"&text="+this.state.blog.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    whatsAppShare(e){

        e.preventDefault()

        // window.location.href = ("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.item.slug)


        window.open("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/blog-detail/"+this.state.blog.slug+"&text="+this.state.blog.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }




    updateViews(){

        axios.post(baseUrl+"blog_views",
            {"post": "/api/posts/"+this.state.blog.id})
            .then(res => {
                const persons = res.data;
                console.log(res.data)



            })



    }


    componentWillReceiveProps(newProps){

        if (newProps.match.params.id !== this.props.match.params.id) {

            window.scrollTo(0, 0)


            console.log(newProps)

            this.setState({

                slug : newProps.match.params.id

            })

            this.loadBlog(newProps)

        }
        console.log("components will recieve props")

    }



    loadBlog(props){

        axios.get(baseUrl+"posts.json?groups[]=posts&slug="+props.match.params.id)
            .then(res => {
                const persons = res.data;
                this.setState({

                    blog : persons[0],

                });

                this.updateViews()
                console.log(persons[0])
            })



    }




    componentDidMount() {

        window.scrollTo(0, 0)
        this.loadBlog(this.props)

    }

    render() {
        return (
           <div className="bg-gray">

               {this.state.blog &&  <MetaTags>

                   <title>{this.state.blog.title}</title>
                   <meta name="title" content={this.state.blog.metaTitle?this.state.blog.metaTitle:this.state.blog.title} />
                   <meta property="og:type" content="website" />
                   <meta property="og:url" content={"https://www.happy-weekends.com/blog-detail/"+this.state.blog.slug} />
                   <link rel="canonical" href={"https://www.happy-weekends.com/blog-detail/"+this.state.blog.slug} />
                   <meta property="og:title" content={this.state.blog.metaTitle?this.state.blog.metaTitle:this.state.blog.title} />
                   <meta property="og:description" content={this.state.blog.metaDescription?this.state.blog.metaDescription:this.state.blog.summary} />
                   <meta property="og:image" content={this.state.blog.image && baseImgUrl + this.state.blog.image} />
                   <meta property="twitter:card" content="summary_large_image" />
                   <meta property="twitter:url" content={"https://www.happy-weekends.com/blog-detail/"+this.state.blog&&this.state.blog.slug} />
                   <meta property="twitter:title" content={this.state.blog.metaTitle?this.state.blog.metaTitle:this.state.blog.title} />
                   <meta property="twitter:description" content={this.state.blog.metaDescription?this.state.blog.metaDescription:this.state.blog.summary} />
                   <meta property="twitter:image" content={this.state.blog.image && baseImgUrl + this.state.blog.image} />
                   <meta name="description" content={this.state.blog.metaDescription?this.state.blog.metaDescription:this.state.blog.summary} />
                   <meta property="og:title" content={this.state.blog.metaTitle?this.state.blog.metaTitle:this.state.blog.title} />
                   <meta property="og:image" content={this.state.blog.image && baseImgUrl + this.state.blog.image} />

               </MetaTags>}



               { this.state.blog && this.state.blog.title &&    <section className="blog-detail-section">
                      <div className="container">

                        <div className="row justify-content-center">
                        <div className="col-md-10">
                        <h3>
                            <ReactHtml html={this.state.blog.title}/>

                        </h3>
                        <div className="sub-heading-section d-flex justify-space-between align-items-center mb-4">
                        <ul className>
                            <li>
                                <Moment local format="D MMM YYYY">
                                {this.state.blog.publishedAt}
                            </Moment>
                            </li>
                            {this.state.blog.readingTime && <li>{this.state.blog.readingTime }</li>}
                            <li>{this.state.blog.views}  VIEWS</li>
                        </ul>
                        <ul className="shareul">
                            <li>Share on:</li>
                            <li>
                            <button onClick={this.facebookShare.bind(this)} style={{background : "transparent", border : "none"}} className="float-left" ><img src={facebook} alt="fb-imge" /></button>

                            </li>
                            <li>
                                <button onClick={this.linkedInShare.bind(this)} style={{background : "transparent", border : "none"}} className="float-left" ><img src={LinkDin} alt="fb-imge" /></button>

                            </li>

                            <li>
                                <button onClick={this.instaShare.bind(this)} style={{background : "transparent", border : "none"}} className="float-left" ><img src={insta} alt="fb-imge" /></button>

                            </li>
                            <li>
                            <button onClick={this.twitterAppShare.bind(this)} style={{background : "transparent", border : "none"}} className="float-left" ><img style={{width:"45px"}}  src={twi} alt="fb-imge" /></button>

                            </li>
                            <li className={"d-none"}>
                            <button style={{background : "transparent", border : "none"}} className="float-left" href="mailto:info@happy-weekends.com"><img src={Mail} alt="fb-imge" /></button>
                            </li>
                        </ul>
                        </div>
                    </div>
                   </div>


                   <div className="row">
                        <div className="col-md-12">
                            <div className="blog-detail-img">
                                <img src={baseImgUrl + this.state.blog.image } alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                        <div className="blog-detail-content-right">

                            <ReactHtml html={this.state.blog.content}/>

                        </div>
                        </div>
                    </div>
               </div>
           </section> }
           <RelatingBlog blog={this.state.blog}/>
           </div>
        )
    }
}

export default BlogDetail;
