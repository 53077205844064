import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import axios from "axios/index";
import Moment from 'react-moment';
import { Button, Col, Row,InputGroup} from 'react-bootstrap'
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import Rating from '@material-ui/lab/Rating';
import  {Star} from '@material-ui/icons';

class  CustomerBookings extends Component{


    page=1;
    constructor(props){

        super(props)

        this.state = {
            activities : [],
            page : 1,
            displayLoadMore : false,
            reviews:[],

        };


        this.loadMoreClick=this.loadMoreClick.bind(this)
        this.loadBookings=this.loadBookings.bind(this)
        this.loadReviews= this.loadReviews.bind(this)
        this.checkReviewExist= this.checkReviewExist.bind(this)


    }


 checkReviewExist(e){



    }


    loadReviews(){



        if (this.props.userDetail && this.props.userDetail.id) {
            var url = baseUrl + "activity_reviews.json?groups[]=customer-reviews&isPublished=1&customer=" + this.props.userDetail.id;

            console.log(url)


            axios.get(url)
                .then(res => {

                    console.log("reviews customer")

                    console.log(res.data)

                    this.setState({

                        reviews: res.data,
                    })

                    this.loadBookings()


                }).catch(error => {

                // dispatch(stopLoading())

                // dispatch(loginFailed())

                console.log(error)
                // dispatch({ type: AUTH_FAILED });
                // dispatch({ type: ERROR, payload: error.data.error.message });


            });
        }

    }

    loadBookings(){

        var url =baseUrl+"booked_items.json?groups[]=bookings&customer="+this.props.userDetail.id+"&order[id]=desc&page="+this.page;

        console.log("booked iems")
        console.log(url)

        axios.get(url)
            .then(res => {



                if (res.data.length==6){

                    this.setState({
                        displayLoadMore : true

                    })

                }else{

                    this.setState({
                        displayLoadMore : false

                    })

                }



                this.setState({

                    activities : this.state.activities.concat(res.data),

                });


                console.log(res.data)

            })



    }
    componentDidMount (){

        this.loadReviews()

         }


    loadMoreClick(){

        console.log("Load More  ++")

        this.page=this.page+1

        // this.setState({
        //
        //     page : this.state.page + 1
        // })


this.loadBookings()

    }

    render (){
        return(
            <div className="tab-pane fade show active" id="pills-mybooking" role="tabpanel"
                 aria-labelledby="pills-mybooking-tab">

                {  this.state.activities && this.state.activities.map((item)=>

                    <div className="row margin-top mb-5">
                        <div className="col-lg-4">
                            <Link  to={"/activity/"+item.activity.slug} className="title-goes" >
                            <img className="img-fluid border-raidus" src={baseImgUrl+item.activity.images[0].image} alt="" width="100%"/>
                            </Link>
                        </div>
                        <div className="col-lg-6 pr-0">
                            <div className="title_here">
                                <h5 className="title_pirce">{item.title} {item.itemType==1&& <span className={"bundle-name-heading"}>{item.bundle.name}</span>}</h5>
                                {item.address && <p>{item.address.address}, {item.address.city&&item.address.city.name}</p>}
                                <p>Booking ID:  #{item.booking.id}-{item.id}</p>
                                <p>Date:  <Moment local format="DD-MM-YYYY">
                                    {item.purchaseDate}
                                </Moment></p>
                                <p>Time:  <Moment local format="HH:mm">
                                    {item.purchaseDate}
                                </Moment></p>
                                <p>
                                OTP : <span style={{fontWeight:"600"}} className="text-blue">{item.otp}</span>
                                </p>

                            </div>
                            <div className="prize-block-booked">
                                <p className="mb-0">Amount Paid:</p>
                                <h5 className="mb-0 pr-3">AED {item.total}</h5>
                            </div>

                        </div>
                        <div className="col-lg-2 pl-0 border-left">
                            <div className="block-action ">

                                <>
                                    {this.state.reviews.find(review => review.activity.id == item.activity.id) &&

                                    <div data-id={item.activity.id} className="rating">
                                        <h5>You’ve Rated</h5>
                                        <p>{this.state.reviews.find(review => review.activity.id == item.activity.id).rating}</p>


                                        <Rating
                                            readOnly
                                            value={this.state.reviews.find(review => review.activity.id == item.activity.id).rating}
                                            icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                            emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                            name="size-large"  size="large"
                                        />



                                    </div>
                                    }
                                </>

                                <div className="action-button">
                                    <Link to={"/activity/"+item.activity.slug} className="btn-blue" >VIEW DETAILS</Link>
                                    <a className="btn d-none" href="#">CANCEL</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <p className="note-p">Note: Cancellation policy’s can be found on the experience details page.</p>
                        </div>
                    </div>


                )}


                {this.state.displayLoadMore && <Row  className="justify-content-md-center mt-5">

                    <Col xs lg="2"  >
                        <Button onClick={this.loadMoreClick}
                                size="lg" variant="outline-primary" block>
                            Load More

                        </Button>
                    </Col>

                </Row>}



            </div>


        )
    }
}

const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CustomerBookings);