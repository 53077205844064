import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import axios from "axios/index";
import queryString from 'query-string'



import {baseUrl,baseImgUrl} from  '../Util/Constants'
import {connect} from "react-redux";
import * as actionCreator from "../store/actions/actions";
import history from "../History/history";




class  EmailConfirmation extends Component{



    id;
    token;

    constructor(props) {


        super(props)
        this.state = {


            success : false,
            displaySuccess : false,

        };


        this.id = this.props.match.params.id
         this.token= this.props.match.params.token
    }


    componentDidMount(){


        console.log(this.props.match.params)

        const urlParams = queryString.parse(this.props.location.search)

        console.log(urlParams)


        axios.post(baseUrl+"customer_confirmations" ,{ token: urlParams.token ,customer: this.id  }).then(res => {


             console.log("confirmation success ")
             console.log( res.data)




            this.setState({

                success : true,
                displaySuccess : true,
            })


            this.props.loadFavorites(this.props.userDetail)


            if (this.props.isLoggedIn&&this.props.userDetail.isHost){

                // history.push("/checkout")
                window.location.href=("/vendor/dashboard")

            }


        }).catch(error => {

            console.log("favorites error found "+error)


        });




    }

    render (){


        return(

            <>

                 <section className="not-found email-confirm mb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {this.state.displaySuccess &&
                            <div className="text-center">
                                <h1><i class="fas fa-check-circle"></i></h1>
                                <h3>Success! You have confirmed your email address.</h3>
                                <p>You can do login now and make a purchase. Thanks</p>
                                {
                                    this.props.userDetail && this.props.isLoggedIn ?
                                    <Link to="/" className="red-btn">Go to Homepage</Link>:
                                    <Link to="/login" className="red-btn">Login in Now</Link>

                                }
                            </div>
                            }
                        </div>

                    </div>
                </div>
            </section>
                </>
         )
    }



}




const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        abondonCartItem : state.abondonCartItem,
        userDetail: state.userDetail,




    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        setUserDetail : (item) => dispatch(actionCreator.setUserDetail(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(EmailConfirmation);

// export default EmailConfirmation;