import React, { Component } from 'react';

import Work1 from '../img/work-1.png';

import { Link} from 'react-router-dom'

class  HowItWorks extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render (){
        return(
            
        <div className="bg-gray">
                <section className="medium-banner work-banner">
                    <div className="container">
                    <div className="row">
                        <h2 className="text-center w-100">How Tt Works</h2>
                    </div>
                    </div>
                   </section>
                   <section className="blog-section blog-detail-section how-work-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            <div className="custom-container w-100 d-flex">
                                <h2 className="red-heading w-100 text-center">Best Family Guide</h2>
                                <div className="d-flex w-100 left-right-col">
                                <div className="work-img-col">
                                    <img src={Work1} alt="work" />
                                </div>
                                <div className="work-decription">
                                    <h2 className="blue-heading">Find out what's happening this month</h2>
                                    <p>Something’s always happening at Happy Weekends. We’re always adding new experiences, stuff you can see, places you can go to, new hobbies you can pick up. So before you buy an experience, it’d be worth it to check out everything that you can try.</p>
                                </div>
                                </div>
                                <div className="d-flex w-100 left-right-col mt-1 work-left-padding">
                                <div className="work-decription">
                                    <h2 className="blue-heading">Search by category and location</h2>
                                    <p>Not sure what you’d like to do this weekend? Narrow your search to things happening only around you. Select the categories that best apply to you, and let us show you our recommendations.</p>
                                </div>
                                <div className="work-img-col">
                                    <img src={Work1} alt="work" />
                                </div>
                                </div>
                                <div className="d-flex w-100 left-right-col">
                                <div className="work-img-col">
                                    <img src={Work1} alt="work" />
                                </div>
                                <div className="work-decription">
                                    <h2 className="blue-heading">Pick something you like</h2>
                                    <p>Once you’ve seen all the things that you could possibly do, pick something that you’d like to do today. Or, if you’re adventurous, pick a few. It’s the weekend after all!</p>
                                </div>
                                </div>
                                <div className="d-flex w-100 left-right-col mt-1 work-left-padding">
                                <div className="work-decription">
                                    <h2 className="blue-heading">Check out</h2>
                                    <p>Once you pay for your order, you’ll be sent a confirmation code via SMS and Email. Keep this code carefully, because you’ll need to show it the vendor when you go to claim your happy weekend experience./p&gt;
                                    </p></div>
                                <div className="work-img-col">
                                    <img src={Work1} alt="work" />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                                   
         </div>
          )
    }
}

export default HowItWorks;