import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import DetailIcon1 from '../img/detail-icon-1.png';
import ProductImg from '../img/at-the-top-dubai.png';
import DateFnsUtils from '@date-io/date-fns';
import PlusIcon from '../img/plus.png';
import MinIcon from '../img/min.png';
import Rating from '@material-ui/lab/Rating';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    KeyboardTicmePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {baseUrl,baseImgUrl} from  '../Util/Constants'



class  EditCartPopUp extends Component{

    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            dateSelected : new Date(),
            activity: null,
            images: [],
            total: 0,
            priceOptions: [],

        }

        this.hidePopUp = this.hidePopUp.bind(this);
        this.hideEditCartPopUp= this.hideEditCartPopUp.bind(this)
        this.disableRandomDates = this.disableRandomDates.bind(this)
        this.disableWeekends = this.disableWeekends.bind(this)
        this.disableWeekDays = this.disableWeekDays.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.loadValues = this.loadValues.bind(this)
        this.updateCartSubmit = this.updateCartSubmit.bind(this)


    }


    handleDateChange = date => {


        this.setState({

            dateSelected : date

        })

    };


    incrementQuantity(e){

        this.setState({ showBuyNowError: false });

        let priceOptionNew = this.state.priceOptions.slice();

        for (var i=0;i<priceOptionNew.length;i++) {


            if (priceOptionNew[i].id == e.currentTarget.dataset.id) {


                priceOptionNew[i].quantity = priceOptionNew[i].quantity + 1

                var price = 0
                if(priceOptionNew[i].discountedPrice){

                    price = priceOptionNew[i].discountedPrice
                }else {

                    price = priceOptionNew[i].price


                }

                this.setState({ total: this.state.total + price });


            }
        }




        this.setState({ priceOptions: priceOptionNew });



    }



    decrementQuantity(e){


        let priceOptionNew = this.state.priceOptions.slice();

        for (var i=0;i<priceOptionNew.length;i++) {


            if ((priceOptionNew[i].id == e.currentTarget.dataset.id)&&(priceOptionNew[i].quantity>0)) {
                priceOptionNew[i].quantity = priceOptionNew[i].quantity - 1


                var price = 0
                if(priceOptionNew[i].discountedPrice){

                    price = priceOptionNew[i].discountedPrice
                }else {

                    price = priceOptionNew[i].price


                }

                this.setState({ total: this.state.total - price });


            }else {

            }
        }

        this.setState({ priceOptions: priceOptionNew });

    }

    disableWeekDays(date) {

        var daysArray=[0,1,2,3,4,5,6]// 0- sunday, 6 saturday


        if (this.props.cartPopUpItem.activity.day && this.props.cartPopUpItem.activity.day.length>0 ){
            var days = this.props.cartPopUpItem.activity.day

            for (var i=0;i<days.length;i++) {


                if (days[i].name==="Thursday"){

                    daysArray=daysArray.filter((value) => value!=4)

                }

                else if (days[i].name==="Friday"){

                    daysArray=daysArray.filter((value) => value!=5)


                }

                else if (days[i].name==="Saturday"){

                    daysArray=daysArray.filter((value) => value!=6)

                }



            }


        }


        return (daysArray.indexOf(date.getDay())>-1)



    }



    disableWeekends(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    disableRandomDates() {
        return Math.random() > 0.7;
    }


    hideEditCartPopUp(){


        this.props.editCartPopUp(false)

    }




    hidePopUp(){

        this.props.loginPopUp(false)
        this.props.loginCheckoutPopUp(false)


    }


    showLogin(){


        this.setState({

            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,

        })



    }

    showSignUp(){

        this.setState({
            showSignUp: true,
            showGuestSignUp: false,
            showLogin: false,
        })


    }

    showGuestSignUp(){

        this.setState({

            showSignUp: false,
            showGuestSignUp: true,
            showLogin: false,

        })
    }


    componentDidMount(){


        this.loadValues()

    }




    updateCartSubmit(){

        this.hideEditCartPopUp()




        this.props.addCartItem(

            {
                "user":this.props.userDetail,
                "cartItem":{

                    "itemType":0,
                    "activity": this.state.activity,
                    'cartPriceOptions': this.state.activity.priceOption

                }
            }

        )





    }

    loadValues(){

        if (this.props.cartPopUpItem){

            var activity = this.props.cartPopUpItem.activity
            var priceOptionNew = this.props.cartPopUpItem.activity.priceOption;

            this.setState({ priceOptions: priceOptionNew });

            this.setState({

                activity : activity,
                priceOptions : activity.priceOption


            })


            var price = 0

            for(var k=0;k<priceOptionNew.length;k++){

                if (priceOptionNew[k].quantity && priceOptionNew[k].quantity>0){


                                        if(priceOptionNew[k].discountedPrice){

                                            price = price + priceOptionNew[k].discountedPrice*priceOptionNew[k].quantity
                                        }
                                        else {

                                            price = price + priceOptionNew[k].price*priceOptionNew[k].quantity

                                        }

                                        this.setState({ total: this.state.total + price });

                }

            }


            console.log("edit cart pop up price options")
            console.log(priceOptionNew)





            console.log("updated quantity")
            console.log(priceOptionNew)


        }


    }


    render (){

        return(


            <Modal   show={this.props.showEditCartPopUp}

                     onHide={this.hideEditCartPopUp}
                     className={"custom-modal-popup popup-form"}
            >
                <button onClick={this.hideEditCartPopUp} className="close" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                <div className="row py-3 justify-content-center mobile-menu-row">
                    {this.state.activity && <div className="mobile-menu edit-cart-block col-lg-12">
                        <div className="form-col-left pl-3 pr-3">
                            <h3 class="form-heading">Edit Cart</h3>
                            <div className="offers wl_scroll_fix_detail ">
                                <div className="block border-bottom pt-0">
                                    <div className="product-img-block">
                                        <div className="product-img">
                                            <img src={baseImgUrl + this.state.activity.images[0].image}
                                                 className="img-fluid"
                                                 alt="Product detail"/>
                                        </div>
                                        <h2>{this.state.activity.title}</h2>
                                    </div>
                                </div>
                                {this.state.activity.dateInput &&<div className="block border-bottom ">
                                    <form className="w-100">
                                        <Form.Group controlId="formPlaintextEmail">
                                            <Form.Label className="date-label pl-0 ">
                                                <img src={DetailIcon1} alt=""/> Date
                                            </Form.Label>


                                            <Col className={"custom-select"}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                    
                                                        shouldDisableDate={this.disableWeekDays}
                                                        minDate={new Date()}
                                                        autoOk={true}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="dd/MM/yyyy"
                                                        value={this.state.dateSelected}
                                                        id="date-picker-inline"
                                                        onChange={this.handleDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />

                                                </MuiPickersUtilsProvider>

                                            </Col>
                                        </Form.Group>

                                    </form>
                                </div>}


                                {this.state.priceOptions.length>0 && this.state.priceOptions.map((item) =>


                                    <div className="block border-bottom">
                                        <div className="left-side">

                                            <div className="top-block">
                                                <p className="mb-0 d-none">0 bought</p>
                                                <h4>{item.description}</h4>
                                            </div>

                                            <div className="offer-data-left">
                                                <div>
                                                    <button className="value-button"
                                                            value="Decrease Value"
                                                            data-id={item.id} data-price={item.id}
                                                            onClick={this.decrementQuantity.bind(this)}
                                                    ><img src={MinIcon} alt=""/>
                                                    </button>
                                                    <input type="hidden" multiple name="priceOption[]"
                                                           className="number" value={item.id}/>
                                                    <input dataprice={175} multiple name="priceOptionQuantity[]"
                                                           className="number exists"
                                                           readOnly type="text" id="number-1845" value={item.quantity?item.quantity:0}/>
                                                    <button data-id={item.id} data-price={item.id}
                                                            onClick={this.incrementQuantity.bind(this)}
                                                            className="value-button"
                                                            value="Increase Value"><img src={PlusIcon} alt=""/>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="right-side">
                                            <div className="offer-data-right">
                                                {item.discountedPrice &&
                                                <p className="totaloff">{Math.floor(((item.price - item.discountedPrice) / item.price) * 100)}%
                                                    OFF</p>}

                                                {!item.discountedPrice &&
                                                <h4>AED <span id="price-1845">{item.price}</span></h4>}
                                                {item.discountedPrice &&
                                                <h4>AED <span id="price-1845">{item.discountedPrice}</span></h4>}
                                                {item.discountedPrice &&
                                                <p className="text-line">AED ${item.price}  </p>}

                                            </div>

                                        </div>
                                    </div>
                                )
                                }


                                <div className="order-total">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <p className="mb-0">Total</p>
                                            <p className="small-text mb-0">Inclusive of VAT</p>
                                        </div>
                                        <div className="col-md-5 text-right">
                                            <h5>AED <span id="totalPrice">{this.state.total}</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-block">
                                    <button onClick={this.updateCartSubmit} className="buy-now">UPDATE CART</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Modal>

        )
    }
}



const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        userDetail: state.userDetail,
        abondonCartItem : state.abondonCartItem,
        showEditCartPopUp: state.showEditCartPopUp,
        cartPopUpItem : state.cartPopUpItem,


    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        editCartPopUp: (item) => dispatch(actionCreator.editCartPopUp(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(EditCartPopUp);


