import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import {baseUrl} from "../Util/Constants";
import axios from "axios/index";
import {saveKey,getKey} from "../LocalStorage/user";
import Moment from 'react-moment';
// import {Modal, ModalBody, Alert, Col,Form} from 'react-bootstrap';
import { Spinner,Button, Col,  Modal,Alert,ModalBody,  Form,  Tab, Tabs} from 'react-bootstrap'


class  CustomerBookings extends Component{


    page=1;
    constructor(props){

        super(props)

        this.state = {
            activities: [],
            page: 1,
            displayLoadMore: false,
            reviews: [],
            verifyPopUp: false,
            verifyId: null,
            error: false,
            loading: false,
            dashboard: {}
        }




        this.loadMoreClick=this.loadMoreClick.bind(this)
        this.loadBookings=this.loadBookings.bind(this)
        this.checkReviewExist= this.checkReviewExist.bind(this)
        this.verifyPopUp= this.verifyPopUp.bind(this)
        this.verifyItem= this.verifyItem.bind(this)



    }


    checkReviewExist(e){



    }



    handleSubmit = event => {



        event.preventDefault();

        const form = event.currentTarget;



        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,
                formHasError : true
            });
        }


        const data = new FormData(event.target);

        if(true) {

            this.setState({
                btnLoading: true,
                formHasError : false
            })



            console.log("host form data")
            console.log(data)





            var activityData = {


                "code": data.get("code"),
                "bookedItem":"/api/booked_items/"+this.state.verifyId


            };


            console.log(activityData)


            event.preventDefault();

            this.setState({

                loading:true,
                error:false,
            })

            var url = baseUrl + "verify_bookings.json";


            axios.post(url, activityData )
                .then(res => {

                    this.setState({

                        loading : false,
                        error:false,
                        success:true

                    })


                    this.verifyPopUp()

                    this.loadBookings()


                }).catch(error => {

                console.log("activity submit error")
                console.log(error)


                this.setState({

                    loading : false,
                    error:true,
                    success:false
                })

            });

        }
    }

    verifyPopUp(e) {


        if (e && e.currentTarget.dataset.id) {

            this.setState({

                verifyId: e.currentTarget.dataset.id
            })


        }
        this.setState({

            verifyPopUp: !this.state.verifyPopUp
        })

    }



    verifyItem(){



        axios.post(baseUrl+"verify.json",
            {"activity": "/api/activities/"+this.state.unpublishItemId})
            .then(res => {

                const persons = res.data;
                console.log(res.data)

                this.unpublishPopUp()

            })
    }






    loadBookings(){


        this.setState({

            activities : [],

        });

        var hostId = getKey("host").id

        var url =baseUrl+"booked_items.json?groups[]=bookings&host="+hostId+"&order[id]=desc&page="+this.page;

        console.log("booked iems")
        console.log(url)

        axios.get(url)
            .then(res => {


                if (res.data.length==6){

                    this.setState({
                        displayLoadMore : true

                    })

                }else{

                    this.setState({
                        displayLoadMore : false

                    })

                }



                this.setState({

                    activities : this.state.activities.concat(res.data),

                });


                console.log(res.data)

            })



    }
    loadDashboard(){


        this.setState({

            activities : [],

        });

        var hostId = getKey("host").id

        var url =baseUrl+"dashboard_hosts.json";


        axios.post(url,{"host":"api/hosts/"+hostId})
            .then(res => {


                this.setState({

                    dashboard:res.data.dashboard
                })


            })



    }
    componentDidMount (){

        this.loadDashboard()

        this.loadBookings()


    }


    loadMoreClick(){

        console.log("Load More  ++")

        this.page=this.page+1

        // this.setState({
        //
        //     page : this.state.page + 1
        // })


        this.loadBookings()

    }



    render (){
        return(
            <div className="tab-pane fade show active" id="pills-mybooking" role="tabpanel"
                 aria-labelledby="pills-mybooking-tab">
                <div className="row mb-5">
                    <div className="col-lg-4">
                    <div className="act-booking-block">
                        <p>Total Revenue <i className="fas fa-info-circle" /></p>
                        <h5>{this.state.dashboard.revenue} AED</h5>
                        <div className="detail">
                        <p className="text-success">In this quarter (Jan - Mar)</p>
                        <p>In last quarter 0 AED</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="act-booking-block">
                        <p>Total Orders <i className="fas fa-info-circle" /></p>
                        <h5>{this.state.dashboard.orders}</h5>
                        <div className="detail">
                        <p className="d-flex justify-content-between">
                            <span className="text-warning"><i className="fas fa-clock" /> Pending: {this.state.dashboard.pending}</span>
                            <span className="text-success"><i className="fas fa-clock" /> Redeemed: {this.state.dashboard.redeemed}</span>
                        </p>
                        <p>In this quarter (Jan - Mar)</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="act-booking-block">
                            <p>Amount Due <i className="fas fa-info-circle" />  </p>
                            <h5>{this.state.dashboard.amountDue} AED</h5>
                            <div className="detail">
                                <p>To be paid during the first week of each month
                                    (After deducting platform commission)
                                </p>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="row mb-5">
                    <div className="col-lg-6">

                        <div className="act-booking-block">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="act-booking-block border-right">
                                        <p>Revenue From Corporate <i className="fas fa-info-circle" /></p>
                                        <h5>{this.state.dashboard.corporate} AED</h5>
                                        {/*<div className="detail">*/}
                                        {/*<p className="w-50">In this quarter<span>(Jan - Mar)</span></p>*/}
                                        {/*<p />*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="act-booking-block">
                                        <p>Revenue From Retail <i className="fas fa-info-circle" /></p>
                                        <h5>{this.state.dashboard.retail} AED</h5>
                                        {/*<div className="detail">*/}
                                        {/*<p className="w-50">In this quarter<span>(Jan - Mar)</span></p>*/}
                                        {/*<p />*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="act-booking-block border-right">
                            <p>Lifetime Sales <i className="fas fa-info-circle" /></p>
                            <div className="detail mt-3">
                                <p className="w-45 border-right mr-2">Number of Orders
                                    <span className="font-24">{this.state.dashboard.orders} AED</span>
                                </p>
                                <p className="w-45 pl-2">Total Amount
                                    <span className="font-24">{this.state.dashboard.revenue} AED</span>
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row mb-4">


                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="act-booking-block">
                        <p className="table-title mb-3">Sales Overview <i className="fas fa-info-circle" /></p>
                        <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Order No</th>
                                <th>Date</th>
                                <th>Details</th>
                                <th>Status</th>
                                <th>Amount</th>
                                {/*<th>Customer Passcode</th>*/}
                                <th>Verify</th>
                            </tr>
                            </thead>
                            <tbody>



                            {this.state.activities.map((item) =>  <tr>
                                <td>#{item.booking.id}-{item.id}</td>
                                <td><Moment local format="DD-MM-YYYY">
                                    {item.purchaseDate}
                                </Moment></td>
                                <td>{item.title}</td>
                                <td>{item.status.name}</td>
                                <td>{item.hostAmount}</td>
                                {/*<td><span style={{fontWeight:"600"}} className="text-blue">{item.otp}</span></td>*/}
                                <td>

                                    {item.status.name==="Pending"?<a  data-id={item.id} className="Verify" onClick={this.verifyPopUp.bind(this)}>Click To Verify</a>:"Verified"}

                                </td>
                            </tr>
                            )}

                            <tr>
                                <td colSpan={7} className="text-center"><a href="#">View Detailed Report</a></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>


                <Modal className={"loop-popup"}
                       aria-labelledby="contained-modal-title-vcenter"
                       centered show={this.state.verifyPopUp} onHide={this.verifyPopUp} animation={false}>

                    <ModalBody>



                        <div className={"row justify-content-center"}>
                            <div className={"col-10 text-center"}>
                                <h4 className={"text-bold text-caps"}>Verify Order</h4>
                                <p>Kindly enter secure code to verify order.</p>
                            </div>
                        </div>

                        <Form noValidate validated={this.state.validatedForm} onSubmit={this.handleSubmit}>


                        <div className={"row justify-content-center"}>




                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Control
                                               required type="text"
                                              placeholder="" defaultValue="" name={"code"}/>

                            </Form.Group>


                            {this.state.error && <Alert className={"mt-2"} variant={"danger"}>
                                Oops!Code entered is incorrect.
                            </Alert>
                            }

                            <div className={"col-12 text-center mt-2"}>


                                <div className={"row justify-content-center"}>
                                    <div className={"col-6"} style={{textAlign:"center"}}>



                                         <Button className="btn btn-signup" type="submit">

                                            {this.state.loading && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}

                                            {this.state.loading ? "Wait.." : "Verify"}
                                        </Button>



                                    </div>
                                    <div className={"col-6"} style={{textAlign:"center"}}>
                                        <button onClick={this.verifyPopUp} className={"btn btn-primary"}>Cancel</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                        </Form>


                    </ModalBody>

                </Modal>

            </div>


        )
    }
}

export default CustomerBookings;