import React, { Component, Fragment, useState } from 'react';

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends Component {


    constructor(props) {

        super(props)



        this.state = {

            onInfoWindowClose : false,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            // images : this.props.images


        };

        this.onMarkerClick=this.onMarkerClick.bind(this)

        this.onMapClicked=this.onMapClicked.bind(this)

    }


    onMarkerClick = (props, marker, e) => {

        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        })
    }


    onMapClicked = (props) => {

        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };


    render() {
        return (

            <Map onClick={this.onMapClicked}

                style={{width: '100%', height: '450px', position: 'relative!important'}}
                google={window.google}
                initialCenter={{
                    lat: this.props.item.address.latitude,
                    lng: this.props.item.address.longitude
                }}
                zoom={15}

            >

                <Marker
                    onClick={this.onMarkerClick}

                        position={{
                            lat: this.props.item.address.latitude,
                            lng: this.props.item.address.longitude
                        }}
                         />

                <InfoWindow
                    marker={this.state.activeMarker}
                             visible={this.state.showingInfoWindow}
                    >
                    <div>
                        <h1>{this.props.item.title}</h1>
                    </div>
                </InfoWindow>

            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyDipmTZFO_aGOiT8GBUHWS_8NA3QQNJwmo")
})(MapContainer)