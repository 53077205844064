import React, {Component, Fragment} from 'react';
import axios from "axios/index";
import TrendingSectionItem from "../Home/TrendingSectionItem";
import {Button, Col, InputGroup, Row} from 'react-bootstrap'
import queryString from 'query-string'
import Slider from "@material-ui/core/Slider/index";
import {makeStyles, withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import {Star} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import MetaTags from 'react-meta-tags';
import {baseImgUrl, baseUrl} from '../Util/Constants'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
        maxWidth: 20,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



class  ListingNew extends Component{

     page=1;
     slug;

    constructor(props){


        super(props)
        this.state = {

            catItem : null,
            activityItems:[],
            catItems : [],
            days : [],
            cities : [],
            timings: [],
            sortBy: [ {"id":"1","name":"New"},{"id":"2","name":"Trending"},{"id":"3","name":"Popular"}],
            activityPreference : [ ],
            rating: [],
            slug : this.props.match.params.id,
            page : 1,
            backgroundimage : {},
            displayLoadMore : false,
            activeFilters: {},
            displayFilterMenu: false,
            displayNoResults: false,
            reloadFilters : true

        };

        this.togglefilter = this.togglefilter.bind(this);
        this.loadCategory = this.loadCategory.bind(this);
        this.loadMoreClick = this.loadMoreClick.bind(this);
        this.fetchFilters = this.fetchFilters.bind(this);
        this.fetchActivities = this.fetchActivities.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.fetchFilteredActivities = this.fetchFilteredActivities.bind(this);
        this.getActiveFiltersQuery = this.getActiveFiltersQuery.bind(this);
        this.reloadFilters = this.reloadFilters.bind(this);


    }


     reloadFilters(){



        this.setState({
            reloadFilters: false
        })


         setTimeout(function() {

             // this.setState({timerEnd: true})



         this.setState({
             reloadFilters: true
         })

         }.bind(this), 500)



     }


    setFilters(filter){


        var filtersObj = this.state.activeFilters
        filtersObj[filter.name]=filter.value

        this.setState({

            activeFilters: filtersObj

         })

        console.log(this.state.activeFilters)


        this.getActiveFiltersQuery()

    }

    togglefilter(){


        this.setState({
            displayFilterMenu : !this.state.displayFilterMenu
        })


        this.getActiveFiltersQuery()
    }



    loadCategory(props){

        // alert("load categories")

        this.props.loading()
        console.log("Slug : "+this.state.slug)

        axios.get(baseUrl+"categories.json?groups[]=list&slug="+props.match.params.id)
            .then(res => {
                this.setState({
                    catItem : res.data[0],
                    backgroundimage : res.data[0].imageHeader

                });
                console.log(res.data)

                this.props.stopLoading()
                this.makeQuery()

            })


    }


    componentWillReceiveProps(newProps){

        if (newProps.match.params.id !== this.props.match.params.id) {

            console.log(newProps)

            this.setState({

                activityItems: [],
                slug : newProps.match.params.id,

                activeFilters: {},
                displayNoResults : false,
                displayLoadMore : false,


            })

            // alert("new load")


            this.reloadFilters()



            this.page = 1
        this.loadCategory(newProps)

        }
        console.log("components will recieve props")

    }


    componentDidMount (){

        window.scrollTo(0, 0)

        this.loadCategory(this.props)
        this.fetchFilters()


    }



    fetchFilteredActivities(url){


        this.props.loading()

        this.setState({

            activityItems : [],

        });

        console.log(url)
        axios.get(url)
            .then(res => {


                var activities = res.data.filter((item)=> item.images.length>0)

                this.props.stopLoading()



                if (activities.length==0&&this.state.activityItems.length==0) {

                    this.setState({
                        displayNoResults : true

                    })

                }else{

                    this.setState({
                        displayNoResults : false

                    })

                }


                    if (activities.length==6){



                    this.setState({
                        displayLoadMore : true

                    })

                }else{

                    this.setState({
                        displayLoadMore : false

                    })




                }





                var itemsActivity = this.state.activityItems.concat(activities)


                const filteredArr = itemsActivity.reduce((acc, current) => {
                    const x = acc.find(item => item.id === current.id);
                    if (!x) {



                        for (var m=0; m<current.priceOption.length;m++){


                            if (this.state.activeFilters.price[0]<=current.priceOption[m].price&&this.state.activeFilters.price[1]>=current.priceOption[m].price) {
                                return acc.concat([current]);

                            }else{
                                return acc;
                            }
                        }


                        return acc.concat([current]);

                    } else {
                        return acc;
                    }
                }, []);








                this.setState({

                    activityItems : filteredArr,

                });



                console.log("final array")
                console.log(filteredArr)

            })

    }

    fetchFilters(){

        axios.get(baseUrl+"categories.json?groups[]=list&level=1")
            .then(res => {
                this.setState({

                    catItems: res.data,

                });
            })

        axios.get(baseUrl+"days.json?groups[]=activity-detail")
            .then(res => {
                this.setState({

                    days: res.data,

                });


            })

        axios.get(baseUrl+"cities.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    cities: res.data,

                });


            })


        axios.get(baseUrl+"activity_preferences.json?groups[]=detail")
            .then(res => {
                this.setState({

                    activityPreference: res.data,

                });


            })


        axios.get(baseUrl+"timings.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    timings: res.data,

                });


            })

    }

    getActiveFiltersQuery(){



        const values =  this.state.activeFilters

        var  url =  baseUrl+"activities.json?groups[]=activities&page="+this.page;

        var makeQueryFlag=false



        if (values.category && values.category.length>0){

            makeQueryFlag = true

            for (var i = 0;i<values.category.length;i++) {

                url = url +"&category[]="+values.category[i];

            }

        }else {

            url = url +"&category[]="+this.state.catItem.id;

        }

        if (values.cities && values.cities.length>0){

            makeQueryFlag=true

            for (var i =0;i<values.cities.length;i++) {

                url = url +"&address.city[]="+values.cities[i];

            }

        }


        if (values.timings && values.timings.length>0){

            makeQueryFlag=true


            for (var i =0;i<values.timings.length;i++) {

                url = url +"&timings[]="+values.timings[i];

            }

        }


        if (values.days && values.days.length>0){

            makeQueryFlag=true


            for (var i =0;i<values.days.length;i++) {

                url = url +"&day[]="+values.days[i];

            }

        }

        if (values.activityPreference){

            makeQueryFlag=true

            url = url +"&activityPreference="+values.activityPreference;


        }



        if (values.ratings){

            url = url +"&rating="+values.ratings;
            makeQueryFlag=true

        }


        if (values.price){

            url=url+"&priceOption.price[between]="+values.price[0]+".."+values.price[1];
            makeQueryFlag=true

        }


            if (values.sort){


            makeQueryFlag=true

            if (values.sort==1)
            url = url +"&order[id]=desc";


            if (values.sort==2)
                url = url +"&order[lifeTimeSales]=desc&order[views]=desc";


            if (values.sort==3)
                url = url +"&order[lifeTimeSales]=desc&order[views]=desc";



        }



        console.log("final Query url")
        console.log(url)

          // if (makeQueryFlag)
            this.fetchFilteredActivities(url)


    }


    makeQuery(){


        const values = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})

        console.log("Search params")

        console.log(values)

        var  url =  baseUrl+"activities.json?groups[]=activities&isActive=1&page="+this.page;

        // var  url = baseImgUrl+"activities.json?groups[]=activities&category[]="+this.state.catItem.id+"&page="+this.page;




        if (this.state.catItem){


                url = url +"&category[]="+this.state.catItem.id;

        }


        if (values.city && values.city.length>0){


            for (var i =0;i<values.city.length;i++) {

                url = url +"&address.city[]="+values.city[i];

            }

        }



        if (values.day && values.day.length>0){


            for (var i =0;i<values.day.length;i++) {

                url = url +"&day[]="+values.day[i];

            }

        }



        console.log("final Query url")
        console.log(url)
        this.fetchActivities(url)


    }

    fetchActivities(url){


        this.props.loading()


        console.log(url)
        axios.get(url)
            .then(res => {


                var activities = res.data.filter((item)=> item.images.length>0 )


                this.props.stopLoading()


                if (activities.length==0){

                    this.setState({
                        displayNoResults : true

                    })

                }else{

                    this.setState({
                        displayNoResults : false

                    })

                }


                if (activities.length==6){
                    console.log("filter data")
                    console.log(res.data)


                    this.setState({
                        displayLoadMore : true

                    })

                }else{

                    this.setState({
                        displayLoadMore : false

                    })

                }


                this.setState({

                    activityItems : this.state.activityItems.concat(activities),

                });


                console.log(res.data)





            })

    }


    loadMoreClick(){

        console.log("Load More  ++")

        this.page=this.page+1

        // this.setState({
        //
        //     page : this.state.page + 1
        // })


        this.makeQuery()


    }




    goToDetailPage(){

    }



    render (){

        return(

            <>
            <div>


                {this.state.catItem &&  <MetaTags>

                    <title>{this.state.catItem.name}</title>
                    <meta name="title" content={this.state.catItem.metaTitle?this.state.catItem.metaTitle:this.state.catItem.name} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={"https://www.happy-weekends.com/category/"+this.state.catItem.slug} />
                    <link rel="canonical" href={"https://www.happy-weekends.com/category/"+this.state.catItem.slug} />
                    <meta property="og:title" content={this.state.catItem.metaTitle?this.state.catItem.metaTitle:this.state.catItem.name} />
                    <meta property="og:description" content={this.state.catItem.metaDescription?this.state.catItem.metaDescription:this.state.catItem.name} />
                    <meta property="og:image" content={this.state.backgroundimage && baseImgUrl + this.state.backgroundimage} />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={"https://www.happy-weekends.com/category/"+this.state.catItem&&this.state.catItem.slug} />
                    <meta property="twitter:title" content={this.state.catItem.metaTitle?this.state.catItem.metaTitle:this.state.catItem.name} />
                    <meta property="twitter:description" content={this.state.catItem.metaDescription?this.state.catItem.metaDescription:this.state.catItem.name} />
                    <meta property="twitter:image" content={this.state.backgroundimage && baseImgUrl + this.state.backgroundimage} />
                    <meta name="description" content={this.state.catItem.metaDescription?this.state.catItem.metaDescription:this.state.catItem.name} />
                    <meta property="og:title" content={this.state.catItem.metaTitle?this.state.catItem.metaTitle:this.state.catItem.name} />
                    <meta property="og:image" content={this.state.backgroundimage && baseImgUrl + this.state.backgroundimage} />

                </MetaTags>}




               {this.state.backgroundimage  &&
                   <section className="container-fluid catagory-top-img" style={{
                   backgroundImage: "url("+baseImgUrl + this.state.backgroundimage + ")",
                   // backgroundPosition: 'center',
                   backgroundSize: 'cover',
                   backgroundRepeat: 'no-repeat'
               }}>
                   <div className="container">
                       <div className="row text-center d-block">
                           <h2 className="banner-font"></h2>
                       </div>
                   </div>
               </section>
               }
                <section className="filters-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="fillter-sec">
                                    {this.state.catItem && this.state.catItems && this.state.cities
                                    && this.state.days && this.state.rating && this.state.timings
                                    &&

                                    <Fragment>

                                        <div className="fliter-mobile">
                                            <button className="filter-mobile-btn" onClick={this.togglefilter}><i
                                                className="fas fa-filter"></i> Filter
                                            </button>

                                            {this.state.displayFilterMenu &&

                                            <div className="filter-mobile-open">
                                                <ul>
                                                    <li className="mobilefilter-title" onClick={this.togglefilter}><i
                                                        className="fas fa-filter"></i> Filter
                                                    </li>


                                                    {(this.state.catItem && this.state.catItem.children.length > 0) ?
                                                        <li><MultipleSelect width={"130px"}  setFilters={(filter) => this.setFilters(filter)}  type={"category"} className="all-category" key={"category"}
                                                                            title={"All Categories"}
                                                                            items={this.state.catItem.children}/>
                                                        </li> : <li className={"d-none"}></li>
                                                    }


                                                    <li className="active"><MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"cities"} key={"cities"}
                                                                                           title={"By The City"}
                                                                                           items={this.state.cities}/>
                                                    </li>
                                                    <li><MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"days"} key={"days"} title={"By The Day"}
                                                                        items={this.state.days}/></li>
                                                    <li><MultipleSelect width={"115px"} setFilters={(filter) => this.setFilters(filter)}  type={"timings"} key={"timings"} title={"By The Time"}
                                                                        items={this.state.timings}/></li>
                                                    <li><CheckboxSingleSelect width={"165px"} setFilters={(filter) => this.setFilters(filter)}  type={"activityPreference"} key={"locations"} title={"Location Preference"}
                                                                        items={this.state.activityPreference}/></li>
                                                    <li><StarSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"ratings"} key={"rating"} title={"Ratings"}
                                                                    items={this.state.ratings}/></li>
                                                    <li><PriceRangePopover setFilters={(filter) => this.setFilters(filter)}  type={"price"}/></li>
                                                    <li><CheckboxSingleSelect width={"100px"} setFilters={(filter) => this.setFilters(filter)}  type={"sort"} key={"sortBy"} title={"Sort By"}
                                                                      items={this.state.sortBy}/></li>
                                                    <li><Button className="blue-btn" onClick={this.togglefilter}>Apply
                                                        Now</Button></li>
                                                </ul>
                                            </div>
                                            }

                                        </div>

                                        {this.state.reloadFilters &&
                                        <div className="filter-web">

                                            {(this.state.catItem && this.state.catItem.children.length > 0) &&
                                            <MultipleSelect width={"130px"} setFilters={(filter) => this.setFilters(filter)}  type={"category"} className="active"  key={"category"} title={"All Categories"}
                                                            items={this.state.catItem.children}/>
                                            }

                                            <MultipleSelect  width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"cities"} className="active" key={"cities"} title={"By The City"}
                                                            items={this.state.cities}/>
                                            <MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"days"}  key={"days"} title={"By The Day"} items={this.state.days}/>
                                            <MultipleSelect width={"115px"} setFilters={(filter) => this.setFilters(filter)}  type={"timings"}  key={"timings"} title={"By The Time"}
                                                            items={this.state.timings}/>



                                            <CheckboxSingleSelect width={"165px"} setFilters={(filter) => this.setFilters(filter)}  type={"activityPreference"}  key={"locations"} title={"Location Preference"}
                                                          items={this.state.activityPreference}/>


                                            <CheckboxSingleSelect width={"100px"} setFilters={(filter) => this.setFilters(filter)}  type={"sort"} key={"sortBy"} title={"Sort By"} items={this.state.sortBy}/>
                                            <StarSelect width={"100px"} setFilters={(filter) => this.setFilters(filter)}  type={"ratings"} className="rating-block" key={"ratings"} title={"Ratings"}
                                                        items={this.state.ratings}/>
                                            <PriceRangePopover  width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"price"} />
                                            <Button onClick={this.getActiveFiltersQuery} className="blue-btn d-none">Filter</Button>

                                        </div>}
                                    </Fragment>



                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="listing-section pb-5">
                    <div className="container">


                        {this.state.displayNoResults &&   <div className="row justify-content-md-center keep-revisitng">


                                <Col xs lg="8" className={"m-5 block-title text-center "} >

                                    <h4  className={"m-5"} >Oops! No results found. Try different category...</h4>

                                </Col>



                        </div>}

                            <div className="row mb-5">

 {
                            this.state.activityItems.map((activityitem)=>
                                <>

                            {activityitem.images && (activityitem.images.length >0) &&


                                <>

                            {/*<ActivityItem key={"list-"+activityitem.id} item={activityitem} ></ActivityItem>*/}
                                    <TrendingSectionItem item={activityitem}/>


</>


                            }
                            </>


                            )
                            }


                                </div>

                        {this.state.displayLoadMore && <Row  className="justify-content-md-center m-5 ">

                            <Col xs lg="2"  >
                                <Button onClick={this.loadMoreClick}
                                    size="lg" variant="outline-primary" block>
                                    Load More

                                </Button>
                            </Col>

                        </Row>}



                        {!this.state.displayLoadMore && this.state.activityItems.length>0 && this.state.activityItems.length<10 &&
                        <Row className="row justify-content-md-center keep-revisitng">




                                <Col xs lg="6" className={"m-3 block-title text-center"} >
                                    <h4  className={"m-5"} >Keep revisiting as more experiences coming every week...


                                    </h4>
                                </Col>


                        </Row>
                        }


                        </div>

                </section>
           </div>

            </>
         )
    }
}


function StarSelect(props) {

    const [active, setActive] = React.useState(false);
    const classes = useStyles();
    const [item, setItem] = React.useState('');

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {
        setItem(event.target.value);
        setActive(true);


        if (props.type==="ratings") {

            props.setFilters({"name":props.type,"value":event.target.value})


        }


    };

    return (


        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}

            >
                <MenuItem   value={"Ratings"}>
                    All
                </MenuItem>

                <MenuItem value={5}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/></MenuItem>
                <MenuItem value={4}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star color="disabled"/></MenuItem>
                <MenuItem value={3}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled"/></MenuItem>
                <MenuItem value={2}><Star style={{color: "#29abe2"}} /><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled"/><Star color="disabled"/></MenuItem>

                <MenuItem value={1}><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled" /><Star color="disabled"/><Star color="disabled"/></MenuItem>

            </Select>
        </FormControl>

    );
}

function CheckboxSingleSelect(props) {


    const classes = useStyles();
    const [item, setItem] = React.useState('');
    const [active, setActive] = React.useState(false);
    const [checked, setChecked] = React.useState(true);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    },[]);

    const [categoryName, setCategoryName] = React.useState([]);


    const handleChange = event => {

        console.log("checkox single select ")
        console.log(event.target.value)
        console.log(event)

        setCategoryName(event.target.value);

        setItem(event.target.value);



        setActive(true);

        if(event.target.value==="All"||event.target.value===props.title) {

            setChecked(true);

        }else{

            setChecked(false);

        }

        var selectedOption = props.items.filter((item)=> item.name==event.target.value)[0]

        if (props.type==="activityPreference") {

            if (selectedOption)

                props.setFilters({"name":props.type,"value":selectedOption.id})

            else

                props.setFilters({"name":props.type,"value":null})

        }

        if (props.type==="sort") {

            if (selectedOption)

            props.setFilters({"name":props.type,"value":selectedOption.id})
        else
            props.setFilters({"name":props.type,"value":null})


        }




    };

    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                renderValue={ selected => selected }
            >


                {/*<MenuItem   value={props.title}>*/}
                    {/*<ListItemText primary={"All"} />*/}
                {/*</MenuItem>*/}


                <MenuItem   value={props.title}>
                    <Checkbox checked={checked} />
                    <ListItemText primary={"All"} />
                </MenuItem>

                {props.items.map((item)=>


                    <MenuItem key={"key-"+item.id} data-id={item.name}  value={item.name}>
                        <Checkbox checked={categoryName===(item.name)} />
                        <ListItemText primary={item.name} />
                    </MenuItem>

                )}
            </Select>
        </FormControl>

    );
}


function SimpleSelect(props) {


    const classes = useStyles();
    const [item, setItem] = React.useState('');
    const [active, setActive] = React.useState(false);


    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {

        setItem(event.target.value);
        setActive(true);


        if (props.type==="activityPreference") {

            props.setFilters({"name":props.type,"value":event.target.value})

        }

        if (props.type==="sort") {


            props.setFilters({"name":props.type,"value":event.target.value})


        }




    };

    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
            >

                {props.items.map((item)=>

                    <MenuItem key={"key-"+item.id} value={item.id}>{item.name}</MenuItem>


                )}

            </Select>
        </FormControl>

    );
}




function PriceRangePopover(props) {


    const [show, setShow] = React.useState(false);

    const [value, setValue] = React.useState([0, 1000]);
    const [active, setActive] = React.useState(false);


    const useMyStyles = makeStyles(theme => ({
        typography: {
            padding: theme.spacing(2),
        },
    }));
    const classes = useMyStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);

    };

    const handleChange = (event,newValue) => {

        setShow(true)
        setValue(newValue)
        console.log( newValue)
        setActive(true);

        props.setFilters({"name":props.type,"value":newValue})



    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={active?" active "+" price-range":"price-range"} >
            <Button
                className={active?" active "+classes.formControl:classes.formControl}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
            >{show ? "AED "+value[0]+"-"+"AED "+value[1]:"Price Range"} <ArrowDropDownIcon/>
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>

                    <AirbnbSlider
                        onChange={handleChange}
                        ThumbComponent={AirbnbThumbComponent}
                        getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
                        defaultValue={

                            [value[0], value[1]]
                        }
                        min={0}
                        max={1000}
                    />

                    <Row>

                        <Col className="price-text">
                            <InputGroup className="mb-3">
                                <InputGroup.Append>
                                    <InputGroup.Text style={{backgroundColor:"transparent", border:"none"}}>AED</InputGroup.Text>
                                    <InputGroup.Text style={{backgroundColor:"transparent"}}>{value[0]}</InputGroup.Text>
                                </InputGroup.Append>

                                <InputGroup.Append>
                                    <InputGroup.Text style={{backgroundColor:"transparent", border:"none"}}>To</InputGroup.Text>

                                    <InputGroup.Text style={{backgroundColor:"transparent"}} >{value[1]}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                </Typography>



            </Popover>
        </div>
    );
}

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
        {/*<span className="bar" />*/}
            {/*<span className="bar" />*/}
            {/*<span className="bar" />*/}
      </span>
    );
}


const AirbnbSlider = withStyles({
    root: {
        color: '#00adee',
        height: 3,
        padding: '13px 0',
        width: 220 + 5 * 2,

    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid #eeeeee',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0px 2px 2px',
        '&:focus,&:hover,&$active': {
            boxShadow: '#ccc 0px 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: '#00adee',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },

})(Slider);

function MultipleSelect(props) {


    const [active, setActive] = React.useState(false);

    const classes = useStyles();

    const [checked, setChecked] = React.useState(true);

    const theme = useTheme();

    const [categoryName, setCategoryName] = React.useState([]);

    const handleChange = event => {


        var values=[]

        for(var i=0; i<props.items.length;i++){


                if (event.target.value.indexOf(props.items[i].name)>-1){

                    values.push(props.items[i].id)
                }


            }


        if (props.type==="category") {

            props.setFilters({"name":props.type,"value":values})


        }

       else if (props.type==="cities") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="days") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="timings") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="activityPreference") {

            props.setFilters({"name":props.type,"value":values})


        }

        console.log("checked valued")
        console.log(event.target.value)


        if(!(categoryName.indexOf(props.title)!==-1)&&event.target.value.length >0 && event.target.value.indexOf(props.title)!==-1) {

                setCategoryName([props.title])

                setChecked(true);

                props.setFilters({"name":props.type,"value":[]})
                setActive(false);


            // alert("all")
        }else {

                // alert("other")

             var removedTitleArray=event.target.value.filter(x=> x!== props.title)

            setCategoryName(removedTitleArray);

            setActive(true);

            setChecked(false);


        }


        console.log("cat name ")

        console.log(categoryName)
        console.log("value ")

        console.log(event.target.value)



    };


    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>

            <InputLabel id="demo-mutiple-checkbox-label">{props.title}</InputLabel>

            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={categoryName}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}

            >

                <MenuItem   value={props.title}>
                    <Checkbox checked={checked} />
                    <ListItemText primary={"All"} />
                </MenuItem>

                {
                    props.items.map(item => (
                    <MenuItem key={item.id} data-id={item.id}  value={item.name}>
                        <Checkbox checked={categoryName.indexOf(item.name) > -1} />
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))
                }

            </Select>
        </FormControl>



    );
}


function SingleSelect(props) {



    const [active, setActive] = React.useState(false);


    const classes = useStyles();


    const theme = useTheme();
    const [categoryName, setCategoryName] = React.useState([]);

    const handleChange = event => {

        setCategoryName(event.target.value);
        setActive(true);


        console.log(event.target.value)

        if (props.type==="activityPreference") {

            props.setFilters({"name":props.type,"value":event.target.value})

        }

        if (props.type==="sort") {


            props.setFilters({"name":props.type,"value":event.target.value})


        }






    };


    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">{props.title}</InputLabel>
            <Select
                // multiple
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                value={categoryName}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected}
                MenuProps={MenuProps}
            >
                <MenuItem   value="Location Preferences">
                <ListItemText primary={"All "} />
                </MenuItem>

                {props.items.map(item => (
                    <MenuItem key={item.id} data-id={item.id}  value={item.name}>
                        <Checkbox checked={categoryName.indexOf(item.name) > -1} />
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>



    );
}




const mapStateToProps = state => {
    return {
        categories: state.categories,
        // loading: state.loading,
        // userDetail: state.userDetail,
        // salesQuote: state.salesQuote,
        // cartTotal: state.cartTotal,
        // couponDiscountVisible : state.couponDiscountVisible,
        // total: state.total,
        // subTotal: state.subTotal,
        // cartDiscount: state.cartDiscount,
        // couponDiscount: state.couponDiscount,
        // couponCode: state.couponCode,
        // couponDiscountPercentage: state.couponDiscountPercentage,
        // couponCheckloading: state.couponCheckloading,
        // couponError : state.couponError,
        // addressInput : state.addressInput,
        // isGuest : state.isGuest,
        // isLoggedIn : state.isLoggedIn



    };
};

const mapDispachToProps = dispatch => {
    return {

        // onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        // onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        // addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        // removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item)),
        // addCoupon : (item)=> dispatch(actionCreator.addCoupon(item)),
        // setBookingResponse  : (item)=> dispatch(actionCreator.setBookingResponse(item)),
        // loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loading : (item) => dispatch(actionCreator.loading(item)),
        stopLoading : (item) => dispatch(actionCreator.stopLoading(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(ListingNew);

