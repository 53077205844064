import React, {Component, Fragment} from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import history from "../History/history";
import axios from "axios/index";
import {loginFailed} from "../store/actions/actions";
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {baseUrl,baseImgUrl} from  '../Util/Constants'





const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


class  FavoriteButton extends Component{

    item;
    constructor(props){


        super(props)

        this.item = props
        this.state = {

            isFavorite : false,
            showSnackbar : false
        };

        this.addFavorite= this.addFavorite.bind(this)
        this.removeFavorite= this.removeFavorite.bind(this)
        this.goToLoginPage= this.goToLoginPage.bind(this)

    }



    componentDidMount(){

        var filterActivities =  this.props.favorites.filter(item => item.id === this.props.item.id)

        if (filterActivities.length>0){

            this.setState({

                isFavorite : true
            })

        }

    }

    goToLoginPage(e){

        console.log(e)

        e.preventDefault()

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // history.push("/login")

        this.props.loginPopUp(true)

        return false

    }


    removeFavorite(e){

        e.preventDefault()

        var activityId= parseInt(e.currentTarget.dataset.id);
        var customerId= this.props.userDetail.id


        axios.post(baseUrl+"customer_favorites" ,{ removeActivity: activityId ,customer: customerId  }).then(res => {

            // alert("success")



            this.setState({

                isFavorite : false
            })


            this.props.loadFavorites(this.props.userDetail)



        }).catch(error => {

            console.log("favorites error found "+error)


        });



    }
    addFavorite(e){


        e.preventDefault()


        var activityId= parseInt(e.currentTarget.dataset.id);
        var customerId= this.props.userDetail.id

        axios.post(baseUrl+"customer_favorites" ,{ activity: activityId ,customer: customerId  }).then(res => {


            this.setState({

                isFavorite : true,
                showSnackbar : true

            })


            this.props.loadFavorites(this.props.userDetail)


        }).catch(error => {

            console.log("favorites error found "+error)


        });



    }


    hideSnakbar(){

        this.setState({

            showSnackbar : false

        })


    }




    render (){


        return(
            <>

                { this.props.isLoggedIn ?

                    <button data-id={this.props.item.id}
                         onClick={this.state.isFavorite? this.removeFavorite.bind(this):this.addFavorite.bind(this)}
                            style={{backgroundColor: "transparent", border: "none"}}
                         className="btn-heart heart">

                        { this.state.isFavorite ?
                            <i className="fa fa-heart favorite"></i>
                            : <i className="far fa-heart"></i>
                        }
                        </button> :

                    <button data-id={this.props.item.id}
                            onClick={this.goToLoginPage.bind(this)} style={{backgroundColor: "transparent", border: "none"}}
                            className="btn-heart heart">
                        <i className="far fa-heart"></i>

                    </button>

                }

            </>

        )
    }
}



const mapStateToProps = state => {
    return {
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        favorites: state.favorites



    };
};

const mapDispachToProps = dispatch => {
    return {

        addFavoriteItem: (data) => dispatch(actionCreator.addFavoriteItem(data)),
        removeFavoriteItem: (data) => dispatch(actionCreator.removeFavoriteItem(data)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loadFavorites : (item) => dispatch(actionCreator.loadFavorites(item)),


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(FavoriteButton);


