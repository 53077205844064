import React, { Component } from 'react';

import { Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags';


class  Terms extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }


    render (){
        return(
            <div className="bg-gray">

                <MetaTags>

                    <title>General Booking Terms & Conditions | Happy Weekends</title>
                    <meta name="title" content=" General Booking Terms & Conditions | Happy Weekends" />
                    <meta name="description" content="Happy Weekends the online marketplace is essentially a platform that allows its registered customers to search, view, book, purchase and participate in the experiences listed on the platform to make their weekends special and memorable. Customer can choose the experiences based on the descriptions. " />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content=" General Booking Terms & Conditions | Happy Weekends" />
                    <meta property="og:description" content="Happy Weekends the online marketplace is essentially a platform that allows its registered customers to search, view, book, purchase and participate in the experiences listed on the platform to make their weekends special and memorable. Customer can choose the experiences based on the descriptions. " />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content=" General Booking Terms & Conditions | Happy Weekends" />
                    <meta property="twitter:description" content="IHappy Weekends the online marketplace is essentially a platform that allows its registered customers to search, view, book, purchase and participate in the experiences listed on the platform to make their weekends special and memorable. Customer can choose the experiences based on the descriptions. " />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>
                <section className="container-fluid term-condition-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 how-work-txt text-center">
                                <h2 className="">Terms And Conditions</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{padding:"10px"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-hd-main">
                                    <h2 className="best-family-txt text-center">GENERAL BOOKING TERMS AND CONDITIONS</h2>
                                    <p className="term-condi-pg text-center">Before you book please make sure you fully understand our terms and conditions as stated below</p>
                                    <h2 className="updated-date text-center">Updated on : October 2, 2019</h2>
                                    <div className="term-condi-scroll">
                                        <p className="eugait-txt">1.	About Happy Weekends</p>
                                        <p className="term-condi-pg">1.1	Ownership</p>
                                        <p>This website, mobile application, proprietary platform and any service offered under the name “<strong>Happy Weekends</strong>” (collectively referred to as “<strong>Online Marketplace</strong>”) is operated by Meraki Digital for Marketing and Entertainment Services L.L.C (a limited liability company incorporated under the laws of Emirate of Abu Dhabi holding professional licence number CN-2818859) and its related entities or body corporates (hereinafter referred to as “<strong>Happy Weekends</strong>”, “<strong>us</strong>”, “<strong>we</strong>” or “<strong>our</strong>”).</p>
                                        <p>1.2	What does the Online Marketplace offer?</p>
                                        <p>The Online Marketplace is essentially a platform that allows its registered customers ("<strong>Customers</strong>") to search, view, book,purchase and/or participate in the experiences listed on the platform to make their weekends special and memorable. The Customer can choose the experiences based on the description of each experience provided on the Online Marketplace (“<strong>Experience Listing</strong>”). </p>
                                        <p>The experiences listed on the platform are mostly provided by registered third party service providers ("<strong>Hosts</strong>") who publish the Experience Listing of the experiences they are offering for sale (“<strong>Host Experiences</strong>”). The Hosts and the Customers can communicate and transact directly through the Online Marketplace and Happy Weekends will only act as an intermediary between the Customers and the Hosts.The Customers and Hosts are collectively referred to “<strong>Users</strong>” and individually as a “<strong>User</strong>”, “<strong>you</strong>” or “<strong>your</strong>”.</p>
                                        <p>There may also be instances where Happy Weekends may organise certain experiences in association with the Hosts, third parties or its related entities (“<strong>Other Experiences</strong>”). The Experience Listings for such Other Experiences will provide the description of the experience and also describe the roles, responsibilities and liabilities of the parties involved. Please refer to Clause 6 for further details.</p>
                                        <p>Host Experiences and Other Experiences are collectively referred to as “<strong>Experiences</strong>” and individually as “<strong>Experience</strong>”.</p>
                                        <p className="term-condi-pg">1.3	Our Services</p>
                                        <p>The services provided as above by Happy Weekends by way of the Online Marketplace are hereinafter collectively referred to the "Services".We reserve the right to modify or discontinue, temporarily or permanently, our Services and/or Online Marketplace (or any part thereof) with or without notice.</p>
                                        <p className="eugait-txt">2.	Acceptance of Terms of Use </p>
                                        <p className="term-condi-pg">2.1	Agreement</p>
                                        <p>These Terms of Use, together with our  Privacy Policy, and any other policy displayed on the Online Marketplace(collectively, the “Terms”), govern all Users access to and use of the Online Marketplace and our Services, and constitute a legally binding agreement between every User and Happy Weekends. </p>
                                        <p className="term-condi-pg">2.2	Acceptance of Terms</p>
                                        <p>By downloading, accessing or otherwise using the Online Marketplace and/or our Services, you confirm that you have read, understood and agreed to these Terms. If you do not agree to the Terms, you must not use the Online Marketplace.Hosts are further governed by the terms and conditions under the ‘<strong>Host Agreement</strong>’ entered into separately between the Hosts and Happy Weekends.</p>
                                        <p className="term-condi-pg">2.3	Modification</p>
                                        <p>We may amend or modify the platform supporting the Online Marketplaceand/or the Terms at our sole discretion and at any time and may notify you of such changes by way of e-mail.  Any amendments are effective immediately upon publication on the Online Marketplace. If you disagree with the revised Terms, you may terminate the legal agreement between us as per the terms contained below, subject to any termination obligations that you may have. Your continued use of the Online Marketplace indicates your continued acceptance of the Terms as modified. We encourage you to review these Terms periodically to stay informed about our current policies and practices.</p>
                                        <p className="term-condi-pg">2.4	Access and Use</p>
                                        <p>Access to and use of this Online Marketplace is subject to you being:</p>
                                        <ul>
                                            <li>At least 18 years old and having the legal capacity to enter into a binding contract; and</li>
                                            <li>If representing an entity, then disclosing all information to us as necessary and indicated in the Online Marketplace. </li>
                                        </ul>
                                        <p>Accordingly, by using the Online Marketplace and/or providing or accepting any Experience, you warrant the above to us. If the above conditions are not satisfied, please cease using the Online Marketplace immediately.</p>
                                        <p className="eugait-txt">3.	Happy Weekends Account and Registration</p>
                                        <p className="term-condi-pg">3.1	Registration</p>
                                        <p>You may be required to register on the Online Marketplace to access certain features of the Online Marketplace. You can do this via our website/mobile appor through your account with certain third-party social networking services such as Facebook or Google (each, an “<strong>SNS Account</strong>”).</p>
                                        <p className="term-condi-pg">3.2	Information for Registration</p>
                                        <ul>
                                            <li>When you register and activate your account, you will provide us with personal information such as your name, email address, the name of the legal entity you are representing and other details. You must ensure that all this information is accurate and current. </li>
                                            <li>If you choose to register through an SNS Account, we willcreate your account by extracting certain personal information from your SNS Account such as your name and email address and other personal information that your privacy settings on the SNS Account permit us to access.</li>
                                            <li>On registration, we may also ask for any other information which we may deem reasonably necessary to enable you to enjoy the benefits of the Online Marketplace. We may require you to provide evidence and documents confirming certain information. </li>
                                        </ul>
                                        <p className="term-condi-pg">3.3	Account Credentials </p>
                                        <p>While registering an account with us, you will create a username and password. You are responsible for keeping this username and password secure and are responsible for all use and activity carried out under this username. You must not share your account credentials with any third party. You agree to </p>
                                        <ul>
                                            <li>immediately notify us of any unauthorised use of your account, password, or any other breach of security; and </li>
                                            <li>ensure that you exit from your account at the end of each session. </li>
                                        </ul>
                                        <p>We will not be liable for any loss or damage arising from any kind of unauthorised activity that takes place under your accountor your failure to comply with this provision.</p>
                                        <p className="term-condi-pg">3.4	Additional Obligations of Host</p>
                                        <p>If you are a Host, you may be required to furnish further details and information to us including details related to the experiences being offered by you. We reserve the right to approve or reject any Host application for any reason whatsoever without giving any reason. If you are approved and you agree to the terms and conditions of the ‘<strong>Host Agreement</strong>’ made available to you in connection with the application process, you will be deemed to be a ‘Host’ for the Online Marketplace. The Host Agreement contains terms regarding the creation and posting of Experience Listings, how and what the Hosts get paid for their Host Experiences and other specific terms.</p>
                                        <p className="term-condi-pg">3.5	Cancellation of Account</p>
                                        <ul>
                                            <li>If you provide any information that is untrue, inaccurate, not current, or incomplete, or we have reasonable ground to suspect that such information is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Online Marketplace.</li>
                                            <li>You must not impersonate some other individual, business or company. In case you try to present yourself as another individual or company, your account may be suspended, and legal action may be taken against you.</li>
                                            <li>You agree not to create an account or use the Online Marketplace if you have been previously removed or suspended by us from the Online Marketplace.</li>
                                        </ul>
                                        <p className="eugait-txt">4. Your Privacy</p>
                                        <p className="term-condi-pg">4.1	Personal Information</p>
                                        <p>We take the privacy of your Personal Information (defined in the Privacy Policy) very seriously. We encourage you to carefully review our Privacy Policy for important disclosures about ways that we may collect, use, and share personal data and your choices. Our Privacy Policy is incorporated in and subject to these Terms of Use, and available <Link to="/privacy">here</Link>.</p>
                                        <p className="term-condi-pg">4.2	Alerts/Notification</p>
                                        <ul>
                                            <li>As part of the Services we provide, you may (if enabled) receive push notifications, text messages, alerts, emails, or other types of messages directly sent to you outside or inside the Online Marketplace (“<strong>Notifications</strong>”). You may also receive calls to your designated phone number using an auto-dialer or a pre-recorded voice. By agreeing to these Terms, you expressly and specifically authorize Happy Weekends to call you or send Notifications as above.</li>
                                            <li>You have control over the Notification and call settings and can opt in or out of these communications through the Online Marketplace (with the possible exception of infrequent, important service announcements and administrative messages). </li>
                                            <li>You may incur certain third party charges for receiving some of the Notification depending on the message plan you have with your wireless carrier.</li>
                                        </ul>
                                        <p className="eugait-txt">5.	Your conduct</p>
                                        <p className="term-condi-pg">5.1	All interactions on the Online Marketplace must comply with the Terms. To the extent your conduct, in our sole discretion, restricts or inhibits any other User from using or enjoying any part of the Online Marketplace, we may limit or terminate your privileges and seek other remedies, including, without limitation, cancellation of your account.</p>
                                        <p className="term-condi-pg">5.2	Necessary Conduct</p>
                                        <p>In using the Online Marketplace, you must: </p>
                                        <ul>
                                            <li>Always act courteously and politely to us and to any other User;</li>
                                            <li>Strictly comply with any policy displayed on the Online Marketplace; and</li>
                                            <li>Obey all laws whatsoever (including international law) which may apply in respect of your use of the Online Marketplace and ensure you do not do anything which is likely to result in you or us breaching any law, regulation, rule, code or other legal obligation.</li>
                                        </ul>
                                        <p className="term-condi-pg">5.3	Prohibitions</p>
                                        <p>In using the Online Marketplace, you must not: </p>
                                        <ul>
                                            <li>Access, copy, or otherwise use the Online Marketplace including our intellectual property and trademarks, except as authorised by these Terms of Use or as otherwise authorised by us in writing;</li>
                                            <li>Use the Services for any commercial, governmental or other purposes that are not expressly permitted by these Terms;</li>
                                            <li>Offer to purchase under a false name, impersonate any participant, or use another participant's password(s) or engage in any other fraudulent conduct or conduct intended to interfere with the orderly conduct of business on the Online Marketplace;</li>
                                            <li>Register for more than one account or register for an account on behalf of an individual other than yourself or register under a false name or use an invalid or unauthorized credit card;</li>
                                            <li>Accept or make payments for an Experience outside of the Online Marketplace;</li>
                                            <li>Take any action that is likely to impose upon the Online Marketplace (or our servers, or the servers of our or third-party suppliers) a disproportionately large load;</li>
                                            <li>Interfere with the proper working of the Online Marketplace or any activities conducted via the Online Marketplace, including by using any automated or manual software or process to "crawl", "spider" or engage in similar conduct in relation to the Online Marketplace;</li>
                                            <li>Reverse engineer or otherwise seek to obtain any source code forming part of the Online Marketplace;</li>
                                            <li>Collect or retain any personally identifiable information contained in the Online Marketplace;</li>
                                            <li>Access the Online Marketplace by any means other than authorised herein, including virtual private networks which are expressly forbidden; </li>
                                            <li>Stalk, harass, bully or harm others; </li>
                                            <li>Impersonate any person or entityor otherwise misrepresent yourself or your affiliation with any person or entity; or </li>
                                            <li>Advocate, encourage, or assist any third party in doing any of the foregoing.</li>
                                        </ul>
                                        <p className="eugait-txt">6.	Our Role</p>
                                        <p className="term-condi-pg">6.1	Our role in Host Experiences</p>
                                        <ul>
                                            <li>We are an Intermediary: The Customer agrees and acknowledges the following:
                                                <ul>
                                                    <li>The Host Experiences are provided solely by the Hosts via the Online Marketplace and we are not responsible for the execution of such Host Experiences;</li>
                                                    <li>We do not endorse any Host Experience or set or control the prices displayed for the listed Host Experiences;</li>
                                                    <li>In case of certain Host Experiences, our team may provide creative inputs to the Host and help the Hosts in implementing these ideas in order to enhance the user experience. However, the delivery of such Host Experience will continue to remain the responsibility of the concerned Host and all liabilities arising therefrom shall be to the sole account of the concerned Host;and</li>
                                                    <li>We only act as an intermediary between the Customer and the Host and our responsibilities under these Terms are limited to facilitating the availability of the Services and collecting payments on behalf of the Hosts via the Online Marketplace. </li>
                                                </ul>
                                            </li>
                                            <li>Customer’s Interaction with Host: Certain Host Experiences will include the ability of a Customer to interact with a Host. We do not, and cannot control such interactions with the Hosts and we expressly disclaim any liability arising from such interactions. </li>
                                            <li>No Warranty: It is our constant endeavour to facilitate provision of high quality Host Experiences to the Customers via the Online Marketplace with the Hosts maintaining top quality service standards and providing safe and enjoyable experiences to the Customers. Towards this motto, we do undertake reasonable quality control measures and carry out necessary checks from time to time on the Hosts before and after they are listed on the Online Marketplace. However, the Customer understands and acknowledges that despite all such measures, we cannot provide any warranty or guarantee whatsoever with respect to:
                                                <ul>
                                                    <li>the quality, safety, suitability, or legality of any Experience Listings or Host Experiences; </li>
                                                    <li>the truth or accuracy of the descriptions of any Experience Listing; or </li>
                                                    <li>the performance or conduct of any Host. </li>
                                                    <li>In case the Customer has any bad experience with a Host or is not satisfied with the quality of a Host Experience, we encourage the Customer to report us the same as per clause 21.2 so that we can undertake the corrective measures against the reported Host or the concerned Host Experience.</li>
                                                    <li>No Liability: By using our Services for Host Experiences, you agree that any legal remedy or liability that you seek to obtain which arise from or is related to a Host Experiencewill be limited to a claim against the particular User or other third parties who caused you harm.You agree not to attempt to impose liability on Happy Weekends, or seek any legal remedy from Happy Weekends with respect to Host Experiences. Accordingly, you provide us with the indemnities and releases in clause 18.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p className="term-condi-pg">6.2	Our role in Other Experiences</p>
                                        <p>We may organise certain Other Experiences i.e., experiences that may be organised by us in association with one or more Hosts, third parties or our related entities. However, our liability will be strictly limited to the extent of our involvement in the concerned Other Experience and will be specified in detail in the terms applicable to such Other Experience. The Hosts, third parties or other related parties will continue to be responsible for their respective role in the Other Experience and liabilities arising from the Other Event will be attributed in accordance with the roles and responsibilitiesof the parties involved. Happy Weekends is merely an aggregator of experiences and cannot be held solely liable for the Other Experiences offered through the Online Marketplace.</p>
                                        <p className="eugait-txt">7.	Booking/Purchasing Experiences</p>
                                        <p className="term-condi-pg">7.1	Compliance with Listing Terms </p>
                                        <ul>
                                            <li>Prior to booking any Experience, the Customer must review the details provided in the Experience Listing of that Experience and ensure that the Customer meets any minimum age, proficiency, fitness or other terms and conditions which the Experience Provider has specified in the Experience Listing (“Listing Terms”).</li>
                                            <li>In the event the Customer has any medical, physical or mental conditions, or other circumstances that may impact his/her ability to safely participate in any Experience, he/she must not participate in the Experience. </li>
                                            <li>If the Customer booksan Experience on behalf of additional guests, he/she must ensure that every additional guest meets the Listing Terms, and is made aware of and agrees to the Listing Terms and these Terms. </li>
                                            <li>If the Customer is booking for an additional guest who is a minor, the Customer represents and warrants that he/she is legally authorised to act on behalf of the minor and the minor meets and is made aware of the Listing Terms. Minors may only participate in an Experience if accompanied by an adult who is responsible for them.</li>
                                        </ul>
                                        <p className="term-condi-pg">7.2	Compliance with Applicable Laws</p>
                                        <p>In addition to the Listing Terms, certain laws, like the minimum legal drinking age in the location of the Experience, may also apply. The Customer is solely responsible for identifying, understanding, and complying with all laws, rules and regulations that apply to his/her participation in any Experience.</p>
                                        <p className="term-condi-pg">7.3	Result of Non-Compliance</p>
                                        <p>Breach of any Listing Terms may result in cancellation of bookings, associated benefits and/or attract additional charges.We are not liable in any way for any loss or injury caused to the Customer or its guests due to their negligence in observing the Listing Termsor breach of applicable laws and regulations, and accordingly, the Customer provides us with the indemnities and releases in Clause 18.</p>
                                        <p className="term-condi-pg">7.4	Booking/Purchasing an Experience</p>
                                        <ul>
                                            <li>Subject to these Terms and any Listing Terms, a Customer may book or purchase Experiences available on the Online Marketplace by following the applicable booking process specified with the concerned Experience. </li>
                                            <li>We reserve the right to modify any prices published on the Online Marketplace for the Experiences at any time and the Customer should refer to the latest published prices before making any booking or purchase.</li>
                                            <li>The Customer may be required to sign and return a waiver and liability release before he/she participates in an Experience.</li>
                                        </ul>
                                        <p className="eugait-txt">8.	Payment for Experiences</p>
                                        <p className="term-condi-pg">8.1	Experience Fees</p>
                                        <p>All applicable Experience Fees and any applicable taxes (collectively, “Total Fees”) will be presented to the Customer prior to booking or purchasing an Experience. The Customer agrees to pay the Total Fees when due and payable as required for any booking or purchase via the payment method options available on the Online Marketplace.</p>
                                        <p>All expenses and incidental costs not specifically stated in the Experience Listing and/or receipt, including, but not limited to, telephone charges, data/connectivity charges, expenses related to travel, food or lodging, etc. are to the sole account of the Customer.</p>
                                        <p className="term-condi-pg">8.2	Billing Information</p>
                                        <p>When the Customer initiates a booking or a purchase transaction as above, he/she will be asked to provide us with customary billing information such as name, billing address, and credit card or other payment instrument information.The Customer represents and warrants that any such information that he/she provides is true and accurate, and that the Customer is authorised to use the chosen payment instrument. </p>
                                        <p>The Customer must promptly update his/her account information with any changes (for example, a change in billing address or credit card expiration date) that may occur. </p>
                                        <p className="term-condi-pg">8.3	Payment</p>
                                        <p>Upon successful booking/purchase of an Experience on the Online Marketplace and payment of the Total Fees, the Customer will be issued a confirmation/voucher which shall be used by the Customer for availing the Experience on the booked date. </p>
                                        <p>If the Customer wishes to dispute any charge(s), he/she must inform us within seven (7) days after the date of making such payment(s).</p>
                                        <p className="eugait-txt">9.	Service Agreement between Customer and Host</p>
                                        <p className="term-condi-pg">9.1	When a Customer books or purchases any Host Experience through the Online Marketplace and makes the payment as per Clause 8 above, the Customer and the Host will be deemed to have entered into a separate service agreement (“Service Agreement”) which will govern the relationship and the transaction between the Customer and the Host. Any confirmation/voucher issued by the Host directly or through the Online Marketplace to the Customer shall form a part of the Service Agreement.</p>
                                        <p className="term-condi-pg">9.2	Even though we undertake reasonable quality control measures and carry out necessary checks from time to time on the Hosts to ensure high quality standards of all Host Experiences,our role is limited in case of Host Experiences, as specified in Clause 6.1. Therefore, the Customer and the Host acknowledge and agree that:</p>
                                        <ul>
                                            <li>a Service Agreement is a separate agreement directly between the Customer and the Host in relation to every Host Experience. We are not a party to the Service Agreement in any way, and its formation will not, under any circumstance, create any relationship of employment, agency, partnership or otherwise between us and the Customer, or us and the Host;</li>
                                            <li>each Host is running a separate, independent business from our business. There is no relationship between us and the Host beyond that of independent contractors; and</li>
                                            <li>we retain the Online Marketplace Fee from the Experience Fee payable to the Host as part of an independent contract between us and the Host, and that this does not constitute any relationship between us and the Host beyond that of independent contractors.</li>
                                        </ul>
                                        <p className="eugait-txt">10.	Change Request</p>
                                        <p className="term-condi-pg">10.1	Once an Experience is booked by the Customer, unless the terms of Experience Listing mention otherwise, the terms of the booked Experience cannot be changed including re-scheduling of the Experience to another date and time.</p>
                                        <p className="term-condi-pg">10.2	However, if the terms of the booked Experience so permits, the Customer and the Experience Providermay agree to change certain details for the particular Experience (“Change Request”) such as the date and time, number of attendees, contents and duration of the Experience and/or also agree to certain add-ons to an Experience using a Change Request.</p>
                                        <p className="term-condi-pg">10.3	Depending on the type of Change Request, certain Change Fees may apply. The Experience Provideris responsible for indicating in the Experience Listing whether Change Requestis allowed for a particular Experience and if any Change Fee applies. This information will be provided in the Change Request communications between the Customer and the Experience Provider.</p>
                                        <p className="term-condi-pg">10.4	A valid Change Request will require mutual agreement by both Customer and the Experience Provider.If the Change Fee is not indicated in the applicable Experience Listing, both the Experience Providerand Customer must agree to the Change Fee amount. We are not liable for any disagreements or claims arising out of such Change Requests.</p>
                                        <p className="term-condi-pg">10.5	If a Change Request is not approved by both Customer and the Experience Providerwithin seventy two (72) hours from the time the original Change Request is submitted by the Customer, such Change Request will be deemed to have been declined by the Experience Provider.</p>
                                        <p className="term-condi-pg">10.6	If a Change Request is mutually agreed upon by the Customer and the Experience Provider, the Change Request will be effective only upon payment of the applicable Change Fee, if any, plus applicable taxes by the Customer in accordance with the process prescribed for payment of Total Fees under Clause 8 above.</p>
                                        <p className="term-condi-pg">10.7	The Customer acknowledges and agrees that he/she will not receive a refund of any of the Total Fees paid or due for the applicable Experience as a result of a Change Request.</p>
                                        <p className="term-condi-pg">10.8	If the Customer and the Experience Providerfail to reach to an agreement on the terms for the Change Request, either party may choose to cancel the Experience as per these Terms.</p>
                                        <p className="eugait-txt">11.	Cancellation Policy</p>
                                        <p className="term-condi-pg">11.1	If a Customer needs to cancel his/her booking for an Experience, he/she can do so by logging in to his/her account which will have details of all upcoming experiences. Please note that the Experience will stand officially cancelled only when the Customer receives an email from us confirming the cancellation and the refund status. </p>
                                        <p className="term-condi-pg">11.2	In case the Customer has any questions or needsany assistance with cancellation of a booking, he/she must contact our Customer Care Service.</p>
                                        <p className="term-condi-pg">11.3	The relevant Experience’s cancellation policy will determine whether or not the Customer will receive a full refund for the cancelled Experience.</p>
                                        <p className="term-condi-pg">11.4	We reserve the right to deny and cancel bookings or purchases of any of our Services or Experiences that are deemed to be in violation of these Terms, which determination will be made by us at our sole discretion.</p>
                                        <p className="eugait-txt">12.	Refund Policy</p>
                                        <p className="term-condi-pg">12.1	The Customer acknowledges and agrees that the Total Fees payable for all Experiences are non-refundable unless otherwise specified on the Experience Listing.</p>
                                        <p className="term-condi-pg">12.2	If any Experience is cancellable as per the terms of the Experience Listing, the Customer may cancel and obtain a refund of whole or part of the payment made as per our Cancellation Policy stated under Clause 11.</p>
                                        <p className="term-condi-pg">12.3	In the event that the relevant Experience Providerdeclines the Customer’s booking and/or purchase, the Customer will be entitled to a refund pursuant to our refund policy. </p>
                                        <p className="term-condi-pg">12.4	In the event that the Customer encounters any other issue with his/her booking or purchase with the relevant Experience Provider, he/she must contact us within twelve (12) hours of the relevant service start time.  Our Customer Care Service shall investigate the matter and refund none, part, or all of the payment made by the Customer as it may deem fit based on the result of the investigation.</p>
                                        <p className="term-condi-pg">12.5	A Customer will not dispute the Total Fees and claim any refund if the Experience is fulfilled substantially as presented in the Experience Listing.</p>
                                        <p className="term-condi-pg">12.6	If we terminate the Customer’s account and/or use of our Services because of breach of any of these Terms by the Customer, the Customer will not be entitled to a refund of any unused portion of payments made, if any.</p>
                                        <p className="eugait-txt">13.	Intellectual Property Rights</p>
                                        <p className="term-condi-pg">13.1	Except where otherwise indicated or implied by context, we are the sole owners or licensees of all intellectual property comprised in the Online Marketplaceand our Services, including all intellectual property comprised in the Online Marketplace Content andthe technology and software underlying the platform of the Online Marketplace. Nothing in these Terms of Use constitutes a transfer of any rights, title or interest to the intellectual property rights in or related to the Online Marketplace or Online Marketplace Contentexcept for the limited licence to use the Online Marketplace and/or our Services granted to you pursuant to these Terms.All goodwill generated from the use of Online Marketplace, our Services and/or the intellectual property rights contained therein will inure to Happy Weekend’s exclusive benefit.</p>
                                        <p className="term-condi-pg">13.2	You acknowledge and agree that the Online Marketplace and the Content contained therein are protected by copyright, trademarks, service marks, patents, design registrations, and other proprietary rights and laws, and you agree to comply with and maintain all copyright notices and other restrictions on Content accessed on or via the Online Marketplace.</p>
                                        <p className="term-condi-pg">13.3	You must not do anything which breaches or otherwise interferes with our intellectual property rights or the intellectual property rights of any of its third-party licensors. You may not copy, distribute, reproduce, publish, alter, modify or create derivative works from the Online Marketplace Content without our prior written permission or the relevant third-party licensor or exploit such contents for commercial benefit.</p>
                                        <p className="term-condi-pg">13.4	Any rights not expressly granted herein are reserved by Happy Weekends.</p>
                                        <p className="term-condi-pg">13.5	You acknowledge and agree that damages may not be an adequate remedy for a breach of this clause 13 and that equitable or injunctive relief may be necessary.</p>
                                        <p className="eugait-txt">14.	User Content</p>
                                        <p className="term-condi-pg">14.1	Representation and Warranties </p>
                                        <p>Where the Online Marketplace allows you to upload any User Content (or in case of Host where we upload any User Content on behalf of the Host or where the Host uploads any User Content that has been pre-approved by us), you hereby represent and warrant to us that:</p>
                                        <ul>
                                            <li>you have all right, title, interest and/or authority in the User Content and have all rights that are necessary for you to grant us the license rights in your User Content under these Terms;</li>
                                            <li>you have the permission to use the name and likeness of each person whose image appears in any User Content in the manner contemplated by these Terms;</li>
                                            <li>the provision, use or exploitation of User Content will not infringe or violate the rights of any third party (including, but are not limited to, intellectual property rights, publicity and privacy rights) and is not in violation of any applicable law; and</li>
                                            <li>you will pay all amounts which become owing to any person (whether by way of royalty or otherwise) as a result of or in connection with your submission of the User Content to or via the Online Marketplace.</li>
                                        </ul>
                                        <p className="term-condi-pg">14.2	Prohibited Content</p>
                                        <p>You must not add any User Content or Feedback: </p>
                                        <ul>
                                            <li>that is or could reasonably be considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive, pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, profane, in breach of confidence, in breach of privacy or harassing;</li>
                                            <li>that would bring us or the Online Marketplace into disrepute;</li>
                                            <li>that infringes the rights of any person;</li>
                                            <li>that you know (or ought reasonably to suspect) is false, misleading, untruthful or inaccurate;</li>
                                            <li>that contains unsolicited or unauthorised advertising (including junk mail or spam); or</li>
                                            <li>that contains computer or software viruses, files or programs that are designed to interfere with the ordinary functionality of the Online Marketplace, or obtain unauthorised access to any system, information, security device belonging to us or any third party.</li>
                                        </ul>
                                        <p className="term-condi-pg">14.3	Grant of Rights</p>
                                        <p>By making your User Content available on or through the Online Marketplace, you hereby grant to Happy Weekends a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, modify (for formatting purposes only), distribute, license, publicly display, publicly perform, transmit, stream, broadcast, access and otherwise exploit your User Content on, through or by means of the Services (including through websites operated or owned by third parties), including for commercial, advertising and promotional purposes relating to the Services.</p>
                                        <p>You further agree to us sharing your User Content (including any part of the User Content which constitutes ‘personal information’ under privacy laws) with other Users to the extent required for you to use the Online Marketplace, avail the Services and avail/provide the Host Experiences.</p>
                                        <p className="term-condi-pg">14.4	Rights in Feedback </p>
                                        <p>To the extent where you provide us with any suggestions, comments, improvements, ideas or other feedback in any form or media ("Feedback"), you hereby assign ownership of all rights, title and interest in such Feedback including all intellectual property rights subsisting therein to us.You further acknowledge and agree that we have no obligation (whether of confidentiality, compensation or otherwise) with respect to such Feedback and that we can use, exploit and share such Feedback for any purpose at our discretion without any restrictions.</p>
                                        <p className="term-condi-pg">14.5	Liability arising from User Content</p>
                                        <p>Happy Weekends shall not be liable in any manner for any User Content displayed on Online Marketplace, including but not limited to for (a) any errors or omissions in any User Content; or (b) any loss or damage of any kind incurred in connection with the use of or exposure to any User Content posted, emailed, accessed, transmitted, or otherwise made available via Online Marketplace.</p>
                                        <p>To the maximum extent permitted by law, you hereby release and indemnify us from any claim or loss in relation to User Content being stored in our servers or displayed on Online Marketplace.</p>
                                        <p className="term-condi-pg">14.6	Back-Up of User Content </p>
                                        <p>It is your responsibility to back up any of your User Content to your own systems. We do not guarantee that the Online Marketplace will always be available at all times. </p>
                                        <p className="term-condi-pg">14.7	This clause 14 will survive termination of these Terms of Use.</p>
                                        <p className="eugait-txt">15.	Content Enforcement Rights</p>
                                        <p className="term-condi-pg">15.1	Although we are not legally obligated to monitor access to or use of the Services or User Content or to review or edit any User Content, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms or to comply with applicable law. </p>
                                        <p className="term-condi-pg">15.2	We reserve the right, but are not obligated, to remove or disable access to any User Content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider any User Content to be objectionable or in violation of these Terms.</p>
                                        <p className="term-condi-pg">15.3	We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute Users who violate the law or other legal requirements, including providing such authorities with access to any User Content on the Online Marketplace.</p>
                                        <p className="eugait-txt">16.	Third Party Sites </p>
                                        <p className="term-condi-pg">16.1	The Online Marketplace may contain links to websites that are owned and operated by third parties. This may include other suppliers and service providers who provide goods or services in connection with the Services. We have no control over these external websites, which are governed by terms and conditions and privacy policies independent of us.</p>
                                        <p className="term-condi-pg">16.2	You acknowledge and agree that when you access a third-party website available via a link contained on the Online Marketplace: </p>
                                        <ul>
                                            <li>you do so at your own risk and understand that you should review the privacy policy and terms and conditions of that website;</li>
                                            <li>we are not liable for the content, accuracy, lawfulness, appropriateness, or any other aspect of that third-party website; and</li>
                                            <li>you acknowledge and agree that to the full extent permitted by applicable law, we will not be liable for any loss or damage suffered by you or any other person as a result of or in connection with your access or use of any third-party website available via a link on the Online Marketplace.</li>
                                        </ul>
                                        <p className="eugait-txt">17.	Disclaimer and Limitation of Liability</p>
                                        <p className="term-condi-pg">17.1	No Warranties</p>
                                        <ul>
                                            <li>We provide the Online Marketplace on an “as is” and on an “as available” basis without any Warranties as to continuous, uninterrupted or secure access to the Online Marketplace, that its servers are free of computer viruses, bugs or other harmful components, that defects will be corrected, or that you will not have disruption or other difficulties in using the Online Marketplace.</li>
                                            <li>To the maximum extent permitted by law, we exclude all Warranties whatsoever unless expressly stated, including but not limited to warranties in relation to any use of the Online Marketplace, any User or any Services offered.</li>
                                            <li>You acknowledge that when you use the Online Marketplace, enter into Service Agreements, and provide or receive Experiences, you do so entirely at your own risk and relying on your own enquiries and judgement. </li>
                                            <li>We do not vet, endorse or recommend any particular User or an Experience, and any rating information displayed (if any) on the Online Marketplace consists of statements of opinion and not statements of fact or recommendations.</li>
                                            <li>We assume no responsibility for any User’s failure to comply with any applicable laws and regulations. </li>
                                            <li>We do not warrant the authenticity and accuracy of any User Content which other Users may provide. All User Content accessed by you using Online Marketplace is at your own risk and you will be solely responsible for any damage or loss to any party resulting therefrom.</li>
                                        </ul>
                                        <p className="term-condi-pg">17.2	Disclaimers</p>
                                        <ul>
                                            <li>(a)	Any advice provided on the Online Marketplace is of a general nature only. We explicitly disclaimany and all liability arising from the following:
                                                <ul>
                                                    <li>Any act or omission of any User or other third party;or </li>
                                                    <li>The Experience Listing, Experiences or use of the Services.</li>
                                                </ul>
                                            </li>
                                            <li>In the event that a Customer encounters any problems with Experiences and although we may (but no obligation) assist to facilitate the resolution of disputes, the Customer acknowledges that he/she should resolve the issues with the relevant Experience Providerand that his/her sole remedy in such circumstances, including any refund, lies with the relevant Experience Providerand not with Happy Weekends. However, in case of Experiences that are organised mainly by Happy Weekends, we will extend all relevant support to the Customer in resolving the disputes raised by the Customer.</li>
                                            <li>We are also not responsible for any personal injury, damage or harm resulting from your provision or purchase of, or participation in, any Experience or your interaction with another User. You, as a Customer or Host, are responsible for your own acts and omissions, and the acts and omissions of any individual whom you invite to, or otherwise enable his/her participation in, an Experience, including without limitation any minors.</li>
                                        </ul>
                                        <p className="term-condi-pg">17.3	Limitation of Liability</p>
                                        <p>Notwithstanding anything contrary contained elsewhere in the Terms, our liability arising in connection with these Terms or the Online Marketplace is limited as follows:</p>
                                        <ul>
                                            <li>We exclude all liability for consequential, special, indirect or remote loss, including loss of opportunity or business;</li>
                                            <li>Our total maximum total liability arising in connection with these Terms is capped to the total amount of any Online Marketplace Fee relevant to the particular matter;</li>
                                            <li>Our liability is excluded to the extent that you contributed to the liability;</li>
                                            <li>We exclude all liability for anything you have been aware of for longer than six months and you have not commenced a claim in court. You waive and release us from any such liability or claim; and</li>
                                            <li>Our liability is subject to your duty to mitigate your loss.</li>
                                        </ul>
                                        <p className="term-condi-pg">17.4	Sole Remedy</p>
                                        <p>If you are dissatisfied with the Online Marketplace and/or our Services, do not agree with any part of these Terms, or have any other dispute or claim with or against Happy Weekends or another User with respect to these Terms or Online Marketplace, your sole remedy against Happy Weekends is to discontinue use of Online Marketplace and/or our Services.</p>
                                        <p>In the event that we terminate the Online Marketplace or your access to the Online Marketplace pursuant to these Terms, you release us from all liability, loss or claims suffered by you as result of or arising out of such termination.</p>
                                        <p className="eugait-txt">18.	Release and Indemnity</p>
                                        <p className="term-condi-pg">18.1	You agree to release the Released Parties from all Loss or Claims arising out of or in any way connected with any Relevant Matter. You further waive any and all rights and benefits otherwise conferred by any statutory or non-statutory law of any jurisdiction that would purport to limit the scope of a release or waiver. </p>
                                        <p className="term-condi-pg">18.2	You agree to indemnify, defend and hold harmless the Released Parties from any Loss or Claims arising out of or in any way connected with any Relevant Matter.</p>
                                        <p className="term-condi-pg">18.2	You agree to indemnify, defend and hold harmless the Released Parties from any Loss or Claims arising out of or in any way connected with any Relevant Matter.</p>
                                        <ul>
                                            <li><strong>Claim</strong> means a claim, action, proceeding or demand made against a person concerned, however it arises and whether it is present or future, fixed or unascertained, actual or contingent.</li>
                                            <li><strong>Loss</strong> means a damage, loss, cost, expense or liability incurred by the person concerned however arising, including without limitation penalties, fines, and interest and including those which are prospective or contingent and those the amount of which for the time being is not ascertained or ascertainable.</li>
                                            <li><strong>Released Parties</strong> means us and our officers, directors, shareholders, agents, employees, consultants, associates affiliates, subsidiaries, sponsors, and other third-party partners.</li>
                                            <li><strong>Relevant Matter</strong> means anything in connection with:
                                                <ul>
                                                    <li>any Service Agreements</li>
                                                    <li>any Services and use of the Online Marketplace</li>
                                                    <li>any User</li>
                                                    <li>any Experience</li>
                                                    <li>any Experience Listings (in case of a Host) and related Host Experiences and the goods and services provided by the Host, including, but not limited to, any claims for false advertising, product defects and deficiency in services;</li>
                                                    <li>any damage to property, personal injury or death</li>
                                                    <li>your breach of these Terms</li>
                                                    <li>your use, misuse, or abuse of the Online Marketplace</li>
                                                    <li>your User Content; and</li>
                                                    <li>your breach or failure to observe any applicable law, including without limitation any taxation laws.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p className="eugait-txt">19.	Non-Circumvention </p>
                                        <p className="term-condi-pg">19.1	You agree not to circumvent the Online Marketplace for a period of three (3) years following your use of the Online Marketplace in respect of a particular User from when you first contacted, or were first contacted by, another User through the Online Marketplace (“Exclusivity Period”). During the Exclusivity Period, a User may not in any manner solicit, engage, transact, deal, request a quote, or receive payments from such other User outside the Online Marketplace.</p>
                                        <p className="term-condi-pg">19.2	You agree to inform us promptly in case a User asks you to pay, transact, engage, deal, or provide a quote outside the Online Marketplace during the Exclusivity Period. You can opt out of the Exclusivity Period by paying a fee for every User that you intend to engage or deal with outside of the Online Marketplace which fee will be decided by us on a case to case basis (an “Opt-Out Fee”). It is clarified that such Opt-Out Fee will be payableby both Customer and Host for the transaction in question.</p>
                                        <p className="term-condi-pg">19.3	In case you are a Customer, we will charge the Opt-Out Feeagainst the designated mode of payment.In case you are a Host, we will set-off the Opt-Out Fee against any amounts payable to the Host for past or future Host Experiences provided through the Online Marketplace. If we are unable to charge the Opt-Out Fee as above, then we will issue an invoice for the Opt-Out Fee which you agree to pay within thirty (30) days of the date of the invoice. </p>
                                        <p className="eugait-txt">20.	Termination </p>
                                        <p className="term-condi-pg">20.1	You acknowledge and agree that: </p>
                                        <ul>
                                            <li>we may terminate your access to the Online Marketplaceor deactivate or cancel your account at any time without giving any explanation and without any liability at any time and with or without prior notice to you;</li>
                                            <li>we may terminate these Terms immediately by notice to you in writing if you are deemed to be in breach of these Terms or associated policies in any way, in our sole discretion; and</li>
                                            <li>termination of these Terms, your account or your access to the Online Marketplace does not release you from any of your obligations and liabilities that may have arisen or been incurred prior to the date of such termination.</li>
                                        </ul>
                                        <p className="term-condi-pg">20.2	If you are a Customer and the agreement with you pursuant to these Terms is terminated as a result of your breach, we reserve the right to cancel any unused booking you may have purchased prior to said termination, without refund or liability to you.</p>
                                        <p className="term-condi-pg">20.3	If you are a Host, additional provisions related to termination under the Host Agreement will apply.</p>
                                        <p className="term-condi-pg">20.4	A Customer may cancel his/her account at any time by sending an email to us at <a href="mailto:support@happy-weekends.com" target="_top">Send Mail</a></p>
                                        <p className="eugait-txt">21.	Grievance Redressal</p>
                                        <p className="term-condi-pg">21.1	Customer Care Service</p>
                                        <p>If you have any issues or complaints related to the Online Marketplace, our Services, or a Customer/Host, please contact our Customer Care Service. </p>
                                        <p className="term-condi-pg">21.2	Reporting Bad Experience</p>
                                        <p>In case the Customer has a bad Experience, he/she must inform our Customer Care Service within a period of twenty four (24) hours of availing the Experience. If any such bad Experience is reported within the given timeframe, we will conduct internal enquiries and undertake appropriate action regarding the reported matter as we deem fit. We do not guarantee any refunds or compensation in lieu of a bad Experience. </p>
                                        <p className="eugait-txt">22.	General</p>
                                        <p className="term-condi-pg">22.1	Notices are taken to be read when the notice is acknowledged by the receiving party and will be deemed to be read if no acknowledgement is received within 2 business days (in Abu Dhabi) of delivery. In case of any urgent notice, the written notice shall also be brought to the attention of the designated representative of the notified party on the same business day by a telephone call.</p>
                                        <p className="term-condi-pg">22.2	You must not assign, sublicense or otherwise deal in any other way with any of your rights under these Terms. We may assign our rights under these Terms at our sole discretion. </p>
                                        <p className="term-condi-pg">22.3	If a provision of these Terms is invalid or unenforceable, it is to be read down or severed to the extent necessary without affecting the validity or enforceability of the remaining provisions.</p>
                                        <p className="term-condi-pg">22.4	These Terms are governed by the laws of Emirate of Abu Dhabi and Federal laws of the UAE and each party submits to the exclusive jurisdiction of the courts of Abu Dhabi and all courts of appeal from there.</p>
                                        <p className="term-condi-pg">22.5	Any waiver of any term on these Terms by us can only be done in express writing. Any failure on our part to enforce a term does not constitute a waiver and we reserve the right in relation to all breaches unless expressly stated otherwise.</p>
                                        <p className="term-condi-pg">22.6	The contents of these Terms constitute the entire agreement between the parties and supersede any prior negotiations, representations, understandings or arrangements made between the parties regarding the subject matter of this agreement, whether orally or in writing. In case of a Host, these Terms must be read in consonance with the terms of the Host Agreement and, in case of any conflict, the terms of the Host Agreement will prevail.</p>
                                        <p className="term-condi-pg">22.7	A provision of these Terms which can and is intended to operate after its conclusion will remain in full force and effect – including intellectual property rights, all indemnities and releases and limitation of liability.</p>
                                        <p className="eugait-txt">23.	Definitions</p>
                                        <p className="term-condi-pg">23.1	In these Terms of Use:</p>
                                        <p>Change Feesmeans any fees that is payable by a Customer for a Change Request.</p>
                                        <p>Change Request has the meaning ascribed to the term under Clause 10.2.</p>
                                        <p>Contentmeans any content whatsoever that are posted, uploaded, generated, provided or otherwise made available through the Online Marketplace, including but not limited to any bios, experience, descriptions, usage data, chats, photos, audios, videos, music, text, graphics, works of authorship of any kind and any other information.</p>
                                        <p>Customer means a registered User seeking to avail an Experience.</p>
                                        <p>Customer Care Service means the customer care representative(s) appointed by Happy Weekends from time to time who can be reached at: +971- 55- 107-2139</p>
                                        <p>Exclusivity Period has the meaning ascribed to the term under Clause 19.1.</p>
                                        <p>Experience means either a Host Experience or an Other Experience. </p>
                                        <p>Experience Fee means the fee charged from the Customer for the relevant Experience.</p>
                                        <p>Experience Listing means the listing for an Experience created by the Experience Provider on the Online Marketplace as mentioned under Clause 1.2.</p>
                                        <p>Experience Provider means a Host, Happy Weekends or any other third party responsible for providing the concerned Experience.</p>
                                        <p>Feedback has the meaning ascribed to the term under Clause 14.4.</p>
                                        <p>Happy Weekends, we, us, our means Meraki Digital for Marketing and Entertainment Services L.L.C and its related entities or body corporates.</p>
                                        <p>Host Agreement means the agreement entered into between a Host and Happy Weekends in relation to listing and selling of the Host Experiences through the Online Marketplace.</p>
                                        <p>Host Experiences means any experience listed on the Online Marketplace to be provided by the Host to a Customer.</p>
                                        <p>Listing Terms means the terms and conditions applicable to the relevant Experience that the Customer must comply with, as specified in Clause 7.1(a).</p>
                                        <p>Online Marketplace means the Website, mobile application and any service offered by us under the name “Happy Weekends”.</p>
                                        <p>Online Marketplace Content means the all Content available on the Online Marketplace (save and except User Content) and includes all proprietary Content of Happy Weekends and any Content licensed or authorized for use by or through Happy Weekends from a third party.</p>
                                        <p>Online Marketplace Fee means the fee we collect from the Host in exchange for allowing the Host to sell the Host Services through the Online Marketplace, as agreed by the Host and us under the Host Agreement.</p>
                                        <p>Opt-Out Fee has the meaning ascribed to the term under Clause 19.2.</p>
                                        <p>Other Experiences has the meaning ascribed to the term under Clause 1.2.</p>
                                        <p>Privacy Policy means our privacy policy available <Link to="/privacy">Privacy Policy</Link></p>
                                        <p>Services means the services provided by Happy Weekends by way of the Online Marketplace, as described under Clause 1.1.</p>
                                        <p>Terms means these Terms of Use, together with our Privacy Policy, and any other policy displayed on the Online Marketplace.</p>
                                        <p>Terms of Use means these terms of use.</p>
                                        <p>Total Fees means the Experience Fees plus all applicable taxes on the relevant Experience, as specified under Clause 8.1.</p>
                                        <p>User Content means any Content that is uploaded on the Online Marketplace by the User.</p>
                                        <p>Warranties mean any warranties, conditions, terms, representations, statements and promises of whatever nature, whether express or implied.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Terms;