import React, {Component, Fragment} from 'react';
import './Header.css';
import Search from '../img/search.svg';
import Shopingcart from '../img/shopping-cart-alt.svg';
import Logo from '../img/logo.png';
import {Link} from 'react-router-dom'


class  Header extends Component{



    render() {
        return (
            <Fragment>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/" a>
                <img height="53" src={Logo} alt="logo" />
            </Link>
            <button className="navbar-toggler" type="button"
                    data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="nav-link" a>Activities & Weekends</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" a>Weekend Packages</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" a>Become A Partner</a>
                </li>
              </ul>

              <ul className="navbar-nav ml-auto nav-right">
                <li className="nav-item">
                  <a className="nav-link" a><img src={Search} alt="search" /></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" a><img src={Shopingcart} alt="Shoping Cart" /></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link login" a>Login</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link signup" a>Signup</a>
                </li>
              </ul>

            </div>

            </nav>
            </Fragment>
        )
    }
}



export default Header;
