import React, { Component } from 'react';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import axios from "axios/index";


class  DashboardHeader extends Component{



    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            loading: false,
            loadingRefresh: false,
            confirmationFailed: false,
            emailSent: false,
            showPopUp : false

        }

        this.resendConfirmationEmail=this.resendConfirmationEmail.bind(this)

        // this.refreshUser=this.refreshUser.bind(this)
        // this.hidePopUp=this.hidePopUp.bind(this)



    }


    resendConfirmationEmail(){


        this.setState({

            loading : true
        })

        axios.post(baseUrl+"resend_confirmations" ,{
            "customer": this.props.userDetail.id }).then(res => {


            console.log("resend email success")

            this.setState({

                loading : false,
                emailSent: true,
            })



        }).catch(error => {

            console.log("submit request error found "+error)


        });



    }



    render (){




        return(
            <>
            <section className="admin-haeder blue-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="avatar-upload">
                            <div className="avatar-edit">
                                <label htmlFor="imageUpload" />
                            </div>
                            <div className="avatar-preview">
                            <div id="imagePreview">
                            </div>
                            </div>
                        </div>
                            <div className="user-name">
                                <h5>Customer Account</h5>
                                <h2>{this.props.userDetail&& this.props.userDetail.firstName+" "+ this.props.userDetail.lastName}</h2>
                            </div>
                            {!this.props.userDetail.isConfirmed && <div className="activity-status_info">
                                Dear <strong>{this.props.userDetail.firstName + " " + this.props.userDetail.lastName}</strong>
                                <br/>

                                <>
                                    Your Email verification is
                                    <span className="badge badge-danger">Pending</span>
                                    <br/>Kindly click on the confirmation link sent to your registered email at the time
                                    of registration <br/>
                                    <Link onClick={this.resendConfirmationEmail}>Resend Email</Link>
                                </>

                            </div>
                            }
                        </div>
                    </div>
                </div>
            </section>


            </>
        )
    }
}



const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(DashboardHeader);