import React, { Component } from 'react';
import { Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags';


class  Privacy extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }

    render (){
        return(
            <div className="bg-gray">

                <MetaTags>

                    <title>Privacy Policy and Notice | Happy Weekends</title>
                    <meta name="title" content="The privacy policy of Happy Weekends explains the way we collect and store information. When you provide us your personal information, you consent to our collection, use and disclosure of your personal information in accordance with this privacy policy and any arrangements that apply between us. " />
                    <meta name="description" content="Happy Weekends the online marketplace is essentially a platform that allows its registered customers to search, view, book, purchase and participate in the experiences listed on the platform to make their weekends special and memorable. Customer can choose the experiences based on the descriptions. " />
                    <link rel="canonical" href="https://www.happy-weekends.com/" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content="The privacy policy of Happy Weekends explains the way we collect and store information. When you provide us your personal information, you consent to our collection, use and disclosure of your personal information in accordance with this privacy policy and any arrangements that apply between us. " />
                    <meta property="og:description" content="Happy Weekends the online marketplace is essentially a platform that allows its registered customers to search, view, book, purchase and participate in the experiences listed on the platform to make their weekends special and memorable. Customer can choose the experiences based on the descriptions. " />
                    <meta property="og:image" content="/cover-image.jpg" />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content="The privacy policy of Happy Weekends explains the way we collect and store information. When you provide us your personal information, you consent to our collection, use and disclosure of your personal information in accordance with this privacy policy and any arrangements that apply between us. " />
                    <meta property="twitter:description" content="Happy Weekends the online marketplace is essentially a platform that allows its registered customers to search, view, book, purchase and participate in the experiences listed on the platform to make their weekends special and memorable. Customer can choose the experiences based on the descriptions. " />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>
                <section className="container-fluid privacy-policy-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 how-work-txt text-center">
                                <h2 className="">Privacy Policy And Notice</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{padding:"10px"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-hd-main">
                                    <h2 className="best-family-txt text-center">PRIVACY POLICY AND NOTICE</h2>
                                    <p className="before-u-txt text-center">Updated on : October 2, 2019</p>
                                    <div className="term-condi-scroll">
                                        <p className="term-condi-pg">
                                            In this Privacy Policy, ‘Happy Weekends’ 'us' 'we' or 'our' means Meraki Digital for Marketing and Entertainment Services L.L.C (a limited liability company incorporated under the laws of Emirate of Abu Dhabi holding professional licence number CN-2818859) and its related entities or body corporates. This Privacy Policy comprises part of our <Link to="/terms-conditions">Terms of Use</Link>. All capitalised terms used in this Privacy Policy have the same meaning as in the Terms unless otherwise indicated in Clause 1 below.
                                        </p>
                                        <p className="term-condi-pg">
                                            This Privacy Policy explains the way we collect and store your information. By providing Personal Information or User Content to us, you consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy and any other arrangements that apply between us. We may change our Privacy Policy from time to time by publishing changes to it on our Online Marketplace. We encourage you to check our website periodically to ensure that you are aware of our current Privacy Policy.
                                        </p>
                                        <p className="eugait-txt">1. Definitions</p>
                                        <p>1.1	In this Privacy Policy, the following capitalised terms have the following meanings:</p>
                                        <ul>
                                            <li><strong>Applicable Law</strong> means the data protection or privacy laws of Emirate of Abu Dhabi and Federal laws of the UAE applicable to Happy Weekends and the Users.</li>
                                            <li><strong>Automatic Data </strong>has the meaning given to it in Clause 2.3.</li>
                                            <li><strong>Customer</strong>  means a registered User seeking to avail a Host Experience through the Online Marketplace.</li>
                                            <li><strong>Host </strong>means a registered User seeking to provide the Host Experience through the Online Marketplace.</li>
                                            <li><strong>Non-personal</strong> Information means information which does not relate to a person and/or cannot be used to identify a person. </li>
                                            <li><strong>Online Marketplace </strong>means this website, app, platform and any service offered under the name “Happy Weekends”.</li>
                                            <li><strong>Personal Information </strong>means any information which can be used to identify an individual, and described in detail in Clause2.</li>
                                            <li><strong>Spam Policy</strong> means the policy for Unsolicited Electronic Communications issued by the Telecommunications Regulatory Authority of UAE. </li>
                                            <li><strong>User Content </strong>means any content whatsoever which are posted, uploaded, generated, provided or otherwise made available by a User through the Online Marketplace, including but not limited to any bios, experience, descriptions, usage data, chats, photos, audios, videos, music, text, graphics, works of authorship of any kind and any other information.</li>
                                            <li><strong>User</strong> means any person using or registered on the Online Marketplace whatsoever.</li>
                                        </ul>
                                        <p className="eugait-txt">2. What personal information do we collect?</p>
                                        <p>2.1 We may collect Personal Information about you when you use the Online Marketplace, register on the Online Marketplace or interact with us in anyway.</p>
                                        <p>2.1 <sttrong>Information you provide to us: We collect all information which Users voluntarily provide to us through using the Online Marketplace or interacting with us. This includes information provided:</sttrong></p>
                                        <ul className="sub">
                                            <li>During registration</li>
                                            <li>During correspondence, enquiries, support services, phonecalls, or customer surveys or</li>
                                            <li>Uploaded onto the Online Marketplace in any way.</li>
                                        </ul>
                                        <p className="mb-0">The Personal Information we may collect includes without limitation:</p>
                                        <ul className="sub">
                                            <li>During registration</li>
                                            <li>Basic information such as your first and last name</li>
                                            <li>Contact information including email address and phone number</li>
                                            <li>Demographic information such as postal code, age, and gender</li>
                                            <li>Other relevant information (education, profession, work experienceand preferences)</li>
                                            <li>Your logo, contact and other information and links to your social media profiles (if you are a Host); </li>
                                            <li>Geographic location</li>
                                            <li>Login credentials for third-party websites</li>
                                            <li>Other information obtained by your use of the Online Marketplace</li>
                                            <li>Any other information that enables Users to be personally identified</li>
                                        </ul>
                                        <p>2.3	Information we automatically collect: We also automatically collect certain technical data that is sent to us from the computer, mobile device and/or browser through which you access the Online Marketplace (“Automatic Data”). Automatic Data, includes without limitation, a unique identifier associated with your access device and/or browser (including, for example, your Internet Protocol (IP) address) characteristics about your access device and/or browser, statistics on your activities on the Online Marketplace, details of the products and services we have provided to you or that you have enquired about, including any additional information necessary to deliver those products and services and respond to your enquiries, orinformation about how you came to the Online Marketplace. We collect Automatic Data based on your consent which was granted to us by you when you registered as a User and accepted this Privacy Policy.</p>
                                        <p>2.4	Information we obtain from others: We may also collect or receive Personal Information from third-party sources, such as other Customers, other Hosts, social media or other third-party integrations.</p>
                                        <p>2.5	Non-personal Information: When you interact with the Online Marketplace, we may collect Non-Personal Information. The limitations and requirements of this Privacy Policy on our collection, use, disclosure, transfer and storage/retention of Personal Information do not apply to Non-Personal Information. When you register for the Online Marketplace or otherwise submit Personal Information to us, we may associate other Non-Personal Information (including Non-Personal Information we collect from third parties) with your Personal Information. At such instance, we will treat any such combined data as your Personal Information until such time as it can no longer be associated with you or used to identify you.</p>
                                        <p className="eugait-txt">3.	Why do we collect, use and disclose personal information?</p>
                                        <p>3.1	We may collect, hold, use and disclose your Personal Information for the following purposes:</p>
                                        <ul>
                                            <li>to enable you to access and use our Online Marketplace, including allowing you to interact with other Users, such as Hosts whose Host Experiences you wish to book/purchase or Customers who book/purchase the Host Experience</li>
                                            <li>to operate, protect, improve and optimise our Online Marketplace, business and our users’ experience, such as to perform analytics, conduct research and for advertising and marketing</li>
                                            <li>to send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you</li>
                                            <li>to send you marketing and promotional messages and other information that may be of interest to you, including information sent by, or on behalf of, our business partners that we think you may find interesting</li>
                                            <li>to administer rewards, surveys, contests, or other promotional activities or events sponsored or managed by us or our business partners; and</li>
                                            <li>to comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties.</li>
                                        </ul>
                                        <p>3.2	We may also disclose your Personal Information to a trusted third party who also holds other information about you. This third party may combine that information in order to enable it and us to develop anonymised consumer insights so that we can better understand your preferences and requirements, personalise your experience and enhance the services that you receive.</p>
                                        <p>3.3	The information we collect is not distributed, sold or leased to third parties for commercial purposes, except to provide services you have requested on the Online Marketplaceor for other purposes when we have your permission or when we are obliged to do so.</p>
                                        <p className="eugait-txt">4.	Do we use your personal information for direct marketing?</p>
                                        <p>4.1	We and/or our carefully selected business partners may send you direct marketing communications and information about our services. This may take the form of emails, SMS, mail or other forms of communication, in accordance with the Spam Policy. By registering on the Online Marketplace and accepting this Privacy Policy, you hereby grant your consent to receiving such communications from us unless you opt-out as provided hereunder.</p>
                                        <p>4.2	You may opt-out of receiving marketing materials from us by contacting us through the contact us feature below, or by using the opt-out facilities provided (eg: an unsubscribe link).</p>
                                        <p className="eugait-txt">5.	To whom do we disclose your personal information?</p>
                                        <p>5.1	We may disclose Personal Information for the purposes described in this Privacy Policy to:</p>
                                        <ul className="sub">
                                            <li>to other Users (such as Customers or Hosts, as the context requires), for the purpose of evaluating them and possibly entering into discussions for entering into an engagement for the provision of Host Experiences</li>
                                            <li>Our employees and related bodies corporate</li>
                                            <li>third-party suppliers and service providers (including providers for the operation of our Online Marketplace and/or our business or in connection with providing our products and services to you)</li>
                                            <li>Professional advisers, dealers and agents</li>
                                            <li>Payment systems operators (eg merchants receiving card payments); </li>
                                            <li>Our existing or potential agents, business partners or partners;</li>
                                            <li>Our sponsors or promoters of any competition that we conduct via our services;</li>
                                            <li>Anyone to whom our assets or businesses (or any part of them) are transferred;</li>
                                            <li>Specific third-parties authorised by you to receive information held by us; and/or</li>
                                            <li>Other persons, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law.</li>
                                        </ul>
                                        <p className="eugait-txt">6. Using our website and cookies</p>
                                        <p>6.1	We may collect Personal Information about you when you use and access our website. While we do not use browsing information to identify you personally, we may record certain information about your use of our website, such as which pages you visit, the time and date of your visit and the internet protocol address assigned to your computer.</p>
                                        <p>6.2	We may also use 'cookies' or other similar tracking technologies on our website that help us track your website usage and remember your preferences. Cookies are small files that store information on your computer, TV, mobile phone or other device. They enable the entity that put the cookie on your device to recognise you across different websites, services, devices and/or browsing sessions. You can disable cookies through your internet browser but our websites may not work as intended for you if you do so.</p>
                                        <p>6.3	We may also use cookies to enable us to collect data that may include Personal Information. We will handle any Personal Information collected by cookies in the same way that we handle all other Personal Information as described in this Privacy Policy.</p>
                                        <p className="eugait-txt">7. Security</p>
                                        <p>7.1	We may hold your Personal Information in either electronic or hard copy form. We take reasonable steps to protect your Personal Information from misuse, interference and loss, as well as unauthorised access, modification or disclosure and we use a number of physical, administrative, personnel and technical measures to protect your Personal Information. However, we cannot guarantee the security of your Personal Information.</p>
                                        <p className="eugait-txt">8.	Retention</p>
                                        <p>8.1	We may retain your Personal Information as long as you are registered to the Online Marketplace as a User. You may close your account by contacting us. However, we may retain Personal Information for an additional period as is permitted or required under applicable laws. Even if we delete your Personal Information, it may persist on backup or archival media for an additional period of time for legal, tax or regulatory reasons or for legitimate and lawful business purposes.</p>
                                        <p className="eugait-txt">9.	Exclusions</p>
                                        <p>9.1	<strong>Links:</strong> Our Online Marketplace may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on, those linked websites, and have no control over or rights in those linked websites. The privacy policies that apply to those other websites may differ substantially from our Privacy Policy, so we encourage individuals to read them before using those websites.</p>
                                        <p>9.2	<strong>Personal Information Provided to Others:</strong> This Privacy Policy does not apply to any Personal Information that you provide to another User or visitor through the Online Marketplace or through any other means, including to Hosts or Customers, or information posted by you to any public areas of the Online Marketplace or your social media profiles. </p>
                                        <p>9.3	<strong>User Content:</strong> You may post content on the Online Marketplace, including your comments, feedback, pictures, and any other information that you would like to be available on the Online Marketplace. If you post any content to the Online Marketplace, all of the information that you post will be available to all visitors to the Online Marketplace. If you post your own content on the Online Marketplace, your posting may become public and we cannot prevent such information from being used in a manner that may violate this Privacy Policy, the law, or your personal privacy.</p>
                                        <p className="eugait-txt">10.	Accessing or correcting your personal information</p>
                                        <p>10.1	You can access the Personal Information we hold about you by contacting us using the contact us feature below. Sometimes, we may not be able to provide you with access to all of your Personal Information and, where this is the case, we will tell you why. We may also need to verify your identity when you request your Personal Information.</p>
                                        <p>10.2	If you think that any Personal Information we hold about you is inaccurate, please contact us using the contact us feature belowand we will take reasonable steps to ensure that it is corrected.</p>
                                        <p>10.3	We will consider and respond to all requests in accordance with all applicable laws.</p>
                                        <p className="eugait-txt">11.	Making a complaint</p>
                                        <p>11.1	If you think we have breached the Applicable Law, or you wish to make a complaint about the way we have handled your Personal Information, you can contact us using the contact us feature below. Please include your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, we will provide you with information about the further steps you can take. </p>
                                        <p className="eugait-txt">12.	Contact Us</p>
                                        <p>12.1	For further information about our Privacy Policy or practices, or to access or correct your Personal Information, or make a complaint, please contact us using the details set out below:</p>
                                        <p style={{textAlign: 'center'}}>
                                            <a href="mailto:support@happy-weekends.com" style={{float: 'none', maxWidth: '200px', display: 'inline-block'}} className="buy-now" target="_top">Contact Us</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Privacy;