import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import  {MailOutline, Error} from '@material-ui/icons';
import {baseUrl} from "../Util/Constants";
import {saveUserData} from "../LocalStorage/user";
import {loginFailed} from "../store/actions/actions";
import {loadSalesQuote} from "../store/actions/actions";
import  {Sync} from '@material-ui/icons'



class  EmailConfirmationPopUpNew extends Component{



    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            loading: false,
            loadingRefresh: false,
            confirmationFailed: false,
            emailSent: false,
            showPopUp : false

        }

        this.resendConfirmationEmail=this.resendConfirmationEmail.bind(this)

        this.refreshUser=this.refreshUser.bind(this)
        this.hidePopUp=this.hidePopUp.bind(this)



    }


    resendConfirmationEmail(){


        this.setState({

            loading : true
        })

        axios.post(baseUrl+"resend_confirmations" ,{
            "customer": this.props.userDetail.id }).then(res => {


            console.log("resend email success")

            this.setState({

                loading : false,
                emailSent: true,
            })



        }).catch(error => {

            console.log("submit request error found "+error)


        });



    }



    refreshUser(){


        var  url = baseUrl+"customers.json?groups[]=customer&email="+this.props.userDetail.email;

        console.log(url)


        this.setState({

            // loadingRefresh : true
        })


        axios.get(url)
            .then(res => {


                this.setState({

                    // loadingRefresh : false,
                })

                console.log("user data exits")

                console.log(res.data)


                var userDetail =  res.data[0]

                if (userDetail.isConfirmed) {

                    this.setState({

                        confirmationFailed : false,
                        showPopUp : false
                    })

                    this.props.setUserDetail(userDetail)


                    clearInterval(this.interval)

                }else {

                    this.setState({

                        confirmationFailed : true,
                        showPopUp : true

                    })


                }


            }).catch(error => {

            console.log(error)


            this.setState({

                // loadingRefresh : false
            })


        });


    }


    hidePopUp(){


        this.setState({

            showPopUp : false

        })


    }


    interval
    componentDidMount(){

        console.log("show confirmation popup")


        console.log(this.props.userDetail)

        this.interval = setInterval(this.refreshUser, 3000);


    }



    render (){

        return(


            <Modal  dialogClassName="modal-70w" show={this.state.showPopUp }

                     onHide={this.hidePopUp}
                     className={"custom-modal-popup "}
            >
                {/*<button onClick={this.hidePopUp} className="close" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>*/}
                <div className="row py-3 justify-content-center mobile-menu-row">
                    <div className="col mobile-menu">
                        
                        <div className="confirm-mail-popup col-12">
                            
                            <h1><i class="fas fa-envelope"></i></h1>
                            <h3 style={{textAlign : "center"}} className="form-heading">Lets Confirm Your Email Address </h3>

                            <div className="text-center">
                                <p>You are recieving this message because you signed up to our mailing list. Please verify your email.
                                </p>


                            </div>

        <Row className="justify-content-md-center">

                            <Col md="auto">

                            <div className="d-flex btn-group confirm-btn-grp">

                                <Button  variant="light" onClick={this.resendConfirmationEmail}  style={{width: "auto"}}
                                        type={"submit"}

                                         type={"submit"}  >
                                    { this.state.loading && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> }


                                    {!this.state.emailSent &&
                                    <>
                                        {this.state.loading  ? "Sending.." : "Resend Confirmation Email"}

                                    </>}

                                    {this.state.emailSent &&
                                    <>
                                        {this.state.loading  ? "Sending.." : "Email Sent"}

                                    </>}


                                </Button>
                            </div>
                            </Col>


                        </Row>

                    <Row className="justify-content-md-center">

                                <Col md="auto">

                                    {/*{this.state.confirmationFailed &&<div className={"mt-2"}>*/}
                                      {/*<Error  style={{color: "#ec1c24"}} />  Email not confirmed yet. Try again.*/}
                                    {/*</div>}*/}

                                    <div className="d-flex btn-group confirm-btn-grp">

                                        <Button onClick={this.refreshUser} className="btn refresh" style={{width: "auto"}}
                                                type={"submit"}

                                                type={"submit"}  >
                                            { this.state.loadingRefresh && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> }

                                            { this.state.loadingRefresh  ? "Wait.." :<> <Sync/> Refresh(If confirmed)</>}

                                        </Button>


                                    </div>
                                </Col>


                            </Row>


                        </div>
                   


                   </div>
                </div>
            </Modal>

        )
    }
}



const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        userDetail: state.userDetail,
        abondonCartItem : state.abondonCartItem



    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        setUserDetail : (item) => dispatch(actionCreator.setUserDetail(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(EmailConfirmationPopUpNew);


