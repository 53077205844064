import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import axios from "axios/index";
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom';
import { Col, Form, Button, Spinner} from 'react-bootstrap';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import {loadSalesQuote} from "../store/actions/actions";


class  PersonalInformation extends Component{

    constructor(props) {
        super(props)

        this.state = {
            menuItems: [],
            showSearchBox: false,
            displayMenu: false,
            displayMegaMenu: false,
            showItems: [],
            isLoggedIn: false,
            customer : {},
            validatedForm: false,
            isChecked: false,
            passwordError : false,
            firstName : this.props.userDetail.firstName,
            lastName : this.props.userDetail.lastName,
            email : this.props.userDetail.email,
            phoneNumber : this.props.userDetail.phoneNumber,
            loading: false

        };
        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);


    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }




    handleSubmit = event => {

        event.preventDefault();
        const form = event.currentTarget;
        console.log(form.checkValidity())
        if (form.checkValidity() === false) {
            event.stopPropagation();


            this.setState({
               validatedForm : true
            });


        }else{



            this.setState({

                loading : true
            })

            var url = baseUrl + "edit_accounts";

            console.log(url)


            const dataForm = new FormData(event.target);

            const firstName = dataForm.get("firstName")
            const lastName = dataForm.get("lastName")
            const email = dataForm.get("email")
            const phoneNumber = dataForm.get("phoneNumber")



            axios.post(url,
                { firstName: firstName,lastName: lastName,  email: email,
                    phoneNumber : phoneNumber, customer : this.props.userDetail.id
            })
                .then(res => {

                    console.log(res.data)

                    this.setState({

                        loading : false
                    })


                }).catch(error => {

                this.setState({

                    loading : false
                })

            });



        }


    }






    render (){

        return(
               <div class="tab-content" id="pills-tabContent">

                                                <div class="tab-pane fade show active" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                                                    <Form  className="border-0"  noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>First name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                id="customer_firstName"
                                                                name="firstName"
                                                                type="text"
                                                                maxlength="180"
                                                                placeholder=""
                                                                value={this.state.firstName}
                                                                onChange={this.handleInputChange}

                                                            />
                                                        </Form.Group>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control
                                                                required
                                                                id="customer_firstName" 
                                                                name="lastName"
                                                                maxlength="180" 
                                                                value={this.state.lastName}
                                                                type="text"
                                                                placeholder=""
                                                                defaultValue=""
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control
                                                                required
                                                                id="customer_firstName" 
                                                                name="email"
                                                                maxlength="180" 
                                                                value={this.state.email}
                                                                type="email"
                                                                placeholder=""
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </Form.Group>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <Form.Control
                                                                required
                                                                id="customer_firstName" 
                                                                name="phoneNumber"
                                                                maxlength="180" 
                                                                className="" 
                                                                value={this.state.phoneNumber}
                                                                type="text"
                                                                placeholder=""
                                                                defaultValue=""
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </Form.Group>
                                                        </Form.Row>
                                                        <Button className="btn btn-red" type="submit">

                                                        { this.state.loading && <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /> }

                                                        { this.state.loading ? "Wait.." : "Save"}
                                                        </Button>
                                                    </Form>   
                                                </div>
                                            </div>

        )
    }
}


const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(PersonalInformation);


