import React, { Component } from 'react';
import './TrendingWeekend.css';
// import './Dropdown.css';

import Trend from '../img/image.png';
import Trend1 from '../img/image-1.png';
import Trend2 from '../img/image-2.png';
import Trend3 from '../img/image-3.png';
import Trend4 from '../img/image-4.png';
import TrandingIcon from '../img/tranding-icon.svg';
import { Button, Col, Row, Carousel, CarouselItem, NavItem, NavDropdown, Navbar, Nav, NavbarBrand, Container} from 'react-bootstrap'
import axios from "axios/index";
import {connect} from 'react-redux'
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'


class  TestingMenu extends Component{

    constructor(){

        super()

        this.state = {
            displayMenu: false,
            displayMegaMenu: false,
            showItems : [],
            levelTwo : [],
            menuItems : []

        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.showMegaMenu = this.showMegaMenu.bind(this);
        this.hideMegaMenu = this.hideMegaMenu.bind(this);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);

    }


    componentDidMount (){

            // this.props.fetchCategories();


        axios.get("https://www.happy-weekends.com/api/categories.json?groups[]=list&level=1&isActive=1")
            .then(res => {
                const persons = res.data;
                this.setState({ menuItems : persons ,
                });
            })

    }


    toggleSubMenu(e){


        let showItemsNew = this.state.showItems.slice();
        showItemsNew[e.currentTarget.dataset.id] = !showItemsNew[e.currentTarget.dataset.id];

        this.setState({ showItems: showItemsNew });
        //
        // console.log(index)
        // alert(index)


        // alert(e.currentTarget.dataset.id)


    }




    showMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true  });
    }

    hideMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: false  });
    }


    showMegaMenu(event) {
        event.preventDefault();
        this.setState({ displayMegaMenu: true  });
    }

    hideMegaMenu(event) {
        event.preventDefault();
        this.setState({ displayMegaMenu: false  });
    }

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }

    render (){



        return(
            <section  className="tranding-section">


                <div className="container">
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <h2><img width="30px" src={TrandingIcon} alt="" /> Testing this weekend</h2>
                        </div>
                    </div>
                    <div className="row">
                    </div>

                </div>
            </section>
        )
    }
}




function LevelTwo(props) {

    return (

        <li><a href="#Activity Logs">{props.item.name}</a></li>

    )

}
function LevelOne(props) {

    return (

      <h1>hello</h1>
    )


}

const mapStateToProps = (state) => {

    return{
    //     age:state.age,
    //     history : state.history,

        categories : state.categories

    }
}



const mapDispatchToProps = (dispatch) => {

    return {

        // onAgeUp: () => dispatch({type:"AGE_UP", value : 1}),
        // onAgeDown: () => dispatch({type:"AGE_DOWN", value : 12}),
        // onDeleteItem: (id) => dispatch({type:"DELETE_ITEM", key : id}),


        // fetchCategories : state.categories



    }

}


export default connect(mapStateToProps,mapDispatchToProps)(TestingMenu);
