import React, { Component }  from "react";
import { GoSellElements } from "@tap-payments/gosell";
import {Link} from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import {loadingCoupon, loadSalesQuote} from "../store/actions/actions";
import axios from "axios/index";
import history from "../History/history";

class GoSellElementsDemo extends Component {

    constructor(props){
        super(props);
    }

    callbackFunc(response){


        console.log(response);

        // var url="https://api.tap.company/v2/tokens";
        var url="https://api.tap.company/v2/authorize/";

        // window.location.replace(url)

        // history.push("booking/success/"+this.props.userDetail.id)



        // var postData = {
        //
        //     customer: val.customer,
        //     code: val.code,
        //     addCoupon: val.addCoupon
        //
        // }


        const config = {
            headers: { Authorization: `Bearer sk_test_Ww1CyjvpDdPhUfsLV2tFXHiM` }
        };



        var postData = {
            "amount": 1,
            "currency": "KWD",
            "threeDSecure": true,
            "save_card": false,
            "description": "test description",
            "statement_descriptor": "sample",
            "metadata": {
                "udf1": "test"
            },
            "reference": {
                "transaction": "txn_0001",
                "order": "ord_0001"
            },
            "receipt": {
                "email": false,
                "sms": true
            },
            "customer": {
                "first_name": "test",
                "middle_name": "test",
                "last_name": "test",
                "email": "test@test.com",
                "phone": {
                    "country_code": "965",
                    "number": "50000000"
                }
            },
            "source": {
                "id": response.id
            },
            "auto": {
                "type": "VOID",
                "time": 100
            },
            "post": {
                "url": "http://localhost:3000/checkout"
            },
            "redirect": {
                "url": "http://localhost:3000/checkout"
            }
        }


        axios.post(url, postData, config)
            .then(res => {

                console.log(res.data)


            }).catch(error => {

            console.log(error)
            return {type: "ERROR_REQUEST", value: error};


        });





    }

    render() {

        return (
            <div className="App">

                <GoSellElements
                    gateway={{
                        publicKey:"pk_test_8Mo5m9kvfwqlGUuDYSCLTxIg",
                        language:"en",
                        supportedCurrencies: "all",
                        supportedPaymentMethods: "all",
                        notifications:'msg',
                        callback: this.callbackFunc,
                        labels:{
                            cardNumber:"Card Number",
                            expirationDate:"MM/YY",
                            cvv:"CVV",
                            cardHolder:"Name on Card",
                            actionButton:"Pay"
                        },
                        style: {
                            base: {
                                color: '#535353',
                                lineHeight: '18px',
                                fontFamily: 'sans-serif',
                                fontSmoothing: 'antialiased',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.7)',
                                    fontSize:'15px'
                                }
                            },
                            invalid: {
                                color: 'red',
                                iconColor: '#fa755a '
                            }
                        }
                    }}
                />

                <p  style={{color:"#ec1c24"}} id="msg"></p>
                <div className="checkbox">
                    <input id="check3" type="checkbox" name="check" value="check3"/>
                    <label htmlFor="check3">I’ve read and accept the <Link to="/terms-conditions">terms and
                        conditions</Link> & <Link to="/privacy">privacy policy</Link>. <em
                        className="text-danger">*</em></label>
                </div>
                <button className="red-btn w-100" onClick={() => GoSellElements.submit()}>Proceed to Payment</button>
            </div>
        );
    }
}

export default GoSellElementsDemo;
