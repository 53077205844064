import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history';
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom';
import { Col, Form, Button, Spinner} from 'react-bootstrap';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import axios from "axios/index";




class  ChangePassword extends Component{
    constructor(props) {
        super(props)

        this.state = {
            menuItems: [],
            showSearchBox: false,
            displayMenu: false,
            displayMegaMenu: false,
            showItems: [],
            isLoggedIn: false,
            customer : {},
            validatedForm: false,
            isChecked: false,
            passwordError : false,
            loading : false,
            oldPassword : "",
            newPassword : "",
            confirmPassword : "",
            oldPasswordError : false,
            confirmPasswordError : false,

        };
        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        console.log(form.checkValidity())
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
               validatedForm : true
            });
        }else {


            const dataForm = new FormData(event.target);

            const oldPassword = dataForm.get("oldPassword")
            const newPassword = dataForm.get("newPassword")
            const confirmPassword = dataForm.get("confirmPassword")




            if (confirmPassword!=newPassword){


                this.setState({
                    validatedForm : false,
                    confirmPasswordError: true
                });

                    event.stopPropagation();


            } else {


                this.setState({
                    validatedForm: true,
                    confirmPasswordError: false
                });


                this.setState({

                    loading: true
                })

                var url = baseUrl + "change_passwords";

                console.log(url)


                axios.post(url,
                    {
                        oldPassword: oldPassword, newPassword: newPassword, customer: this.props.userDetail.id
                    })
                    .then(res => {

                        console.log(res.data)

                        if (res.data.code==200){


                            this.setState({

                                oldPasswordError: false
                            })
                        }

                        else if (res.data.code==201){


                            this.setState({

                                oldPasswordError: true
                            })


                        }

                        this.setState({

                            loading: false
                        })


                    }).catch(error => {

                    this.setState({

                        loading: false
                    })

                });

            }


        }
    } 
    render (){
        return(

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                     aria-labelledby="pills-personal-tab">
                    <Form  className="border-0"  noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>Old Password    </Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="password"
                                                                maxlength="180"
                                                                placeholder=""
                                                                name="oldPassword"
                                                                isInvalid={this.state.oldPasswordError}

                                                            />

                                                                {this.state.oldPasswordError && <Form.Control.Feedback type="invalid">Wrong password!</Form.Control.Feedback> }
                                                        </Form.Group>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>New Password</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="password"
                                                                placeholder=""
                                                                defaultValue=""
                                                                name="newPassword"
                                                            />

                                                        </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Form.Label>Confirm Password</Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="password"
                                                                placeholder=""
                                                                defaultValue=""
                                                                name="confirmPassword"

                                                                isInvalid={this.state.confirmPasswordError}

                                                            />
                                                                {this.state.confirmPasswordError && <Form.Control.Feedback type="invalid">Confirm Password do not match!</Form.Control.Feedback> }
                                                        </Form.Group>
                                                        </Form.Row>

                        <Button className="btn btn-red" type="submit">

                            { this.state.loading && <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> }

                            { this.state.loading ? "Wait.." : "Save"}
                        </Button>

                                                    </Form>
            </div>
            </div>


        )
    }
}

const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(ChangePassword);