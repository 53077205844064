import {getKey, saveKey} from "../../LocalStorage/user";

export const initialState = {
    age: 20,
    cartItems: [],
    abondonCartItem: null,
    salesQuote: null,
    loading : false,
    isLoggedIn : false,
    rememberMe : false,
    loginFailed : false,
    signUpFailed : false,
    isCustomer : false,
    isCorporateCustomer : false,
    isPartner : false,
    token : {},
    userDetail : null,
    favorites : [],
    location:{},
    cityId:{},
    city:{},
    cartTotal: 0,
    total: 0,
    subTotal: 0,
    cartDiscount: 0,
    couponDiscount: 0,
    couponDiscountVisible : false,
    couponError : false,
    couponDiscountPercentage: 0,
    couponCode: null,
    couponCheckloading : false,
    addressInput : false,
    bookingTapResponse: null,
    showLoginPopUp: false,
    showSocialLoginPopUp: false,
    showLoginCheckoutPopUp: false,
    isGuest : false,
    slides: [],
    trendingItems : [],
    showEditCartPopUp : false,
    showEditBundleCartPopUp : false,
    cartPopUpItem : null,
    reviewBoxOpen : false,
    categories : [],
    reviewSuccessMessage : false,
    reviewLoading : false,
    showNewsletter : true,
    bundleItem : null,
    bundleItems : [],
    isCartItemsLoadingDone : false,
    socialUserInfo:null,
    addressExist: false,
    stayAtHomeAddress: null,
    activityPopUp:false,
    editActiviyId:null


};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {

        case "AGE_UP":
            newState.age += action.value;
            newState.loading = false;
            break;

        case "AGE_DOWN":
            newState.age -= action.value;
            break;

        case "ACTIVITY_POPUP":

            newState.activityPopUp = action.value.show;
            newState.editActiviyId = action.value.activityId;

            break;


        case "NEWSLETTER_POP_UP":

            newState.showNewsletter = action.value;
            break;


        case "LOAD_CART_ITEMS":



            newState.addressInput=false

            // newState.salesQuote = action.value.salesQuote

            if (action.value.salesQuote) {

                var salesQuote = action.value.salesQuote
                // newState.salesQuote = action.value.salesQuote

                var cartItems = []


                //update quantity if already selection made by user


                    for (var j = 0; j < salesQuote.cartItems.length; j++) {

                        if (salesQuote.cartItems[j].itemType==0) {

                            var cartPriceOptions = []
                            var optionsSelected = salesQuote.cartItems[j].cartPriceOptions

                            for (var i = 0; i < optionsSelected.length; i++) {

                                for (var k = 0; k < salesQuote.cartItems[j].activity.priceOption.length; k++) {

                                    if (salesQuote.cartItems[j].activity.priceOption[k].id === optionsSelected[i].priceOptionId) {


                                         salesQuote.cartItems[j].activity.priceOption[k].quantity = optionsSelected[i].quantity


                                    }else {

                                        salesQuote.cartItems[j].activity.priceOption[k].quantity = 0


                                    }

                                }
                            }


                        }else {



                        }
                    }


                //calculate total

                // newState.cartItems = salesQuote.cartItems
                newState.loading = false;

                var items = cartItems;


                    // var total = 0
                    // var cartTotal = 0;
                    // var cartTotalDiscountedPrice = 0;
                    // var subTotal = 0;
                    // var cartDiscount = 0;
                    // var couponDiscount = 0;
                    //
                    // var couponPercentage = 0;
                    //
                    //
                    // var subTotalProcessed = 0;


                    //checl if address input is required

                    for (var i = 0; i < items.length; i++) {


                        // // console.log("cehck if address required")

                        if (items[i].itemType == 0) {


                            // // console.log("location preference")
                            //
                            // // console.log(items[i].activity.activityPreference)

                            if (items[i].activity.activityPreference && items[i].activity.activityPreference.name === "Stay At Home") {


                                newState.addressInput = true

                            }



                        }
                    }



                // cartDiscount = (cartTotal - cartTotalDiscountedPrice)

                    if (salesQuote.promotionalCoupon) {



                        // alert("promo code")

                        newState.couponDiscountPercentage = salesQuote.promotionalCoupon.discountPercentage
                        newState.couponDiscountVisible = true

                        newState.couponCode = salesQuote.promotionalCoupon.code
                        newState.couponError = false
                        newState.couponDiscountVisible = true

                        newState.couponCheckloading= false


                    } else {


                        if (newState.couponCheckloading) {

                            newState.couponError = true
                            newState.couponCheckloading= false


                        } else {

                            newState.couponError = false

                        }

                        newState.couponDiscountPercentage = 0
                        newState.couponDiscountVisible = false
                        newState.couponCode = ""

                    }




                }

                // // console.log("cartItems loaded and added")
                // // console.log(cartItems)
                // // console.log("new state cartItems loaded and added")
                // // console.log(newState.cartItems)


                newState.salesQuote = salesQuote
                newState.cartItems = salesQuote.cartItems

                newState.total = action.value.salesQuote.total
                newState.subTotal = action.value.salesQuote.total
                newState.cartTotal = action.value.salesQuote.cartTotal
                newState.discount = action.value.salesQuote.discount
                newState.cartDiscount = action.value.salesQuote.discount
                newState.couponDiscount = action.value.salesQuote.couponDiscount
                newState.isCartItemsLoadingDone = true



            break;



        case "IS_CART_LOADING":


            if (newState.cartItems.length==0)
                newState.isCartItemsLoadingDone = false


            break;

        case "SET_USER_DETAIL":

            newState.userDetail=( action.value);

            break;


        case "ADD_CART_ITEM_GUEST":


            // // console.log(action.value)

            if (action.value.cartItem.activity.activityPreference&&action.value.cartItem.activity.activityPreference.name==="Stay At Home") {


                newState.addressInput=true

            }


            newState.cartItems=[]
            newState.cartItems.push( action.value.cartItem);

            newState.abondonCartItem = action.value.cartItem

            // // console.log("added guest cart item")
            // // console.log(newState.cartItems)
            newState.loading = false;




            break;

        case "ADD_CART_ITEM":

            // // console.log("add cart item")
            //
            // // console.log(action.value)

            if (action.value.cartItem.activity.activityPreference&&action.value.cartItem.activity.activityPreference.name==="Stay At Home") {


                newState.addressInput=true

            }


            newState.cartItems.push( action.value.cartItem);
            newState.loading = false;
            // // console.log(action.value)

            // window.location.replace("/checkout/"+newState.userDetail.id);


            break;




        case "REMOVE_CART_ITEM":

            // // console.log(action.value)

            var cartItems =newState.cartItems.filter((item) => item.id != action.value.activity)

            newState.cartItems =  cartItems

            newState.loading = false;
            // // console.log(newState.cartItems)

            break;

        case "ADD_FAVORITE_ITEM":
            newState.favorites.push( action.value);
            newState.loading = false;
            // // console.log(action.value)


            break;



        case "LOADING_SPINNER":

            newState.reviewLoading = true;

            break;
        case "REVIEW_BOX_OPEN":

            newState.reviewBoxOpen = action.value;

            break;

        case "REMOVE_FAVORITE_ITEM":

            // // console.log(initialState)

            newState.favorites = initialState.favorites.filter((item) => item.id != action.value)

            // newState.cartItems.push( action.value);
            newState.loading = false;
            // // console.log(action.value)

            break;
        case "LOGIN":

            newState.loginFailed = false;
            newState.isLoggedIn = true;
            newState.loading = false;
            newState.token = action.value.token
            sessionStorage.setItem("token",action.value.token)

            break;

        case "LOGOUT":


            newState.isLoggedIn = false;
            newState.loading = false;
            newState.loginFailed = false
            newState.couponError = false
            newState.addressInput = false
            newState.cartItems = []
            newState.isGuest = false
            newState.showLoginPopUp = false
            newState.guestCartItems = []
            newState.couponError = false
            newState.showLoginCheckoutPopUp = false
            newState.userDetail = null
            newState.loading = false
            newState.favorites = []


            // window.location.href=("/")


            // console.log(action.value)

            break;



        case "STAY_AT_HOME_ADDRESS":

            // console.log("stay at home address selected")
            newState.stayAtHomeAddress = action.value;


            break;


        case "ADDRESS_EXIST":

            // console.log("stay at home address selected")
            newState.addressExist = action.value;


            break;



        case "LOGIN_POPUP":

            // console.log("reducer")
            newState.loginFailed= false
            newState.showLoginPopUp = action.value;
            // console.log(action.value+" "+newState.showLoginPopUp)


            break;



        case "SOCIAL_LOGIN_POPUP":

            // console.log("reducer")
            // newState.loginFailed= false
            newState.showSocialLoginPopUp = action.value;
            newState.showLoginPopUp = action.value;
            // // console.log(action.value+" "+newState.showLoginPopUp)


            break;

        case "SOCIAL_USER_INFO":

            // console.log("reducer")
            // newState.loginFailed= false
            newState.socialUserInfo = action.value;
            // // console.log(action.value+" "+newState.showLoginPopUp)


            break;
        case "EDIT_BUNDLE_CART_POPUP":

            // console.log("edit bundle pop up")
            newState.showEditBundleCartPopUp = action.value;

            break;

        case "ADD_BUNDLE_CART_ITEM":



            var bundleItems = []


            if (getKey("bundleItems")){

                bundleItems= getKey("bundleItems").filter((item)=> item.id!=action.value.id)

                bundleItems.push(action.value)

                saveKey("bundleItems",bundleItems)

            } else {

                bundleItems.push(action.value)

                saveKey("bundleItems",bundleItems)

            }

            newState.bundleItems =  bundleItems;


            // console.log("bundleItems eisting")
            // console.log(newState.bundleItems)

            break;





        case "SET_EDIT_BUNDLE_CART_POPUP":

            newState.bundleItem = action.value;


            break;

            case "EDIT_CART_POPUP":

                // console.log("edit pop up")
                newState.showEditCartPopUp = action.value;

                break;

        case "SET_EDIT_CART_POPUP":

            // console.log("edit activtity value")
            // console.log(action.value)

            var carItems = newState.cartItems

            var cartItem = carItems.find((item) => item.id ==  action.value)
            // console.log(cartItem)
            newState.cartPopUpItem = cartItem

            if (cartItem.itemType==0) {

                newState.showEditCartPopUp = action.value;


            }else {

                newState.showEditBundleCartPopUp = action.value
            }

            // console.log(newState.cartItems)


            // // console.log(newState.cartPopUpItem)
            break;
        case "LOGIN_CHECKOUT_POPUP":

            // console.log("reducer")
            newState.loginFailed= false
            newState.showLoginCheckoutPopUp = action.value;
            // console.log(action.value+" "+newState.showLoginCheckoutPopUp)
            break;

        case "LOADING":
            newState.loading = true;
            break;

        case "IS_GUEST":
            newState.isGuest = true;

            break;

        case "LOADING_COUPON":

            newState.couponCheckloading = true;
            newState.couponError = false;

            break;


        case "SLIDES_LOAD":

            newState.slides = action.value;
            break;


        case "TRENDING_LOAD":

            newState.trendingItems = action.value;

            break;


        case "REVIEW_SUCCESS":


            newState.reviewSuccessMessage = false

            break;


        case "REVIEW_SUBMIT":

            newState.loading = false;
            newState.reviewLoading= false
            newState.reviewBoxOpen = false;
            newState.reviewSuccessMessage = true

            break;

        case "DISABLE_COUPON":

            newState.couponCheckloading = false;
            newState.couponError = false;

            break;
        case "ERROR_REQUEST":
            newState.loading = false;
            // console.log(action.value)
            break;

        case "FAVORITES":
            newState.favorites = action.value.favoriteActivities;
            break;




        case "SET_CATEGORIES":

            newState.categories = action.value;
            // console.log("set categories props")
            // console.log(action.value)
            break;

        case "SET_LOCATION":
            newState.location = action.value;
            break;

        case "SET_CITY":
            newState.city = action.value.city;
            newState.cityId = action.value.cityId;
            break;

        case "LOGIN_FAILED":
            newState.loginFailed = true;
            newState.isLoggedIn = false;
            newState.loading = false;
            break;


        case "STOP-LOADING":
            newState.loading = false;
            break;

        case "USER_DETAIL":

            if (action.value.isGuest){

                newState.isGuest = true;
                newState.isLoggedIn = false;


            } else {

                newState.isLoggedIn = true;
                newState.isGuest = false;

            }


            newState.userDetail = action.value;
            newState.loading = false;
            break;




        case "HOST_SIGN_UP":


            if (action.value.isGuest) {

                newState.isGuest = true;
                newState.isLoggedIn = false;

            }else {

                newState.isLoggedIn = true;
                newState.isGuest = false;
            }

            // console.log(" user sign up ")
            // console.log(action.value)

            newState.showLoginPopUp = false
            newState.showLoginCheckoutPopUp = false
            newState.userDetail = action.value;
            newState.loading = false;

            break;

        case "SIGN_UP":


            newState.showSocialLoginPopUp = false

            if (action.value.isGuest) {

                newState.isGuest = true;
                newState.isLoggedIn = false;

            }else {

                newState.isLoggedIn = true;
                newState.isGuest = false;
            }

            // console.log(" user sign up ")
            // console.log(action.value)

            newState.showLoginPopUp = false
            newState.showLoginCheckoutPopUp = false
            newState.userDetail = action.value;
            newState.loading = false;

            break;

        case "BOOKING_RESPONSE":
            newState.bookingTapResponse = action.value;
            newState.loading = false;
            break;

    }

    return newState;
};


export default reducer;
