import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import axios from "axios/index";
import queryString from 'query-string'



import {baseUrl,baseImgUrl} from  '../Util/Constants'
import {connect} from "react-redux";
import * as actionCreator from "../store/actions/actions";
import history from "../History/history";




class  UnsubscribeEmail extends Component{



    email;

    constructor(props) {


        super(props)
        this.state = {


            success : false,
            displaySuccess : false,
            alreadyExists: false

        };
    

        // this.id = this.props.match.params.id
         this.email= this.props.match.params.email
    }


    componentDidMount(){


        console.log(this.props.match.params)

        const urlParams = queryString.parse(this.props.location.search)

        console.log(urlParams)


        axios.post(baseUrl+"unsubscribes" ,{ email: urlParams.email  }).then(res => {


             console.log( res.data)



            
            if (res.data.code==200) {

                this.setState({

                    success: true,
                    displaySuccess: true,
                })

            }else {

                this.setState({

                    alreadyExists: true,
                    displaySuccess: true,
                })

            }




        }).catch(error => {

            console.log(" error found "+error)


        });




    }

    render (){


        return(

            <>

                 <section className="not-found email-confirm mb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {this.state.success&&this.state.displaySuccess &&
                            <div className="text-center">
                                <h1><i class="fas fa-check-circle"></i></h1>
                                <h3>You have successfully unsubscribed your email address from our newsletters. Thanks</h3>
                                {
                                    <Link to="/" className="red-btn">Go to Homepage</Link>

                                }
                            </div>
                            }

                            {this.state.alreadyExists&&this.state.displaySuccess &&
                            <div className="text-center">
                                <h1><i class="fas fa-check-circle"></i></h1>
                                <h3>You have already unsubscribed your email address from our newsletters. Thanks</h3>
                                {
                                    <Link to="/" className="red-btn">Go to Homepage</Link>

                                }
                            </div>
                            }


                        </div>

                    </div>
                </div>
            </section>
                </>
         )
    }



}




const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        abondonCartItem : state.abondonCartItem,
        userDetail: state.userDetail,




    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        setUserDetail : (item) => dispatch(actionCreator.setUserDetail(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(UnsubscribeEmail);

// export default EmailConfirmation;