import React, { Component,useEffect,useState,useRef } from 'react';
import { Link} from 'react-router-dom'
import './weekend-pass.css';
import RoomImg from './images/room-l-1.jpg';
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap'
import maps from './images/maps.png';
import mapWhite from './images/map-white.png';

import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import Rating from '@material-ui/lab/Rating';
import  {Star} from '@material-ui/icons';
import  {CheckIcon} from './images/icon-check.png';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import axios from "axios/index";
import PlusIcon from '../img/plus.png';
import MinIcon from '../img/min.png';
import history from "../History/history";
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import ReactHtml from 'raw-html-react';


class  WeekendPassDetail extends Component{

    slug;
    constructor(props) {

        super(props);

        this.state = {

            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            email : null,
            userExists : false,
            socialUserExists : false,
            emailLoad : false,
            showPopUp : false,
            btnLoading : false,
            successSubmit : false,
            bundle : false,
            selectedActivity: null,
            bundleItems: [],
            total : 0,
            cartTotal : 0,
            totalDiscount : 0,
            activityDetail:{},
            displayMobilePriceOptions:false,




        }

        this.slug = props.match.params.slug
        this.hidePopUp = this.hidePopUp.bind(this);
        this.showPopUp = this.showPopUp.bind(this);
        this.loadDetail = this.loadDetail.bind(this);
        this.setPriceOptions = this.setPriceOptions.bind(this);
        this.goToCheckout=this.goToCheckout.bind(this)
        this.checkCartItems=this.checkCartItems.bind(this)
        this.loadInitialSelection= this.loadInitialSelection.bind(this)
        this.toggleMobilePriceOptions=this.toggleMobilePriceOptions.bind(this)



    }

    goToCheckout(){


        document.body.classList.remove('body-overflow');


        if ((this.props.isLoggedIn|| this.props.isGuest )&& this.props.userDetail.id) {


            // this.props.addBundleCartItem(this.state.bundle)



            var priceOptions=[];

            for (var i=0; i<this.state.bundle.bundleItems.length;i++){


                for (var j=0; j<this.state.bundle.bundleItems[i].activity.priceOption.length;j++){



                    if (this.state.bundle.bundleItems[i].activity.priceOption[j].quantity>0) {

                        priceOptions.push(this.state.bundle.bundleItems[i].activity.priceOption[j]);

                    }

                }

            }

            console.log("weekend pass options")
            console.log(priceOptions)


            this.props.addCartItem(

                {
                    "user":this.props.userDetail,
                    "cartItem":{
                        "itemType":1,
                        "bundle": this.state.bundle,
                        'cartPriceOptions': priceOptions

                    }
                }

            )


            history.push("/checkout")

        }else{

            // alert("logout  first")


            this.props.loginCheckoutPopUp(true)

        }

    }


    setPriceOptions(){

        let bundle = this.state.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {

            let priceOptionNew = bundleItems[i].activity.priceOption;


            for (var j = 0; j < priceOptionNew.length; j++) {

                priceOptionNew[j].quantity = 0

            }

        }


        this.setState({

            bundleItems: bundleItems

        })

    }


    loadInitialSelection(){


        let bundle = this.state.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {

            bundleItems[i].total=0

            // bundleItems[i].cartTotal=0

            let priceOptionNew = bundleItems[i].activity.priceOption;


                priceOptionNew[0].quantity = priceOptionNew[0].quantity + 1

                    var price = 0

                    var totalPrice = 0

                    var cartPrice = priceOptionNew[0].price

                    // if(priceOptionNew[0].discountedPrice){
                    //
                    //     price = priceOptionNew[0].discountedPrice
                    //
                    //     // bundleItems[i].total=bundleItems[i].total+price
                    //
                    //
                    // }else {

                        price = priceOptionNew[0].price

                        // bundleItems[i].total=bundleItems[i].total+price


                    // }

            bundleItems[i].total=bundleItems[i].total+priceOptionNew[0].price



            this.setState({

                        total: this.state.total + price*(100 - bundleItems[i].discount)/100 ,
                        cartTotal : this.state.cartTotal + cartPrice


                    });

                    }



        this.setState({

            bundleItems: bundleItems

        })


        this.checkCartItems()
    }



    checkCartItems(){


        var cartItems =  this.props.cartItems

            for(var n=0;n<cartItems.length;n++) {

                console.log("cartItems")
               console.log(cartItems)

                if (cartItems[n].itemType == 1 && cartItems.bundle && cartItems[n].bundle.id == this.state.bundle.id) {


                    alert("aler Exists")

                    let bundle = this.state.bundle

                    var bundleItems = bundle.bundleItems

                    for (var i = 0; i < bundleItems.length; i++) {

                        bundleItems[i].total = 0

                        let priceOptionNew = bundleItems[i].activity.priceOption;



                        for (var p = 0; p < priceOptionNew.length; p++) {


                            for (var f = 0; f < cartItems[n].cartPriceOptions.length; f++) {

                                
                                
                                if (cartItems[n].cartPriceOptions[f].priceOptionId==priceOptionNew[p].id) {


                                    priceOptionNew[0].quantity = cartItems[n].cartPriceOptions[f].quantity

                                    var price = 0
                                    var cartPrice = priceOptionNew[p].price*cartItems[n].cartPriceOptions[f].quantity


                                    if (priceOptionNew[p].discountedPrice) {

                                        price = priceOptionNew[p].discountedPrice*cartItems[n].cartPriceOptions[f].quantity

                                        bundleItems[i].total = bundleItems[i].total + price


                                    } else {

                                        price = priceOptionNew[p].price*cartItems[n].cartPriceOptions[f].quantity

                                        bundleItems[i].total = bundleItems[i].total + price


                                    }

                                    this.setState({

                                        total: this.state.total + price * (100 - bundleItems[i].discount) / 100,
                                        cartTotal: this.state.cartTotal + price


                                    });

                                }
                            }

                        }
                    }


                    this.setState({

                        bundleItems: bundleItems

                    })


                }

            }
    }



    incrementQuantity(e){

        let bundle = this.state.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {

            // bundleItems[i].total=0
            // bundleItems[i].cartTotal=0

            let priceOptionNew = bundleItems[i].activity.priceOption;


            for (var j = 0; j < priceOptionNew.length; j++) {



                if (priceOptionNew[j].id == e.currentTarget.dataset.id) {


                    priceOptionNew[j].quantity = priceOptionNew[j].quantity + 1

                    var price = 0

                    var cartPrice = priceOptionNew[j].price


                    // if(priceOptionNew[j].discountedPrice){
                    //
                    //     price = priceOptionNew[j].discountedPrice
                    //
                    //     // bundleItems[i].total=bundleItems[i].total+price
                    // }else {

                        price = priceOptionNew[j].price
                        // bundleItems[i].total=bundleItems[i].total+price

                    // }


                    bundleItems[i].total=bundleItems[i].total+priceOptionNew[j].price



                    this.setState({

                        total: this.state.total + price*(100 - bundleItems[i].discount)/100 ,
                        cartTotal : this.state.cartTotal + cartPrice


                    });


                }



            }

        }


        this.setState({

            bundleItems: bundleItems

        })





    }
    decrementQuantity(e){


        let bundle = this.state.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {


            let priceOptionNew = bundleItems[i].activity.priceOption;


            for (var j = 0; j < priceOptionNew.length; j++) {


                if ((priceOptionNew[j].id == e.currentTarget.dataset.id) && (priceOptionNew[j].quantity > 0)) {


                    priceOptionNew[j].quantity = priceOptionNew[j].quantity - 1


                    var price = 0
                    var cartPrice = priceOptionNew[j].price
                    // if (priceOptionNew[j].discountedPrice) {
                    //
                    //     price = priceOptionNew[j].discountedPrice
                    //     // bundleItems[i].total=bundleItems[i].total - price
                    // } else {

                        price = priceOptionNew[j].price

                        // bundleItems[i].total=bundleItems[i].total - price

                    //
                    // }


                    bundleItems[i].total=bundleItems[i].total -  priceOptionNew[j].price

                    this.setState({
                        total: this.state.total - price*(100 - bundleItems[i].discount)/100,
                        cartTotal : this.state.cartTotal - cartPrice


                    });


                } else {

                }
            }
        }


        this.setState({

            bundleItems: bundleItems

        })

    }


    loadDetail(){


        console.log("detail slug")
        console.log(this.slug)

        axios.get(baseUrl+"bundles.json?groups[]=activities&slug="+this.slug)
            .then(res => {

                const persons = res.data;
                console.log(res.data)

                this.setState({

                    bundle : persons[0],
                    priceOptions : persons[0].priceOption

                });


                this.setPriceOptions()

                this.loadInitialSelection()


            })

    }

    componentDidMount (){

        window.scrollTo(0, 0)
        this.loadDetail()

    }



    showPopUp(e){

        console.log("Selected Activity")

        console.log(e.currentTarget.dataset.id)

        var items= this.state.bundle.bundleItems

        console.log(items)


        var activity = items.filter((item)=> item.activity.id==e.currentTarget.dataset.id)[0].activity


        console.log(activity)

        this.setState({

            selectedActivity : activity

        })



        axios.get(baseUrl+"activities/"+activity.id+".json?groups[]=activity-detail")
            .then(res => {
                const persons = res.data;



                console.log("weekend pass item detail")

                console.log(res.data)



                this.setState({

                    activityDetail: persons

                });
            })



        this.setState({
            showPopUp: true
        })
    }

    hidePopUp(){

        this.setState({
            showPopUp: false
        })


    }


    toggleMobilePriceOptions(){


        if (!this.state.displayMobilePriceOptions){

            document.body.classList.add('body-overflow');

        } else {

            document.body.classList.remove('body-overflow');


        }
        this.setState({
            displayMobilePriceOptions : !this.state.displayMobilePriceOptions
        })



    }




    render (){
        return(

            <div className={"weekend-detail-page"}>

                <div className="hero-image-inner">
                    <div className="fade-overlay" />
                    <div className="bottom-left">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-8 col-xs-10">
                                    <ul>
                                        <li><Link to="/">Home <i className="fa fa-angle-right" aria-hidden="true" /></Link></li>
                                        <li><Link to="/weekend-passes">Weekend Pass <i className="fa fa-angle-right" aria-hidden="true" /></Link></li>
                                        <li>{this.state.bundle.name}</li>
                                    </ul>
                                    <h1>{this.state.bundle.name}</h1>
                                    <span className={"d-none"}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="weekend1">
                                    <p>{this.state.bundle.description}</p>
                                </div>
                                <div className="experiance_block_main">

                                    {this.state.bundle&&this.state.bundle.bundleItems.map((item,index) =>
                                        <>
                                        <div className="row">
                                        <div className="col-md-12">
                                            <span className="exp_title">{"Experience "+(index+1)+" -"}</span>
                                        </div>
                                    </div>
                                    <div className={"room_list_item internal"}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div href="" className="room_img">
                                                    <img src={baseImgUrl+item.activity.images[0].image} alt />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="room_list_l_text">

                                                        <span className={"title-name"}>{item.activity.title}</span>

                                                    {item.activity.address && item.activity.address.address &&  item.activity.address.city &&
                                                    <ul>
                                                        <li><img src={maps}
                                                                 alt/> {item.activity.address.address},{item.activity.address.city.name}
                                                        </li>
                                                    </ul>
                                                    }
                                                    <div className="rating_block">{item.activity.rating}/5

                                                        <Rating className="detail-head-r"
                                                                readOnly
                                                                value={item.activity.rating}
                                                                icon={<Star style={{color: "#29abe2"}} name="size-small"
                                                                            fontSize={"small"}/>}
                                                                emptyIcon={<Star color="disabled" name="size-small"
                                                                                 fontSize={"small"}/>}
                                                                name="size-large" size="large"
                                                        />

                                                    </div>
                                                    <div className="offer_prize">


                                                        {/*{item.activity.priceOption[0].discountedPrice && <div className="title-name pull-left">AED {item.activity.priceOption[0].discountedPrice} <span className={"text-line"}>AED {item.activity.priceOption[0].price}</span></div>}*/}
                                                        {/*{!item.activity.priceOption[0].discountedPrice && <div className="title-name pull-left">AED {item.activity.priceOption[0].price} </div>}*/}

                                                         <div className="title-name pull-left">AED {item.activity.priceOption[0].price} </div>



                                                        <button data-id={item.activity.id} onClick={this.showPopUp.bind(this)}  className="pull-right view_btn">View Details</button>
                                                        <div className="clearfix" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </>
                                        )}

                                </div>
                            </div>
                            <div id className="col-lg-4 col-md-4 col-sm-5 col-xs-12 pro-detail-web">


                                <div className="pro-detail  pro-detail-new new-block padtop0 pro-detail-web ">
                                    <h2 className="payment">Payment Details</h2>
                                    <div id="sticky-box">
                                        <div id="priceOptionForm">
                                            <div id="offers" className="right-section row display-sm-none">
                                                <Accordion defaultActiveKey={this.state.bundleItems.length>0&&this.state.bundleItems[0].activity.id}>

                                                <div className="offers wl_scroll_fix_detail offers-new ">

                                                    {this.state.bundleItems.map((bundleItem, index)=>

                                                    <div className="block border-bottom ">
                                                        <div className="top-block new">
                                                            <p className="mb-0 d-none">0 bought</p>
                                                            <h4 className="pull-left">
                                                                {bundleItem.activity.title}
                                                            </h4>
                                                            <h5 className="pull-right">AED {bundleItem.total?bundleItem.total:0}</h5>
                                                        </div>
                                                        <div className="left-side">
                                                            <div className="offer-data-left">

                                                                <p className="p1">
                                                                    <Accordion.Toggle as={Link} variant="link" eventKey={bundleItem.activity.id}>
                                                                        Customise Here <i style={{marginLeft:"5px" , color :"#999999"}} className="fas fa-chevron-down"></i>

                                                                    </Accordion.Toggle>
                                                                </p>


                                                                { bundleItem.activity.priceOption && bundleItem.activity.priceOption.map((item) =>

                                                                <>
                                                                <Accordion.Collapse eventKey={bundleItem.activity.id}>
                                                              <div className={"collapsible-content"}>
                                                                  <div className={"collapsable-inside-content"}>
                                                                <p className="p2">{item.description}</p>

                                                                <div>
                                                                    <button
                                                                        className="value-button"
                                                                            value="Decrease Value"
                                                                        data-id={item.id}
                                                                        data-price={item.id}
                                                                        onClick={this.decrementQuantity.bind(this)}

                                                                    ><img src={MinIcon} alt=""/>
                                                                    </button>

                                                                         <input dataprice="175" multiple=""
                                                                               name="priceOptionQuantity[]"
                                                                               className="number exists" readOnly=""
                                                                               type="text" id="number-1845" value={item.quantity} />
                                                                            <button
                                                                                data-id={item.id}
                                                                                data-price={item.id}
                                                                                onClick={this.incrementQuantity.bind(this)}
                                                                                    className="value-button"
                                                                                    value="Increase Value">
                                                                                <img src={PlusIcon} alt=""/>
                                                                            </button>
                                                                            <span className="span1">
                                                                                {/*AED {!item.discountedPrice && item.price}{item.discountedPrice && item.discountedPrice}*/}

                                                                                AED {item.price}


                                                                                </span>
                                                                </div>
                                                              </div>
                                                              </div>
                                                                </Accordion.Collapse>

                                                                    </>
                                                                    )
                                                                }



                                                            </div>

                                                        </div>
                                                    </div>



                                                    )
                                                    }


                                                    <div className="order-total new block border-bottom">
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p className="mb-0">Cart Total</p>
                                                                <p className="small-text mb-0">Weekend Pass Discount <span
                                                                    style={{ color:"#007dc4"}}>({Math.round((this.state.cartTotal - this.state.total)*100/this.state.cartTotal)}%)</span></p>
                                                            </div>
                                                            <div className="col-md-5 text-right">
                                                                <h5>AED {this.state.cartTotal}</h5>
                                                                <h5>AED {Math.round(this.state.cartTotal - this.state.total)}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-total new ">
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p className="mb-0 total_pay">Total<span>(Inclusive of VAT)</span>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-5 text-right">
                                                                <h5 className="total_sum">AED {Math.round(this.state.total)}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="promo-block new">
                                                        <button onClick={this.goToCheckout} className="buy-now">Book Now</button>
                                                    </div>
                                                </div>
                                                </Accordion>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>




                {!this.state.displayMobilePriceOptions  &&
                <div className="mobile-price" onClick={this.toggleMobilePriceOptions}>
                    <div className="Show Option">
                        <h1>AED 100</h1>
                        <button className="options-show">Book Now</button>
                    </div>
                </div>
                }
                {this.state.displayMobilePriceOptions &&
                <div className="mobile-pricing-view">

                    <div className="pro-detail  pro-detail-new new-block padtop0">
                        {/*<h2 className="payment">Payment Details</h2>*/}

                        <div id="sticky-box">

                            <div className={" col-12 offers wl_scroll_fix_detail "}>
                                <div className="pt-4 pb-4">

                                    <b style={{color: "#00adee", fontSize: "20px"}}>Select number of experiences</b>
                                    <button onClick={this.toggleMobilePriceOptions} className="cross-btn"><i
                                        className="fas fa-times"></i></button>
                                </div>


                            <div id="priceOptionForm">
                                <div id="offers" className="right-section row display-sm-none">
                                    <Accordion
                                        defaultActiveKey={this.state.bundleItems.length > 0 && this.state.bundleItems[0].activity.id}>

                                        <div className="offers wl_scroll_fix_detail offers-new ">

                                            {this.state.bundleItems.map((bundleItem, index) =>

                                                <div className="block border-bottom ">
                                                    <div className="top-block new">
                                                        <p className="mb-0 d-none">0 bought</p>
                                                        <h4 className="pull-left">
                                                            {bundleItem.activity.title}
                                                        </h4>
                                                        <h5 className="pull-right">AED {bundleItem.total ? bundleItem.total : 0}</h5>
                                                    </div>
                                                    <div className="left-side">
                                                        <div className="offer-data-left">

                                                            <p className="p1">
                                                                <Accordion.Toggle as={Link} variant="link"
                                                                                  eventKey={bundleItem.activity.id}>
                                                                    Customise Here <i
                                                                    style={{marginLeft: "5px", color: "#999999"}}
                                                                    className="fas fa-chevron-down"></i>

                                                                </Accordion.Toggle>
                                                            </p>


                                                            {bundleItem.activity.priceOption && bundleItem.activity.priceOption.map((item) =>

                                                                <>
                                                                    <Accordion.Collapse
                                                                        eventKey={bundleItem.activity.id}>
                                                                        <div className={"collapsible-content"}>
                                                                            <div
                                                                                className={"collapsable-inside-content"}>
                                                                                <p className="p2">{item.description}</p>

                                                                                <div>
                                                                                    <button
                                                                                        className="value-button"
                                                                                        value="Decrease Value"
                                                                                        data-id={item.id}
                                                                                        data-price={item.id}
                                                                                        onClick={this.decrementQuantity.bind(this)}

                                                                                    ><img src={MinIcon} alt=""/>
                                                                                    </button>

                                                                                    <input dataprice="175" multiple=""
                                                                                           name="priceOptionQuantity[]"
                                                                                           className="number exists"
                                                                                           readOnly=""
                                                                                           type="text" id="number-1845"
                                                                                           value={item.quantity}/>
                                                                                    <button
                                                                                        data-id={item.id}
                                                                                        data-price={item.id}
                                                                                        onClick={this.incrementQuantity.bind(this)}
                                                                                        className="value-button"
                                                                                        value="Increase Value">
                                                                                        <img src={PlusIcon} alt=""/>
                                                                                    </button>
                                                                                    <span className="span1">
                                                                                {/*AED {!item.discountedPrice && item.price}{item.discountedPrice && item.discountedPrice}*/}

                                                                                        AED {item.price}


                                                                                </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Collapse>

                                                                </>
                                                            )
                                                            }


                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                            }


                                            <div className="order-total new block border-bottom">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <p className="mb-0">Cart Total</p>
                                                        <p className="small-text mb-0">Weekend Pass Discount <span
                                                            style={{color: "#007dc4"}}>({Math.round((this.state.cartTotal - this.state.total) * 100 / this.state.cartTotal)}%)</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-5 text-right">
                                                        <h5>AED {this.state.cartTotal}</h5>
                                                        <h5>AED {Math.round(this.state.cartTotal - this.state.total)}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="order-total new ">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <p className="mb-0 total_pay">Total<span>(Inclusive of VAT)</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-5 text-right">
                                                        <h5 className="total_sum">AED {Math.round(this.state.total)}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="promo-block new">
                                                <button onClick={this.goToCheckout} className="buy-now">Book Now
                                                </button>
                                            </div>
                                        </div>
                                    </Accordion>

                                </div>
                            </div>
                            </div>
                        </div>
                    </div>


                </div>
                }

                <section className="news_letter_block d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <h5>Sign up for our newsletter</h5>
                                <p>Be the first one to receive handpicked weekends plans in your mailbox.</p>
                            </div>
                            <div className="col-md-3">
                                <form>
                                    <input type="text" className="form-control" placeholder="Your email address" />
                                    <input type="submit" defaultValue="submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="padtop0 d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="makepass">
                                    <h1>Make Your Own Weekend Pass</h1>
                                    <a href="#">Click Here to Create <i className="fa fa-plus" aria-hidden="true" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                    <Modal  show={this.state.showPopUp }

                        onHide={this.hidePopUp}
                        className={"weekend-pass-popup"}
                >


                    <button type="button"  onClick={this.hidePopUp} className="close" data-dismiss="modal">×</button>
                        {this.state.selectedActivity &&
                        <div className="room_list_item internal" style={{boxShadow: 'none', overflow: 'inherit'}}>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="room_img">
                                        <img src={baseImgUrl + this.state.selectedActivity.images[0].image} alt/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="room_list_l_text">
                                        <a href="#">
                                            <h4>{ this.state.selectedActivity.title}</h4>
                                        </a>
                                        {this.state.selectedActivity.address && this.state.selectedActivity.address.address && this.state.selectedActivity.address.city &&
                                        <ul>
                                            <li><a href="#"><img src={maps} alt/>{this.state.selectedActivity.address.address},{this.state.selectedActivity.address.city.name} </a></li>
                                        </ul>
                                        }
                                        <div className="rating_block">


                                            <Rating className="detail-head-r"
                                                    readOnly
                                                    value={this.state.selectedActivity.rating}
                                                    icon={<Star style={{color: "#29abe2"}} name="size-small"
                                                                fontSize={"small"}/>}
                                                    emptyIcon={<Star color="disabled" name="size-small"
                                                                     fontSize={"small"}/>}
                                                    name="size-large" size="large"
                                            />


                                            <span style={{color: '#00adee',marginLeft: "10px"}}>{this.state.selectedActivity.rating} ({this.state.selectedActivity.reviewsCount} ratings)</span>
                                            {/*<span>|  100+ Booked</span>*/}
                                        </div>
                                        <ul className="services_ul">
                                            <li><img src={icon1}/>Available days:&nbsp;
                                                {this.state.selectedActivity.day.map((item,index)=>
                                                <>
                                                    {index>0&&", "}{item.name}
                                                </>)}
                                                    </li>
                                            <li><img src={icon2}/>Duration: {this.state.selectedActivity.durationActivity}</li>
                                            <li><img src={icon3}/>Validity: {this.state.selectedActivity.validityPeriod} weekends</li>
                                        </ul>
                                    </div>
                                </div>
                                <div style={{paddingTop:"20px"}} className="col-md-12 ">
                                    <div className="info_block">
                                        <h5>Experience Details</h5>
                                        {/*<p>{this.state.selectedActivity.description}</p>*/}

                                        <p className={"bullet-text"}> <ReactHtml html={this.state.selectedActivity.description}/></p>

                                        {this.state.activityDetail && this.state.activityDetail.activityMiddleInfo&& this.state.activityDetail.activityMiddleInfo.thingsToKeepInMind &&
                                       <>


                                           {this.state.activityDetail&&this.state.activityDetail.included&&this.state.activityDetail.included.length > 0 && this.state.activityDetail.notIncluded.length > 0 &&

                                           <IncludedBox activityDetail={this.state.activityDetail}/>

                                           }


                                           <h5>Things to keep in mind </h5>
                                        <p className={"bullet-text"}><ReactHtml html={this.state.activityDetail.activityMiddleInfo.thingsToKeepInMind} /></p>
                                       </>
                                        }




                                        <h5 className={"d-none"}>Key Highlights</h5>
                                        <ul className="key_highilits d-none">
                                            <li><img src={CheckIcon} alt/>Option 1</li>
                                            <li><img src={CheckIcon} alt/>Option 2</li>
                                            <li><img src={CheckIcon} alt/>Option 3</li>
                                            <li><img src={CheckIcon} alt/>Option 4</li>
                                            <li><img src={CheckIcon} alt/>Option 5</li>
                                        </ul>
                                        <Link target="_blank" to={"/activity/"+this.state.selectedActivity.slug} className="read-full">Read Full Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </Modal>

            </div>


        )
    }
}




function IncludedBox(props) {


    const [height, setHeight] = useState(300)
    const ref = useRef(null)

    let element=     <div className="text-block" ref={ref}  >
        <h5>Whats Included/Excluded  </h5>
        <div className="row">
            <div className="col-lg-6">
                <ul style={{padding:"0px"}} className="include">

                    {props.activityDetail.included.map((item) =>
                        <li style={{padding: "0 0 15px 36px"}}>{item.included}</li>
                    )}
                </ul>
            </div>
            <div className="col-lg-6">
                <ul  style={{padding:"0px"}} className="exclude">

                    {props.activityDetail.notIncluded.map((item) =>
                        <li style={{padding: "0 0 15px 36px"}}>{item.notIncluded}</li>
                    )}
                </ul>
            </div>
        </div>
    </div>



    useEffect(() => {

        // if (ref.current.clientHeight>300) {
        //     setHeight(300)
        //
        // }else {
        //
        //     // setHeight(ref.current.clientHeight)
        //     console.log("heighgt")
        //
        //     console.log(ref.current.clientHeight)
        //
        // }
    })


    return (
        <>
            {element}
        </>
    )
}




const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        userDetail: state.userDetail,
        isLoggedIn:state.isLoggedIn,
        isGuest:state.isGuest,


    };
};

const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        setReviewBoxOpen: (item) => dispatch(actionCreator.setReviewBoxOpen(item)),
        reviewSuccess: (item) => dispatch(actionCreator.reviewSuccess(item)),
        addBundleCartItem : (item) => dispatch(actionCreator.addBundleCartItem(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(WeekendPassDetail);


