import React, { Component, Fragment, useState, useEffect,useRef } from 'react';
import { Spinner,Button, Col, Row, Carousel, CarouselItem,ButtonToolbar, InputGroup, ModalFooter,Modal,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from "react-bootstrap"
import  {Star} from '@material-ui/icons';
import Zoom from '@material-ui/core/Zoom';
import reviewtick from '../img/review-tick.png';

import facebook from '../img/f-round.png';
import insta from '../img/in-round.png';
import LinkDin from '../img/lin-round.png';
import Mail from '../img/mail-round.png';
import Moment from 'react-moment';
import twi from '../img/icon_twitter_circle.png';

import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import Rating from '@material-ui/lab/Rating';
import axios from "axios/index";
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import {initialState} from "../store/reducers/reducer";



class  CustomerReviewBox extends Component {

    description;
    importantInformation;
    constructor(props){

        super(props)

        this.state = {

            show : false,
            rating: "",
            reviews:[],
            successMessage : false

        };

        this.showReviewBox= this.showReviewBox.bind(this)
        this.handleReviewSubmit= this.handleReviewSubmit.bind(this)
        this.loadReviews= this.loadReviews.bind(this)
        this.whatsAppShare= this.whatsAppShare.bind(this)
        this.facebookShare= this.facebookShare.bind(this)
        this.twitterAppShare= this.twitterAppShare.bind(this)
        this.linkedInShare= this.linkedInShare.bind(this)
        this.instaShare = this.instaShare.bind(this)


    }



    instaShare(e){

        e.preventDefault()

        window.open("https://www.instagram.com/happyweekendsuae",'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    linkedInShare(e){

        e.preventDefault()

        window.open("https://www.linkedin.com/shareArticle?mini=true&url=www.happy-weekends.com/activity/"+this.props.activity.slug+"&title="+this.props.activity.title,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }


    facebookShare(e){

        e.preventDefault()


        // window.open("https://www.facebook.com/sharer/sharer.php?display=popup&u=https://www.happy-weekends.com/activity/"+this.item.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


        window.open("https://www.facebook.com/sharer/sharer.php?display=popup&u=https://www.happy-weekends.com/activity/"+this.props.activity.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')

    }

    twitterAppShare(e){


        e.preventDefault()

        // window.location.href = ("whatsapp://send?text=https://www.happy-weekends.com/activity/"+this.item.slug)



        window.open("https://twitter.com/intent/tweet?url=https://www.happy-weekends.com/activity/"+this.props.activity.slug+"&text="+this.props.activity.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    whatsAppShare(e){

        e.preventDefault()

        // window.location.href = ("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.item.slug)


        window.open("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.props.activity.slug+"&text="+this.props.activity.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    loadReviews(){

        var    url = baseUrl+"activity_reviews.json?groups[]=reviews&isPublished=1&activity="+this.props.activity.id;

        console.log(url)


        axios.get(url)
            .then(res => {



                console.log(res.data)

                this.setState({

                    reviews : res.data,
                })





            }).catch(error => {

            // dispatch(stopLoading())

            // dispatch(loginFailed())

            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });

    }

    handleReviewSubmit = event => {


        event.preventDefault()


        const form = event.currentTarget;
        console.log(form.checkValidity())
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                validatedForm : true
            });
        }
        else {

            var    url = baseUrl+"activity_reviews.json?groups[]=reviews";

            console.log(url)

            const formData = new FormData(event.target);
            const title = formData.get("title")
            const content = formData.get("content")
            const rating  = this.state.rating


            var data = {

                title: title,
                content: content,
                rating: rating,
                isPublished: false,
                customer : "/api/customers/"+this.props.userDetail.id,
                activity : "/api/activities/"+this.props.activity.id,


            }



            this.props.reviewSubmit(data)


            // axios.post(url, data)
            //     .then(res => {
            //
            //
            //
            //         console.log("review successfull")
            //
            //
            //
            //
            //     }, (error) => {
            //     console.log(error);
            //
            //     alert("error reviews")
            // });



        }




    }


    showReviewBox(){

        if (this.props.isLoggedIn) {

            // alert("hello")

            var currentStatus = this.props.reviewBoxOpen;
            this.props.setReviewBoxOpen(!currentStatus)


        }else {

            this.props.loginPopUp(true)

        }

    }



    changeRating(event,newValue){

        this.setState({
            rating : newValue

        })

    }


    componentDidMount(){

        this.loadReviews()

    }

    render() {




        return(

            <div id="reviews" className="review-section ">
                <div className="row">
                    {this.state.reviews.length > 0 && <div className="col-lg-12  ">
                        <h3>Customer Reviews</h3>
                        <div className="rating d-none">
                            <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"}/>
                            <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"}/>
                            <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"}/>
                            <Star color="disabled" name="size-small" value={2} fontSize={"small"}/>
                            <Star color="disabled" name="size-small" value={2} fontSize={"small"}/>
                            <p>4.0 (98 ratings)</p>
                        </div>
                        <div className="review-verify d-none">
                            <img src={reviewtick} alt=""/>
                            <div className="verifycontent d-none">
                                <h5>100% Verified Reviews </h5>
                                <p>All reviews are from people who have redeemed deals with this merchant.</p>
                            </div>
                        </div>


                        <div className="reviews ">


                            {this.state.reviews.map((item)=>
                                <div className="block">
                                    {/* <img className="user" src={inspireprofile} alt="" /> */}
                                    <i class="far fa-user user"></i>
                                    <div className="reviewcontent">
                                        <h5>{item.customer.firstName}</h5>
                                        <p className="small-text">   <Moment local fromNow>
                                            {item.publishedAt}
                                        </Moment>
                                        </p>
                                        <p className="ratings">
                                            <span className="rating-total">{item.rating}/5</span>


                                            <Rating
                                                readOnly
                                                value={item.rating}
                                                icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                                emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                                name="size-large"  size="large"
                                            />

                                        </p>
                                        <h5 className="mb-0">{item.title}</h5>
                                        <p>{item.content} </p>
                                    </div>
                                </div>
                            )}


                        </div>




                    </div>
                    }
                    <div className="col-lg-12 mt-3 d-none">
                        <a href="javascripit" className="see-reviews">See all reviews</a>
                    </div>

                    <div className="col-lg-12 mt-3">
                        <div className="text-block">
                            {(!this.state.reviews.length > 0) &&
                            <><h3>Customer Reviews</h3>
                                <p className="mb-2">There aren't any reviews for this activity yet. Be the first to write one!</p>
                            </>
                            }

                            <button onClick={this.showReviewBox} className="blue-btn">Submit A Review</button>

                            {this.props.reviewBoxOpen &&
                            <div className="review-box">
                                <div className="form-row">
                                    <div className="form-group col-lg-12 pl-4 mb-0">
                                        <label>Overall Rating</label>
                                        <Rating

                                            value={this.state.rating}
                                            onChange={this.changeRating.bind(this)}
                                            icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                            emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                            name="size-large"  size="large"
                                        />


                                    </div>
                                </div>


                                <Form noValidate validated={this.state.validatedForm}
                                      onSubmit={this.handleReviewSubmit.bind(this)} className="border-0">
                                    <Form.Group as={Col} md="5" controlId="validationCustom01">
                                        <Form.Label>Add a headine<em class="text-danger">*</em></Form.Label>
                                        <Form.Control
                                            name={"title"}
                                            required
                                            type="text"
                                            placeholder="Add a headine"
                                            defaultValue=""
                                        />
                                    </Form.Group>
                                    <Form.Group  as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Write your review<em class="text-danger">*</em></Form.Label>

                                        <Form.Control
                                            name={"content"}
                                            as={"textarea"} rows={4}
                                            type="text"
                                            required
                                            defaultValue=""
                                            placeholder="Write your review"

                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Button type={"submit"} className=" btn">

                                            { this.props.reviewLoading && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> }

                                            { this.props.reviewLoading ? "Wait.." : "Submit"}



                                        </Button>

                                    </Form.Group>
                                </Form>



                            </div>
                            }




                        </div>





                    </div>

                    {this.props.reviewSuccessMessage && <div className="col-lg-12 ">


                        <Alert key={"success"} variant={"info"}>
                            Your review has been successfully submitted for approval.
                        </Alert>

                    </div>
                    }
                    <div className="col-lg-12 wl_share">
                        <p className="float-left">Share on: </p>
                        <button style={{background : "transparent", border : "none"}} className="float-left" onClick={this.facebookShare.bind(this)}><img src={facebook} alt="fb-imge" /></button>

                        <button style={{background : "transparent", border : "none"}} className="float-left" onClick={this.instaShare.bind(this)}><img style={{width:"45px"}}  src={insta} alt="fb-imge" /></button>

                        <button style={{background : "transparent", border : "none"}} className="float-left" onClick={this.linkedInShare.bind(this)}><img src={LinkDin} alt="fb-imge" /></button>
                        <button style={{background : "transparent", border : "none"}} className="float-left" onClick={this.twitterAppShare.bind(this)}><img style={{width:"45px"}}  src={twi} alt="fb-imge" /></button>
                        <a  className="float-left d-none" href="mailto:support@happy-weekends.com"><img src={Mail} alt="fb-imge" /></a>
                    </div>
                </div>
            </div>

        );
    }

}


const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        userDetail: state.userDetail,
        isLoggedIn:state.isLoggedIn,
        isGuest:state.isGuest,
        reviewBoxOpen: state.reviewBoxOpen,
        reviewSuccessMessage: state.reviewSuccessMessage,
        reviewLoading : state.reviewLoading,


    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        reviewSubmit : (item) => dispatch(actionCreator.reviewSubmit(item)),
        setReviewBoxOpen : (item) => dispatch(actionCreator.setReviewBoxOpen(item)),
        loadingSpinner : (item) => dispatch(actionCreator.loadingSpinner(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CustomerReviewBox);

