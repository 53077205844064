import React, { Component } from 'react';
import facebook from '../img/f-round.png';
import insta from '../img/in-round.png';
import LinkDin from '../img/lin-round.png';
import Mail from '../img/mail-round.png';
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'

class  CareerDetail extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }
    render (){
        return(

            <div className="bg-gray">
                <section className="career-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 how-work-txt text-center">
                                <h2 className="text-center">Careers at Happy Weekends</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="career-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <h3>Manager-Visual Design</h3>
                                <p>
                                Location: Abu Dhabi<br/>
                                Type of job: Permanent<br/>
                                Date published: 11-Sep-2019<br/>
                                Domain: Customer Experience & Design<br/>
                                Job ID: 58916
                                </p>
                            </div>
                            <div className="col-lg-3 text-right"><Link className="backlistlink" to="/career">Back to job list</Link></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 wl_share">
                                <p className="float-left">Share on: </p>
                                <a className="float-left" href="https://www.facebook.com/Happy-Weekends-113793993335534/"><img src={facebook} alt="fb-imge" /></a>
                                <a className="float-left" href="https://www.instagram.com/happyweekendsuae/"><img src={insta} alt="fb-imge" /></a>
                                <a className="float-left" href="https://twitter.com/WeekendsHappy"><img src={LinkDin} alt="fb-imge" /></a>
                                <a className="float-left" href="mailto:info@happy-weekends.com"><img src={Mail} alt="fb-imge" /></a>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p> 
                                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p> 
                                
                            </div>
                            <div className="col-lg-12 mt-4">
                                <h4>What we are looking for in you?</h4>
                                <ul className="dot-ul">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Vestibulum sed erat scelerisque nulla faucibus ultrices.</li>
                                    <li>Phasellus dapibus est id ipsum pellentesque, quis pulvinar nisi pretium.</li>
                                    <li>Nam aliquam turpis eu sem euismod, quis pharetra tellus sagittis.</li>
                                    <li>Integer et nisi vitae nunc viverra semper at ut velit.</li>
                                    <li>Aenean nec diam non magna vehicula interdum</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <h4>Do you have the following?</h4>
                                <ul className="dot-ul">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Vestibulum sed erat scelerisque nulla faucibus ultrices.</li>
                                    <li>Phasellus dapibus est id ipsum pellentesque, quis pulvinar nisi pretium.</li>
                                    <li>Nam aliquam turpis eu sem euismod, quis pharetra tellus sagittis.</li>
                                    <li>Integer et nisi vitae nunc viverra semper at ut velit.</li>
                                    <li>Aenean nec diam non magna vehicula interdum</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <h4>Education</h4>
                                <p>Design Diploma/Degree from a Design College or equivalent experience</p>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <p className="font-18">PORTFOLIO is a must. PDF or ONLINE LINK. Please share your latest work.   PDF/PPT works in case you cannot publish your work online due to company policy.</p>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <Link to="/career-form/visual" className="red-btn mr-3">Apply Now</Link>
                                {/*<a href="#" className="blue-btn mr-3">Apply with LinkedIn</a>*/}
                                {/*<p className="pt-2">Your full LinkedIn profile will be shared. Learn More</p>*/}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="job-listing">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h2>Open roles</h2>
                            </div>
                            <div className="col-lg-8">
                                <p>At HappyWeekends, the world’s most talented engineers, designers, and thought leaders are shaping the future of weekend experiences.
We’re looking for people to join the team who are as excited as we are to help build the platform that empowers the future generation of creators to be successful online.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="items">
                                    <div className="job-title">Digital Marketing Executive</div>
                                    <div className="job-type">Full time</div>
                                    <div className="job-place">Abu Dhabi</div>
                                    <div className="job-title">
                                        <Link className={"view-job"} to={"/career-detail/visual"}>View Job</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default CareerDetail;