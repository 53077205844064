import React, { Component } from 'react';
import './Listing.css';
import Header from '../Header/index';
import Footer from '../Footer/index';
import smallSlide from '../img/image-4.png';
import what from '../img/what.png';
import twi from '../img/twi.png';
import fb from '../img/fb.png';
import axios from "axios/index";
import ActivityItem from "./ActivityItem";
import { Button, Col, Row} from 'react-bootstrap'
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import {connect} from "react-redux";
import * as actionCreator from "../store/actions/actions";

import {baseUrl,baseImgUrl} from  '../Util/Constants'



class  HomeSectionListing extends Component{

     page;
     slug;

    constructor(props){


        super(props)
        this.state = {

            homeSections : {},
            activityItems:[],
            featuredActivityItems:[],



        };

        this.slug = props.match.params.id
        this.page = 1


    }

    componentDidMount (){



        if (this.slug!="trending") {
            axios.get(baseUrl+"home_sections/" + this.slug + ".json?groups[]=activities")
                .then(res => {
                    this.setState({

                        homeSections: res.data,
                        activityItems: res.data.activities,
                        featuredActivityItems: res.data.featuredActivities,

                    });

                    console.log(res.data.activities)

                })

        }else {




            var  url =  baseUrl+"activities.json?groups[]=activities";




            if (this.props.cityId) {

                url = url + "&order[lifeTimeSales]=desc&order[views]=desc&address.city[]="+this.props.cityId;

            }

            else {
                url = url + "&order[lifeTimeSales]=desc&order[views]=desc&address.city[]=11&&address.city[]=12";

            }


            console.log("tending ulr " + url)


            axios.get(url)
                .then(res => {


                    console.log("tending items load")

                    this.setState({

                        activityItems : res.data

                    });






                })





        }

    }


    render (){
        return(
              <div>
                <section className="container-fluid search-sec-img" style={{
                   // backgroundImage: "url(http://neukron.com/admin/public/" + this.state.catItem.imageHeader + ")",
                   // backgroundPosition: 'center',
                   backgroundSize: 'cover',
                   backgroundRepeat: 'no-repeat',
                    backgroundColor: "#007dc4"

                }}>
                   <div className="container">
                       <div className="row text-center d-block">


                           <h2 className="banner-font">{this.slug=="trending"?"Trending" :this.state.homeSections.name}</h2>


                       </div>
                   </div>
               </section>

               <section className="listing-section pb-5">
                   <div className="container">
                       <div className="row">

                           {
                               this.state.featuredActivityItems.map((activityitem) =>

                                   <ActivityItem key={activityitem.id} item={activityitem}></ActivityItem>
                               )
                           }

                           {
                               this.state.activityItems.map((activityitem) =>

                                   <ActivityItem key={activityitem.id} item={activityitem}></ActivityItem>
                               )
                           }


                       </div>

                   </div>


               </section>

           </div>
         )
    }
}

const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        userDetail: state.userDetail,
        isLoggedIn:state.isLoggedIn,
        location : state.location,
        city : state.city,
        cityId : state.cityId,
        trendingItems : state.trendingItems,
        slides : state.slides,




    };
};

const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        setLocation: (item) => dispatch(actionCreator.setLocation(item)),
        setCity: (item) => dispatch(actionCreator.setCity(item)),
        slidesLoad : (item) => dispatch(actionCreator.slidesLoad(item)),
        trendingLoad : (item) => dispatch(actionCreator.trendingLoad(item)),




    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(HomeSectionListing);

