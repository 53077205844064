import React, { Component } from 'react';
//import BollyoodImage1 from '../img/corporate-4.jpg';
//import Star from '../img/star.png';

class  AdminPartnerTab extends Component{
    render (){
        return(
            <div>
                <section className="Admintab darkblue-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Account Sg</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">My Favorite</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Booked Activities</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-review-tab" data-toggle="pill" href="#pills-review" role="tab" aria-controls="pills-contact" aria-selected="false">Reviews/Ratings</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="shdaow-bg tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div className="tab-backgroubnds">
                                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" id="pills-personal-tab" data-toggle="pill" href="#pills-personal" role="tab" aria-controls="pills-home" aria-selected="true">Personal Information</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="pills-business-info" data-toggle="pill" href="#pills-business-info" role="tab" aria-controls="pills-business-info" aria-selected="false">Bbusiness Info</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="pills-change-tab" data-toggle="pill" href="#pills-change" role="tab" aria-controls="pills-change" aria-selected="false">Change Password</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <h4>First Contact Person:</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">First name</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="First2" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Last name</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="Last2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Designation</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="abc@gmail.com" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Contact Number(landline)</label>
                                                                    <div className="input-group phone_no">
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">+971</div>
                                                                        </div>
                                                                        <input type="text" id="" name="" required="required" class=" field field_full_width" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Email</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="abc@gmail.com" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Phone Number</label>
                                                                    <div className="input-group phone_no">
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">+971</div>
                                                                        </div>
                                                                        <input type="text" id="" name="" required="required" class=" field field_full_width" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                    <form className="form-block mt-4">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <h4>Second Contact Person:</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">First name</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="First2" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Last name</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="Last2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Designation</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="abc@gmail.com" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Contact Number(landline)</label>
                                                                    <div className="input-group phone_no">
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">+971</div>
                                                                        </div>
                                                                        <input type="text" id="" name="" required="required" class=" field field_full_width" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Email</label>
                                                                    <input type="text" id="customer_firstName" name="customer[firstName]" required="required" maxlength="180" className="" value="abc@gmail.com" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="customer_firstName" className="required">Phone Number</label>
                                                                    <div className="input-group phone_no">
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">+971</div>
                                                                        </div>
                                                                        <input type="text" id="" name="" required="required" class=" field field_full_width" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <button class="btn btn-red">Save</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="tab-pane fade" id="pills-business-info" role="tabpanel" aria-labelledby="pills-business-info-tab">
                                                    <form>

                                                     </form>
                                                </div>
                                                <div class="tab-pane fade" id="pills-change" role="tabpanel" aria-labelledby="pills-change-tab">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="" className="required">Old Password</label>
                                                                    <input type="text" id="" name="" required="required" maxlength="180" className="" value="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="" className="required">New Password</label>
                                                                    <input type="text" id="" name="" required="required" maxlength="180" className="" value="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label for="" className="required">Confirm Password</label>
                                                                    <input type="text" id="" name="" required="required" maxlength="180" className="" value="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <button class="btn btn-red">Save</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="shdaow-bg tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    </div>
                                    <div className="shdaow-bg tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    </div>
                                    <div className="shdaow-bg tab-pane fade" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default AdminPartnerTab;