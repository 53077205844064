import React, { Component } from 'react';
import AboutMaking from '../img/about-page-making.jpg';
import GoodBye from '../img/goodbye-stress.jpg';
import MM from '../img/mm.jpg';
import Testi1 from '../img/testi-1.png';
import Testi2 from '../img/testi-2.png';
import Carousel from 'react-bootstrap/Carousel';
import { Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags';


class  AboutUs extends Component{


    componentDidMount () {
        window.scrollTo(0, 0)

    }

    render (){
        return(
            <div className="bg-gray">

                <MetaTags>

                    <title>Make Your Weekends Happier | Happy Weekends </title>
                    <meta name="title" content="Make Your Weekends Happier | Happy Weekends " />
                    <meta name="description" content="We are an online market place that is on a mission to make your weekends as amazing as possible. We do this by curating many exciting mini-experiences with our partners that you can be a part of, no matter what kind of a person you are, a home lounger or someone who loves outdoors. " />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content="Make Your Weekends Happier | Happy Weekends " />
                    <meta property="og:description" content="We are an online market place that is on a mission to make your weekends as amazing as possible. We do this by curating many exciting mini-experiences with our partners that you can be a part of, no matter what kind of a person you are, a home lounger or someone who loves outdoors. " />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content="Make Your Weekends Happier | Happy Weekends " />
                    <meta property="twitter:description" content="We are an online market place that is on a mission to make your weekends as amazing as possible. We do this by curating many exciting mini-experiences with our partners that you can be a part of, no matter what kind of a person you are, a home lounger or someone who loves outdoors. " />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>

                <section className="medium-banner about-banner">
                    <div className="container">
                        <div className="row">
                            <h2 className="text-center text-white w-100">About Us</h2>
                        </div>
                    </div>
                </section>
                <section className="blog-section blog-detail-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                            <div className="experience-the-heart">
                                <img className="img-fluid" src={AboutMaking} alt="about-page-making" />
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="making-your">
                                <h5 > MAKING YOUR WEEKENDS HAPPIER!</h5>
                            </div>
                            <div className="experience-the-heart-text">
                                <p>Happy Weekends is a happy online marketplace that’s obsessed with making your weekends as great as they possibly can be. We do that by curating exciting mini-experiences with our partners, that you can be a part of, no matter if you’re an outdoorsy person or a home lounger. The way we see it, there are fifty two weekends in a year that we have to ourselves. Better start making them count!</p>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    <section className="center-banner">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 text-center">
                                    <h4 className="text-white mb-0">Happy Weekends exists to empower people around the world to unwind on the weekend in their own unique way and in doing so, help them live a more balanced, meaningful and happy life. </h4>
                                </div>
                            </div>
                        </div>    
                    </section>
                </section>
                
                <section className="weekend-made-easy my-5">
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-md-7">
                                <div className="weekend-made-right-text">
                                <h4>WEEKENDS MADE EXTRAORDINARY </h4>
                                <p>The thing with weekends is that, you’re often left wondering what to do with them. And then before you know it, they’ve gone, and you’re back to another grueling week at work. Happy Weekends makes weekends fun and easy. With a bouquet of beautifully curated experiences, ranging from meeting celebrities, learning how to make cocktails, adventure sports on the beach, learning how to fish, exploring the Emirati hospitality – there’s literally something for everyone. And the best part is, choosing something you’d like to do is now ridiculously easy.</p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="weekend-made-right-img text-right">
                                <img className="img-fluid" src={GoodBye} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 mobile-reverse">
                                <div className="col-md-5">
                                    <div className="weekend-made-right-text">
                                        <div className="weekend-made-right-img text-left">
                                            <img className="img-fluid" src={MM} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="weekend-made-right-text side-col">
                                        <h4>HOW WE ARE UNIQUE</h4>
                                        <p>Happy Weekends revolves around you. What you like, what you would like to see, where you would like to go. Which is why, apart from handpicking experiences around the city for you, we also curate new and exciting experiences that you can take advantage of, every weekend.</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>
                <section className="testimonial testimonialabout">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-center m-auto">
                        <div className="row">
                            <div className="col-lg-8 col-center m-auto">
                            <h2 className="hometesti" style={{textAlign: 'center', color: '#007dc4', fontSize: '24px', fontWeight: '400 !important'}}>Happy UAE</h2>
                            <Carousel className="abotslider">
                                <Carousel.Item>
                                    <p className="testimonial">"A happy employee is more productive, more energetic and more creative- hence the importance of creating a happy environment within every institution."</p>
                                    <div className="user-test-detial">
                                    <div className="img-box"><img  src={Testi1} alt="" /></div>
                                    <p className="overview"><b>Mohammed bin Rashid Al Maktoum</b>
                                        <span>Vice President of the UAE</span>
                                    </p>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <p className="testimonial">"Studies found that employees who are happy are 80 per cent focused on their work, while unhappy employees are only 40 per cent focused on their work. This means that companies with unhappy employees lose 100 days of work a year."</p>
                                    <div className="user-test-detial">
                                    <div className="img-box"><img  src={Testi2} alt="" /></div>
                                    <p className="overview"><b>Ohood bint Khalfan Al Roumi</b>
                                        <span>Minister of State for Happiness &amp; Wellbeing</span>
                                    </p>
                                    </div>
                                </Carousel.Item>
                                </Carousel>
                            </div>
                           
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default AboutUs;