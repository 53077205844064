import React, { Component,Fragment } from 'react';
import './Listing.css';
import Header from '../Header/index';
import Footer from '../Footer/index';
import smallSlide from '../img/image-4.png';
import what from '../img/what.png';
import twi from '../img/twi.png';
import fb from '../img/fb.png';
import axios from "axios/index";
import ActivityItem from "./ActivityItem";
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'

import queryString from 'query-string'
import FilterResultBox from "./FilterResultBox";
import Slider from "@material-ui/core/Slider/index";
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles/index";


import { Button, Col, Row,InputGroup} from 'react-bootstrap'

import Typography from '@material-ui/core/Typography';
import  {Star} from '@material-ui/icons'

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import TrendingSectionItem from "../Home/TrendingSectionItem";



class  Search extends Component{

    page=1
    constructor(props){

        super(props)
        this.state = {

            catItem : {},
            activityItems:[],
            catItems : [],
            days : [],
            cities : [],
            timings: [],
            sortBy: [ {"id":"1","name":"New"},{"id":"2","name":"Trending"},{"id":"3","name":"Popular"}],
            activityPreference : [ ],
            locationPreference : [ {"id":"1","name":"Stay At Home"},{"id":"2","name":"Out Of Home"}],
            rating: [],
            slug : this.props.match.params.id,
            page : 1,
            backgroundimage : {},
            displayLoadMore : false,
            activeFilters: {},
            displayNoResults: false,
            activeKeyWordSearch : "title"


        };

        this.fetchFilters = this.fetchFilters.bind(this);
        this.loadMoreClick = this.loadMoreClick.bind(this);
        this.getActiveFiltersQuery = this.getActiveFiltersQuery.bind(this);
        this.fetchFilteredActivities = this.fetchFilteredActivities.bind(this);
        this.togglefilter = this.togglefilter.bind(this);


        console.log(this.props)


    }


    togglefilter(){


        this.setState({
            displayFilterMenu : !this.state.displayFilterMenu
        })


        this.getActiveFiltersQuery()
    }


    setFilters(filter){




        var filtersObj = this.state.activeFilters
        filtersObj[filter.name]=filter.value

        this.setState({

            activeFilters: filtersObj

        })

        console.log(this.state.activeFilters)

        this.getActiveFiltersQuery()


    }


    componentDidMount (){

        this.fetchFilters()


        this.getActiveFiltersQuery()


    }


    fetchFilters(){


        const values = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})

        this.setState({
            activeFilters :values

        })

        console.log("Search params")

        console.log(values)



        axios.get(baseUrl+"categories.json?groups[]=list&level=1")
            .then(res => {

                var items= res.data.filter((item)=> item.name!="Weekend Pass")


                this.setState({

                    catItems: items,

                });
            })

        axios.get(baseUrl+"activity_preferences.json?groups[]=detail")
            .then(res => {
                this.setState({

                    activityPreference: res.data,

                });


            })

        axios.get(baseUrl+"days.json?groups[]=activity-detail")
            .then(res => {
                this.setState({

                    days: res.data,

                });


            })


        axios.get(baseUrl+"cities.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    cities: res.data,

                });


            })


        axios.get(baseUrl+"timings.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    timings: res.data,

                });


            })



    }





    getActiveFiltersQuery(){

        const urlParams = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'})

        const values =  this.state.activeFilters

        console.log("Search params")
        console.log(urlParams)

        var makeQueryFlag=false

        var  url =  baseUrl+"activities.json?groups[]=activities&isActive=1&page="+this.page;


        if (urlParams.keyword) {

            url = url +"&"+this.state.activeKeyWordSearch+"="+urlParams.keyword;
            makeQueryFlag= true
        }




        if (urlParams.category && urlParams.category.length>0){

            makeQueryFlag=true

            for (var i =0;i<urlParams.category.length;i++) {

                url = url +"&category[]="+urlParams.category[i];

            }

        }
        if (urlParams.city && urlParams.city.length>0){

            makeQueryFlag=true

            for (var i =0;i<urlParams.city.length;i++) {

                url = url +"&address.city[]="+urlParams.city[i];

            }

        }



        if (urlParams.days && urlParams.days.length>0){

            makeQueryFlag=true


            for (var i =0;i<urlParams.days.length;i++) {

                url = url +"&day[]="+urlParams.days[i];

            }

        }


        if (values.category && values.category.length>0){

            makeQueryFlag=true

            for (var i =0;i<values.category.length;i++) {

                url = url +"&category[]="+values.category[i];

            }

        }
        if (values.cities && values.cities.length>0){

            makeQueryFlag=true

            for (var i =0;i<values.cities.length;i++) {

                url = url +"&address.city[]="+values.cities[i];

            }

        }


        if (values.timings && values.timings.length>0){

            makeQueryFlag=true


            for (var i =0;i<values.timings.length;i++) {

                url = url +"&timings[]="+values.timings[i];

            }

        }


        if (values.days && values.days.length>0){

            makeQueryFlag=true


            for (var i =0;i<values.days.length;i++) {

                url = url +"&day[]="+values.days[i];

            }

        }

        if (values.activityPreference){

            makeQueryFlag=true

            url = url +"&activityPreference="+values.activityPreference;


        }



        if (values.ratings){

            url = url +"&rating="+values.ratings;
            makeQueryFlag=true

        }


        if (values.price){

            url=url+"&priceOption.price[between]="+values.price[0]+".."+values.price[1];
            makeQueryFlag=true

        }


        if (values.sort){


            makeQueryFlag=true

            if (values.sort==1)
                url = url +"&order[id]=desc";


            if (values.sort==2)
                url = url +"&order[lifeTimeSales]=desc&order[views]=desc";


            if (values.sort==3)
                url = url +"&order[lifeTimeSales]=desc&order[views]=desc";



        }



        console.log("final Query url")
        console.log(url)

            this.fetchFilteredActivities(url)

    }

    fetchFilteredActivities(url){


        this.props.loading()

        // this.setState({
        //
        //     activityItems : [],
        //
        // });

        console.log(url)
        axios.get(url)
            .then(res => {


                var activities = res.data.filter((item)=> item.images.length>0)


                this.props.stopLoading()


                if (activities.length==0&&this.state.activityItems.length==0) {



                    // check for search by decription
                    if (queryString.parse(this.props.location.search, {arrayFormat: 'bracket'}).keyword&&this.state.activeKeyWordSearch=="title") {

                        this.setState({

                            activeKeyWordSearch: "description"
                        })

                        this.getActiveFiltersQuery()


                    }else {

                        this.setState({

                            displayNoResults: true

                        })


                    }

                }else{

                    this.setState({
                        displayNoResults : false

                    })

                }


                if (activities.length==6){

                    this.setState({
                        displayLoadMore : true

                    })

                }else{



                    // check for search by decription
                    if (queryString.parse(this.props.location.search, {arrayFormat: 'bracket'}).keyword&&this.state.activeKeyWordSearch=="title") {

                        this.setState({

                            activeKeyWordSearch: "description"
                        })

                        this.getActiveFiltersQuery()


                    }else {

                        this.setState({
                            displayLoadMore: false

                        })

                    }




                }



                var items = [];

                for (var i=0;i<res.data.length;i++) {

                    var found = false
                for (var k=0;k<this.state.activityItems.length;k++){



                        if (this.state.activityItems[k].id==res.data[i].id){

                            found= true
                        }

                    }


                    if (!found){

                        items.push(res.data[i])
                    }


                }


                this.setState({

                    activityItems : this.state.activityItems.concat(items),

                });


                console.log(res.data)


            })

    }


    loadMoreClick(){


        console.log("Load More  ++")

        this.page=this.page+1

        // this.setState({
        //
        //     page : this.state.page + 1
        // })


        this.getActiveFiltersQuery()


    }

    goToDetailPage(){

    }

    render (){


        return(
           <div>
               <section className="container-fluid search-sec-img" style={{
                   // backgroundPosition: 'center',
                   backgroundSize: 'cover',
                   backgroundRepeat: 'no-repeat',
                   backgroundColor: "#007dc4"
               }}>
                   <div className="container">
                       <div className="row text-center d-block">
                           <h5  style={{fontSize:"25px"}} className="banner-font">Choose The Experience/s You Like And Plan Your Weekend </h5>
                       </div>
                   </div>
               </section>

               <section className="filters-bg">
                   <div className="container">




                       <div className="row">
                           <div className="col-lg-12">
                               <div className="fillter-sec">
                                   {this.state.catItem && this.state.catItems && this.state.cities
                                   && this.state.days && this.state.rating && this.state.timings
                                   &&

                                   <Fragment>

                                       <div className="fliter-mobile">
                                           <button className="filter-mobile-btn" onClick={this.togglefilter}><i
                                               className="fas fa-filter"></i> Filter
                                           </button>

                                           {this.state.displayFilterMenu &&

                                           <div className="filter-mobile-open">
                                               <ul>
                                                   <li className="mobilefilter-title" onClick={this.togglefilter}><i
                                                       className="fas fa-filter"></i> Filter
                                                   </li>


                                                   {(this.state.catItems && this.state.catItems.length > 0) ?
                                                       <li><MultipleSelect width={"130px"} setFilters={(filter) => this.setFilters(filter)}
                                                                           type={"category"} className="all-category" key={"category"}
                                                                           title={"All Categories"}
                                                                           items={this.state.catItems}/>
                                                       </li> : <li className={"d-none"}></li>
                                                   }


                                                   <li className="active"><MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"cities"} key={"cities"}
                                                                                          title={"By The City"}
                                                                                          items={this.state.cities}/>
                                                   </li>
                                                   <li><MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"days"} key={"days"} title={"By The Day"}
                                                                       items={this.state.days}/></li>
                                                   <li><MultipleSelect width={"115px"} setFilters={(filter) => this.setFilters(filter)}  type={"timings"} key={"timings"} title={"By The Time"}
                                                                       items={this.state.timings}/></li>
                                                   <li><CheckboxSingleSelect width={"165px"} setFilters={(filter) => this.setFilters(filter)}  type={"activityPreference"} key={"locations"} title={"Location Preference"}
                                                                     items={this.state.activityPreference}/></li>
                                                   <li><StarSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"ratings"} key={"rating"} title={"Ratings"}
                                                                   items={this.state.ratings}/></li>
                                                   <li><PriceRangePopover setFilters={(filter) => this.setFilters(filter)}  type={"price"}/></li>
                                                   <li><CheckboxSingleSelect width={"100px"} setFilters={(filter) => this.setFilters(filter)}  type={"sort"} key={"sortBy"} title={"Sort By"}
                                                                     items={this.state.sortBy}/></li>
                                                   <li><Button className="blue-btn" onClick={this.togglefilter}>Apply
                                                       Now</Button></li>
                                               </ul>
                                           </div>
                                           }

                                       </div>

                                       <div className="filter-web">

                                           {(this.state.catItems && this.state.catItems.length>  0) &&
                                           <MultipleSelect width={"130px"} setFilters={(filter) => this.setFilters(filter)}  type={"category"} className="active"  key={"category"} title={"All Categories"}
                                                           items={this.state.catItems}/>
                                           }

                                           <MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"cities"} className="active" key={"cities"} title={"By The City"}
                                                            items={this.state.cities}/>
                                           <MultipleSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"days"}  key={"days"} title={"By The Day"} items={this.state.days}/>
                                           <MultipleSelect width={"115px"} setFilters={(filter) => this.setFilters(filter)}  type={"timings"}  key={"timings"} title={"By The Time"}
                                                           items={this.state.timings}/>
                                           <CheckboxSingleSelect width={"165px"} setFilters={(filter) => this.setFilters(filter)}  type={"activityPreference"}  key={"locations"} title={"Location Preference"}
                                                         items={this.state.activityPreference}/>
                                           <CheckboxSingleSelect width={"100px"} setFilters={(filter) => this.setFilters(filter)}  type={"sort"} key={"sortBy"} title={"Sort By"} items={this.state.sortBy}/>
                                           <StarSelect width={"110px"} setFilters={(filter) => this.setFilters(filter)}  type={"ratings"} className="rating-block" key={"ratings"} title={"Ratings"}
                                                        items={this.state.ratings}/>
                                           <PriceRangePopover setFilters={(filter) => this.setFilters(filter)}  type={"price"} />
                                           <Button onClick={this.getActiveFiltersQuery} className="blue-btn d-none">Filter</Button>

                                       </div>
                                   </Fragment>



                                   }
                               </div>
                           </div>
                       </div>
                   </div>
               </section>


               <section className="listing-section pb-5">
                    <div className="container">

                        {this.state.displayNoResults &&   <div className="row justify-content-md-center keep-revisitng">


                            <Col xs lg="8" className={"m-5 block-title text-center"} >

                                <h4  className={"m-5"} >Oops! No results found. Try different category</h4>

                            </Col>



                        </div>}

                        <div className="row">

                            {
                            this.state.activityItems.map((activityitem)=>
                                <>

                            {activityitem.images && (activityitem.images.length >0) &&

                                <TrendingSectionItem item={activityitem}/>

                            }

                            </>
                            )
                            }


                                </div>

                        {this.state.displayLoadMore && <Row className="justify-content-md-center m-5">

                            <Col xs lg="2">
                                <Button onClick={this.loadMoreClick}
                                        size="lg" variant="outline-primary" block>
                                    Load More

                                </Button>
                            </Col>

                        </Row>
                        }



                        {!this.state.displayLoadMore && this.state.activityItems.length>0 && this.state.activityItems.length<10 &&
                        <Row className="row justify-content-md-center keep-revisitng">




                            <Col xs lg="6" className={"m-3 block-title text-center"} >
                                <h4  className={"m-5"} >Keep revisiting as more experiences coming every week...


                                </h4>
                            </Col>


                        </Row>
                        }


                        </div>



                </section>
           </div>
         )
    }
}


function SimpleSelect(props) {


    const classes = useStyles();
    const [item, setItem] = React.useState('');
    const [active, setActive] = React.useState(false);


    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {

        setItem(event.target.value);
        setActive(true);


        if (props.type==="activityPreference") {

            props.setFilters({"name":props.type,"value":event.target.value})

        }

        if (props.type==="sort") {


            props.setFilters({"name":props.type,"value":event.target.value})


        }




    };

    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
            >

                {props.items.map((item)=>
                    <MenuItem key={"key-"+item.id} value={item.id}>{item.name}</MenuItem>

                )}

            </Select>
        </FormControl>

    );
}

function StarSelect(props) {

    const [active, setActive] = React.useState(false);
    const classes = useStyles();
    const [item, setItem] = React.useState('');

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {
        setItem(event.target.value);
        setActive(true);


        if (props.type==="ratings") {

            props.setFilters({"name":props.type,"value":event.target.value})


        }


    };

    return (


        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}

            >
                <MenuItem   value={"Ratings"}>
                    All
                </MenuItem>

                <MenuItem value={5}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/></MenuItem>
                <MenuItem value={4}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star color="disabled"/></MenuItem>
                <MenuItem value={3}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled"/></MenuItem>
                <MenuItem value={2}><Star style={{color: "#29abe2"}} /><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled"/><Star color="disabled"/></MenuItem>

                <MenuItem value={1}><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled" /><Star color="disabled"/><Star color="disabled"/></MenuItem>

            </Select>
        </FormControl>

    );
}

function CheckboxSingleSelect(props) {


    const classes = useStyles();
    const [item, setItem] = React.useState('');
    const [active, setActive] = React.useState(false);
    const [checked, setChecked] = React.useState(true);


    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    const [categoryName, setCategoryName] = React.useState([]);


    const handleChange = event => {

        console.log("checkox single select ")
        console.log(event.target.value)
        console.log(event)

        setCategoryName(event.target.value);

        setItem(event.target.value);
        setActive(true);



        if(event.target.value==="All"||event.target.value===props.title) {

            setChecked(true);

        }else{

            setChecked(false);

        }


        var selectedOption = props.items.filter((item)=> item.name==event.target.value)[0]



        if (props.type==="activityPreference") {

            if (selectedOption)
                props.setFilters({"name":props.type,"value":selectedOption.id})
            else
                props.setFilters({"name":props.type,"value":null})


        }

        if (props.type==="sort") {

            if (selectedOption)

                props.setFilters({"name":props.type,"value":selectedOption.id})
            else
                props.setFilters({"name":props.type,"value":null})


        }




    };

    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                renderValue={ selected => selected }
            >

                <MenuItem   value={props.title}>
                    <Checkbox checked={checked} />
                    <ListItemText primary={"All"} />
                </MenuItem>


                {props.items.map((item)=>


                    <MenuItem key={"key-"+item.id} data-id={item.name}  value={item.name}>
                        <Checkbox checked={categoryName===(item.name)} />
                        <ListItemText primary={item.name} />
                    </MenuItem>

                )}
            </Select>
        </FormControl>

    );
}








function PriceRangePopover(props) {


    const [show, setShow] = React.useState(false);

    const [value, setValue] = React.useState([0, 1000]);
    const [active, setActive] = React.useState(false);


    const useMyStyles = makeStyles(theme => ({
        typography: {
            padding: theme.spacing(2),
        },
    }));
    const classes = useMyStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);

    };

    const handleChange = (event,newValue) => {

        setShow(true)
        setValue(newValue)
        console.log( newValue)
        setActive(true);

        props.setFilters({"name":props.type,"value":newValue})



    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={active?" active "+" price-range":"price-range"} >
            <Button
                className={active?" active "+classes.formControl:classes.formControl}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
            >{show ? "AED "+value[0]+"-"+"AED "+value[1]:"Price Range"} <ArrowDropDownIcon/>
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>

                    <AirbnbSlider
                        onChange={handleChange}
                        ThumbComponent={AirbnbThumbComponent}
                        getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
                        defaultValue={

                            [value[0], value[1]]
                        }
                        min={0}
                        max={1000}
                    />

                    <Row>

                        <Col className="price-text">
                            <InputGroup className="mb-3">
                                <InputGroup.Append>
                                    <InputGroup.Text style={{backgroundColor:"transparent", border:"none"}}>AED</InputGroup.Text>
                                    <InputGroup.Text style={{backgroundColor:"transparent"}}>{value[0]}</InputGroup.Text>
                                </InputGroup.Append>

                                <InputGroup.Append>
                                    <InputGroup.Text style={{backgroundColor:"transparent", border:"none"}}>To</InputGroup.Text>

                                    <InputGroup.Text style={{backgroundColor:"transparent"}} >{value[1]}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                </Typography>



            </Popover>
        </div>
    );
}

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
        {/*<span className="bar" />*/}
            {/*<span className="bar" />*/}
            {/*<span className="bar" />*/}
      </span>
    );
}


const AirbnbSlider = withStyles({
    root: {
        color: '#00adee',
        height: 3,
        padding: '13px 0',
        width: 220 + 5 * 2,

    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid #eeeeee',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0px 2px 2px',
        '&:focus,&:hover,&$active': {
            boxShadow: '#ccc 0px 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: '#00adee',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },

})(Slider);

function MultipleSelect(props) {


    const [active, setActive] = React.useState(false);


    const classes = useStyles();

    const [checked, setChecked] = React.useState(true);

    const theme = useTheme();
    const [categoryName, setCategoryName] = React.useState([]);

    const handleChange = event => {




        var values=[]
        for(var i=0; i<props.items.length;i++){

            if (event.target.value.indexOf(props.items[i].name)>-1){

                values.push(props.items[i].id)
            }


        }
        if (props.type==="category") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="cities") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="days") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="timings") {

            props.setFilters({"name":props.type,"value":values})


        }

        else if (props.type==="activityPreference") {

            props.setFilters({"name":props.type,"value":values})


        }

        console.log("checked valued")
        console.log(event.target.value)


        if(!(categoryName.indexOf(props.title)!==-1)&&event.target.value.length >0 && event.target.value.indexOf(props.title)!==-1) {

            setCategoryName([props.title])

            setChecked(true);

            props.setFilters({"name":props.type,"value":[]})
            setActive(false);


            // alert("all")
        }else{

            // alert("other")

            var removedTitleArray=event.target.value.filter(x=> x!== props.title)

            setCategoryName(removedTitleArray);

            setActive(true);

            setChecked(false);


        }


        console.log("cat name ")

        console.log(categoryName)
        console.log("value ")

        console.log(event.target.value)



    };


    return (

        <FormControl style={{"min-width": props.width }} className={active?" active "+classes.formControl:classes.formControl}>

            <InputLabel id="demo-mutiple-checkbox-label">{props.title}</InputLabel>

            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={categoryName}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}

            >

                <MenuItem   value={props.title}>
                    <Checkbox checked={checked} />
                    <ListItemText primary={"All"} />
                </MenuItem>

                {
                    props.items.map(item => (
                        <MenuItem key={item.id} data-id={item.id}  value={item.name}>
                            <Checkbox checked={categoryName.indexOf(item.name) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))
                }

            </Select>
        </FormControl>



    );
}



const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
        maxWidth: 20,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const mapStateToProps = state => {
    return {
        // cartItems: state.cartItems,
        // loading: state.loading,
        // userDetail: state.userDetail,
        // salesQuote: state.salesQuote,
        // cartTotal: state.cartTotal,
        // couponDiscountVisible : state.couponDiscountVisible,
        // total: state.total,
        // subTotal: state.subTotal,
        // cartDiscount: state.cartDiscount,
        // couponDiscount: state.couponDiscount,
        // couponCode: state.couponCode,
        // couponDiscountPercentage: state.couponDiscountPercentage,
        // couponCheckloading: state.couponCheckloading,
        // couponError : state.couponError,
        // addressInput : state.addressInput,
        // isGuest : state.isGuest,
        // isLoggedIn : state.isLoggedIn



    };
};

const mapDispachToProps = dispatch => {
    return {

        // onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        // onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        // addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        // removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item)),
        // addCoupon : (item)=> dispatch(actionCreator.addCoupon(item)),
        // setBookingResponse  : (item)=> dispatch(actionCreator.setBookingResponse(item)),
        // loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loading : (item) => dispatch(actionCreator.loading(item)),
        stopLoading : (item) => dispatch(actionCreator.stopLoading(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(Search);

