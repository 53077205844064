import React, { Component } from 'react';
import './GetApp.css';
import Android from '../img/google-play.png';
import IOS from '../img/ios.png';
import Smartmockups from '../img/smartmockups.png';

class  GetApp extends Component{ 
    render (){
        return(
            <section className="getapp">
                <div className="container">
                    <div className="row title-row justify-content-center">
                        <div className="col-lg-4 text-right">
                            <img src={Smartmockups} alt="" />
                        </div>
                        <div className="col-lg-6">
                            <h2>Get the Happy-Weekends app</h2>
                            <p>Get our app to book the happiest weekends of them all</p>
                            <a className="mr-3" href="javascriptvoid()">
                                <img src={Android} alt="android" />
                            </a>
                            <a href="javascriptvoid()">
                                <img src={IOS} alt="ios" />
                            </a>
                            <div className="row">
                            <div className="col-lg-12">
                                {/* <a className="launching-btn" href="javascripit">Launching Soon</a> */}
                                <div className="launching-btn" href="javascripit">Launching Soon</div>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
         )
    }
}

export default GetApp;