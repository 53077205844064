import React, {Component, Fragment} from 'react';
import axios from "axios/index";
import {Link} from 'react-router-dom'
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import {Button, Form, Spinner} from 'react-bootstrap'
import MetaTags from 'react-meta-tags';


import {baseImgUrl, baseUrl} from '../Util/Constants'


class  Blog extends Component{

    constructor(props) {

        super(props)

        this.state = {

            blogItems: [],
            btnLoading : false,
            successSubmit : false,
            emailExist : false,

        }

    }

    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const username = data.get("email")



            axios.post(baseUrl+"newsletters",{"email" : data.get("email")})
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        btnLoading : false,
                        successSubmit : true
                    })

                })
                .catch(error => {
                    console.log("Bad Response")

                    console.log(error.response)


                    this.setState({
                        btnLoading : false,
                        emailExist : true,
                        successSubmit : true

                    })

                });
        }


    }

    componentDidMount (){

        window.scrollTo(0, 0)

        axios.get(baseUrl+"posts.json?groups[]=posts&isActive=1")
            .then(res => {

                this.setState({

                    blogItems : res.data,

                });

                console.log(res.data)

            })


    }


    render() {
        return (
           <div className="bg-gray">


               <MetaTags>

                   <title>Make the most of the Weekend Vibes | Happy Weekends</title>
                   <meta name="title" content="Make the most of the Weekend Vibes | Happy Weekends" />
                   <meta name="description" content=" Happy Weekends plans a perfect weekend for you in UAE. We make your weekends very memorable and happening. Our exciting experiences and deals for the weekend fun will get the best out of you. Plan your weekends with us. " />

                   <link rel="canonical" href="https://www.happy-weekends.com/" />

                   <meta property="og:type" content="website" />
                   <meta property="og:url" content="https://www.happy-weekends.com/" />
                   <meta property="og:title" content="Make the most of the Weekend Vibes | Happy Weekends" />
                   <meta property="og:description" content=" Happy Weekends plans a perfect weekend for you in UAE. We make your weekends very memorable and happening. Our exciting experiences and deals for the weekend fun will get the best out of you. Plan your weekends with us. " />
                   <meta property="og:image" content="/cover-image.jpg" />

                   <meta property="twitter:card" content="summary_large_image" />
                   <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                   <meta property="twitter:title" content="Make the most of the Weekend Vibes | Happy Weekends" />
                   <meta property="twitter:description" content=" Happy Weekends plans a perfect weekend for you in UAE. We make your weekends very memorable and happening. Our exciting experiences and deals for the weekend fun will get the best out of you. Plan your weekends with us. " />
                   <meta property="twitter:image" content="/cover-image.jpg" />


               </MetaTags>

               <section className="medium-banner blog-bg">
                    <div className="container">
                    <div className="row">
                        <h2 className="text-center w-100">Read our blogs</h2>
                    </div>
                    </div>
                </section>
               {  this.state.blogItems.length > 0 &&  <section className="blog-section">
                    <div className="container">
                        <div className="row mb-5">
                        <div className="col-lg-12">
                        <div className="first-post">
                            <div className="row">
                            <div className="col-lg-7">
                                <div className="blog-img">
                                <Link to={"/blog-detail/"+this.state.blogItems[0].slug}>
                                    <img className="img-fluid" src={baseImgUrl + this.state.blogItems[0].image } alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="blog-heading">
                                <ul>
                                    <li>
                                        <Moment local format="D MMM YYYY">
                                            {this.state.blogItems[0].publishedAt}
                                        </Moment>
                                    </li>
                                </ul>
                                <h5>
                                    <Link to={"/blog-detail/"+this.state.blogItems[0].slug}>

                                        <ReactHtml html={this.state.blogItems[0].title}/>

                                    </Link>
                                </h5>
                                <p>

                                    <ReactHtml html={this.state.blogItems[0].content.substring(0,300)}/>

                                    </p>
                                <div className="read-more">
                                    <Link to={"/blog-detail/"+this.state.blogItems[0].slug} className="btn" > Read more..</Link>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        <div className="row">

                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-sm-12">

                                    {this.state.blogItems.map((item,index) =>
                                          < BlogItem item = {item} index={index} />
                                    )
                                    }
                            </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="most-popular">
                                <h5>Most Popular</h5>
                                <div className="popular-lisitng">


                                    {this.state.blogItems.map((item,index)=>
                                        <Fragment>

                                            <div className="card mb-3">
                                                <Link to={"/blog-detail/" + item.slug}>

                                                    <div className="row no-gutters">

                                                        <div className="col-lg-3">

                                                            <img className="card-img" src={ baseImgUrl+ item.image } alt="" />

                                                        </div>
                                                        <div className="col-lg-9">
                                                            <div className="card-body">
                                                                <p className="card-text"><Moment local format="DD-MM-YYYY">
                                                                    {item.publishedAt}
                                                                </Moment></p>
                                                                <h5 className="card-title">
                                                                    <ReactHtml html={item.title}/>

                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>

                                            </div>

                                        </Fragment>

                                    )}





                                </div>
                            </div>
                            <div id="suscribe-us" className="suscribe-us">
                                <h5>Subscribe Us</h5>
                                <p>Get more experience inspiration, tips and exclusive offers sent straight to your inbox</p>


                                <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>


                                    {/*<input name={"email"} type="email" required placeholder="Your Email Address" />*/}
                                    <Form.Control  disabled ={this.state.successSubmit} value={this.state.email} onChange={this.checkIfUserExist}  required name={"email"} type="email" placeholder="Your Email Address" />


                                    <Button  style={{width:"auto"}} variant={"light"} className={"btn-blog-newsletter-submit"}
                                            type={"submit"}



                                    >
                                        { this.state.btnLoading && <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> }

                                        {!this.state.successSubmit &&
                                        <>
                                            {this.state.btnLoading ?  "Wait" : "Submit" }
                                        </>

                                        }

                                        { this.state.successSubmit &&!this.state.emailExist&&  "Subscribed Successfully" }
                                        { this.state.successSubmit &&this.state.emailExist &&  "Already Subscribed" }


                                    </Button>





                                </Form>

                            </div>
                        </div>

                        </div>
                    </div>
                </section> }
           </div>
        )
    }
}



function BlogItem(props) {


    if (props.index==0){

        return null
    }
    return(

        <div className="blog-column">
            <div className="blog-heading">
                <h5>
                    <Link to={"/blog-detail/"+props.item.slug} >
                        <ReactHtml html={props.item.title}/>
                    </Link>
                </h5>
                <ul>
                    <li>
                        <Moment  format="D MMM YYYY">
                        {props.item.publishedAt}
                    </Moment></li>
                    {props.item.readingTime && <li>{props.item.readingTime}</li> }
                    <li>{props.item.views} VIEWS</li>
                </ul>
            </div>
            <div className="blog-img">
                <Link to={"/blog-detail/"+props.item.slug} >
                    <img src={baseImgUrl + props.item.image } alt="blog" />
                </Link>
            </div>
            <div className="read-more">
                <Link className="btn" to={"/blog-detail/"+props.item.slug}> Read more..</Link>
            </div>
        </div>

    )


}
export default Blog;
