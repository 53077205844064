import React, { Component } from 'react';
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import Linkd from '../img/linkd.png';
import Inst from '../img/inst.png';
import Face from '../img/face.png';
import axios from "axios/index";
import {baseUrl} from "../Util/Constants";

class  Footer extends Component{



    constructor(props) {
        super(props)
        this.state = {

            items: [],



        };



    }



    componentDidMount(){


        axios.get(baseUrl+"categories.json?groups[]=list&level=1&isActive=1" )
            .then(res => {
                const persons = res.data;

                var items= res.data.filter((item)=> item.name!="Weekend Pass")

                this.setState({ items : items });

                // this.props.setCategories(res.data)
                // console.log("categories")
                //
                // console.log(persons)
            })
    }

    render (){
        return(
            <div>
                <footer className={"footer-bottom-link"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p>Activities & Weekends</p>
                            </div>
                            <div className="col-12">
                                <ul>


                                    {this.state.items.map((item) =>

                                        <li>
                                            <Link to={"/category/"+item.slug}><b>{item.name}</b></Link> {item.children.length>0&&":"}

                                            {item.children.map((subitem,index)=>

                                                <>
                                                    {index>0?" | ":" "}
                                                    <Link to={"/category/"+subitem.slug}>{subitem.name}</Link>

                                                </>

                                            )}


                                        </li>

                                    )
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <footer>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3">
                                <h3>Company</h3>

                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/career">Careers</Link></li>
                                    <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <h3>Learn More</h3>

                                <ul>
                                    <li><Link to="/partner-contact">Become A Host</Link></li>
                                    {/*<li><Link to="/how-it-works">How It Works</Link></li>*/}
                                    <li><Link to="/blog">Our Blog</Link></li>
                                    <li><Link to="/faq">FAQs</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <h3>Corporate</h3>

                                <ul>
                                    <li><Link to="/corporate">Corporate Services</Link></li>
                                    {/* <li><Link to="/how-it-works">Work with Us</Link></li>
                                <li><Link to="">Our Partners</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <h3>Customer Support</h3>

                                <ul>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><a href="mailto:care@happy-weekends.com">care@happy-weekends.com</a></li>
                                    <li className="social-icon">
                                        <a target="_blank" href="https://www.facebook.com/Happy-Weekends-113793993335534"><img src={Face} alt="" /></a>
                                        <a target="_blank" href="https://www.instagram.com/happy_weekends_uae/"><img src={Inst} alt="" /></a>
                                        <a target="_blank" href="https://www.linkedin.com/in/happy-weekends-99b258193/"><img src={Linkd} alt="" /></a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </footer>

                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <p className="mb-0">Happy Weekends © 2020 All rights reserved.</p>
                                <p className="mb-0 mt-3">Made in the UAE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;