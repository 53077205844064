import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import { Spinner,Button, Col, Row, Carousel,FormFile, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import {Switch, Link} from 'react-router-dom'

import  {CloudUpload} from '@material-ui/icons';
import axios from "axios/index";
import {baseUrl} from "../Util/Constants";
import {saveUserData} from "../LocalStorage/user";
import {connect} from "react-redux";


class  BusinessInformation extends Component{



    constructor(props) {
        super(props);
        this.state = {

            validatedForm: false,
            isChecked: false,
            file: null,
            userExists : false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            cities : [],
            areas:[],
            formHasError: false,
            host : this.props.host,
            loading: false,

        }


        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fetchCities=this.fetchCities.bind(this)
        this.fetchArea=this.fetchArea.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);

    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    fetchCities() {

        axios.get(baseUrl + "cities.json?groups[]=detail&isActive=1")
            .then(res => {

                this.setState({

                    cities: res.data,

                });

            })
    }

    fetchArea(e) {


        console.log(e.target.value)
        var cityId = e.target.value

        var url = baseUrl + "areas.json?groups[]=detail&city="+cityId

        console.log(url)

        axios.get(url)
            .then(res => {

                console.log("areas")
                console.log(res.data)

                this.setState({

                    areas: res.data,

                });


            })


    }





    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;



        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,
                formHasError : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true,
                formHasError : false,

            })


            this.setState({

                loading: true
            })
            const data = new FormData(event.target);


            console.log("host form data")
            console.log(data)



            this.setState({

                confirmPasswordError: false
            })

            this.setState({

                passwordError: false
            })



            let hostData = {

                "host": this.state.host.id,

                "companyName": data.get("companyName"),
                "tradeLicenseId": data.get("tradeLicenseId"),
                "businessDescription": data.get("businessDescription"),
                // "tradeLicenseImage": data.get("tradeLicenseImage"),

                "address": data.get("address"),
                "streetName": data.get("streetName"),
                "buildingName": data.get("buildingName"),
                "poBox": data.get("poBox"),

                "city": data.get("city"),
                "area": data.get("area"),
                "companyWebsiteLink": data.get("companyWebsiteLink"),
                "youtubeLink": data.get("youtubeLink"),
                "linkedInLink": data.get("linkedInLink"),
                "instagramLink": data.get("instagramLink"),
                "facebookPageLink": data.get("facebookPageLink"),
                "twitterLink": data.get("twitterLink"),

            };


            var urlHost = baseUrl + "edit_businesses";

            axios.post(urlHost, hostData )
                .then(res => {

                    this.setState({

                        loading : false
                    })


                }).catch(error => {
                console.log("host business error")
                console.log(hostData)
                console.log(error)


                this.setState({

                    loading : false
                })

            });




        }
    }


    componentDidMount(){



        this.setHostInfo(this.state.host)

        this.fetchCities()
    }



    getHostInformation(){


        var    url = baseUrl+"hosts.json?groups[]=customer&email="+this.props.userDetail.email;

        console.log(url)


        axios.get(url)
            .then(res => {


                console.log("host data exits")

                console.log(res.data[0])


                this.setHostInfo(res.data[0])

                this.setState({

                    host: res.data[0]
                })




            }).catch(error => {


            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }

    setHostInfo(host){


        this.setState({

            companyName: host.companyName,
            tradeLicenseId: host.tradeLicenseId,
            businessDescription: host.businessDescription,

            companyWebsiteLink: host.companyWebsiteLink,
            facebookPageLink: host.facebookPageLink,
            instagramLink: host.instagramLink,
            linkedInLink: host.linkedInLink,
            twitterLink:  host.twitterLink,
            youtubeLink:  host.youtubeLink,


            streetName:  host.streetName,
            buildingName:  host.buildingName,
            address: host.address,
            poBox: host.poBox,


        })

    }


    onChange(e) {

        var files = e.target.files;

        console.log(files);


        var filesArr = Array.prototype.slice.call(files);

        console.log(filesArr);

        this.setState({ file: files[0]});
    }




    render (){
        return(

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                     aria-labelledby="pills-personal-tab">
                    <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>

                        <div className="form-block mt-4">
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <h4>Business (Host) Information</h4>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Company name</Form.Label>
                                    <Form.Control
                                        name={"companyName"}
                                        required
                                        type="text"
                                        placeholder=""

                                        value={this.state.companyName}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Trade license id</Form.Label>
                                    <Form.Control
                                        name={"trade_license_id"}
                                        required
                                        type="text"
                                        placeholder=""

                                        value={this.state.tradeLicenseId}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Business description</Form.Label>
                                    <Form.Control as="textarea" rows="3"
                                                  name={"businessDescription"}
                                                  required
                                                  type="text"
                                                  placeholder=""

                                                  value={this.state.businessDescription}
                                                  onChange={this.handleInputChange}
                                    />

                                </Form.Group>
                                <Form.Group  as={Col} md="6" controlId="validationCustom01">

                                    <Form.Label className={""}>Trade License Image</Form.Label>

                                    <p/>
                                    <Form.Label for="uploadBtn" className={"custom-file-upload"}>

                                        <Form.Control
                                            accept="image/jpg, image/jpeg" id={"uploadBtn"}
                                                      onChange={this.onChange}
                                            className="custom-file-input-control"

                                                      type="file"
                                                      placeholder=""

                                                      custom
                                                      name={"tradeLicenseImage"}

                                        />
                                        <CloudUpload/> Click To Upload
                                    </Form.Label>
                                    {this.state.file && <img className={"upload-img-thumbnail"} src={URL.createObjectURL(this.state.file)} />}


                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""

                                        name={"address"}
                                        value={this.state.address}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Street Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""

                                        name={"streetName"}
                                        value={this.state.streetName}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Building </Form.Label>
                                    <Form.Control
                                        value={this.state.buildingName}
                                        onChange={this.handleInputChange}
                                        required
                                        type="text"
                                        placeholder=""
                                        
                                        name={"buildingName"}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>PO Box No.</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""

                                        name={"poBox"}
                                        value={this.state.poBox}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label >City</Form.Label>
                                    <Form.Control   required as="select" className=""  onChange={this.fetchArea} name={"city"}>
                                        <option value={""} >Choose...</option>
                                        {this.state.cities.map((item)=>
                                            <option value={item.id}>{item.name}</option>

                                        )
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Area</Form.Label>
                                    <Form.Control    required  as="select" className="" name={"area"}>
                                        <option value={""}>Choose...</option>
                                        {this.state.areas.map((item)=>
                                            <option value={item.id}>{item.name}</option>

                                        )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                        </div>
                        <div className="form-block mt-4">
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <h4>Website and Social Media Profiles</h4>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Website</Form.Label>
                                    <InputGroup className="phone_no">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i class="fas fa-globe"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name={"companyWebsiteLink"}

                                            type="text"
                                            placeholder=""

                                            value={this.state.companyWebsiteLink}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    <p class="small-text">For eg: www.yourwebsite.com</p>

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Linkedin</Form.Label>
                                    <InputGroup className="phone_no">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-linkedin-in"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name={"linkedInLink"}
                                            type="text"
                                            placeholder=""

                                            value={this.state.linkedInLink}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    <p class="small-text">For eg: www.yourwebsite.com</p>

                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Facebook</Form.Label>
                                    <InputGroup className="phone_no">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-facebook-f"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name={"facebookPageLink"}
                                            type="text"
                                            placeholder=""

                                            value={this.state.facebookPageLink}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    <p class="small-text">For eg: www.facebook.com</p>

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Twitter</Form.Label>
                                    <InputGroup className="phone_no">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-twitter"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name={"twitterLink"}
                                            type="text"
                                            placeholder=""

                                            value={this.state.twitterLink}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    <p class="small-text">For eg: www.twitter.com</p>

                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Instagram</Form.Label>
                                    <InputGroup className="phone_no">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-instagram"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control

                                            name={"instagramLink"}
                                            type="text"
                                            placeholder=""
                                            
                                            value={this.state.instagramLink}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    <p class="small-text">For eg: www.instagram.com</p>

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>youtube</Form.Label>
                                    <InputGroup className="phone_no">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-youtube"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            name={"youtubeLink"}

                                            type="text"
                                            placeholder=""
                                            
                                            value={this.state.youtubeLink}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    <p class="small-text">For eg: www.youtube.com</p>

                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="12" className="text-center mt-4">
                                    <Button className="btn btn-red" type="submit">

                                        { this.state.loading && <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> }

                                        { this.state.loading ? "Wait.." : "Save"}
                                    </Button>
                                    {this.state.formHasError && <Alert className={"mt-2"} variant={"danger"}>
                                        Information provided is not valid or missing!
                                    </Alert>
                                    }
                                </Form.Group>
                            </Form.Row>
                        </div>
                    </Form>
                </div>
            </div>


        )
    }
}

// export default BusinessInformation;

const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(BusinessInformation);