import React, {Component, Fragment} from 'react';

import axios from "axios/index";
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import { Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, ModalTitle, ModalBody} from 'react-bootstrap'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DashboardHeader from "./DashboardHeader";
//import './AdminPartnerDummy.css';
import ChangePassword from "./ChangePassword";
import PersonalInformation from "./PersonalInformation";
import CustomerBookings from "./CustomerBookings";
import ManageActivity from "./ManageActivity";
import CustomerReviews from "./CustomerReviews";
import BusinessInformation from "./BusinessInformation";
import PaymentSettingsNew from "./PaymentSettingsNew";
import AddActivity from "./AddActivity";
import '../admin.css';
import {saveKey,getKey} from "../LocalStorage/user";
import {baseUrl} from "../Util/Constants";
import {connect} from "react-redux";
import Rating from '@material-ui/lab/Rating';


class  VendorDashboard extends Component{



    constructor(props){

        super(props)
        this.state = {
            homeSections : [],
            modal: false,
            host : null
        };
    }




    getHostInformation(){


        var    url = baseUrl+"hosts.json?groups[]=customer&email="+this.props.userDetail.email;

        console.log(url)


        axios.get(url)
            .then(res => {

                console.log("host index exits")
                console.log(res.data[0])

                this.setState({

                    host: res.data[0]
                })

                // this.setHostInfo(res.data[0])


                saveKey("host",res.data[0])

                // saveUserData(res.data[0])



            }).catch(error => {


            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }


    componentDidMount (){

      this.getHostInformation()

    }

    render() {
        return (
            <div>

                {/*<div className={"m-5 pd-5"}>*/}
                    {/*<AddActivity/>*/}

                {/*</div>*/}


                {this.state.host && <>

           <DashboardHeader  host={this.state.host} />

                <MainTabs host={this.state.host} />
          </>}



            </div>

        )
    }
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

  function MainTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>
            <AppBar position="static">
                <Tabs className="custom-color" centered value={value} onChange={handleChange} aria-label="simple tabs example">

                    <Tab label="Account Settings" {...a11yProps(0)} />
                    <Tab label="Manage Activities" {...a11yProps(1)} />
                    <Tab label="View Activity Bookings" {...a11yProps(2)} />
                    <Tab label="Reviews/Ratings" {...a11yProps(3)} />


                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <div className="tile tile-bg">
                                        <AccountTabs  host={props.host} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>

            </TabPanel>

            <TabPanel value={value} index={1}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                                    <ManageActivityTabs host={props.host}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </TabPanel>

            <TabPanel value={value} index={2}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                                    <BookedActivities host={props.host} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </TabPanel>
            <TabPanel value={value} index={3}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                                    <ReviewsTabs  host={props.host}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </TabPanel>

        </div>


    );
}

function AccountTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>

                <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                    <Tab label="Personal Information" {...a11yProps(0)} />
                    <Tab label="Business Information" {...a11yProps(1)} />

                    <Tab label="Change Password" {...a11yProps(2)} />
                    <Tab label="Payment Settings" {...a11yProps(3)} />

                </Tabs>

            <TabPanel value={value} index={0}>

                <PersonalInformation host={props.host}/>

            </TabPanel>

            <TabPanel value={value} index={1}>

                <BusinessInformation host={props.host}/>

            </TabPanel>
            <TabPanel value={value} index={2}>

                <ChangePassword host={props.host}/>

            </TabPanel>
            <TabPanel value={value} index={3}>

                <PaymentSettingsNew host={props.host}/>

            </TabPanel>

        </div>


    );
}
function ManageActivityTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                <Tab label="Manage Activities" {...a11yProps(0)} />


                {props.host.canManageActivities &&
                <Tab label="Add New Activity" {...a11yProps(1)} />}


            </Tabs>

            <TabPanel value={value} index={0}>
                <ManageActivity host={props.host}/>
            </TabPanel>


            {props.host.canManageActivities &&
            <TabPanel value={value} index={1}>
                <AddActivity host={props.host}/>
            </TabPanel>}


        </div>


    );
}
function BookedActivities() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                <Tab label="View Activity Bookings" {...a11yProps(0)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <CustomerBookings/>
            </TabPanel>



        </div>


    );
}
function ReviewsTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>

            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                <Tab label="Reviews/Ratings" {...a11yProps(0)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <CustomerReviews/>
            </TabPanel>

        </div>


    );
}



const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(VendorDashboard);

// export default VendorDashboard;