import React, { Component } from 'react';
import AboutMaking from '../img/about-page-making.jpg';
import GoodBye from '../img/goodbye-stress.jpg';
import MM from '../img/mm.jpg';
import Testi1 from '../img/testi-1.png';
import Testi2 from '../img/testi-2.png';
import Carousel from 'react-bootstrap/Carousel';
import { Link} from 'react-router-dom'
import './weekend-pass.css';
import RoomImg from './images/room-l-1.jpg';
import p1 from './images/p1.jpg';
import p2 from './images/p2.jpg';
import p3 from './images/p3.jpg';
import p4 from './images/p4.jpg';
import p5 from './images/p5.jpg';
import p6 from './images/p6.jpg';
import p11 from './images/p11.jpg';
import p12 from './images/p12.jpg';
import p13 from './images/p13.jpg';
import {baseImgUrl, baseUrl} from "../Util/Constants";
import axios from "axios/index";
import history from "../History/history";
import MetaTags from 'react-meta-tags';


class  WeekendPassList extends Component{


    constructor(props) {

        super(props)

        this.state = {

            bundleItems: [],
            btnLoading : false,
            successSubmit : false,
            readMore : false
        }

        this.toggleReadmore=this.toggleReadmore.bind(this)

    }


    componentDidMount (){

        window.scrollTo(0, 0)

        axios.get(baseUrl+"bundles.json?groups[]=activities")
            .then(res => {

                this.setState({

                    bundleItems : res.data,

                });

                console.log(res.data)

            })




    }




toggleReadmore(){


        this.setState({

            readMore : !this.state.readMore
        })

}



    render (){
        return(


            <div className={"weekend-list-page"}>


                <MetaTags>

                    <title>Spend Mind blowing weekends all Year Round | Weekend Pass </title>
                    <meta name="title" content={"Spend Mind blowing weekends all Year Round | Weekend Pass "} />
                    <meta name="description" content=" Have you ever struggled to figure out what to do over the weekend and how to make the most of it? If yes, Weekend Pass is made for you. Our team handpicks unique experiences, clubs them together and offers them at discounted prices to help you make the most of your weekends." />
                    <link rel="canonical" href="https://www.happy-weekends.com/" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content="Spend Mind blowing weekends all Year Round | Weekend Pass " />
                    <meta property="og:description" content=" Have you ever struggled to figure out what to do over the weekend and how to make the most of it? If yes, Weekend Pass is made for you. Our team handpicks unique experiences, clubs them together and offers them at discounted prices to help you make the most of your weekends." />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content="Spend Mind blowing weekends all Year Round | Weekend Pass " />
                    <meta property="twitter:description" content=" Have you ever struggled to figure out what to do over the weekend and how to make the most of it? If yes, Weekend Pass is made for you. Our team handpicks unique experiences, clubs them together and offers them at discounted prices to help you make the most of your weekends." />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>

                <div className={this.state.readMore?"hero-image-big hero-image":"hero-image"}>
                    <div className="hero-text">
                        <a href="#" className="weekendpass"> Weekend <span>Pass</span></a>
                        <h1> What is Weekend Pass?</h1>
                        <div>{this.state.readMore?"Have you ever struggled to figure out what to do over the weekend and how to make the most of it?\n" +
                            " If yes, Weekend Pass is made for you. \n" +
                            "\n" +
                            "Let’s be honest, we struggle because we don’t have time to plan for weekends. We get that. Hence our team takes care of the planning part and handpicks unique experiences, clubs them together and offers them at discounted prices to help you make the most of your weekends. \n" +
                            "\n" +
                            "Furthermore,  weekend Pass makes it exciting for your loved ones to know what you’ve planned for them in coming weekends. \n" +
                            "\n" +
                            "We believe if you have one weekend which is special and unique every month, you will have 12 in a year and 60 in five years. Life will be much more balanced, meaningful and happier. So, choose the one you love the most. Happy Weekends! ":"Have you ever struggled to figure out what to do over the weekend and how to make the most of it? If yes, Weekend Pass is made for you."}</div>

                        <a onClick={this.toggleReadmore} className="readmore"> {this.state.readMore?"Read Less":"Read More"}</a>



                    </div>
                </div>
                <section className={this.state.readMore?"padtop0 martopfirst_item section-read-more":"padtop0 martopfirst_item"}>
                    <div className="container">
                        <div className="row">
                            <div className="room_list_inner">




                                {this.state.bundleItems.map((item,index) =>
                                    <div className="room_list_item">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <a href="#" className="room_img">
                                                    <img src={baseImgUrl+item.listingCoverImage} alt="" />
                                                </a>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="room_list_l_text">
                                                            <Link to={"/weekend-detail/"+item.slug}><h4>{item.name}</h4></Link>
                                                            <ul className={"d-none"}>
                                                                <li><Link to={"/weekend-detail/"+item.slug} >Weekends Pass</Link></li>
                                                            </ul>
                                                            <p>{item.description} </p>
                                                            <div className="row">


                                                                {item.bundleItems.map((bundleItem,index) =>

                                                                    (index < 3) && <div className="col-md-3 col-sm-3 col-3">
                                                                        <div className="explor_item">
                                                                            <div className="room_image">
                                                                                <img src={baseImgUrl+bundleItem.activity.images[0].image} alt="" />
                                                                            </div>
                                                                            <div className="explor_text">
                                                                                <a href="#"><span className={"list-title-bundle"}>{bundleItem.activity.title}</span></a>
                                                                                <div className="explor_footer">
                                                                                    <div className="pull-left">
                                                                                        <span className={"list-title-bundle"}>AED {bundleItem.activity.priceOption[0].price}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )}


                                                                {item.bundleItems.length > 2 &&
                                                                <div className="col-md-3 col-sm-6">
                                                                    <div className="moreitem">
                                                                        <h3>+{item.bundleItems.length-3}<br/>more</h3>
                                                                    </div>
                                                                </div>
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="room_price_box">
                                                            <div className="above">
                                                                <h3>Highlights</h3>
                                                                <ul>


                                                                    {item.highlightOne &&  <li><i className="fa fa-check" aria-hidden="true" /> {item.highlightOne}</li>}
                                                                    {item.highlightTwo &&  <li><i className="fa fa-check" aria-hidden="true" /> {item.highlightTwo}</li>}
                                                                    {item.highlightThree &&  <li><i className="fa fa-check" aria-hidden="true" /> {item.highlightThree}</li>}
                                                                    {item.highlightFour &&  <li><i className="fa fa-check" aria-hidden="true" /> {item.highlightFour}</li>}
                                                                    {item.highlightFive &&  <li><i className="fa fa-check" aria-hidden="true" /> {item.highlightFive}</li>}


                                                                </ul>
                                                            </div>
                                                            <div className="below">
                                                                <h6>Start from</h6>
                                                                <h5>AED <GetTotalAfterDiscount item={item} /></h5>
                                                                <h6><strike>AED <GetCartTotal item={item} /></strike></h6>
                                                                <Link className="book_now_btn" to={"/weekend-detail/"+item.slug}>Learn More</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> )}

                            </div>
                        </div>
                    </div>
                </section>
                <section className="padtop0 d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="makepass">
                                    <h1>Make Your Own Weekend Pass</h1>
                                    <a href="#">Click Here to Create <i className="fa fa-plus" aria-hidden="true" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        )
    }
}




function GetCartTotal(props) {


    var total=0;

    for (let i = 0; i < props.item.bundleItems.length; i++) {

        if (props.item.bundleItems[i].activity.priceOption.length > 0){
            // if (props.item.bundleItems[i].activity.priceOption[0].discountedPrice && props.item.bundleItems[i].activity.priceOption[0].discountedPrice > 0) {
            //
            //     total = total + props.item.bundleItems[i].activity.priceOption[0].discountedPrice
            //
            // } else {

                total = total + props.item.bundleItems[i].activity.priceOption[0].price

            // }
    }
    }


    return (

        <>
            {total}
        </>
    );

}


function GetTotalAfterDiscount(props) {

    var cartTotal=0;

    for (let i = 0; i < props.item.bundleItems.length; i++) {

        if (props.item.bundleItems[i].activity.priceOption.length > 0){
            // if (props.item.bundleItems[i].activity.priceOption[0].discountedPrice && props.item.bundleItems[i].activity.priceOption[0].discountedPrice > 0) {
            //
            //     cartTotal = cartTotal + props.item.bundleItems[i].activity.priceOption[0].discountedPrice*(100-props.item.bundleItems[i].discount)/100
            //
            // } else {

                cartTotal = cartTotal + props.item.bundleItems[i].activity.priceOption[0].price*(100-props.item.bundleItems[i].discount)/100

            // }

        }
    }

    cartTotal=Math.round(cartTotal)

    return (

        <>
            {cartTotal}
        </>
    );


}






export default WeekendPassList;