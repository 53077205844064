import React, {Component, Fragment} from 'react';
import ProductImg from '../img/at-the-top-dubai.png';
import FB from '../img/fb-small.png';
import Insta from '../img/link-small.png';
import Twitter from '../img/twitter-small.png';
import EmailFriend from '../img/mail-small.png';
import axios from "axios/index";
import {saveUserData} from "../LocalStorage/user";
import {loginFailed} from "../store/actions/actions";
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import Moment from 'react-moment';
import Map from '../img/map.png';
import Calander from '../img/calander.png';
import Duration from '../img/duration.png';
import Date from '../img/day.png';
import Ticket from '../img/ticket.png';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import  'moment-timezone'


class  OrderSuccess extends Component{


    page=1;
    slug;

    constructor(props) {

        super(props)

        this.state = {

            slug: this.props.match.params.id,
            booking: null

        };

        this.createBooking=this.createBooking.bind(this)

    }

    componentDidMount(){



        this.createBooking()


    }

    createBooking(){

        console.log("order create")
        var  url = baseUrl+"customer_bookings";

        console.log(this.state.slug)

        var data = {

            customer : (this.props.userDetail.id)

        }




            axios.post(url, data)
                .then(res => {

                    console.log(res.data)


                    this.getBookingDetails(res.data.booking)


                }).catch(error => {

                console.log(error)

            });



    }


    getBookingDetails(bookingId){


        console.log("order details")


        var    url = baseUrl+"bookings/"+bookingId+".json?groups[]=bookingDetails";

        console.log(url)



        axios.get(url)
            .then(res => {

                console.log(res.data)

                this.setState({
                    booking : res.data

                })



            }).catch(error => {



            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }

 render (){

        return(

            <section className="success-order">
                <div className="container">
                    <div className="row pb-3">
                        <div className="col-md-12">
                            <h2>Success! Thank you for your order {this.props.userDetail.firstName} {this.props.userDetail.lastName} </h2>
                            <p>You’ll receive an email at {this.props.userDetail.email} once your order is confirmed.</p>
                        </div>
                    </div>
                    { this.state.booking &&
                    <>
                    <div className="row pb-3">
                        <div className="col-md-12">
                            <div className="order-summary white-box rounded">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Order Number</th>
                                                        <th>Name</th>
                                                        <th>Payment Method</th>
                                                        <th>Total <span className="my-smal-text">(Inclusive of VAT)</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>#{this.state.booking.id}</td>
                                                        <td><p className="font-weight-bold">{this.props.userDetail.firstName} {this.props.userDetail.lastName}</p></td>
                                                        <td>Online Payment</td>
                                                        <td><p className="font-weight-bold">AED {this.state.booking.total}</p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* <h2>Order <span class="font-weight-bolder">#{this.state.booking.id}</span></h2> */}
                                <div className="row d-none">
                                    <div className="col-md-4">
                                        <h4>{this.props.userDetail.firstName}</h4>
                                        <p className="font-weight-bold d-none">Shipping Address</p>
                                        <p className={"d-none"}>
                                            <span>2301, Amaya Tower One,</span>
                                            <span>Unnamed Road - Al Reem Island,</span>
                                            <span>Abu Dhabi, United Arab Emirates+971-55-1072139</span>
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="font-weight-bold">Payment Method</p>
                                        <p>Credit Card</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="font-weight-bold">Order Summary</p>
                                        <p>Total <span class="small-text d-block">Includes all taxes.</span></p>
                                    </div>
                                    <div className="col-md-2">
                                        <h4 className="pt-5">AED {this.state.booking.total}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-12">
                            <div className="user-address white-box rounded">
                                <h4>Order Details</h4>

                                {this.state.booking && this.state.booking.bookedItems.map((bookedItem)=>

                                    <div className="row mb-3">
                                    <div className="col-lg-3">
                                    <img src={baseImgUrl+bookedItem.activity.images[0].image} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="user-detail">
                                        <h2 className="mb-1">{bookedItem.activity.title}    {bookedItem.itemType==1&& <span className={"bundle-name-heading"}>{bookedItem.bundle.name}</span>}</h2>
                                           
                                        <div className="pt-1 pb-4 product-dis-list">
                                            {bookedItem.activity.address&&(bookedItem.activity.address.address||bookedItem.activity.address.city)&&<div className="row">
                                                <div className="col-lg-12"><p className="font-weight-bold">Address: &nbsp;</p><p>{bookedItem.activity.address&&bookedItem.activity.address.address?bookedItem.activity.address.address+"    , ":""+bookedItem.activity.address&&bookedItem.activity.address.city&&bookedItem.activity.address.city.name}</p></div>
                                             </div>}
                                            <div className="row">
                                                <div className="col-lg-12"><p className="font-weight-bold">Booking Item ID: &nbsp;</p><p>#{bookedItem.id}</p></div>
                                             </div>
                                            <div className="row">
                                                <div className="col-lg-12"><p className="font-weight-bold">Date Booked: &nbsp;</p><p>
                                                    <Moment format="HH:mm D MMM YYYY"  unix tz={"Asia/Dubai"} >
                                                     {bookedItem.timeStamp}
                                                </Moment>


                                                </p></div>
                                             </div>
                                            <div className="row d-none">
                                                <div className="col-lg-12"><p className="font-weight-bold">Duration:&nbsp; </p><p>1 Hours</p></div>
                                                
                                            </div>
                                            <div className="row d-none" >
                                                <div className="col-lg-12"><p className="font-weight-bold">Avilable Days:&nbsp; </p><p>Friday, Saturday</p></div>
                                              
                                            </div>
                                            <div className="row ">
                                                <div className="col-lg-12"><p className="font-weight-bold">Tickets: &nbsp; </p>
                                                { bookedItem.bookedPriceOption.map((option)=>

                                                <p>{option.quantity} x AED {option.discountedPrice ? option.discountedPrice : option.price }</p>

                                                )}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-lg-3 text-right">
                                        <h2 className="text-right mb-1">Voucher Code: <span className="text-blue">{bookedItem.otp}</span></h2>
                                        <h4 className="text-right">AED {bookedItem.total}</h4>
                                    </div>
                                    </div>

                                )}



                            </div>
                        </div>
                    </div>
                        </>

                    }
                </div>
            </section>

        )
    }
}

// function ChangeTimezone(props){
//
//
//
//
//         var format = 'YYYY/MM/DD HH:mm:ss ZZ';
//         return moment(props.date, format).tz("ASIA/Dubai").format(format);
//
//
//     return(
//         <>
//         usaTime
//             </>
//     )
//
//
//
//
//
//
// }

const mapStateToProps = state => {
    return {
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail
    };
};

const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item))
    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(OrderSuccess);
