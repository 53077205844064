import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import { Col, Form, Button, ButtonGroup,InputGroup,ButtonToolbar, Row, Tabs, Tab, Nav} from 'react-bootstrap';
import axios from "axios/index";
import {baseUrl,baseImgUrl} from "../Util/Constants";
import  {Star} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import {connect} from "react-redux";
import * as actionCreator from "../store/actions/actions";
import EditActivityPopUp from "./EditActivityPopUp";
import { Modal, ModalBody, Alert } from 'react-bootstrap';


class  ManageActivity extends Component{


    constructor(props) {
        super(props);

        this.state = {
            validatedForm: false,
            isChecked: false,
            file: null,
            userExists: false,
            passwordError: false,
            oldPasswordError: false,
            confirmPasswordError: false,
            cities: [],
            areas: [],
            formHasError: false,
            host: this.props.host,
            hostBankInfo: {},
            loading: false,
            activities: null,
            filteredActivities: null,
            filter:0,
            unpublishPopUp:false,
            unpublishItemId:null



        }

        this.getActivities=this.getActivities.bind(this)
        this.editActivityPopUp=this.editActivityPopUp.bind(this)
        this.unpublishPopUp=this.unpublishPopUp.bind(this)
        this.unpublishItem=this.unpublishItem.bind(this)






    }


    unpublishItem(){



        axios.post(baseUrl+"unpublish_activities.json",
            {"activity": "/api/activities/"+this.state.unpublishItemId})
            .then(res => {

                const persons = res.data;
                console.log(res.data)

                this.unpublishPopUp()

            })
    }

    unpublishPopUp(e){



        if (e&&e.currentTarget.dataset.id) {

            this.setState({

                unpublishItemId: e.currentTarget.dataset.id
            })


        }
        this.setState({

            unpublishPopUp:!this.state.unpublishPopUp
        })
    }



    componentDidMount(){


        this.getActivities()

    }



    editActivityPopUp(e){

        // alert("show")


        var activityId = e.currentTarget.dataset.id;


        console.log("edit activity "+activityId)


        this.props.activityPopUpShow({show:true,activityId:activityId})


    }



    filterActivities(e){



    //    filer : 0 - all, 1 - active, 2 - in active, 3- sold out



        var filter = e.currentTarget.dataset.id;





        if (filter == 0) {


            this.setState({

                filteredActivities: this.state.activities,
                filter : 0

            })

        }

      else  if (filter == 1) {


            this.setState({

                filteredActivities: this.state.activities.filter((item)=> item.isActive==1),
                filter : 1

            })

        }

        else  if (filter == 2) {


            this.setState({

                filteredActivities: this.state.activities.filter((item)=> item.isActive==0),
                filter : 2

            })

        }

        else  if (filter == 3) {


            this.setState({

                filteredActivities: this.state.activities.filter((item)=> item.soldOut==1),
                filter : 3

            })

        }





    }


    getActivities(){





        var    url = baseUrl+"hosts/"+this.state.host.id+".json?groups[]=activities";



        // var    url = baseUrl+"hosts/9.json?groups[]=activities";


        console.log(url)


        axios.get(url)
            .then(res => {

                console.log("host activities ")
                console.log(res.data.activities)

                this.setState({

                    activities: res.data.activities,
                    filteredActivities: res.data.activities,
                })

                // this.setHostInfo(res.data[0])

                // saveUserData(res.data[0])



            }).catch(error => {


            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }


    render (){
        return(


<>

            <div className="tab-pane fade show active" id="pills-myfavorite" role="tabpanel"
                 aria-labelledby="pills-myfavorite-tab">
                <Row>
                    <Col>
                        <ButtonGroup  className="mr-2" aria-label="First group">
                            <Button data-id={0}  onClick={this.filterActivities.bind(this)} className={this.state.filter==0?"status-button  isActive":"status-button "}>All</Button>
                            <Button data-id={1} onClick={this.filterActivities.bind(this)} className={this.state.filter==1?"status-button  isActive":"status-button"}>Active</Button>
                        <Button data-id={2} onClick={this.filterActivities.bind(this)} className={this.state.filter==2?"status-button  isActive":"status-button"}>In-Active</Button>
                        <Button data-id={3} onClick={this.filterActivities.bind(this)} className={this.state.filter==3?"status-button  isActive":"status-button"}>Sold Out</Button>
                        </ButtonGroup>

                    </Col>

                </Row>


                {this.state.filteredActivities && this.state.filteredActivities.map((item)=>

                    <>
                    <div className="row mt-4">
                    <div className="col-md-12 col-lg-4 title-goes">
                        <img className="border-raidus" src={item.images.length>0? baseImgUrl + item.images[0].image:""} alt="" width="100%"/>
                    </div>
                    <div className="col-md-12 col-lg-8">
                        <div className="title-top">
                            <span className="best-seller ">BEST SELLER</span>
                        </div>
                        <div className="title_here">
                            <h5 className="title_pirce">{item.title}</h5>
                            <div className="rating rating-admin">
                                <p className="ratings">
                                <span className="rating-total">{item.rating}/5</span>
                                <Rating
                                    readOnly
                                    value={item.rating}
                                    icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                    emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                    name="size-large"  size="large"
                                />
                                </p>
                            </div>

                        </div>
                        <div className="vendor-bottom-block">
                            <p className="title_price2">AED {item.priceOption[0]&&item.priceOption[0].price}</p>

                            {this.state.host.canManageActivities &&
                            <div className="vendor-bottom-button">
                                <button data-id={item.slug} onClick={this.editActivityPopUp} className="blue-btn pr-4 pl-4 mr-3">Edit</button>
                                {item.isActive &&<button data-id={item.id} onClick={this.unpublishPopUp.bind(this)} className="btn">Unpublish</button>}
                            </div>}

                        </div>
                    </div>
                </div>
                    </>

                )}




                {this.props.activityPopUp && <EditActivityPopUp />}




                <Modal className={"loop-popup"}
                       aria-labelledby="contained-modal-title-vcenter"
                       centered show={this.state.unpublishPopUp} onHide={this.unpublishPopUp} animation={false}>

                    <ModalBody>



                        <div className={"row justify-content-center"}>
                            <div className={"col-10 text-center"}>
                                <p className={"text-bold text-caps"}>Unpublish</p>
                                <p>Are you sure you want to unpublish ?</p>
                            </div>
                        </div>



                        <div className={"row justify-content-center"}>


                            <div className={"col-12 text-center mt-2"}>


                                <div className={"row justify-content-center"}>
                                    <div className={"col-6"} style={{textAlign:"center"}}>

                                        <button onClick={this.unpublishItem}  className={"shadow-sm mr-2 btn btn-link btn-green mt-2 mb-2 btn-blue"} type={"submit"}  >Unpublish </button>


                                    </div>
                                    <div className={"col-6"} style={{textAlign:"center"}}>
                                        <button onClick={this.unpublishPopUp} className={"shadow-sm mr-2 btn btn-link green-btn-border mt-2 mb-2 btn-blue"}>Cancel</button>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </ModalBody>

                </Modal>

            </div>


    </>





        )
    }
}



const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        editActiviyId: state.editActiviyId,
        activityPopUp:state.activityPopUp


    };
};

const mapDispachToProps = dispatch => {
    return {

        activityPopUpShow: (data) => dispatch(actionCreator.activityPopUpShow(data)),



    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(ManageActivity);

