import React, {Component, Fragment,Suspense } from 'react';
import PersonalisedWeekends from '../PersonalisedWeekends/index';
import NewsLetter from '../NewsLetter/index';
import Testimonial from '../Testimonial/index';
import axios from "axios/index";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TrandingIcon from '../img/tranding-icon.svg';
import TrendingSectionItem from "./TrendingSectionItem";
import PlanWeekendSection from "./PlanWeekendSection";
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import { Button,  Modal} from 'react-bootstrap'
import PlanWeekendPopUp from "./PlanWeekendPopUp";
import SliderHome from "../SliderHome/index";
import GetApp from "../GetApp";
import SaveWeekend from "../SaveWeekend";
import ArrowDown from '../img/arrow-down.png';
import SocialCoverImage from '../img/cover-image.jpg';

import ArrowRight from '../img/owl-arrow-right.png';
import { createBrowserHistory } from 'history';
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import MetaTags from 'react-meta-tags';
import InfoTool from '../img/info-tooltip.png';
import {saveKey,getKey} from '../LocalStorage/user'
import {Helmet} from "react-helmet";


const history = createBrowserHistory();

// Get the current location.
const location = history.location;

// Listen for changes to the current location.
const unlisten = history.listen((location, action) => {
    // location is an object like window.location
    // console.log(action, location.pathname, location.state);

});

const HomeSections = React.lazy(() => import('./HomeSections'));
const CategoryScrollSections = React.lazy(() => import('./CategoryScrollSections'));


class  Home extends Component{


    constructor(props){

        super(props)

        this.state = {

            homeSections : [],
            modal: false,
            catItems : [],
            days : [],
            cities : [],
            slides: [],
            trendingItems : [],
            homeSectionTrendingItems : [],
            city:null,
            cityId:null,
            cityDropDown:false,
            trendingHomeSections: []

        };
        this.toggle = this.toggle.bind(this);
        this.showCityDropDown = this.showCityDropDown.bind(this);
        this.fetchTrending= this.fetchTrending.bind(this)
        this.loadSlides= this.loadSlides.bind(this)
        this.loadTrending= this.loadTrending.bind(this)
        this.loadTrendingHomeSections= this.loadTrendingHomeSections.bind(this)
        // this.removeDuplicates= this.removeDuplicates.bind(this)


    }



    handleClickOutside = event => {

        // alert("click")
        // console.log(event.target)

        if (event.target.className!="country-dropdown-a"&&event.target.className!="country-dropdown-a-list-item") {

            this.setState({cityDropDown: false});

        }

    }

    componentWillUnmount(){

        document.removeEventListener('click', this.handleClickOutside, true);

    }


    loadTrendingHomeSections(city) {

        axios.get(baseUrl+"home_sections.json?groups[]=activities&name=Trending")
            .then(res => {

                // alert("trending")

                var items = this.state.trendingItems

                var filterExistingItems =  []

                this.setState({

                    homeSectionTrendingItems:res.data[0].activities
                })


                var homeSectionItems=res.data[0].activities
                homeSectionItems=homeSectionItems.filter((item)=>  (!item.submitRequest)&&item.isActive==1)


                if (city){

                    homeSectionItems=homeSectionItems.filter((item)=>  item.address&&item.address.city&&item.address.city.id==city)

                }



                for (var i=0;i<items.length;i++){

                    var exists=false

                    for (var j=0;j<homeSectionItems.length;j++) {

                        if (items[i].id==homeSectionItems[j].id){

                            exists = true
                            // alert("equal "+items[i].title)

                        }

                    }


                    if (!exists){

                        filterExistingItems.push(items[i])

                    }

                }


               items =  homeSectionItems.concat(filterExistingItems)

                // console.log("trending section received")
                // console.log(res.data)
                // console.log(items)

                this.setState({

                    trendingItems :items

                });

            })


    }

    selectCity(e){


       this.setState({

          city : e.currentTarget.dataset.value,
           cityId : e.currentTarget.dataset.id,
           cityDropDown:false

       })



        this.fetchTrending(e.currentTarget.dataset.id)

        this.props.setCity(
            {
                // "latitude":data.latitude,
                // "longitude":data.longitude,
                "city": this.state.city,
                "cityId": this.state.cityId,

            })

    }


    fetchTrending(cityId){


        var  url =  baseUrl+"activities.json?groups[]=activities";

        if (cityId) {

            url = url + "&order[lifeTimeSales]=desc&order[views]=desc&isActive=1&address.city[]="+cityId;

        }

        else {
            // url = url + "&order[lifeTimeSales]=desc&order[views]=desc&isActive=1&address.city[]=11&address.city[]=12";


            url = url + "&order[lifeTimeSales]=desc&order[views]=desc&isActive=1";

        }


        // console.log("tending ulr " + url)


        axios.get(url)
            .then(res => {

                // console.log("trending items recieved")
                this.setState({

                    trendingItems : res.data

                });


                // console.log(res.data)

                this.props.trendingLoad(res.data)


                this.loadTrendingHomeSections(cityId)



            })






    }


    loadTrending(){


        this.fetchSearchData()


        if(this.props.trendingItems.length>0){

            this.state.trendingItems = this.props.trendingItems

        } else {

            if (this.state.cityId){

                this.fetchTrending(this.state.cityId)

            } else {

                this.fetchTrending(null)

            }
        }



    }


        loadSlides(){



        // if (this.props.slides.length>0){
        //
        //     this.state.slides = this.props.slides
        //
        // } else {

            axios.get(baseUrl+"slideshows.json?isActive=1")
                .then(res => {

                    this.setState({

                        slides: res.data,

                    });
                    // this.props.slidesLoad(res.data)




                })


        // }



    }

        componentDidMount (){

        window.scrollTo(0, 0)
        document.addEventListener('click', this.handleClickOutside, true);


        // if (getKey("cityId")){
        //
        //
        //
        //     this.setState({
        //
        //         city : getKey("city"),
        //         cityId : getKey("cityId"),
        //
        //     })
        //
        // }else {

            if (this.props.city){

                // console.log("city ip")
                // console.log(this.props.city)


                var cities = this.state.cities;
                // console.log(cities)

                for(var i=0;i< cities.length;i++){


                    if (cities[i].name === this.props.city) {


                        // alert("equal")
                        this.setState({

                            city: cities[i].name,
                            cityId: cities[i].id

                        })


                        this.props.setCity({

                            city: cities[i].name,
                            cityId: cities[i].id

                        })


                    }
                }

            }


        // }

        this.loadSlides()
        this.loadTrending()

        this.loadTrendingHomeSections()





    }


    fetchSearchData(){


        axios.get(baseUrl+"categories.json?groups[]=list&level=1&isActive=1")
            .then(res => {
                this.setState({

                    catItems: res.data,

                });
            })

        axios.get(baseUrl+"days.json?groups[]=activity-detail")
            .then(res => {
                this.setState({

                    days: res.data,

                });


            })


        axios.get(baseUrl+"cities.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    cities: res.data,

                });


            })
    }


    showCityDropDown() {
        this.setState({cityDropDown: !this.state.cityDropDown});
    }
    toggle() {
        this.setState({modal: !this.state.modal});
    }

    render() {


        return (
            <div className={"homepage-section-wl"}>

                <MetaTags>

                    <title>Happy-Weekends: Find Your Perfect Weekend In UAE</title>
                    <meta name="description" content="Happy Weekends is all about making your weekends special. We curate distinctive, entertaining and interesting experiences to make your weekends standout and become more memorable. Our vision is to help you live a more balanced, meaningful and happy life." />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://www.happy-weekends.com/" />
                            <meta property="og:title" content="Happy-Weekends: Find Your Perfect Weekend In UAE" />
                                <meta property="og:description" content="Happy Weekends is all about making your weekends special. We curate distinctive, entertaining and interesting experiences to make your weekends standout and become more memorable. Our vision is to help you live a more balanced, meaningful and happy life." />
                                    <meta property="og:image" content="/cover-image.jpg" />

                                        <meta property="twitter:card" content="summary_large_image" />
                                            <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                                                <meta property="twitter:title" content="Happy-Weekends: Find Your Perfect Weekend In UAE" />
                                                    <meta property="twitter:description" content="Happy Weekends is all about making your weekends special. We curate distinctive, entertaining and interesting experiences to make your weekends standout and become more memorable. Our vision is to help you live a more balanced, meaningful and happy life." />
                                                        <meta property="twitter:image" content="/cover-image.jpg" />




                </MetaTags>

                <div id={"covid-strip"} className={"pt-3 pb-3 pl-5 pr-5"}>
                    <img src={InfoTool}></img>Due to <span>COVID-19</span> most of our experiences are on hold. Please feel free to submit request and we will inform you when the experience is available.
                </div>

                 <SliderHome  slides={this.state.slides} className={"home-slides-section"}/>


                {this.state.slides.length > 0 && <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-lg-11 text-center perfect-weekened">
                            <h1>Find your perfect weekend in <a className={"country-dropdown-a"}
                                onClick={this.showCityDropDown}>{this.state.city ? this.state.city : " UAE"}<img
                                src={ArrowDown} alt=""/>
                                {this.state.cityDropDown && <div className="country-dropdown">
                                    <ul>

                                        {this.state.city && <li data-id={null} data-value={""}
                                            onClick={this.selectCity.bind(this)}><span  className={"country-dropdown-a-list-item"}>{"UAE"}</span></li> }

                                        {this.state.cities.filter((item)=> item.id!=this.state.cityId).map((item) =>
                                            <li data-id={item.id} data-value={item.name}
                                                onClick={this.selectCity.bind(this)}><span  className={"country-dropdown-a-list-item"}>{item.name}</span></li>
                                        )
                                        }
                                        </ul>
                                    <div className="arrow-up"></div>
                                </div>
                                }
                            </a></h1>
                            <p>Discover the best activities & weekend plans tailored to your taste.</p>
                            <Button className="perfect-btn" onClick={this.toggle}>Plan my weekend</Button>
                        </div>
                    </div>
                </div>
                }



                <Fragment>
                    <section className="tranding-section">
                        <div className="container">
                            <div className="row mb-3 justify-content-between">
                                <div className="col-lg-12">
                                    <h2><img width="30px" src={TrandingIcon} alt=""/> Trending</h2>
                                </div>

                            </div>
                            <div className="row">
                                {
                                    this.state.trendingItems.map((item, index) =>

                                        (index < 6) && <TrendingSectionItem item={item}/>

                                    )
                                }

                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <Link className="seeall" to={"/sections/trending"}>
                                        SEE ALL <img src={ArrowRight} alt=""/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>

                </Fragment>




                 <section className={"PersonalisedWeekends   "}>
                    <div className="container">
                        <PersonalisedWeekends/>

                        {this.state.slides.length>0 &&
                        <Suspense fallback={<div>Loading...</div>}>
                            <HomeSections/>
                        </Suspense>
                        }
                    </div>
                </section>


                <NewsLetter/>
                <section className="letsplan">
                    <div className="container">
                        <div className="lets-plan-title row title-row justify-content-center">
                            <div className="col-lg-10 text-center">
                                <h2>Let’s plan your weekend</h2>
                                <p>Tell us your choice of days and mood, we’ll show you ideas you’ll love to discover</p>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="white-box">
                                    {this.state.cities.length>0 && this.state.catItems.length>0 && this.state.days.length>0 &&

                                    <PlanWeekendSection days={this.state.days} catItems={this.state.catItems}
                                                        cities={this.state.cities}/>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <Testimonial/>

                {this.state.trendingItems.length > 0 &&
                <Suspense fallback={<div>Loading...</div>}>
                    <CategoryScrollSections/>
                </Suspense>
                }

                { this.state.cities && this.state.catItems && this.state.days &&

                <ShowPlanWeekend show={this.state.modal}  onHide={() => this.setState({modal: false})}
                                                                                                 days={this.state.days} catItems={this.state.catItems} cities={this.state.cities}
                />

                }
                <SaveWeekend/>
          <GetApp/>
            </div>
        )
    }
}




function ShowPlanWeekend(props) {


    return (

        <Fragment>

            <Modal
                size="lg"
                className={"custom-modal-popup "}
                aria-labelledby="contained-modal-title-vcenter"
                centered

                show={props.show} onHide={props.onHide}
            >
                <button onClick={props.onHide} className="close-btn-popup"><i class="fas fa-times"></i></button>
                <div className="row py-3 title-row justify-content-center">
                    <div className="col-lg-12 text-center">
                        <h2>Let’s plan your weekend</h2>
                        <p>Tell us your choice of days and mood, we’ll show you ideas you’ll love to discover</p>
                    </div>
                </div>
                <div className="row title-row justify-content-center">
                    <div className="col-lg-12">
                        <div className="white-box py-0">
                            <PlanWeekendPopUp days={props.days} catItems={props.catItems} cities={props.cities} />
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}



const mapStateToProps = state => {
    return {

        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        userDetail: state.userDetail,
        isLoggedIn:state.isLoggedIn,
        location : state.location,
        city : state.city,
        cityId : state.cityId,
        trendingItems : state.trendingItems,
        slides : state.slides,

    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        setLocation: (item) => dispatch(actionCreator.setLocation(item)),
        setCity: (item) => dispatch(actionCreator.setCity(item)),
        slidesLoad : (item) => dispatch(actionCreator.slidesLoad(item)),
        trendingLoad : (item) => dispatch(actionCreator.trendingLoad(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(Home);
