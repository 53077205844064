import React, {Component, Fragment} from 'react';
import Search from '../img/search.svg';
import Shopingcart from '../img/shopping-cart-alt.svg';
// import Logo from '../img/logo.png';
import LogoBeta from '../img/happyweekends-beta-logo.png';

import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'

import {Redirect} from 'react-router-dom';
import axios from 'axios';
import  {PersonOutline as UserIcon } from '@material-ui/icons'

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import {Navbar, Nav, NavDropdown, NavItem, Button,ButtonGroup} from 'react-bootstrap'
import mobileicon from '../img/mobile-icon.png';
import Modal from 'react-bootstrap/Modal'
import NavImg from '../img/blog-listing.jpg';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';


import { withStyles } from '@material-ui/core/styles';

import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";

import  history from '../History/history'
import {logOutUser} from '../LocalStorage/user'
// import { useHistory } from "react-router-dom";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {baseUrl,baseImgUrl} from  '../Util/Constants'



class  NavigationNew extends Component{



    constructor(props){
        super(props)
        this.state = {

            menuItems : [],
            showSearchBox : false,
            displayMenu: false,
            displayMegaMenu: false,
            showItems : [],
            displayMobileMenu: false,


        };

        this.toggleSearchBox = this.toggleSearchBox.bind(this)
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.showMegaMenu = this.showMegaMenu.bind(this);
        this.hideMegaMenu = this.hideMegaMenu.bind(this);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);

        this.logOut = this.logOut.bind(this);
        this.goToContactUs = this.goToContactUs.bind(this);
        this.goToProfile = this.goToProfile.bind(this);
        this.goToBookings = this.goToBookings.bind(this);
        this.getTotalItem = this.getTotalItem.bind(this);

        this.goToCheckout = this.goToCheckout.bind(this);


        this.goToLogin = this.goToLogin.bind(this);
        this.goToRegister = this.goToRegister.bind(this);
        this.searchBoxClick = this.searchBoxClick.bind(this);
        this.checkIfLogin = this.checkIfLogin.bind(this);


    }



    checkIfLogin(){



        if (this.props.isLoggedIn||this.props.isGuest){


            history.push("/checkout")
        } else {


            this.props.loginPopUp(true)

        }



    }


    getTotalItem(priceOption){

        var total = 0;

        for (var i=0;i<priceOption.length;i++){

            if (priceOption[i].discountedPrice)
                total = total + priceOption[i].quantity*priceOption[i].discountedPrice
            else
                total = total + priceOption[i].quantity*priceOption[i].price


        }


        return total
    }

    showMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true  });
    }

    hideMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: false  });
    }


    showMegaMenu(event) {
        event.preventDefault();
        this.setState({ displayMegaMenu: true  });
    }

    hideMegaMenu(event) {
        event.preventDefault();
        this.setState({ displayMegaMenu: false  });
    }

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }


    logOut(){

        logOutUser()
        this.props.logOut()
        history.push("/");

    }

    goToContactUs(){

        // this.props.history.push("/contact");
        // return <Redirect to="/contact" />;


        history.push("/contact")


    }

    goToVendorDashboard(){

        // this.props.history.push("/contact");
        // return <Redirect to="/contact" />;

        history.push("/vendor/dashboard")


    }

    goToLogin(){

        history.push("/login")

    }
    goToProfile(){

        history.push("/customer/dashboard/profile")

    }

    goToRegister(){

        history.push("/register")

    }

    goToCheckout(){

        history.push("/checkout/")

    }

    goToBookings(){

        history.push("/customer/dashboard/bookings")

    }

    toggleSearchBox(e){







        console.log("toggle clicked")

        e.stopPropagation()

        e.preventDefault()

        console.log(e)

        // if (e.currentTarget.dataset.type==="search-click")

        this.setState({

            showSearchBox : !this.state.showSearchBox

        })
    }

    searchBoxClick(e){

        e.stopPropagation()
        e.preventDefault()

        console.log("search clicked")


    }




    toggleMobileMenu(){

        this.setState({

            displayMobileMenu : !this.state.displayMobileMenu

        })
    }



    lastHoverItemId={}

    toggleSubMenu(e){

        let showItemsNew = this.state.showItems.slice();
        showItemsNew[e.currentTarget.dataset.id] = !showItemsNew[e.currentTarget.dataset.id];

        if (this.lastHoverItemId) {

            showItemsNew[this.lastHoverItemId] = !showItemsNew[this.lastHoverItemId];

        }
        this.setState({ showItems: showItemsNew });
        this.lastHoverItemId =  e.currentTarget.dataset.id;

    }

    componentDidMount (){


        axios.get(baseUrl+"categories.json?groups[]=list&level=1&isActive=1" )
            .then(res => {
                const persons = res.data;
                this.setState({ menuItems : persons });

                this.props.setCategories(res.data)
                // console.log("categories")
                //
                // console.log(persons)
            })
    }


    render() {


        return <Fragment>
            <div id={"navbar-container-sticky"} className={"navbar-container-sticky"}>
                <div id={"navbar"} className="web-nav">
                    <Navbar  collapseOnSelect expand="lg" bg="light" variant="light">
                        <Navbar.Brand>
                            <Link to={"/"}>
                                <img src={LogoBeta} alt="logo"/>
                            </Link>

                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>

                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">

                                <NavDropdown title="Activities & Weekends" id="collasible-nav-dropdown"
                                             className={"d-none"}>
                                    {
                                        this.state.menuItems.length  >0 &&  this.state.menuItems.filter((item)=> item.name!="Weekend Pass").map((item) =>
                                            <NavDropdown.Item key={"menu-"+
                                            item.id}>

                                                {item.name}

                                            </NavDropdown.Item>
                                        )
                                    }

                                </NavDropdown>
                                <div onMouseEnter={this.showMegaMenu}
                                     onMouseLeave={this.hideMegaMenu} className="custom-dropdown dropdown nav-item"
                                     style={{}}>
                                    <div className="button"> Activities & Weekends <i className="fas fa-chevron-down"></i>
                                    </div>

                                    {this.state.displayMegaMenu ? (

                                            <div className={"custom-dropdown "} style={{}}>
                                                <div className={"custom-dropdown-container"} style={{}}>

                                                    <div className={"menu-level-1 menu-column"} style={{}}>
                                                        <div>
                                                            <ul>
                                                                {
                                                                    this.state.menuItems.filter((item)=> item.name!="Weekend Pass").map((item, index) =>
                                                                        <li
                                                                        >
                                                                            <Link key={"menu-level-"+index} to={"/category/" + item.slug}
                                                                                  data-id={item.id}
                                                                                  onMouseOver={this.toggleSubMenu.bind(this)}

                                                                                  item={item}>
                                                                                <img src={baseImgUrl + item.image} alt=""/>{item.name}
                                                                            </Link>
                                                                            <span><i
                                                                                className="fas fa-chevron-right"></i></span>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>

                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.menuItems.filter((item)=> item.name!="Weekend Pass").map((item) =>

                                                            <Fragment>
                                                                {item.children.length > 0 &&
                                                                <div
                                                                    className={this.state.showItems[item.id] ? "menu-side-column menu-column" : "d-none menu-side-column menu-column"}
                                                                    key={item.id} style={{}}>

                                                                    <div className="sub-cat-block">
                                                                        <h3 className="cat-name">{item.name}</h3>
                                                                        <ul>
                                                                            {item.children.map((subItem) =>

                                                                                <li>
                                                                                    <Link className="active"
                                                                                          to={"/category/" + subItem.slug}>{subItem.name}</Link>
                                                                                </li>
                                                                            )
                                                                            }

                                                                            { item.menuActivities.map((activityItem) =>

                                                                                <li>
                                                                                    <Link className="active"
                                                                                          to={"/activity/" + activityItem.slug}>{activityItem.title}</Link>
                                                                                </li>

                                                                            )}




                                                                        </ul>

                                                                    </div>
                                                                </div>
                                                                }
                                                            </Fragment>

                                                        )}

                                                    {this.state.menuItems.map((item) =>

                                                        <Fragment>

                                                        <div className={this.state.showItems[item.id] ? "menu-side-column menu-column-img" : "d-none menu-side-column menu-column"}
                                                             key={item.id} >


                                                            { item.children.length > 0 ?
                                                                <h3 className="cat-name"> &nbsp;</h3>
                                                                :
                                                                <h3 className="cat-name"> {item.name}</h3>

                                                            }
                                                            <div className="sub-cat-block">

                                                                <Link to={"/category/"+item.slug}> <img className="img-fluid rounded" src={baseImgUrl+item.menuImage} alt=""/></Link>

                                                            </div>
                                                        </div>


                                                        </Fragment>
                                                    )
                                                    }
                                                </div>


                                            </div>

                                        ) :
                                        (
                                            null
                                        )
                                    }

                                </div>


                                <NavItem ><Link to="/corporate">Corporate Services</Link></NavItem>
                                <NavItem ><Link to={"/partner-contact"}>Become A Host</Link></NavItem>


                                {this.state.menuItems.filter((item)=>item.name=="Weekend Pass").length>0&&  <NavItem ><Link to={"/weekend-passes"}>Weekend Pass</Link></NavItem>}

                            </Nav>
                            <div className="right-nav">
                                <SearchPopover/>

                                <button  className="shoping-cart">
                                    <div onClick={this.checkIfLogin} ><img src={Shopingcart} alt="Shoping Cart"/></div>


                                    <div className="dropdown-content">

                                        <ul>
                                            { this.props.salesQuote && this.props.salesQuote.cartItems.length > 0 ?

                                                <>

                                                    { this.props.salesQuote.cartItems.map((item,index,{length})=>


                                        <>
                                   {    (item.itemType==0)?
                                                            <Link to={"/activity/" + item.activity.slug}
                                                                  className={(index + 1 == length) ? "cart-item border-none" : "cart-item"}>
                                                                <div className="media">
                                                                    <div className="media-left">


                                                                        <img
                                                                            src={baseImgUrl + item.activity.images[0].image}
                                                                            width={150} height="auto"
                                                                            className="img-fluid"
                                                                            alt={item.activity.title}/>

                                                                    </div>
                                                                    <div className="media-body">
                                                                        <div className="left">
                                                                            <h4 className="media-heading">
                                                                                {item.activity.title}
                                                                            </h4>
                                                                            <h5 className="media-subheading">

                                                                                {item.cartPriceOptions.map((option, index, {length}) =>

                                                                                    <><span
                                                                                        className="bag-qty">{option.quantity}</span>
                                                                                        <span
                                                                                            className="bag-close"> x</span> AED <span
                                                                                        className="bag-price">{option.discountedPrice ? option.discountedPrice : option.price}</span>

                                                                                        {index + 1 == length ? "" : ","}

                                                                                    </>
                                                                                )}


                                                                            </h5>
                                                                        </div>
                                                                        <div className="right">
                                                                            <div
                                                                                className="price-wrapper">AED {item.total}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>:
                                       <Link to={"/weekend-detail/" + item.bundle.slug}
                                       className={(index + 1 == length) ? "cart-item border-none" : "cart-item"}>
                                       <div className="media">
                                       <div className="media-left">


                                       <img
                                       src={baseImgUrl + item.bundle.listingCoverImage}
                                       width={150} height="auto"
                                       className="img-fluid"
                                       alt={item.bundle.name}/>

                                       </div>
                                       <div className="media-body">
                                       <div className="left">
                                       <h4 className="media-heading">
                                       {item.bundle.name}
                                       </h4>
                                       <h5 className="media-subheading">

                                               <span
                                               className="bag-qty">{item.bundle.itemCount}
                                               </span>
                                               <span
                                                   className="bag-close"> x</span> Items



                                       </h5>
                                       </div>
                                       <div className="right">
                                       <div
                                       className="price-wrapper">AED {(item.total)}</div>
                                       </div>
                                       </div>
                                       </div>
                                       </Link>



                                   }
    </>






                                                    )
                                                    }
                                                </>

                                                :
                                                <Link to={""} className="cart-item border-none">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div className="left">
                                                                <h4 className="media-heading">
                                                                    Your cart is empty!
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Link>
                                            }
                                        </ul>


                                    </div>

                                </button>

                                {this.props.isLoggedIn && !this.props.isGuest && <NavDropdown title={<UserIcon/>} id="basic-nav-dropdown">
                                    <NavDropdown.Item >
                                        <span className="font-weight-bold">Hello {this.props.userDetail && (this.props.userDetail.firstName+" "+this.props.userDetail.lastName)}  </span>
                                        <span>{this.props.userDetail && this.props.userDetail.email}</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item onClick={this.goToProfile}>Profile</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.goToBookings}>My Booking</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    {this.props.userDetail&&this.props.userDetail.isHost &&  <NavDropdown.Item onClick={this.goToVendorDashboard}>Host Dashboard</NavDropdown.Item>}
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item onClick={this.goToContactUs}>Contact Us</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.logOut}>Logout</NavDropdown.Item>

                                </NavDropdown>
                                }
                                {!this.props.isLoggedIn && <NavItem className="bordernone ml-2 mr-2" href="#features">
                                    <Link className="login nav-link" to={"/login"}>Login</Link>
                                </NavItem>
                                }
                                {!this.props.isLoggedIn && <NavItem className="bordernone" href="#features">
                                    <Link className="signup nav-link" to="/register">Sign Up</Link>

                                </NavItem>
                                }
                            </div>

                        </Navbar.Collapse>

                    </Navbar>

                    {this.props.loading && <LinearIndeterminate/>}

                </div>


                <div className="mobile-nav">
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                        <Navbar.Brand>
                            <Link to={"/"}>
                                <img src={LogoBeta} alt="logo"/>
                            </Link>
                        </Navbar.Brand>


                        <div className="right-nav">

                            {/*<button   className="search">*/}
                            {/*<img src={Search} alt="Search Icon"/>*/}

                            {/*<div className="search-drop search-drop-mobile" href="#action/3.1">*/}
                            {/*<form action={"/search"} method={"GET"} autoComplete="off">*/}
                            {/*<input name={"keyword"} type="text"*/}
                            {/*placeholder="Search for experiences"/>*/}
                            {/*<img src={Search} alt="search"/>*/}
                            {/*</form>*/}
                            {/*</div>*/}


                            {/*</button>*/}

                            <SearchPopover/>
                            <button className="shoping-cart">
                                <div onClick={this.checkIfLogin} ><img src={Shopingcart} alt="Shoping Cart"/></div>

                                 <div className="dropdown-content">

                                    <ul>
                                        {  this.props.salesQuote&&this.props.salesQuote.cartItems.length > 0 ?

                                            <>
                                                { this.props.salesQuote.cartItems.map((item,index,{length})=>

                                                    <>
                                                        {(item.itemType == 0) &&

                                                        <Link to={"/activity/" + item.activity.slug}
                                                              className={(index + 1 == length) ? "cart-item border-none" : "cart-item"}>
                                                            <div className="media">
                                                                <div className="media-left">


                                                                    <img
                                                                        src={baseImgUrl + item.activity.images[0].image}
                                                                        width={150} height="auto" className="img-fluid"
                                                                        alt={item.activity.title}/>

                                                                </div>
                                                                <div className="media-body">
                                                                    <div className="left">
                                                                        <h4 className="media-heading">
                                                                            {item.activity.title}
                                                                        </h4>
                                                                        <h5 className="media-subheading">

                                                                            {item.cartPriceOptions.map((option, index, {length}) =>

                                                                                <><span
                                                                                    className="bag-qty">{option.quantity}</span>
                                                                                    <span
                                                                                        className="bag-close"> x</span> AED <span
                                                                                    className="bag-price">{option.discountedPrice ? option.discountedPrice : option.price}</span>

                                                                                    {index + 1 == length ? "" : ","}

                                                                                </>
                                                                            )}


                                                                        </h5>
                                                                    </div>
                                                                    <div className="right">
                                                                        <div
                                                                            className="price-wrapper">AED {(item.total)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <a className="close-icon" href="#"><i className="fas fa-times"/></a> */}
                                                        </Link>
                                                        }
                                                        </>
                                                )
                                                }
                                            </>

                                            :
                                            <li className="cart-item border-none">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <div className="left">
                                                            <h4 className="media-heading">
                                                                Your cart is empty!
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <a className="close-icon" href="#"><i className="fas fa-times" /></a> */}
                                            </li>
                                        }
                                    </ul>


                                </div>


                            </button>

               {this.props.isLoggedIn && <NavDropdown title={<UserIcon/>} className="user-icon" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">
                                    <span className="font-weight-bold">Hello {this.props.userDetail && (this.props.userDetail.firstName+" "+this.props.userDetail.lastName)}  </span>
                                    <span>{this.props.userDetail && this.props.userDetail.email}</span>
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={this.goToProfile}>Profile</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.goToBookings}>My Booking</NavDropdown.Item>
                   <NavDropdown.Divider/>
                   {this.props.userDetail&&this.props.userDetail.isHost &&  <NavDropdown.Item onClick={this.goToVendorDashboard}>Host Dashboard</NavDropdown.Item>}

                   <NavDropdown.Divider/>
                                <NavDropdown.Item onClick={this.goToContactUs}>Contact Us</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.logOut}>Logout</NavDropdown.Item>

                            </NavDropdown>
                            }
                            {/* {!this.props.isLoggedIn && <NavItem className="bordernone ml-2 mr-2" href="#features">
                                <Link className="login nav-link" to={"/login"}>Login</Link>
                            </NavItem>
                            }
                            {!this.props.isLoggedIn && <NavItem className="bordernone" href="#features">
                                <Link className="signup nav-link" to="/register">Sign Up</Link>

                            </NavItem>
                            } */}

                            <button onClick={this.toggleMobileMenu} style={{backgroundColor:"transparent", border:"none" }}>
                                {/* <MenuIcon className="menu-icon"  style={{ fontSize: 40 }} /> */}
                                <img className="menu-icon" src={mobileicon} alt="mobile icon" />
                            </button>
                        </div>

                        {this.state.displayMobileMenu
                        &&
                        <ShowMobileMenu isLoggedIn={this.props.isLoggedIn}
                                        goToLogin={() => this.goToLogin()} goToRegister={() => this.goToRegister()} menu={this.state.menuItems}
                                        show={this.state.displayMobileMenu}
                                        onHide={() => this.setState({displayMobileMenu: false})}  />}


                    </Navbar>
                </div>
            </div>

        </Fragment>
    }
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useMenuDialogStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));


function ShowMobileMenu(props) {

    const classesMenu = useStylesMobileMenu();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const classes = useMenuDialogStyles();
    const [open, setOpen] = React.useState(false);

    const {menu, show, onHide} = props;

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event) => {

        event.stopPropagation()

        // setOpen(false);

        onHide(false)


    };


    const handleLogin = (event) => {

        event.stopPropagation()

        // setOpen(false);

        onHide(false)
        history.push("/login")


    };


    const handleRegister = (event) => {

        event.stopPropagation()

        // setOpen(false);

        onHide(false)

        history.push("/register")

    };


    const handleMaxWidthChange = event => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = event => {
        setFullWidth(event.target.checked);
    };
    return (


        <Modal

            show={props.show}

            onHide={props.onHide}
            className={"custom-modal-popup"}

        >
            {!props.isLoggedIn && <div className={"d-flex flex-column login-signup-option"}>
                <ButtonGroup>
                    <Button size="lg" block onClick={handleLogin.bind(this)} to={"/login"}>Login</Button>
                    <Button size="lg" block onClick={handleRegister.bind(this)} to={"/register"}>Sign Up</Button>
                </ButtonGroup>


            </div>
            }
            <div className="row py-3 title-row justify-content-center mobile-menu-row">

                <div className="col mobile-menu">


                    <div className={classesMenu.root}>
                        {
                            props.menu.filter((item)=> item.name=="Weekend Pass").map((catitem, index) =>
                                <ExpansionPanel className="mobile-view weekend-panel" expanded={expanded === 'panel-23232'+catitem.id} onChange={handleChange('panel-'+catitem.id)}>
                            <ExpansionPanelSummary


                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                style={{ border : "none!important"}}
                            >
                                <Link onClick={handleClose.bind(this)} className="active"
                                      to={"/weekend-passes"}>
                                    <img style={{width:"50px",height:"50px"}} src={baseImgUrl + catitem.menuImage} alt=""/>{catitem.name}

                                </Link>

                            </ExpansionPanelSummary>


                        </ExpansionPanel>
                            )}

                        {

                            props.menu.filter((item)=> item.name!="Weekend Pass").map((item, index) =>

                                <ExpansionPanel className="mobile-view" expanded={expanded === 'panel-'+item.id} onChange={handleChange('panel-'+item.id)}>
                                    <ExpansionPanelSummary
                                        expandIcon={<i className="fas fa-chevron-down"></i>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        style={{ border : "none!important"}}
                                    >
                                        <Link onClick={handleClose.bind(this)} className="active"
                                              to={"/category/" + item.slug}>
                                            <img style={{width:"50px",height:"50px"}} src={baseImgUrl + item.image} alt=""/>{item.name}

                                        </Link>

                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography >
                                            <ul className="submenu-mobile">
                                                {
                                                    item.children.map((subItem, index) =>
                                                        <li key={subItem+"-"+index}>
                                                            <Link  onClick={handleClose.bind(this)} className="active"
                                                                   to={"/category/" + subItem.slug}>{subItem.name}</Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        }

                    </div>
                </div>

            </div>


        </Modal>
    );
}


const useStylesMobileMenu = makeStyles(theme => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},

}));


const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'white',
        borderBottom: 'none',
        marginBottom: "0px",
        border : "none!important",
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',

        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: 'rgba(0, 0, 0, .03)',

    },
}))(MuiExpansionPanelDetails);





// const useStyles = makeStyles(theme => ({
//     root: {
//         width: '100%',
//         '& > * + *': {
//             marginTop: theme.spacing(2),
//         },
//     },
// }));

function LinearIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgress style={{backgroundColor:"#00adee"}}  />
        </div>
    );
}





function SearchPopover() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);





    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>

            <button onClick={handleClick}  data-type="search-click" className="search">
                <img   src={Search} alt="Search Icon"/>
            </button>

            {/*<Button aria-describedby={id} variant="contained" color="primary" >*/}
            {/*Open Popover*/}
            {/*</Button>*/}
            <Popover

                id={id}
                className="search-drop"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div >
                    <Typography className={classes.typography}>

                        <form action={"/search"} method={"GET"} autoComplete="off">
                            <input autoFocus={true} name={"keyword"} type="search"
                                   placeholder="Search for experiences"/>
                            {/*<img src={Search} alt="search"/>*/}
                        </form>

                    </Typography>
                </div>
            </Popover>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    typography: {

    },
}));




const mapStateToProps = state => {
    return {

        age: state.age,
        cartItems: state.cartItems,
        salesQuote: state.salesQuote,

        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        isGuest: state.isGuest


    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        logOut: () => dispatch(actionCreator.logOut()),
        removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        setCategories : (item) => dispatch(actionCreator.setCategories(item)),


    };
};



export default connect(
    mapStateToProps,
    mapDispachToProps
)(NavigationNew);

