import React, { Component } from 'react';
import { Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags';


class  Faq extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render (){
        return(
            <div className="bg-gray">
                <MetaTags>

                    <title> Happy Weekends related question answered here | FAQ</title>
                    <meta name="title" content=" Happy Weekends related question answered here | FAQ" />
                    <meta name="description" content=" All your questions about Happy Weekends are answered here. Browse through these and we are sure we would have answered most of your questions. If there is anything that we haven’t answered, fill out the form or drop us a mail at care@happy-weekends.com. " />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content=" Happy Weekends related question answered here | FAQ" />
                    <meta property="og:description" content=" All your questions about Happy Weekends are answered here. Browse through these and we are sure we would have answered most of your questions. If there is anything that we haven’t answered, fill out the form or drop us a mail at care@happy-weekends.com. " />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content="Happy Weekends related question answered here | FAQ" />
                    <meta property="twitter:description" content=" All your questions about Happy Weekends are answered here. Browse through these and we are sure we would have answered most of your questions. If there is anything that we haven’t answered, fill out the form or drop us a mail at care@happy-weekends.com. " />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>

                <section className="container-fluid faq-top-img">
                    <div className="container">
                    <div className="row text-center d-block">
                        <h2 className="banner-font">FAQs</h2>
                    </div>
                    </div>
                </section>
                <section className="faq">
                    <div className="container vender-background-image1">
                    <div className="row text-center">
                        <div className="col-md-10 accodiyan_block text-left lorem-font">
                        <div className="main-loremclass">
                            <h5 className="lorem_heading"> How is Happy Weekends different than other coupon/deal-based marketplaces? </h5>
                            <p className="paregraf">Happy Weekends exists to make the world a happier place. All our experiences are built around you. Which is why we think what you’d like to experience, and curate the best possible experiences every weekend to make that happen for you. So you won’t find us doing anything on a weekday – we’re all about the weekend, and making that an enjoyable experience for you, and your community.</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading"> I’ve an interesting idea that I would like to submit for Happy-Weekends. Is it allowed?</h5>
                            <p className="paregraf">Yup. We’re always on the lookout for ways to transform how people spend weekends. You can either fill out the form below, or email your ideas to newidea@happy-weekends.com</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading">What are the advantages of buying an experience through Happy Weekends?</h5>
                            <p className="paregraf">Our range includes hundreds of hand-picked experiences and options which means that Happy Weekends is the only platform you’ll ever need to spend an unforgettable weekend. Our team carefully combs through the many experiences to curate only the best for you. We believe in quality over quantity, so while you may find fewer experiences here, you can be sure that they’ll be the absolute best.</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading">Can I transfer my coupon to somebody else?</h5>
                            <p className="paregraf">Yes absolutely. If for some reason, you can’t enjoy the experience you’ve already paid for, you’re more than welcome to let someone else try it. Just remember though, the person you give it to, has to have the coupon code.</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading">Can I cancel my experience post buying?</h5>
                            <p className="paregraf">The cancellation policy depends entirely on the experience you’re buying. The detail of every experience lays out the cancellation policy. Having said that, if you cancel any experience 48 hours before, you get a full refund, no questions asked.</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading">Can I reschedule my experience, once booked?</h5>
                            <p className="paregraf">Yes of course. You have a 48 hour window to cancel and re-schedule. But again, since every experience comes with its own set of things to watch out for, we encourage you to read through the detail on your voucher carefully.</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading">What do I do if I’ve booked for an experience at home, and nobody shows up?</h5>
                            <p className="paregraf">That should never, ever happen. But in the rare possibility that it does, the first thing you should do is get in touch with our friendly helpdesk, and we’ll try to get to the bottom of it. In the event that we cannot resolve an issue, you of course, will be refunded the entire amount that you paid.</p>
                        </div>
                        <div className="main-loremclass">
                            <h5 className="lorem_heading">Can I buy an experience during the weekdays?</h5>
                            <p className="paregraf">As the name suggests, we’re all about happy weekends. So no, our experiences are not available on the weekdays. They’re available from Thursday evening right till Saturday night. Of course, when there’s an extended weekend, you can be sure we’ll have something for that too.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Faq;