import React, {Component, Fragment, useState} from 'react';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Button} from 'react-bootstrap'

import {baseImgUrl} from '../Util/Constants'


class  PlanWeekendPopUp extends Component{


    constructor(props){

        super(props)
        this.state = {

            catItems : [],
            days : this.props.days,
            cities : [],
            resetValues : false,
            fullWeekend: true

        };


        this.resetDaySelection=this.resetDaySelection.bind(this)
        this.unCheckFullWeekend=this.unCheckFullWeekend.bind(this)

        this.handleSubmit=this.handleSubmit.bind(this)

    }


    handleSubmit = event => {


        // console.log(event.target)


        const data = new FormData(event.target);


        const cities = data.get("city[]")
        const categories = data.get("category[]")
        const days = data.get("day[]")



        // console.log(data)
        // console.log(cities)
        // console.log(days)
        // console.log(categories)


        // if (cities||categories||days){
        //
        // } else {
        //     event.preventDefault();
        //
        // }


    }

    responsiveValues = {
        1025 : {
            items : 5

        },
        1024 : {
            items : 3

        },
        0 : {
            items : 2

        }
    }




    unCheckFullWeekend(){



        this.setState({

            fullWeekend: false

        })


    }

    resetDaySelection(){



        var days = this.state.days


        this.setState({

            fullWeekend: true,
            resetValues: true,
            days : []

        })


        setTimeout(function() { //Start the timer

        this.setState({


            days : days

        })

        }.bind(this), 1)


    }

    render (){

        return(
            <Fragment>
                <form onSubmit={this.handleSubmit} action={"/search"} method={"GET"}>

                <div className="row planday popup-mood-slider">


                    <div className="col-lg-12 mb-3">
                                            <h2 className="mb-3">I'm in</h2>
                                            <div className="btn-days btn-group-toggle" data-toggle="buttons">

                                                {this.props.cities.map((item)=>

                                                <CityItem item={item} />
                                                )}

                                            </div>
                    </div>

                                        <div className="col-lg-12">
                                            <h2 className="mb-3">I want to plan for</h2>
                                            <div className="btn-days btn-group-toggle" data-toggle="buttons">

                                                {this.state.days.map((item,index)=>

                                                    <DayItem key={index} resetValues={this.state.resetValues} unCheckFullWeekend={() => this.unCheckFullWeekend()}  item={item} />
                                                )}

                                                <div onClick={this.resetDaySelection}  className={this.state.fullWeekend?"btn btn-secondary active":"btn btn-secondary"}>

                                                    <input   onClick={this.resetDaySelection}
                                                           checked={this.state.fullWeekend} value={null}
                                                           type="checkbox"    autoComplete="off"  />
                                                    Full Weekend
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row planday planday-mobile mt-4 mb-5 pb-4">
                                        <div className="col-lg-12">
                                            <h2 className="mb-3">And I’m in the mood to</h2>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mood-ul">
                                                <OwlCarousel mouseDrag={false} draggable={false}  rewind={true} merge={true} center={false} className="owl-theme" responsive={this.responsiveValues} margin={15} nav>

                                                {this.props.catItems.map((item)=>

                                                    <CategoryItem item={item} />
                                                )}
                                                </OwlCarousel>
                                            </div>
                                        </div>
                                    </div>


                <Button type={"submit"} className="play-weekend mb-3" >Plan my weekend</Button>
                </form>

            </Fragment>

        )
    }
}


function CityItem(props) {

    const [state, setState] = useState(false);

    function toggle() {
        setState(!state);
    }

    return(

        <div  onClick={toggle} className={state? "btn btn-secondary active":"btn btn-secondary"}>
            <input id={"city-"+props.item.id} checked={state} value={props.item.id} type="checkbox" name="city[]"  id="option1" autoComplete="off" /> {props.item.name}
        </div>
    )
}


function DayItem(props) {

    const [state, setState] = useState(false);


    function reset() {
        setState(props.resetValues);

    }

    function toggle() {
        setState(!state);
        props.unCheckFullWeekend()

    }
    return(

        <div  onClick={toggle} className={state? "btn btn-secondary active":"btn btn-secondary"}>
            <input id={"day-"+props.item.id} checked={state} value={props.item.id} type="checkbox" name="day[]"  id="option1" autoComplete="off"  /> {props.item.name}
        </div>
    )

}



function CategoryItem(props) {

    const [state, setState] = useState(false);

    function toggle() {
        setState(!state);
    }
    return(
        <div onClick={toggle}  className={state? "active item" : "item"}>
            <img src={baseImgUrl+props.item.image} alt="explore Option"/>
            <p>{props.item.name}</p>
            <span className="check-mark"><i className="fas fa-check"></i></span>
            <input style={{"display" :  "none"}} id={"category-"+props.item.id} checked={state} value={props.item.id} type="checkbox" name="category[]"  id="option1" autoComplete="off" />

        </div>
    )



}



export default PlanWeekendPopUp;
