import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup,Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import DetailIcon1 from '../img/detail-icon-1.png';
import ProductImg from '../img/at-the-top-dubai.png';
import DateFnsUtils from '@date-io/date-fns';
import PlusIcon from '../img/plus.png';
import MinIcon from '../img/min.png';
import Rating from '@material-ui/lab/Rating';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    KeyboardTicmePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {baseUrl,baseImgUrl} from  '../Util/Constants'


class  EditBundleCartPopUp extends Component{

    constructor() {

        super();

        this.state = {

            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            dateSelected : new Date(),
            activity: null,
            images: [],
            total: 0,
            priceOptions: [],
            bundle : null,
            selectedActivity: null,
            bundleItems: [],
            cartTotal : 0,
            totalDiscount : 0,


        }

        this.hidePopUp = this.hidePopUp.bind(this);
        this.hideEditCartPopUp= this.hideEditCartPopUp.bind(this)
        this.disableRandomDates = this.disableRandomDates.bind(this)
        this.disableWeekends = this.disableWeekends.bind(this)
        this.disableWeekDays = this.disableWeekDays.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.updateCartSubmit = this.updateCartSubmit.bind(this)
        this.loadInitialSelection=this.loadInitialSelection.bind(this)

        this.loadBundle=this.loadBundle.bind(this)


    }


    handleDateChange = date => {


        this.setState({

            dateSelected : date

        })

    };




    incrementQuantity(e){

        var bundle = this.state.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {

            // bundleItems[i].total=0
            // bundleItems[i].cartTotal=0

            let priceOptionNew = bundleItems[i].activity.priceOption;


            for (var j = 0; j < priceOptionNew.length; j++) {



                if (priceOptionNew[j].id == e.currentTarget.dataset.id) {


                    priceOptionNew[j].quantity = priceOptionNew[j].quantity + 1

                    var price = 0
                    var cartPrice = priceOptionNew[j].price


                    if(priceOptionNew[j].discountedPrice){

                        price = priceOptionNew[j].discountedPrice

                        // bundleItems[i].total=bundleItems[i].total+price
                    }else {

                        price = priceOptionNew[j].price

                        // bundleItems[i].total=bundleItems[i].total+price


                    }

                    bundleItems[i].total=bundleItems[i].total+priceOptionNew[j].price


                    this.setState({

                        total: this.state.total + price*(100 - bundleItems[i].discount)/100 ,
                        cartTotal : this.state.cartTotal + cartPrice


                    });


                }



            }

        }


        this.setState({

            bundleItems: bundleItems,
            bundleItem : bundle

        })





    }
    decrementQuantity(e){


        let bundle = this.state.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {


            let priceOptionNew = bundleItems[i].activity.priceOption;


            for (var j = 0; j < priceOptionNew.length; j++) {


                if ((priceOptionNew[j].id == e.currentTarget.dataset.id) && (priceOptionNew[j].quantity > 0)) {


                    priceOptionNew[j].quantity = priceOptionNew[j].quantity - 1


                    var price = 0
                    var cartPrice = priceOptionNew[j].price
                    if (priceOptionNew[j].discountedPrice) {

                        price = priceOptionNew[j].discountedPrice
                        // bundleItems[i].total=bundleItems[i].total - price
                    } else {

                        price = priceOptionNew[j].price

                        // bundleItems[i].total=bundleItems[i].total - price


                    }

                    bundleItems[i].total=bundleItems[i].total - priceOptionNew[j].price


                    this.setState({
                        total: Math.floor(this.state.total - price*(100 - bundleItems[i].discount)/100),
                        cartTotal : Math.floor(this.state.cartTotal - cartPrice)


                    });


                } else {

                }
            }
        }


        this.setState({

            bundleItems: bundleItems,
            bundle : bundle


        })

    }

    disableWeekDays(date) {

        var daysArray=[0,1,2,3,4,5,6]// 0- sunday, 6 saturday


        if (this.props.cartPopUpItem.activity.day && this.props.cartPopUpItem.activity.day.length>0 ){
            var days = this.props.cartPopUpItem.activity.day

            for (var i=0;i<days.length;i++) {


                if (days[i].name==="Thursday"){

                    daysArray=daysArray.filter((value) => value!=4)

                }

                else if (days[i].name==="Friday"){

                    daysArray=daysArray.filter((value) => value!=5)


                }

                else if (days[i].name==="Saturday"){

                    daysArray=daysArray.filter((value) => value!=6)

                }



            }


        }


        return (daysArray.indexOf(date.getDay())>-1)



    }



    disableWeekends(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    disableRandomDates() {
        return Math.random() > 0.7;
    }


    hideEditCartPopUp(){


        this.props.editBundleCartPopUp(false)

    }




    hidePopUp(){

        this.props.loginPopUp(false)
        this.props.loginCheckoutPopUp(false)


    }


    showLogin(){


        this.setState({

            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,

        })



    }

    showSignUp(){

        this.setState({
            showSignUp: true,
            showGuestSignUp: false,
            showLogin: false,
        })


    }

    showGuestSignUp(){

        this.setState({

            showSignUp: false,
            showGuestSignUp: true,
            showLogin: false,

        })
    }


    componentDidMount(){



        console.log(this.props.cartPopUpItem)
        if (this.props.cartPopUpItem) {

            console.log(this.props.cartPopUpItem)
            this.setState({

                bundle: this.props.cartPopUpItem.bundle

            })


            this.loadBundle(this.props.cartPopUpItem.bundle.slug)




        }

    }


    loadBundle(bundleSlug){

        axios.get(baseUrl+"bundles.json?groups[]=activities&slug="+bundleSlug)
            .then(res => {

                const persons = res.data;
                console.log(res.data)

                // this.setState({
                //
                //     bundle : persons[0],
                //     priceOptions : persons[0].priceOption
                //
                // });

                console.log("loaded bundle items")
                console.log(res.data)
                // setBundle (persons[0])

                this.setState({

                    bundle : persons[0],
                    priceOptions : persons[0].priceOption

                })


                this.loadInitialSelection()


                // this.setPriceOptions()
                //
                // this.loadInitialSelection()


            })

    }



    setPriceOptions(){

        let bundle = this.props.cartPopUpItem.bundle

        var bundleItems= bundle.bundleItems

        for (var i=0;i<bundleItems.length;i++) {

            let priceOptionNew = bundleItems[i].activity.priceOption;


            for (var j = 0; j < priceOptionNew.length; j++) {

                priceOptionNew[j].quantity = 0

            }

        }


        this.setState({

            bundleItems: bundleItems

        })

    }




    updateCartSubmit(){


        // console.log(this.state.bundle)
        // this.props.addBundleCartItem(this.state.bundle)


        var priceOptions=[];

        for (var i=0; i<this.state.bundle.bundleItems.length;i++){


            for (var j=0; j<this.state.bundle.bundleItems[i].activity.priceOption.length;j++){



                if (this.state.bundle.bundleItems[i].activity.priceOption[j].quantity>0) {

                    priceOptions.push(this.state.bundle.bundleItems[i].activity.priceOption[j]);

                }

            }

        }


        this.props.addCartItem(

            {
                "user":this.props.userDetail,
                "cartItem":{
                    "itemType":1,
                    "bundle": this.state.bundle,
                    'cartPriceOptions': priceOptions

                }
            }

        )


        this.props.editBundleCartPopUp(false)


    }


    loadInitialSelection(){


        //set quantity



        if (this.props.cartPopUpItem) {




            let bundle = this.state.bundle


            var bundleItems = bundle.bundleItems

            for (var i = 0; i < bundleItems.length; i++) {

                var total = 0;


                bundleItems[i].total = bundleItems[i].total


                for (var j = 0; j < bundleItems[i].activity.priceOption.length; j++) {

                    for (var k = 0; k < this.props.cartPopUpItem.cartPriceOptions.length; k++)

                    if (bundleItems[i].activity.priceOption[j].id==this.props.cartPopUpItem.cartPriceOptions[k].priceOptionId){

                        bundleItems[i].activity.priceOption[j].quantity = this.props.cartPopUpItem.cartPriceOptions[k].quantity


                    }



                    if (bundleItems[i].activity.priceOption[j].quantity > 0) {

                        // if (bundleItems[i].activity.priceOption[j].discountedPrice && bundleItems[i].activity.priceOption[j].discountedPrice > 0) {
                        //
                        //     total = total + bundleItems[i].activity.priceOption[j].quantity * bundleItems[i].activity.priceOption[j].discountedPrice
                        //
                        //
                        //     // bundleItems[i].total= total
                        //
                        // } else {

                            total = total + bundleItems[i].activity.priceOption[j].quantity * bundleItems[i].activity.priceOption[j].price

                            // bundleItems[i].total= total
                        // }
                    }


                    bundleItems[i].total= total


                }



            }


            this.setState({

                bundleItems: bundleItems,
                bundleItem: bundle,
                total : this.props.cartPopUpItem.total,
                cartTotal: this.props.cartPopUpItem.cartTotal

            })

        }


    }


    render (){

        return(


            <Modal   show={this.props.showEditBundleCartPopUp}

                     onHide={this.hideEditCartPopUp}
                     className={"custom-modal-popup popup-form bundle-pop-up-form"}
            >
                <button onClick={this.hideEditCartPopUp} className="close" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                <div className="row py-3 justify-content-center mobile-menu-row">
                    {this.state.bundle && <div className="mobile-menu edit-cart-block col-lg-12">
                        <div className="form-col-left pl-3 pr-3">
                            <h3 class="form-heading">Edit Cart</h3>
                            <div className="offers wl_scroll_fix_detail ">
                                <div className="block border-bottom pt-0 pl-3 pr-3">
                                    <div className="product-img-block">
                                        <div className="product-img">
                                            <img src={baseImgUrl + this.state.bundle.listingCoverImage}
                                                 className="img-fluid"
                                                 alt="Product detail"/>
                                        </div>
                                        <h2>{this.state.bundle.name}</h2>
                                    </div>
                                </div>

                                {this.state.bundle&&this.state.bundle.bundleItems &&
                                <div className="pro-detail edit-cart-bundle new-block padtop0">
                                    <div id="sticky-box">
                                        <div id="priceOptionForm">
                                            <div id="offers" className="right-section row display-sm-none">
                                                <Accordion
                                                    defaultActiveKey={this.state.bundle && this.state.bundle.bundleItems && this.state.bundle.bundleItems.length > 0 && this.state.bundle.bundleItems[0].activity.id}>

                                                    <div className="offers wl_scroll_fix_detail offers-new ">


                                                        {this.state.bundle.bundleItems.map((bundleItem, index) =>

                                                            <div className="block border-bottom ">
                                                                <div className="top-block new">
                                                                    <p className="mb-0 d-none">0 bought</p>
                                                                    <h4 className="pull-left">
                                                                        {bundleItem.activity.title}
                                                                    </h4>
                                                                    <h5 className="pull-right">AED {bundleItem.total}</h5>
                                                                </div>
                                                                <div className="left-side">
                                                                    <div className="offer-data-left">

                                                                        <p className="p1">
                                                                            <Accordion.Toggle as={Link} variant="link"
                                                                                              eventKey={bundleItem.activity.id}>
                                                                                Customise Here <i style={{
                                                                                marginLeft: "5px",
                                                                                color: "#999999"
                                                                            }} className="fas fa-chevron-down"></i>

                                                                            </Accordion.Toggle>
                                                                        </p>


                                                                        {bundleItem.activity.priceOption && bundleItem.activity.priceOption.map((item) =>

                                                                            <>
                                                                                <Accordion.Collapse
                                                                                    eventKey={bundleItem.activity.id}>
                                                                                    <div
                                                                                        className={"collapsible-content"}>
                                                                                        <p className="p2">{item.description}</p>

                                                                                        <div>
                                                                                            <button
                                                                                                className="value-button"
                                                                                                value="Decrease Value"
                                                                                                data-id={item.id}
                                                                                                data-price={item.id}
                                                                                                onClick={this.decrementQuantity.bind(this)}

                                                                                            ><img src={MinIcon} alt=""/>
                                                                                            </button>

                                                                                            <input dataprice="175"
                                                                                                   multiple=""
                                                                                                   name="priceOptionQuantity[]"
                                                                                                   className="number exists"
                                                                                                   readOnly=""
                                                                                                   type="text"
                                                                                                   id="number-1845"
                                                                                                   value={item.quantity}/>
                                                                                            <button
                                                                                                data-id={item.id}
                                                                                                data-price={item.id}
                                                                                                onClick={this.incrementQuantity.bind(this)}
                                                                                                className="value-button"
                                                                                                value="Increase Value">
                                                                                                <img src={PlusIcon}
                                                                                                     alt=""/>
                                                                                            </button>
                                                                                            <span className="span1">
                                                                                {/*AED {!item.discountedPrice && item.price}{item.discountedPrice && item.discountedPrice}*/}
                                                                                                AED { item.price}

                                                                                </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Collapse>

                                                                            </>
                                                                        )
                                                                        }

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                        }


                                                        <div className="order-total new block border-bottom">
                                                            <div className="row">
                                                                <div className="col-md-7">
                                                                    <p className="mb-0">Cart Total</p>
                                                                    <p className="small-text mb-0">Weekend Pass
                                                                        Discount <span
                                                                            style={{color: "#007dc4"}}>({Math.floor(((this.state.cartTotal - this.state.total) * 100) / this.state.cartTotal)}%)</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-5 text-right">
                                                                    <h5>AED {this.state.cartTotal}</h5>
                                                                    <h5>AED {Math.floor(this.state.cartTotal - this.state.total)}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="order-total new ">
                                                            <div className="row">
                                                                <div className="col-md-7">
                                                                    <p className="mb-0 total_pay">Total<span>(Inclusive of VAT)</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-5 text-right">
                                                                    <h5 className="total_sum">AED {Math.floor(this.state.total)}</h5>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </Accordion>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }


                                <div className="promo-block">
                                    <button onClick={this.updateCartSubmit} className="buy-now">UPDATE CART</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </Modal>

        )
    }
}






const mapStateToProps = state => {
    return {

        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        userDetail: state.userDetail,
        abondonCartItem : state.abondonCartItem,
        showEditBundleCartPopUp: state.showEditBundleCartPopUp,
        cartPopUpItem : state.cartPopUpItem,
        bundleItem : state.bundleItem,
        salesQuote : state.salesQuote

    };
};



const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        editBundleCartPopUp: (item) => dispatch(actionCreator.editBundleCartPopUp (item)),
        addBundleCartItem : (item) => dispatch(actionCreator.addBundleCartItem(item)),



    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(EditBundleCartPopUp);


