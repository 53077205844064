import React, {Component, Fragment} from 'react';
import './Slider.css';


import { Button, Col, Row, Carousel, CarouselItem} from 'react-bootstrap'
import {baseUrl,baseImgUrl} from  '../Util/Constants'


class SliderHome extends Component {



    constructor(props) {


        super(props)


    }


    componentDidMount (){


    }

    render() {
      return (
          <Fragment>
              <div className={"home-slideshow-top"}>
         <Carousel>

             {
                 this.props.slides.map((item) =>


                     <CarouselItem>
                         <img
                             className="d-block w-100"
                             src={baseImgUrl + item.image }
                             alt="First slide"
                         />
                         <Carousel.Caption>
                             <div className="container">
                                 <div className="banner-image">
                                     <img src={baseImgUrl + item.imageSmall } alt="" />
                                 </div>
                             </div>
                         </Carousel.Caption>
                     </CarouselItem>


             )
             }
                </Carousel>
          </div>
          </Fragment>
        );
    }
  }



  export default SliderHome;