import React, {Component, Fragment} from 'react';
import './Listing.css';
import Header from '../Header/index';
import Footer from '../Footer/index';
import smallSlide from '../img/image-4.png';
import what from '../img/what.png';
import twi from '../img/twi.png';
import fb from '../img/fb.png';
import axios from "axios/index";
import ActivityItem from "./ActivityItem";
import { Button, Col, Row, InputGroup} from 'react-bootstrap'
import {  FormControl as FormBootControl} from 'react-bootstrap'

import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import Popover from '@material-ui/core/Popover';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Typography from '@material-ui/core/Typography';
import  {Star} from '@material-ui/icons'


import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
        maxWidth: 20,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function MultipleSelect(props) {

    const [active, setActive] = React.useState(false);

    const classes = useStyles();
    const theme = useTheme();
    const [categoryName, setCategoryName] = React.useState([]);

    const handleChange = event => {
        setCategoryName(event.target.value);
        setActive(true);
    };

    const handleChangeMultiple = event => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setCategoryName(value);
    };

    return (

        <FormControl className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">{props.title}</InputLabel>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={categoryName}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
            >
                <MenuItem   value="">
                    <ListItemText primary={"Select "} />
                </MenuItem>
                {props.items.map(item => (
                    <MenuItem key={item.id} value={item.name}>
                        <Checkbox checked={categoryName.indexOf(item.name) > -1} />
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>



    );
}



class  FilterResultBox extends Component{

     page;
     slug;

    constructor(props){

        super(props)
        this.togglefilter = this.togglefilter.bind(this);
        this.state = {

            displayFilterMenu: false,
        }
    }

 
    togglefilter(){
        this.setState({
            displayFilterMenu : !this.state.displayFilterMenu
        })
    }


    componentDidMount(){

        // console.log("cat selected")
        // if (this.props.catSelected) {
        //
        //
        //
        //
        //     for (var i=0;i<this.props.categories.length;i++){
        //
        //        if(this.props.c)
        //
        //     }
        //
        //
        //     console.log(cats)
        //
        //     if (cats.length>0)
        //     this.setState({
        //
        //         children : cats[0].children
        //     })
        //
        // }

    }

    render (){
        return(
       <Fragment>
           <div className="fliter-mobile">
               <button className="filter-mobile-btn" onClick={this.togglefilter}><i class="fas fa-filter"></i> Filter</button>
               {this.state.displayFilterMenu && 
                     
               <div className="filter-mobile-open">
                    <ul>
                    <li className="mobilefilter-title" onClick={this.togglefilter}><i class="fas fa-filter"></i> Filter</li>


                   <li> < MultipleSelect activeFilters={this.props.activeFilters} className="all-category" key={"category"} title={"All Categories"} items={this.props.categories} /></li>
                   <li><MultipleSelect activeFilters={this.props.activeFilters} key={"cities"} title={"By The City"}  items={this.props.cities} /></li>
                    <li><MultipleSelect activeFilters={this.props.activeFilters} key={"days"} title={"By The Day"} items={this.props.days} /></li>
                    <li><MultipleSelect activeFilters={this.props.activeFilters} key={"timings"} title={"By The Time"} items={this.props.timings} /></li>
                    <li><MultipleSelect activeFilters={this.props.activeFilters} key={"locations"} title={"Location Preference"} items={this.props.locationPreference} /></li>
                    <li><StarSelect activeFilters={this.props.activeFilters} key={"ratings"} title={"Ratings"} items={this.props.ratings} /></li>
                    <li><SimplePopover activeFilters={this.props.activeFilters} /></li>
                    <li><SimpleSelect activeFilters={this.props.activeFilters}  key={"sortBy"}  title={"Sort By"} items={this.props.sortBy} /></li>
                    <li><Button className="blue-btn"  onClick={this.togglefilter}>Apply Now</Button></li>

                </ul>
               </div>
    }
                
           </div>
           <div className="filter-web">


               <MultipleSelect className="all-category" key={"category"} title={"All Categories"}
                                   items={this.props.categories}/>

               <MultipleSelect key={"cities"} title={"By The City"}  items={this.props.cities} />
               <MultipleSelect key={"days"} title={"By The Day"} items={this.props.days} />
               <MultipleSelect key={"timings"} title={"By The Time"} items={this.props.timings} />
               <MultipleSelect key={"locations"} title={"Location Preference"} items={this.props.locationPreference} />

               <SimpleSelect key={"sortBy"}  title={"Sort By"} items={this.props.sortBy} />
               <StarSelect className="rating-block"  key={"ratings"} title={"Ratings"} items={this.props.ratings} />
               <SimplePopover/>
               <Button className="blue-btn" >Filter</Button>

           </div>
       </Fragment>
         )
    }



}


function SimpleSelect(props) {


    const classes = useStyles();
    const [item, setItem] = React.useState('');
    const [active, setActive] = React.useState(false);


    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {

        setItem(event.target.value);
        setActive(true);
    };

    return (

        <FormControl className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
            >

                {props.items.map((item)=>
                    <MenuItem key={"key-"+item.id} value={item.id}>{item.name}</MenuItem>

                )}

            </Select>
        </FormControl>

    );
}

function StarSelect(props) {

    const [active, setActive] = React.useState(false);
    const classes = useStyles();
    const [item, setItem] = React.useState('');

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {
        setItem(event.target.value);
        setActive(true);

    };

    return (


        <FormControl className={active?" active "+classes.formControl:classes.formControl}>
            <InputLabel  id="demo-simple-select-placeholder-label-label">
                {props.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={item.id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
            >

                <MenuItem value={5}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/></MenuItem>
                <MenuItem value={4}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star color="disabled"/></MenuItem>
                <MenuItem value={3}><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled"/></MenuItem>
                <MenuItem value={2}><Star style={{color: "#29abe2"}} /><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled"/><Star color="disabled"/></MenuItem>

                <MenuItem value={1}><Star style={{color: "#29abe2"}}/><Star color="disabled"/><Star color="disabled" /><Star color="disabled"/><Star color="disabled"/></MenuItem>



            </Select>
        </FormControl>

    );
}




function valuetext(value) {
    return value+" AED";
}

function RangeSlider() {
    const classes = useStyles();
    const [value, setValue] = React.useState([20, 37]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root} style={{"width":"200px"}}>
            <Typography id="range-slider" gutterBottom>
                Price Range
            </Typography>
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
            />
        </div>
    );
}




const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

function CustomizedMenus() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="price-range">
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"

                onClick={handleClick}
            >
                Open Menu
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <RangeSlider  />

            </StyledMenu>
        </div>
    );
}





function SimplePopover() {


    const [show, setShow] = React.useState(false);

    const [value, setValue] = React.useState([0, 1000]);
    const [active, setActive] = React.useState(false);


    const useMyStyles = makeStyles(theme => ({
        typography: {
            padding: theme.spacing(2),
        },
    }));
    const classes = useMyStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);

    };

    const handleChange = (event,newValue) => {

        setShow(true)
        setValue(newValue)
        console.log( newValue)
        setActive(true);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={active?" active "+" price-range":"price-range"} >
            <Button
                className={active?" active "+classes.formControl:classes.formControl}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
            >{show ? "AED "+value[0]+"-"+"AED "+value[1]:"Price Range"} <ArrowDropDownIcon/>
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>

                    <AirbnbSlider
                        onChange={handleChange}
                        ThumbComponent={AirbnbThumbComponent}
                        getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
                        defaultValue={[0, 1000]}
                        min={0}
                        max={1000}
                    />

                    <Row>
                        <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Append>
                            <InputGroup.Text>AED</InputGroup.Text>
                            <InputGroup.Text>{value[0]}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                        </Col>

                        <Col>
                            <InputGroup className="mb-3">

                                <InputGroup.Append>
                                    <InputGroup.Text>AED</InputGroup.Text>
                                    <InputGroup.Text>{value[1]}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                </Typography>



            </Popover>
        </div>
    );
}

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
        {/*<span className="bar" />*/}
        {/*<span className="bar" />*/}
        {/*<span className="bar" />*/}
      </span>
    );
}


const AirbnbSlider = withStyles({
    root: {
        color: '#00adee',
        height: 3,
        padding: '13px 0',
        width: 220 + 5 * 2,

    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid #eeeeee',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0px 2px 2px',
        '&:focus,&:hover,&$active': {
            boxShadow: '#ccc 0px 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: '#00adee',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },

})(Slider);




  
export default FilterResultBox;