import React, { Component, Fragment, useState, useEffect,useRef } from 'react';
import { Button, Col, Row, Nav, Sonnet} from 'react-bootstrap'
import ReactHtml from 'raw-html-react';
import MapIcon from '../img/map.png';
import DetailIcon1 from '../img/detail-icon-1.png';
import DetailIcon2 from '../img/detail-icon-2.png';
import DetailIcon5 from '../img/detail-icon-5.png';
import DetailIcon7 from '../img/detail-icon-7.png';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import TabsMaterial from '@material-ui/core/Tabs';
import TabMaterial from '@material-ui/core/Tab';
import Tabs  from '@material-ui/core/Tabs';
import Tab  from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import  {Star} from '@material-ui/icons';
import Zoom from '@material-ui/core/Zoom';
import reviewtick from '../img/review-tick.png';
import inspireprofile from '../img/inspire-profile.png';
import emailfriend from '../img/email-friend.png';
import CrossBlack from '../img/cross-black.png';
import facebook from '../img/f-round.png';
import insta from '../img/in-round.png';
import LinkDin from '../img/lin-round.png';
import Mail from '../img/mail-round.png';
import ViewArrowDown from '../img/red-arrow-down.png';
import ViewArrowUp from '../img/red-arrow-up.png';
import ArrowDown from '../img/arrow-down.png';
import {MapContainer} from "./MapContainer";
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import CustomerReviewBox from './CustomerReviewBox';
import CustomerReviewBoxNew from './CustomerReviewBoxNew';


class  BottomTabsNew extends Component {

    description;
    importantInformation;
    constructor(props){

        super(props)


        this.description = props.activity.description
        this.importantInformation = props.activity.importantInformation


        this.state = {

            // activity : {},
            // images : this.props.images


        };



    }


    componentDidMount(){


        console.log(this.props.activityDetail)
    }




    render() {

        const options = {
            center: {lat: 36.890257, lng: 30.707417},
            zoom: 12
        };



        return(


            <ScrollableTabsButtonForce   activity={this.props.activity} activityDetail={this.props.activityDetail} />



        );
    }

}






function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: "transparent",
        boxShadhow:"none"

    },
}));

function ScrollableTabsButtonForce(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" className="detailtabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    textColor={"primary"}
                    indicatorColor={"primary"}
                    aria-label="scrollable  tabs example"
                >


                    <Tab label="KNOW BEFORE YOU GO"  {...a11yProps(0)} />



                    { props.activityDetail.activityMiddleInfo && props.activityDetail.activityMiddleInfo.highlight &&

                    <Tab label="WHATS THE HIGHLIGHT"
                         {...a11yProps(1)} /> }


                    {props.activityDetail.activityMiddleInfo &&
                    props.activityDetail.activityMiddleInfo.cancellationPolicy &&

                    <Tab label="CANCELLATION POLICY"  {...a11yProps(props.activityDetail.activityMiddleInfo.highlight?2:1)} />
                    }



                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className="detailtab-dis">
                <Fragment eventKey="home" title="KNOW BEFORE YOU GO">

                    <div className="text-block">
                        <DescriptionBox title={"Description"} content={props.activity.description}></DescriptionBox>
                    </div>


                    {props.activityDetail.included.length > 0 && props.activityDetail.notIncluded.length > 0 &&

                    <IncludedBox activityDetail={props.activityDetail}/>


                    }
                    {props.activityDetail.activityMiddleInfo &&

                    <div className="text-block dot-ul">

                        <DescriptionBox title={"Things To Keep In Mind"}
                                        content={props.activityDetail.activityMiddleInfo && props.activityDetail.activityMiddleInfo.thingsToKeepInMind}/>

                    </div>
                    }

                    {props.activityDetail.activityMiddleInfo &&   props.activityDetail.activityMiddleInfo.additionalInformation &&
                    <div className="text-block dot-ul">

                        <DescriptionBox title={"Additional Information"}
                                        content={props.activityDetail.activityMiddleInfo && props.activityDetail.activityMiddleInfo.additionalInformation}/>


                        {/* <button className="less-link">View Less <img src={RedArrowUp} alt="" /></button> */}
                    </div>
                    }
                    <div className="text-block">
                        <h4>When and Where</h4>
                        <ul className="where-ul">


                            { props.activityDetail.day.length >0 &&  <li><img src={DetailIcon1} alt="" /><span>Available days:</span> {props.activityDetail.day.map((item,index)=>
                                <>
                                    {index>0&&", "}{item.name}
                                </>
                            )}

                                { props.activityDetail.activityBottomInfo  && ( props.activityDetail.activityBottomInfo.thursdayWorkingHours || props.activityDetail.activityBottomInfo.fridayWorkingHours ||
                                    props.activityDetail.activityBottomInfo.saturdayWorkingHours || props.activityDetail.activityBottomInfo.sundayWorkingHours) &&
                                <>
                                    <button type="click">Click Here for Schedule Information <img src={ArrowDown} alt="" />
                                        <div className="shedual-drop">
                                            <h3 className="title">Host's Schedule</h3>
                                            <div className="shedual-drop-content">
                                                <h3>Host’s working Hours</h3>
                                                <div className="d-flex days-shedual">

                                                    {props.activityDetail.activityBottomInfo && props.activityDetail.activityBottomInfo.thursdayWorkingHours && <>

                                                        <div className="col">

                                                            <h4>Thu</h4>
                                                            < p> {props.activityDetail.activityBottomInfo.thursdayWorkingHours}</p>


                                                        </div>
                                                    </>
                                                    }
                                                    {props.activityDetail.activityBottomInfo && props.activityDetail.activityBottomInfo.fridayWorkingHours && <>

                                                        <div className="col">

                                                            <h4>Fri</h4>
                                                            < p> {props.activityDetail.activityBottomInfo.fridayWorkingHours}</p>


                                                        </div>
                                                    </>
                                                    }
                                                    {props.activityDetail.activityBottomInfo && props.activityDetail.activityBottomInfo.saturdayWorkingHours && <>

                                                        <div className="col">

                                                            <h4>Sat</h4>
                                                            < p> {props.activityDetail.activityBottomInfo.saturdayWorkingHours}</p>


                                                        </div>
                                                    </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </>}
                            </li> }


                            {props.activityDetail.activityTopInformation && <>
                                {  props.activityDetail.activityTopInformation.duration &&    <li><img src={DetailIcon7} alt="" /><span>Duration of the experience:</span> {props.activityDetail.activityTopInformation.duration }</li> }
                                { props.activityDetail.activityTopInformation.workingHours &&   <li><img src={DetailIcon2} alt="" /><span>Working Hours:</span> {props.activityDetail.activityTopInformation.workingHours }</li>}
                                { props.activityDetail.activityTopInformation.whatsNext && <li><img src={DetailIcon5} alt="" /><span>Schedule:</span> {props.activityDetail.activityTopInformation.whatsNext }</li> }
                            </>}
                            <li><img src={MapIcon} alt="" /><span>Address:</span> {props.activity.address && props.activity.address.address }{ props.activity.address && props.activity.address.city && ", "+props.activity.address.city.name }</li>
                        </ul>


                        {/*{props.activity.address && props.activity.address.latitude && props.activity.address.longitude &&*/}
                        {/*<a href={"https://www.google.com/maps/dir/?api=1&destination="+props.activity.address.latitude+","+props.activity.address.longitude}>See Directions</a>*/}

                        {/*}*/}


                        {/*{props.activity.address && props.activity.address.latitude && props.activity.address.longitude &&*/}
                        {/*<div className="map-block">*/}
                            {/*<MapContainer style={{position: "relative!important"}} item={props.activity}/>*/}
                            {/*<a href="#" className="see-review mt-3 d-flex">Visit Website</a>*/}
                        {/*</div>*/}
                        {/*}*/}
                    </div>


                    {props.activityDetail.activityBottomInfo &&

                    <div className="text-block dot-ul">

                        <DescriptionBox title={"Terms & Conditions"}
                                        content={props.activityDetail.activityBottomInfo.termsAndConditions}></DescriptionBox>
                    </div>

                    }
                    {props.activityDetail.activityBottomInfo && props.activityDetail.activityBottomInfo.otherInformation &&
                    <div className="text-block dot-ul">
                        {/*<h4>Notes</h4>*/}

                        <DescriptionBoxH5 title={"Notes"}
                                          content={props.activityDetail.activityBottomInfo.otherInformation}></DescriptionBoxH5>
                    </div>

                    }


                    {/*<CustomerReviewBox loginPopUp={(data)=>this.props.loginPopUp(data)}  isLoggedIn={this.props.isLoggedIn} />*/}


                    <CustomerReviewBox activity={props.activity}/>



                </Fragment>
            </TabPanel>
            {props.activityDetail.activityMiddleInfo &&
            props.activityDetail.activityMiddleInfo.highlight && <TabPanel value={value} index={1}>
                <div className="text-block dot-ul">

                    <DescriptionBox title={"Highlights"}
                                    content={props.activityDetail.activityMiddleInfo && props.activityDetail.activityMiddleInfo.highlight}/>

                </div>

            </TabPanel>
            }

            {props.activityDetail.activityMiddleInfo &&
            props.activityDetail.activityMiddleInfo.cancellationPolicy &&
            <TabPanel value={value} index={props.activityDetail.activityMiddleInfo.highlight ? 2 : 1}>


                <div className="text-block dot-ul">
                    <DescriptionBox title={"Cancellation Policy"}
                                    content={props.activityDetail.activityMiddleInfo.cancellationPolicy}></DescriptionBox>

                </div>
            </TabPanel>
            }
            <TabPanel value={value} index={3}  href="#reviews">

            </TabPanel>

        </div>
    );
}


function DescriptionBoxH5(props) {


    return (
        <Fragment>

            <h5>{props.title}</h5>


            <p><ReactHtml html={props.content}/></p>


        </Fragment>

    )

}



function DescriptionBox(props) {


    const [more, showMore] = useState(false);


    // let words = props.content.split(" ").length
    //
    // var wordsLength = words.length




    if (props.content ) {


        return (

            <Fragment>

                <h4>{props.title}</h4>

                {more && <p><ReactHtml html={props.content}/></p>}
                {!more && <p><ReactHtml html={props.content.split(" ").slice(0,75).join(" ")+(props.content.split(" ").length > 75? " ...":"")}/></p>}

                {props.content.split(" ").length > 75 &&
                <button onClick={() => showMore(!more)} style={{color: "#00adee!important"}}
                        className="less-link">{more ? "Read Less" : "Read More"}
                    <img src={more ? ViewArrowUp : ViewArrowDown} alt=""/></button>
                }


            </Fragment>

        )
    }else {

        return <Fragment></Fragment>
    }


}


function IncludedBox(props) {


    const [height, setHeight] = useState(300)
    const ref = useRef(null)

    let element=     <div className="text-block" ref={ref}  >
        <h4>Whats Included/Excluded  </h4>
        <div className="row">
            <div className="col-lg-6">
                <ul className="include">

                    {props.activityDetail.included.map((item) =>
                        <li>{item.included}</li>
                    )}
                </ul>
            </div>
            <div className="col-lg-6">
                <ul className="exclude">

                    {props.activityDetail.notIncluded.map((item) =>
                        <li>{item.notIncluded}</li>
                    )}
                </ul>
            </div>
        </div>
    </div>



    useEffect(() => {

        // if (ref.current.clientHeight>300) {
        //     setHeight(300)
        //
        // }else {
        //
        //     // setHeight(ref.current.clientHeight)
        //     console.log("heighgt")
        //
        //     console.log(ref.current.clientHeight)
        //
        // }
    })


    return (
        <>
            {element}
        </>
    )
}


// function CustomerReviewBox(props) {
//
//     const [show, setShow] = useState(false);
//
//     return(
//
//         <div id="reviews" className="review-section ">
//             <div className="row">
//                 <div className="col-lg-12 d-none ">
//                     <h3>Customer Reviews</h3>
//                     <div className="rating d-none">
//                         <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"} />
//                         <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"} />
//                         <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"} />
//                         <Star color="disabled" name="size-small" value={2} fontSize={"small"} />
//                         <Star color="disabled" name="size-small" value={2} fontSize={"small"} />
//                         <p>4.0 (98 ratings)</p>
//                     </div>
//                     <div className="review-verify d-none">
//                         <img src={reviewtick} alt="" />
//                         <div className="verifycontent d-none">
//                             <h5>100% Verified Reviews </h5>
//                             <p>All reviews are from people who have redeemed deals with this merchant.</p>
//                         </div>
//                     </div>
//
//                     <CustomerReview />
//
//                 </div>
//
//                 <div className="col-lg-12 mt-3 d-none">
//                     <a href="javascripit" className="see-reviews">See all reviews</a>
//                 </div>
//                 <div className="col-lg-12 mt-3">
//                     <div className="text-block">
//                         <h3 >Customer Reviews</h3>
//                         <p className="mb-2">There aren't any reviews for this activity yet. Be the first to write one!</p>
//
//                         <button onClick={props.isLoggedIn? () => setShow(!show) : props.loginPopUp(true)} className="blue-btn">Submit A Review</button>
//
//                             {show && <div className="review-box">
//                                 <div className="form-row">
//                                     <div className="form-group col-lg-12">
//                                         <label>Overall Rating</label>
//                                         <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"} />
//                                         <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"} />
//                                         <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"} />
//                                         <Star color="disabled" name="size-small" value={2} fontSize={"small"} />
//                                         <Star color="disabled" name="size-small" value={2} fontSize={"small"} />
//                                     </div>
//                                 </div>
//                                 <div className="form-row">
//                                     <div className="form-group col-lg-5">
//                                         <label>Add a headine</label>
//                                         <input className="form-control" type="text" placeholder="Add a headine"/>
//                                     </div>
//                                 </div>
//                                 <div className="form-row">
//                                     <div className="form-group col-lg-12">
//                                         <label>Write your review</label>
//                                         <textarea className="form-control"
//                                                   placeholder="Write your review"></textarea>
//                                     </div>
//                                 </div>
//                                 <div className="form-row">
//                                     <div className="form-group col-lg-12">
//                                         <a href="#" className="btn">Submit</a>
//                                     </div>
//                                 </div>
//                             </div> }
//
//
//                     </div>
//                 </div>
//                 <div className="col-lg-12 wl_share">
//                     <p className="float-left">Share on: </p>
//                     <a className="float-left" href="https://www.facebook.com/Happy-Weekends-113793993335534/"><img src={facebook} alt="fb-imge" /></a>
//                     <a className="float-left" href="https://www.instagram.com/happyweekendsuae/"><img src={insta} alt="fb-imge" /></a>
//                     <a className="float-left" href="https://twitter.com/WeekendsHappy"><img src={LinkDin} alt="fb-imge" /></a>
//                     <a className="float-left" href="mailto:info@happy-weekends.com"><img src={Mail} alt="fb-imge" /></a>
//                 </div>
//             </div>
//         </div>
//
//     )
//
//
//
// }







const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        userDetail: state.userDetail,
        isLoggedIn:state.isLoggedIn,
        isGuest:state.isGuest,


    };
};

const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        // addCartItemGuest: (item) => dispatch(actionCreator.addCartItemGuest(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(BottomTabsNew);