import React, { Component } from 'react';
import { Carousel,CarouselItem, Col, Row} from 'react-bootstrap'
import User from '../img/user.png';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rating from '@material-ui/lab/Rating';
import  {Star} from '@material-ui/icons'
class  Testimonial extends Component{ 




    componentDidMount(){



    }

    render (){
        return(
            <section className="testimonial home-testi">
                 <div className="container">
                        <div className="row justify-content-center text-center testi-title">
                            <div className="col-lg-6">
                                <h2>Hear from the people</h2>
                                <p>How we are making your weekends special</p>
                            </div>
                        </div>
                        <div className="row blog">
                        <div className="col-md-12 p-0">
                           <GetCarousel />
                        </div>
                        </div>
                    </div>
            </section>
         )
    }
}



function GetCarousel() {

  const  responsiveValues = {
        1025 : {
            items : 4

        },
        1024 : {
            items : 3

        },
        0 : {
            items : 1

        }
    }


    return(
        <OwlCarousel smartSpeed={450} responsiveRefreshRate={10} loop={true} autoplay={true} autoplayTimeout={2000}   className="owl-theme" responsive={responsiveValues} margin={15}   nav>
            <div className={"item"}>
                <div className="user-info">
                    <div className="testi-contenttitle p-0">
                        <div className="row">
                            <div className="col">
                            <h3>Ammara Malik</h3>
                            </div>
                            <div className="col">
                                <div className="user-info-left pl-0">
                                    <fieldset className="rating">
                                    <Rating className="detail-head-r"
                                        readOnly
                                        value={5}
                                        icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                        emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                        name="size-large"  size="large"
                                    />
                                    </fieldset>
                                    <p>5 stars</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="p-0">
                        <p>Thanks Happy Weekends for infusing a new life to our weekends. Adventure, thrill, rejuvenation, new skills, now my weekends have new themes every time. You have given a new meaning to work life balance.</p>
                    </div>
                </div>

            </div>
            <div className={"item"}>
                <div className="user-info">
                    <div className="testi-contenttitle p-0">
                        <div className="row">
                            <div className="col">
                            <h3>Aisha Mahmood</h3>
                            </div>
                            <div className="col">
                                <div className="user-info-left pl-0">
                                    <fieldset className="rating">
                                    <Rating className="detail-head-r"
                                        readOnly
                                        value={5}
                                        icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                        emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                        name="size-large"  size="large"
                                    />
                                    </fieldset>
                                    <p>5 stars</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="p-0">
                        <p>I don't have the energy to step out every weekend. What I didn't know was there's a whole lot of possibilities to have a fun and fulfilling weekend right at your home. Thank you, Happy Weekends!</p>
                    </div>
                </div>

            </div>
            <div className={"item"}>
                <div className="user-info">
                    <div className="testi-contenttitle p-0">
                        <div className="row">
                            <div className="col">
                            <h3>Jeff Grindley</h3>
                            </div>
                            <div className="col">
                                <div className="user-info-left pl-0">
                                    <fieldset className="rating">
                                    <Rating className="detail-head-r"
                                        readOnly
                                        value={5}
                                        icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                        emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                        name="size-large"  size="large"
                                    />
                                    </fieldset>
                                    <p>5 stars</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="p-0">
                        <p>It’s so interesting that so much can be done in one weekend and Happy Weekends helps you achieve just that! I love the idea of enjoying a new experience every weekend. No more Monday blues!</p>
                    </div>
                </div>

            </div>
            <div className={"item"}>
                <div className="user-info">
                    <div className="testi-contenttitle p-0">
                        <div className="row">
                            <div className="col">
                            <h3>Faraz Obeid</h3>
                            </div>
                            <div className="col">
                                <div className="user-info-left pl-0">
                                    <fieldset className="rating">
                                    <Rating className="detail-head-r"
                                        readOnly
                                        value={5}
                                        icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                        emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                        name="size-large"  size="large"
                                    />
                                    </fieldset>
                                    <p>5 stars</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="p-0">
                        <p>The best part is you don’t have to rack you brain over weekend plans. Amazingly curated ideas and concepts. Just pick one and have a happy weekend!</p>
                    </div>
                </div>

            </div>
            <div className={"item"}>
                <div className="user-info">
                    <div className="testi-contenttitle p-0">
                        <div className="row">
                            <div className="col">
                            <h3>Manal Youssef</h3>
                            </div>
                            <div className="col">
                                <div className="user-info-left pl-0">
                                    <fieldset className="rating">
                                    <Rating className="detail-head-r"
                                        readOnly
                                        value={5}
                                        icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                        emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                        name="size-large"  size="large"
                                    />
                                    </fieldset>
                                    <p>5 stars</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="p-0">
                        <p>There is something refreshing for all kind of moods. I definitely have happy weekends now.</p>
                    </div>
                </div>

            </div>
           </OwlCarousel>


    )

}


export default Testimonial;
