import React, {Component, Fragment} from 'react';
// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutUs from "./info_pages/About";
import {Route, Switch} from 'react-router-dom'
import Footer from "./Footer";
import Corporate from "./Corporate";
import ContactUs from "./ContactUs";
import Terms from "./info_pages/terms";
import Privacy from "./info_pages/Privacy";
import Faq from "./info_pages/Faq";
import Home from "./Home";
import ActivityDetail from "./Detail";
import TestingMenu from "./Testing/TestingMenu";
import Search from "./Listing/Search";
import HomeSectionListing from "./Listing/HomeSectionListing";
import BecomePartner from "./BecomePartner";
import CustomerDashbaord from "./CustomerDashboard";
import AdminPartner from "./AdminPartner";
import Checkout from "./Checkout";
import EditCartPopUp from "./Checkout/EditCartPopUp";
import EditBundleCartPopUp from "./Checkout/EditBundleCartPopUp";

import './style.css';
import LoginNew from "./Login/login";
import Logout from "./Login/logout";
import CorporateRegister from "./Login/CorporateRegister";
import LoginPopUp from "./Login/login_pop_up";
// import SocialLoginPopUp from "./Login/social_login_pop_up";
import NewsletterPopUpNew from "./NewsLetter/newsletter_pop_up_new";
import Register from "./Login/register";
import Blog from "./Blog";
import BlogDetail from "./Blog/BlogDetail";
import NavigationNew from "./Header/NavigationNew";
import Career from "./info_pages/career";
import HowItWorks from "./info_pages/HowItWorks";
// import SportsWeekend from "./SportsWeekend";
import GiveBack from "./GiveBack";
import SportsW from "./Sports";
import PartnerContact from "./BecomePartner/PartnerContact";
import NotFound from "./NotFound";
import AuthRoute from './Util/AuthRoute';
import HostAuthRoute from './Util/HostAuthRoute';
import AuthRouteAccess from './Util/AuthRouteAccess';
import HostAuthRouteAccess from './Util/HostAuthRouteAccess';
import CheckoutRoute from './Util/CheckoutRoute';
import {getKey, loadUserData, saveKey} from './LocalStorage/user'

import * as actionCreator from "./store/actions/actions";
import {connect} from "react-redux";
import OrderSuccess from "./Booking/OrderSuccess";
import axios from "axios/index";
import ListingNew from "./Listing/listing";
import EmailConfirmation from "./Login/EmailConfirmation";
import UnsubscribeEmail from "./Login/UnsubscribeEmail";

import EmailComfirmationPopUpNew from "./Login/EmailConfirmationPopUpNew";
import CareerDetail from "./info_pages/Careerdetail";
import CareerForm from "./info_pages/CareerForm";
import {baseUrl} from "./Util/Constants";
import RecoverPasswordLoginNew from "./Login/RecoverPasswordLoginNew";
import WeekendPassList from "./WeekendPass/index";
import WeekendPassDetail from "./WeekendPass/WeekendPassDetail";
import VendorDashboard from "./VendorDashboard";


// const geolib = require('geolib');


class App extends Component{


    constructor(props){
        super(props)


        this.state = {
            latitude: null,
            longitude: null,
            country:null,
            countryCode : null,
            slides: [],

        }

        // this.getLocation=this.getLocation.bind(this)
        // this.getCoordinates=this.getCoordinates.bind(this)
        this.getGeoInfoFromIp=this.getGeoInfoFromIp.bind(this)

    };


    getLocation() {




        // if(navigator.geolocation){
        //
        //     navigator.geolocation.getCurrentPosition(this.getCoordinates)
        //
        // }


        // if(navigator.geolocation){

        // navigator.geolocation.getCurrentPosition(
        //     function (position) {
        //         console.log(
        //             'You are ',
        //             geolib.getDistance(position.coords, {
        //                 latitude: 51.525,
        //                 longitude: 7.4575,
        //             }),
        //             'meters away from 51.525, 7.4575'
        //         );
        //     },
        //     () => {
        //         // alert('Position could not be determined.');
        //     }
        // );
    // }


    }




    getCoordinates(position){

       this.setState({
           latitude: position.latitude,
           longitude: position.longitude,

       })


        // console.log("Allow Location Data")

        // this.props.setLocation(position.Coordinates)

        // console.log(position)
        // console.log(position.GeolocationPosition)


        // this.getAddress(position.Position.coords.Coordinates.latitude,
        //     position.Position.coords.coordinates.longitude)

    }
    //
    // getAddress (latitude, longitude) {
    //
    //     axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
    //         latitude + ',' + longitude + '&key=' +"ycKoncx53Gpx4Z1aMZldJqIRYik=").then((response) => {
    //         let data = response.data;
    //         console.log(response.data)
    //
    //
    //         this.setState({
    //             countryName: data.country_name,
    //             countryCode: data.country_calling_code
    //         });
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    //
    //
    //
    // }


    getGeoInfoFromIp = () => {

        axios.get('https://ipapi.co/json/').then((response) => {


            let data = response.data;



            if (data.country_name)
            saveKey("country",data.country_name)

            if (data.country_code)
                saveKey("countryCode",data.country_code)

            if (data.latitude)
                saveKey("latitude",data.latitude)

            if (data.longitude)
            saveKey("longitude",data.longitude)



            // console.log("IP Data")
            // console.log(response.data)

            this.setState({
                countryName: data.country_name,
                countryCode: data.country_calling_code
            });


            if (data.longitude)

            this.props.setLocation(
                {
                    "latitude":data.latitude,
                    "longitude":data.longitude,

                })

            // this.props.setCity(
            //     {
            //         "city":"Abu Dhabi",
            //         "cityId": 0
            //
            //     })


        }).catch((error) => {

            // console.log(" getting ip error")
            // console.log(error);



        });
    };



    refreshUser(email){


        var    url = baseUrl+"customers.json?groups[]=customer&email="+email;

        // console.log(url)


        axios.get(url)
            .then(res => {

                var userDetail =  res.data[0]


                this.props.setUserDetail(userDetail)



            }).catch(error => {

            // console.log(error)


        });


    }

    componentDidMount(){




    }

    componentWillMount(){


        // this.loadSlides()
          var  user = loadUserData()





        if (getKey("bundleItems")){

            var bundles = getKey("bundleItems")
            for (var i=0;i<bundles.length;i++) {


                this.props.addBundleCartItem(bundles[i])


            }


        }


        if (user){



          this.refreshUser(user.email)

           this.props.setRememberUserDetail(user)
            this.props.loadFavorites(user)
            this.props.loadSalesQuote(user)

        }

        // this.getLocation()
        this.getGeoInfoFromIp()


    }

    render() {


        return (
            <Fragment>

                <NavigationNew  slides={this.state.slides} />
                <Switch>
                    <Route exact path="/"  component={Home} />
                    <AuthRoute exact path="/login"  component={LoginNew} />
                    <Route exact path="/logout"  component={Logout} />
                    <Route exact path="/forgot-password"  component={RecoverPasswordLoginNew} />
                    <AuthRoute exact path="/register"  component={Register} />
                    <Route exact path="/search"  component={Search} />
                    <Route exact path="/testing"  component={TestingMenu} />
                    <Route exact path="/about"  component={AboutUs} />
                    <Route exact path="/contact"  component={ContactUs} />
                    <Route exact path="/terms-conditions"  component={Terms} />
                    <Route exact path="/privacy"  component={Privacy} />
                    <Route exact path="/how-it-works"   component={HowItWorks} />
                    <Route exact path="/faq"  component={Faq} />
                    <Route exact path="/corporate"  component={Corporate} />
                    <Route exact path="/give-back"  component={GiveBack} />
                    <Route exact path="/partner-contact"  component={PartnerContact} />
                    <HostAuthRoute exact path="/partner-new"  component={BecomePartner} />
                    <Route exact path="/sports-weekend"  component={SportsW} />
                    <Route exact path="/testing"  component={TestingMenu} />
                    <Route exact path="/admin"  component={AdminPartner} />
                    <Route exact path="/unsubscribe" component={UnsubscribeEmail} />
                    <CheckoutRoute exact path="/checkout"  component={Checkout} />
                    <Route exact path="/checkout/:id"  component={Checkout} />
                    <AuthRouteAccess exact path="/customer/dashboard/:id"  component={CustomerDashbaord} />
                    <HostAuthRouteAccess exact path="/vendor/dashboard"  component={VendorDashboard} />
                    {/*<Route exact path="/vendor/dashboard"  component={VendorDashboard} />*/}
                    <Route exact path="/career"  component={Career} />
                    <Route exact path="/career-detail/:id"  component={CareerDetail} />
                    <Route exact path="/career-form/:id"  component={CareerForm} />
                    <Route exact path="/blog" component={Blog} ></Route>
                    <Route exact path="/blog-detail/:id" component={BlogDetail} />
                    <Route exact path="/category/:id" component={ListingNew} ></Route>
                    <Route exact path="/sections/:id" component={HomeSectionListing} />
                    <Route exact path="/activity/:id" component={ActivityDetail} />
                    <Route exact path="/booking/:id" component={OrderSuccess} />
                    <Route exact path="/customer/:id" component={EmailConfirmation} />
                    <Route exact path="/weekend-passes" component={WeekendPassList} />
                    <Route exact path="/weekend-detail/:slug" component={WeekendPassDetail} />
                    <AuthRoute exact path="/corporate/:name/register"  component={CorporateRegister} />
                    <Route  component={NotFound} />


                </Switch>
                    <Footer/>

                {(this.props.showLoginPopUp||this.props.showLoginCheckoutPopUp) && <LoginPopUp/>}

                {/*{(this.props.showSocialLoginPopUp) && <SocialLoginPopUp/>}*/}

                {(this.props.showEditCartPopUp) && <EditCartPopUp />}

                {(this.props.showEditBundleCartPopUp) && <EditBundleCartPopUp />}

                {
                    this.props.userDetail && !this.props.userDetail.isGuest && !this.props.userDetail.isConfirmed &&
                <EmailComfirmationPopUpNew userDetail={this.props.userDetail} />
                }


                {!this.props.isLoggedIn&&(!getKey("newsletter_popup")||getKey("newsletter_popup")<2)&& <NewsletterPopUpNew/>}


            </Fragment>

        );

    }
}


const mapStateToProps = state => {
    return {


        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        isGuest: state.isGuest,
        location: state.location,
        showLoginPopUp: state.showLoginPopUp,
        showSocialLoginPopUp: state.showSocialLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        showEditCartPopUp: state.showEditCartPopUp,
        showEditBundleCartPopUp: state.showEditBundleCartPopUp,
        reviewSuccessMessage: state.reviewSuccessMessage,
        showNewsletter: state.showNewsletter

    };
};



const mapDispachToProps = dispatch => {
    return {

        loadFavorites: (user) => dispatch(actionCreator.loadFavorites(user)),
        setLocation: (data) => dispatch(actionCreator.setLocation(data)),
        setCity: (data) => dispatch(actionCreator.setCity(data)),
        loadSalesQuote: (user) => dispatch(actionCreator.loadSalesQuote(user)),
        setRememberUserDetail: (user) => dispatch(actionCreator.setRememberUserDetail(user)),
        setUserDetail: (user) => dispatch(actionCreator.setUserDetail(user)),
        addBundleCartItem: (user) => dispatch(actionCreator.addBundleCartItem(user)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(App);

