import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import GoogleLogin from 'react-google-login';
import { FacebookProvider, Login  } from 'react-facebook';import config from "./social/config";
import queryString from 'query-string'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {saveGuestData, saveUserData, saveUserToken} from '../LocalStorage/user'


class  LoginPopUp extends Component{


    componentDidMount(){


    }
    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            email : null,
            userExists : false,
            socialUserExists : false,
            emailLoad : false,
            socialUserInfo: null,
            existingUserInfo: {},
            invalidPhone:false,
            fields: {},
            errors: {},
            fieldsGuest: {},
            errorsGuest: {},
            fieldsSocial: {},
            errorsSocial: {},
            phoneNumberInValid: false,
            phoneNumberInValidGuest: false,
            phoneNumberInValidSocial: false,



        }

        this.socialUserPhoneInput= this.socialUserPhoneInput.bind(this)

        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
        this.handleSubmitSignUp = this.handleSubmitSignUp.bind(this);
        this.handleSubmitSignUpNew = this.handleSubmitSignUpNew.bind(this);
        this.handleSubmitSocialSignUpNew = this.handleSubmitSocialSignUpNew.bind(this);

        this.showSignUp = this.showSignUp.bind(this);
        this.showGuestSignUp = this.showGuestSignUp.bind(this);
        this.showLogin = this.showLogin.bind(this);

        this.hidePopUp = this.hidePopUp.bind(this);
        this.checkIfUserExist = this.checkIfUserExist.bind(this);

         this.responseGoogle=this.responseGoogle.bind(this)
        this.createUser=this.createUser.bind(this)
        this.responseFacebook=this.responseFacebook.bind(this)
        this.checkIfUserExist=this.checkIfUserExist.bind(this)
        this.checkIfSocialUserExist=this.checkIfSocialUserExist.bind(this)
        this.checkIfGuestUserExist=this.checkIfGuestUserExist.bind(this)
        this.hideDummy= this.hideDummy.bind(this)
        this.handleValidationSignUp= this.handleValidationSignUp.bind(this)
        this.handleValidationGuest= this.handleValidationGuest.bind(this)
        this.handleValidationSocial= this.handleValidationSocial.bind(this)
        this.onChange= this.onChange.bind(this)
        this.handleChange= this.handleChange.bind(this)
        this.handleChangeGuest= this.handleChangeGuest.bind(this)

    }




    handleChangeSocial(field, e){

        // console.log(field)
        // console.log(e)

        let fields = this.state.fieldsSocial;
        fields[field] = e;
        this.setState({fieldsSocial:fields});
    }


    handleChangeGuest(field, e){

        // console.log(field)
        // console.log(e)

        let fields = this.state.fieldsGuest;
        fields[field] = e;
        this.setState({fieldsGuest:fields});
    }


    handleChange(field, e){

        // console.log(field)
        // console.log(e)

        let fields = this.state.fields;
        fields[field] = e;
        this.setState({fields});
    }

    onChange(field, e){

        // console.log(field)
        // console.log(e)

        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    handleValidationSignUp(){

        // alert("called")
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            errors["phoneNumber"] = "Required";
            this.setState({

                "phoneNumberInValid" : true
            })
        }


        if(!fields["password"]){
            // alert("invalid ")
            formIsValid = false;
            // errors["password"] = "Required";
        }

        // if(!fields["confirmPassword"]){
        //     // alert("invalid ")
        //     formIsValid = false;
        //     // errors["confirmPassword"] = "Required";
        //
        // }


        if (typeof fields["password"] !== "undefined"){


            // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            // const strongRegex = new RegExp("^(?=.*[A-Z])");

            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})");


            // console.log(strongRegex.test(fields["password"]))

            if (!strongRegex.test(fields["password"])){

                formIsValid = false;
                errors["password"] = "The password field must be at least 6 characters, one upper case, one numerical charactor, one special character!";

            }


            if (fields["password"].length<6){

                formIsValid = false;
                errors["password"] = "The password field must be at least 6 characters long, must contain one upper case, one numerical characters";

            }


            // // console.log(strongRegex.test(fields["password"]))


        }


        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValid" : true
            })


        }



        this.setState({errors: errors});

        return formIsValid;
    }

    handleValidationGuest(){

        // alert("called")
        let fields = this.state.fieldsGuest;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            errors["phoneNumber"] = "Required";
            this.setState({

                "phoneNumberInValidGuest" : true
            })
        }



        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValidGuest" : true
            })


        }



        this.setState({errorsGuest: errors});

        return formIsValid;
    }
    handleValidationSocial(){

        // alert("called")
        let fields = this.state.fieldsSocial;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            errors["phoneNumber"] = "Required";
            this.setState({

                "phoneNumberInValidSocial" : true
            })
        }



        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValidSocial" : true
            })


        }



        this.setState({errorsSocial: errors});

        return formIsValid;
    }


    mergeAccount(user){


        //  this.updateAccountInfoMerge(user)
        // this.updatePasswordAccountMerge(user)


        var url = baseUrl + "merge_accounts";

        const firstName = user.firstName
        const lastName = user.lastName
        const email = user.email
        const phoneNumber = user.phoneNumber
        const password = user.password

        axios.post(url,
            { firstName: firstName,lastName: lastName,  email: email,
                phoneNumber : phoneNumber, customer : this.state.existingUserInfo.id,
                password : password, socialLogin : 0, isGuest : false
            })
            .then(res => {

                // console.log(res.data)

                this.setState({

                    loading : false
                })


                this.props.logIn({

                    username : user.email,
                    password : user.password
                })


            }).catch(error => {

            this.setState({

                loading : false
            })

        });


    }


    mergeGuestAccount(data){


        //  this.updateAccountInfoMerge(user)
        // this.updatePasswordAccountMerge(user)


        var url = baseUrl + "merge_guests";

        const firstName = data.user.firstName
        const lastName = data.user.lastName
        const email = data.user.email
        const phoneNumber = data.user.phoneNumber

        axios.post(url,
            { firstName: firstName,lastName: lastName,  email: email,
                phoneNumber : phoneNumber, customer : this.state.existingUserInfo.id
            })
            .then(res => {

                // console.log(res.data)

                this.setState({

                    loading : false
                })

                // saveUserData(this.state.existingUserInfo)

                this.props.setUserDetail(this.state.existingUserInfo)



                // console.log("guest checkout abondoned values ")
                // console.log(data.abondonCartItem)


                this.hidePopUp()

                this.props.logIn({

                    username : data.user.email,
                    password : "qwerty"
                })

                // this.props.addCartItem(data.abondonCartItem)


                this.props.addCartItem(

                    {
                        "user":this.props.userDetail,
                        "cartItem":{
                            "itemType":0,
                            "activity": data.abondonCartItem.activity,
                            'cartPriceOptions': data.abondonCartItem.cartPriceOptions

                        }
                    }

                )
                //
                //
                //
                history.push("/checkout")


            }).catch(error => {

            this.setState({

                loading : false
            })

        });


    }



    createUser(user){



        this.setState({

            socialUserInfo:   {
                "firstName": user.name.trim().split(" ")[0],
                "lastName": user.name.trim().split(" ")[1],
                "email": user.email,
                "password": "qwerty",
                "image": "",
                "socialLogin":user.socialLogin
            }

        })



        this.socialUserPhoneInput()


    }



    socialUserPhoneInput(){


        // this.setState({
        //
        //     socialUserInfo:   {
        //                 "firstName": "Dua",
        //                 "lastName":"Arun",
        //                 "email": "email@gmail.com",
        //                 "image": "",
        //                  "socialLogin":1
        //     }
        //
        // })

        this.props.setSocialUserInfo(this.state.socialUserInfo)

        // this.props.socialLogin(true)
        this.props.socialLoginPopUp(true)


    }



    responseGoogle(response){

        // console.log("google response")
        // console.log(response)


        // this.checkIfSocialUserExist(
        //     {
        //         "name": "Sample Name",
        //         "email": "sample@gmail.com",
        //         "image": "",
        //         "socialLogin":1
        //
        //     }
        // )


        // this.checkIfUserExist(
        //         {
        //             "name": "Riya Arora", "email": "riyaarora080492@gmail.com", "socialLogin": 2
        //
        //         })


        if (response&&response.profileObj) {

            this.checkIfSocialUserExist(
                {
                    "name": response.profileObj.name, "email": response.profileObj.email, "socialLogin": 2

                })
        }


    }


    checkIfUserExist(event){

        this.setState({

            email : event.target.value,
            emailLoad : true
        })


        var  url = baseUrl+"customers.json?groups[]=customer&email="+event.target.value;

        // console.log(url)



        axios.get(url)
            .then(res => {

                if (res.data.length>0){


                    // console.log("user exists ")
                    // console.log(res.data)



                    this.setState({

                        existingUserInfo: res.data[0]
                    })




                    if (this.state.existingUserInfo.isGuest||this.state.existingUserInfo.socialLogin>0){


                        alert("social user "+ this.state.existingUserInfo.isGuest +" "+this.state.existingUserInfo.socialLogin)

                        this.setState({

                            userExists : false,
                        })
                    }else {


                        this.setState({

                            userExists : true,
                        })
                    }

                        // this.props.logIn({"username":user.email,"password": "qwerty"})


                        // console.log("already user exists ")

                        this.setState({

                            // userExists: true,
                            // existingUserInfo:res.data[0],
                            emailLoad: false

                        })




                } else {

                    this.setState({

                        userExists : false,
                        emailLoad: false
                    })


                    // this.createUser(user)

                }




            }).catch(error => {

            // console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }

    checkIfGuestUserExist(event){

        this.setState({

            email : event.target.value,
            emailLoad : true
        })


        var  url = baseUrl+"customers.json?groups[]=customer&email="+event.target.value;

        // console.log(url)



        axios.get(url)
            .then(res => {

                if (res.data.length>0){


                    // console.log("user exists ")
                    // console.log(res.data)



                    this.setState({

                        existingUserInfo: res.data[0]
                    })




                    if (this.state.existingUserInfo.isGuest){


                        // alert("social user "+ this.state.existingUserInfo.isGuest +" "+this.state.existingUserInfo.socialLogin)

                        this.setState({

                            userExists : false,
                        })



                    }else {


                        this.setState({

                            userExists : true,
                        })

                        if (this.state.existingUserInfo.socialLogin>0){


                            this.setState({

                                socialUserExists : true,
                            })

                        }


                    }

                    // this.props.logIn({"username":user.email,"password": "qwerty"})


                    // console.log("already user exists ")

                    this.setState({

                        // userExists: true,
                        // existingUserInfo:res.data[0],
                        emailLoad: false

                    })




                } else {

                    this.setState({

                        userExists : false,
                        emailLoad: false
                    })


                    // this.createUser(user)

                }




            }).catch(error => {

            // console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }




    returningGuestSignUpAndUpdate(data){


        var url = baseUrl + "edit_accounts";

        // console.log(url)


        const firstName = data.user.firstName
        const lastName = data.user.lastName
        const email = data.user.email
        const phoneNumber = data.user.phoneNumber



        axios.post(url,
            { firstName: firstName,lastName: lastName,  email: email,
                phoneNumber : phoneNumber, customer : this.state.existingUserInfo.id
            })
            .then(res => {

                // console.log(res.data)

                this.setState({

                    loading : false
                })


                this.props.logIn({username:email, password : "qwerty"})


            }).catch(error => {

            this.setState({

                loading : false
            })

        });




    }

    checkIfSocialUserExist(user){



        var  url = baseUrl+"customers.json?groups[]=customer&email="+user.email;

        // console.log(url)



        axios.get(url)
            .then(res => {

                if (res.data.length>0){


                    // console.log("social user exists ")
                    // console.log(res.data)

                    if (res.data[0].socialLogin>0){

                        // console.log("social user exists ")

                        this.props.logIn({"username":user.email,"password": "qwerty"})


                        this.setState({

                            socialUserExists: false
                        })

                    } else {


                        // console.log("social user doesnt  exists ")

                        this.setState({

                            socialUserExists: true
                        })
                    }



                } else {

                    this.setState({

                        socialUserExists : false,
                    })


                    this.createUser(user)

                }




            }).catch(error => {

            // console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }
    checkIfSocialUserExistOld(event){

        this.setState({

            email : event.email,
            emailLoad : true
        })


        var    url = baseUrl+"customers.json?groups[]=customer&email="+event.email;

        // console.log(url)



        axios.get(url)
            .then(res => {


                this.setState({

                    emailLoad : false
                })

                this.setState({

                    loadingRefresh : false,
                })



                // console.log(res.data)


                if (res.data.length>0){


                    this.setState({

                        socialUserExists : true,
                    })

                } else {

                    this.setState({

                        socialUserExists : false,
                    })


                }




            }).catch(error => {

            // console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }




    handleSubmitGuestSignUp = event => {


        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            // console.log(data)



                this.setState({

                    passwordError : false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")


                // const confirmPassword = data.get("confirmPassword")




            if (this.state.existingUserInfo&&this.state.existingUserInfo.isGuest) {



                this.mergeGuestAccount( {
                    "abondonCartItem": this.props.abondonCartItem,
                    "user": {
                        "firstName": firstName, "lastName": lastName,
                        "email": email, "phoneNumber": phoneNumber,
                        "isGuest": true,

                        "password": "qwerty"
                    }

                })




                // this.returningGuestSignUpAndUpdate({
                //
                //
                //     "abondonCartItem": this.props.abondonCartItem,
                //     "user": {
                //     "firstName": firstName, "lastName": lastName,
                //         "email": email, "phoneNumber": phoneNumber,
                //         "isGuest": true,
                //
                //         "password": "qwerty"
                //
                // }
                // })


            }else {
                this.props.signUpWithCartItem(
                    {

                        "abondonCartItem": this.props.abondonCartItem,
                        "user": {
                            "firstName": firstName, "lastName": lastName,
                            "email": email, "phoneNumber": phoneNumber,
                            "isGuest": true,

                            "password": "qwerty"
                        }

                    }
                )

                // if user was trying to access checkout
                if (this.props.showLoginCheckoutPopUp) {

                    history.push("/checkout")


                }

            }






        }



    }
    handleSubmitGuestSignUpNew = event => {




        event.preventDefault();




        const form = event.currentTarget;


        if (!this.handleValidationGuest() ) {



            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(this.handleValidationGuest()&&form.checkValidity()){

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            // console.log(data)



            this.setState({

                passwordError : false
            })

            const firstName = data.get("firstName")
            const lastName = data.get("lastName")
            const email = data.get("email")
            const phoneNumber = data.get("phoneNumber")


            // const confirmPassword = data.get("confirmPassword")




            if (this.state.existingUserInfo&&this.state.existingUserInfo.isGuest) {



                this.mergeGuestAccount( {
                    "abondonCartItem": this.props.abondonCartItem,
                    "user": {
                        "firstName": firstName, "lastName": lastName,
                        "email": email, "phoneNumber": phoneNumber,
                        "isGuest": true,

                        "password": "qwerty"
                    }

                })




                // this.returningGuestSignUpAndUpdate({
                //
                //
                //     "abondonCartItem": this.props.abondonCartItem,
                //     "user": {
                //     "firstName": firstName, "lastName": lastName,
                //         "email": email, "phoneNumber": phoneNumber,
                //         "isGuest": true,
                //
                //         "password": "qwerty"
                //
                // }
                // })


            }else {
                this.props.signUpWithCartItem(
                    {

                        "abondonCartItem": this.props.abondonCartItem,
                        "user": {
                            "firstName": firstName, "lastName": lastName,
                            "email": email, "phoneNumber": phoneNumber,
                            "isGuest": true,

                            "password": "qwerty"
                        }

                    }
                )

                // if user was trying to access checkout
                if (this.props.showLoginCheckoutPopUp) {

                    history.push("/checkout")


                }

            }






        }



    }


    handleSubmitSocialSignUp = event => {


        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            // console.log(data)



            this.setState({

                passwordError : false
            })

            const firstName = data.get("firstName")
            const lastName = data.get("lastName")
            const email = data.get("email")
            const phoneNumber = data.get("phoneNumber")




            this.props.signUpWithCartItem(

                {

                        // "abondonCartItem" : this.props.abondonCartItem,
                        "user" : {"firstName":firstName, "lastName":lastName,
                        "email":email,"phoneNumber":phoneNumber,
                        "isGuest":false,
                        "socialLogin": this.props.socialUserInfo.socialLogin,
                            "password":"qwerty"

                        }

                }
            )




            // if user was trying to access checkout
            // if (this.props.showLoginCheckoutPopUp) {
            //
            //     history.push("/checkout")
            //
            //
            // }
        }



    }
    handleSubmitSocialSignUpNew = event => {


        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())


        if (this.handleValidationSocial()) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(this.handleValidationSocial()&&form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            // console.log(data)



            this.setState({

                passwordError : false
            })

            const firstName = data.get("firstName")
            const lastName = data.get("lastName")
            const email = data.get("email")
            const phoneNumber = data.get("phoneNumber")




            this.props.signUpWithCartItem(

                {

                    // "abondonCartItem" : this.props.abondonCartItem,
                    "user" : {"firstName":firstName, "lastName":lastName,
                        "email":email,"phoneNumber":phoneNumber,
                        "isGuest":false,
                        "socialLogin": this.props.socialUserInfo.socialLogin,
                        "password":"qwerty"

                    }

                }
            )




            // if user was trying to access checkout
            // if (this.props.showLoginCheckoutPopUp) {
            //
            //     history.push("/checkout")
            //
            //
            // }
        }



    }



    handleSubmitSignUp = event => {

        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            // console.log(data)

            // if (data.get("password")==="qwerty"){
            //
            //     // alert("hello")
            //
            //     this.setState({
            //
            //         passwordError : true
            //     })
            //
            //     event.stopPropagation();
            //
            //
            // } else {

                this.setState({

                    passwordError : false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")
                const password = data.get("password")






            if (this.state.existingUserInfo.isGuest||this.state.existingUserInfo.socialLogin>0){

                // this.props.signUp({
                //     "firstName": firstName, "lastName": lastName,
                //     "email": email, "phoneNumber": phoneNumber,
                //     "password": password
                // })
                //


                // this.props.logIn({"username":email,"password": "qwerty"})



                this.mergeAccount({
                    "firstName": firstName, "lastName": lastName,
                    "email": email, "phoneNumber": phoneNumber,
                    "password": password
                })


            }else {
                this.props.signUpWithCartItem(
                    {

                        "abondonCartItem": this.props.abondonCartItem,
                        "user": {
                            "firstName": firstName, "lastName": lastName,
                            "email": email, "phoneNumber": phoneNumber,
                            "isGuest": false, "password": password
                        }

                    }
                )


            }




        }
    }
    handleSubmitSignUpNew = event => {

        event.preventDefault();
        //
        // const form = event.currentTarget;
        //
        // // console.log(form.checkValidity())
        //
        // if (form.checkValidity() === false) {
        //
        //     event.stopPropagation();
        //
        //     this.setState({
        //
        //         validatedForm : true
        //     });
        // }
        //
        //
        // if(form.checkValidity()) {

        const form = event.currentTarget;

        // // console.log(form.checkValidity())


        if (!this.handleValidationSignUp() ) {



            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }



        if (this.handleValidationSignUp()&&form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            // console.log(data)



            this.setState({

                passwordError : false
            })

            const firstName = data.get("firstName")
            const lastName = data.get("lastName")
            const email = data.get("email")
            const phoneNumber = data.get("phoneNumber")
            const password = data.get("password")




            if (this.state.existingUserInfo&&this.state.existingUserInfo.isGuest||this.state.existingUserInfo.socialLogin>0){

                // this.props.signUp({
                //     "firstName": firstName, "lastName": lastName,
                //     "email": email, "phoneNumber": phoneNumber,
                //     "password": password
                // })
                //


                // this.props.logIn({"username":email,"password": "qwerty"})



                this.mergeAccount({
                    "firstName": firstName, "lastName": lastName,
                    "email": email, "phoneNumber": phoneNumber,
                    "password": password
                })


            }else {
                this.props.signUpWithCartItem(
                    {

                        "abondonCartItem": this.props.abondonCartItem,
                        "user": {
                            "firstName": firstName, "lastName": lastName,
                            "email": email, "phoneNumber": phoneNumber,
                            "isGuest": false, "password": password
                        }

                    }
                )


            }




        }
    }




    handleSubmitLogin = event => {

        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const username = data.get("email")
            const password = data.get("password")

            this.props.logIn({"username": username, "password": password})

            // history.push("/")



        }

    }



    hidePopUp(){

        this.props.loginPopUp(false)
        this.props.loginCheckoutPopUp(false)


    }

    hideDummy(){



    }


    responseFacebook(response) {


        // console.log("Facebook Login")


        // console.log(response)

        // console.log(response)

        if (response&&response.profile) {

            // console.log("check if exists")

            this.checkIfSocialUserExist(
                {
                    "name": response.profile.name,
                    "email": response.profile.email,
                    "image": response.profile.picture.data.url,
                     "socialLogin":1

                }
            )


        }


    }



    showLogin(){


        this.setState({

            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            emailLoad: false

        })



    }

    showSignUp(){

        this.setState({
            showSignUp: true,
            showGuestSignUp: false,
            showLogin: false,
        })


    }

    showGuestSignUp(){

        this.setState({

            showSignUp: false,
            showGuestSignUp: true,
            showLogin: false,

        })
    }




    render (){

        return(


            <Modal   show={!this.props.isLoggedIn &&(this.props.showLoginPopUp||this.props.showLoginCheckoutPopUp)}

                     onHide={this.hideDummy}
                     className={"custom-modal-popup popup-form"}
            >
                <button onClick={this.hidePopUp} className="close" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                <div className="row py-3 justify-content-center mobile-menu-row">
                    <div className="col mobile-menu">

                      {!this.props.showSocialLoginPopUp&&this.state.showLogin &&
                      <div className="form-col-left col-12">
                            <h3 className="form-heading">Sign In </h3>
                            { this.props.loginFailed &&
                            <Alert key={"alert"} variant={"danger"}>Invalid Credentials, Try again!</Alert> }
                                <Form  noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitLogin}>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Form.Label column sm={10}>
                                            Email
                                        </Form.Label>
                                        <Col sm={12}>
                                            <Form.Control value={this.state.email} onChange={this.checkIfUserExist}  autocomplete="email" required name={"email"} type="email" placeholder="" />
                                            {this.state.emailLoad &&    <Spinner
                                                className={"email-input-spinner"}
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}  controlId="formHorizontalPassword">
                                        <Form.Label column sm={12}>
                                            Password
                                        </Form.Label>
                                        <Col sm={12}>
                                            <Form.Control autocomplete="password" required name={"password"} type="password" placeholder="Password" />
                                            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalCheck">
                                        <Col sm={{ span: 6 }}>
                                            <Form.Check label="Remember me" size="lg" />
                                        </Col>
                                        <Col sm={{ span: 6 }} className="text-right">
                                            <Link onClick={this.hidePopUp} to="/forgot-password">Forgot Password?</Link>
                                        </Col>
                                    </Form.Group>
                                    {/*<Form.Group as={Row}   controlId="exampleForm.ControlInput1">*/}
                                        {/*<Col className={"justify-content-md-center d-flex"}>*/}
                                            {/*<Captcha  siteKey="YOUR_SITE_KEY" onResponse={this.showResponse}></Captcha>*/}
                                        {/*</Col>*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group as={Row} className="justify-content-center"  controlId="exampleForm.ControlInput1">
                                        <Col sm={{ span: 5 }}>
                                            <div className="d-flex btn-group">
                                                <button type={"submit"}  className="btn btn-no-radius mr-1">Sign In</button>
                                                {/* <button to="/login"  onClick={this.showSignUpPopUp} className="btn btn-signup" >Sign Up</button> */}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col sm={{ span: 12, offset: 0 }}>

                                            {this.state.socialUserExists &&
                                            <Alert key={"alert"} variant={"danger"}>
                                                An account with this email already exists, please proceed to login. Thanks!
                                            </Alert>}
                                        <div className="forgot-col d-flex text-center justify-content-center">

                                            <div  className="social fb-login-btn">




                                                <FacebookProvider appId="860677251005804">

                                                    <Login
                                                        scope="email"
                                                        onCompleted={this.responseFacebook}
                                                        onError={this.responseFacebook}
                                                    >

                                                        {({ loading, handleClick, error, data }) => (

                                                            <div onClick={handleClick}
                                                                 className="fb-btn mr-4">

                                                            </div>


                                                        )}

                                                    </Login>



                                                </FacebookProvider>


                                                <GoogleLogin
                                                    clientId="178867397101-br3ke941t0iaensupo3bh5b5ct1oklha.apps.googleusercontent.com"
                                                    render={renderProps => (

                                                        <div onClick={renderProps.onClick} disabled={renderProps.disabled} id="g-btn"
                                                             className="loginBtn loginBtn--google">

                                                        </div>

                                                    )}
                                                    buttonText="Login"
                                                    onSuccess={this.responseGoogle}
                                                    onFailure={this.responseGoogle}
                                                    cookiePolicy={'single_host_origin'}
                                                />
                                            </div>

                                        </div>
                                        <div className=" d-flex w-100 justify-content-between">

                                        </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-0">
                                        <Col sm={{ span: 12, offset: 0 }}>
                                            <p className="text-center">Don't have an account? <button   onClick={this.showSignUp} className="btn signup-link" >Sign Up New</button></p>
                                            {this.props.showLoginCheckoutPopUp && <p className="text-center">or</p>}

                                        </Col>
                                    </Form.Group>

                                    {this.props.showLoginCheckoutPopUp  && <Form.Group as={Row} style={{margin:"0!important"}}>
                                        <Col sm={{ span: 1 }}></Col>

                                        <Col sm={{ span: 10  }}>
                                            <div className="d-flex btn-group">
                                              <div  onClick={this.showGuestSignUp}  className="btn " >Continue as a Guest</div>
                                            </div>

                                        </Col>
                                        <Col sm={{ span: 1 }}>
                                        </Col>
                                    </Form.Group>  }
                                </Form>
                        </div>

                            }

                            {this.state.showSignUp &&
                               <div className="form-col-left col-12">
                                      <h4 className="back-btn-popupform">
                                        <button onClick={this.showLogin} className="btn"><i class="fas fa-chevron-left"></i></button>Sign In
                                    </h4>
                               <h3 className="form-heading">Sign Up</h3>


                               <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitSignUpNew}>
                                   <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                       <Col sm={{ span: 12 }}>
                                       <Form.Label>First Name</Form.Label>
                                       <Form.Control autocomplete="given-name" required  name={"firstName"} type="text" placeholder="" />
                                           <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                       </Col>
                                   </Form.Group>

                                   <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                       <Col sm={{ span: 12 }}>
                                       <Form.Label>Last Name</Form.Label>
                                       <Form.Control autocomplete="family-name" required  name={"lastName"} type="text" placeholder="" />
                                           <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                       </Col>
                                   </Form.Group>

                                   <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                       <Col sm={{ span: 12 }}>
                                       <Form.Label>Email</Form.Label>
                                           <Form.Control autocomplete="email" value={this.state.email} onChange={this.checkIfUserExist}  required name={"email"} type="email" placeholder="" />
                                           {this.state.emailLoad &&
                                           <Spinner
                                               className={"email-input-spinner"}
                                               as="span"
                                               animation="border"
                                               size="sm"
                                               role="status"
                                               aria-hidden="true"
                                           />
                                           }
                                           <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                       </Col>
                                   </Form.Group>


                                   {this.state.userExists &&
                                   <Form.Group as={Row} controlId="exampleForm.ControlInput1">
                                       <Col sm={{span: 12}}>


                                           <div  style={{color : "#EC1C24"}} className="email-exists-block">An account with this email already exists.</div>

                                           <div className="email-exists-block">Go to <Link  style={{color : "#007dc4", fontWeight: "600"}} onClick={this.showLogin} className="">Log In</Link> page</div>



                                       </Col>
                                   </Form.Group>
                                   }


                                   <Form.Group as={Row} className=""  controlId="exampleForm.ControlInput1">
                                       <Col sm={{ span: 12 }}>
                                       <Form.Label>Phone Number</Form.Label>
                                           {/*<InputGroup className="phone_no">*/}
                                               {/*<InputGroup.Prepend>*/}
                                                   {/*<InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>*/}
                                               {/*</InputGroup.Prepend>*/}

                                           {/*<Form.Control  maxLength={9} minLength={9} required  name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                           {/*</InputGroup>*/}

                                           <PhoneInput
                                               onChange={this.handleChange.bind(this, "phoneNumber")}
                                               inputClass={this.state.phoneNumberInValid?"is-invalid":""}
                                               inputProps={{
                                                   name: 'phoneNumber',
                                                   // required: true,
                                                   defaultErrorMessage:"Invalid",
                                                   // minLength:9,
                                               }}
                                               country={'ae'}
                                           />


                                           {/*<PhoneInputIntl />*/}


                                           {/*<Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                           {/*<Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>*/}
                                           {/*{this.state.phoneNumberInValid && <div  style={{display:"block"}} className="invalid-feedback">Phone No. must be vaild</div>}*/}
                                           <span className={"invalid-feedback-phone"}>{this.state.errors["phoneNumber"]}</span>


                                       </Col>
                                   </Form.Group>


                                   <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                       <Col sm={{ span: 12 }}>
                                       <Form.Label>Password</Form.Label>
                                       <Form.Control onChange={this.onChange.bind(this, "password")} autocomplete="password"  required name={"password"} type="password" placeholder="" />
                                           <span className={"invalid-feedback-phone"}>{this.state.errors["password"]}</span>

                                       </Col>
                                   </Form.Group>
                                   {!this.state.userExists &&
                                   <Form.Group as={Row} className={"justify-content-md-center"}
                                               controlId="exampleForm.ControlInput1">
                                       <Col sm={{span: 1}}/>

                                       <Col sm={{span: 5}}>
                                           <div className="d-flex btn-group">
                                               <button className="btn btn-signup">Sign Up</button>
                                           </div>

                                       </Col>
                                       <Col sm={{span: 1}}/>
                                   </Form.Group>
                                   }

                                   <Form.Group as={Row} className="mb-0 mt-4">
                                        <Col sm={{ span: 12, offset: 0 }}>
                                            <p className="text-center">Already have an account? <button onClick={this.showLogin} className="btn signup-link" >Sign In</button></p>
                                        </Col>
                                    </Form.Group>

                               </Form>
                           </div>

                            }


                        {this.state.showGuestSignUp &&

                        <div className="form-col-left col-12">
                            <h4 className="back-btn-popupform">
                                        <button onClick={this.showLogin} className="btn"><i class="fas fa-chevron-left"></i></button>
                                         Sign In
                                    </h4>


                            <h3 className="form-heading">Continue as Guest</h3>


                            <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitGuestSignUpNew}>
                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control autocomplete="given-name" required  name={"firstName"} type="text" placeholder="" />
                                        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control autocomplete="family-name" required  name={"lastName"} type="text" placeholder="" />
                                        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control autocomplete="email" value={this.state.email} onChange={this.checkIfGuestUserExist}  required name={"email"} type="email" placeholder="" />
                                        {this.state.emailLoad &&    <Spinner
                                            className={"email-input-spinner"}
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                {this.state.userExists &&
                                <Form.Group as={Row} controlId="exampleForm.ControlInput1">
                                    <Col sm={{span: 12}}>


                                        <div  style={{color : "#EC1C24"}} className="email-exists-block">

                                            {this.state.existingUserInfo.socialLogin==0||this.state.existingUserInfo.socialLogin==null&&"An account with this email already exists."}

                                            {this.state.existingUserInfo.socialLogin==2&&"Opps! You last time signed up with your Gmail account."}
                                            {this.state.existingUserInfo.socialLogin==1&&"Opps! You last time signed up with your Facebook account."}


                                        </div>

                                        <div className="email-exists-block">Go to <Link  style={{color : "#007dc4", fontWeight: "600"}} onClick={this.showLogin} className="">Log In</Link> page</div>



                                    </Col>
                                </Form.Group>
                                }


                                <Form.Group as={Row} className=""  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>Phone Number</Form.Label>

                                        <PhoneInput
                                            onChange={this.handleChangeGuest.bind(this, "phoneNumber")}
                                            inputClass={this.state.phoneNumberInValidGuest?"is-invalid":""}
                                            inputProps={{
                                                name: 'phoneNumber',
                                                // required: true,

                                                defaultErrorMessage:"Invalid",
                                                // minLength:9,
                                            }}
                                            country={'ae'}
                                        />


                                        {/*<PhoneInputIntl />*/}


                                        {/*<Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                        {/*<Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>*/}
                                        {/*{this.state.phoneNumberInValid && <div  style={{display:"block"}} className="invalid-feedback">Phone No. must be vaild</div>}*/}
                                        <span className={"invalid-feedback-phone"}>{this.state.errorsGuest["phoneNumber"]}</span>



                                            {/*<InputGroup.Prepend>*/}
                                                {/*<InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>*/}
                                            {/*</InputGroup.Prepend>*/}

                                            {/*<Form.Control  maxLength={9} minLength={9} required  name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}


                                        {/*</InputGroup>*/}

                                    </Col>
                                </Form.Group>


                                {!this.state.userExists &&
                                <Form.Group as={Row}  className={"justify-content-md-center"} controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 1 }}/>

                                    <Col sm={{ span: 5 }}>
                                        <div className="d-flex btn-group">

                                            <Button  type={"submit"}
                                                     className="btn btn-no-radius"
                                                     type={"submit"}  >
                                                { this.props.loading && <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> }

                                                { this.props.loading  ? "Wait.." : "Checkout"}

                                            </Button>



                                        </div>

                                    </Col>
                                    <Col sm={{ span: 1 }}/>
                                </Form.Group> }



                            </Form>
                        </div>

                        }


                        {this.props.showSocialLoginPopUp&&

                        <div className="form-col-left col-12">
                            {/*<h4 className="back-btn-popupform">*/}
                                {/*<button onClick={this.showLogin} className="btn"><i class="fas fa-chevron-left"></i></button>*/}
                                {/*Sign In*/}
                            {/*</h4>*/}


                            {this.props.showSocialLoginPopUp? <h3 className="form-heading">{this.props.socialUserInfo.socialLogin==1?"Facebook":"Google"} Login</h3>:  <h3 className="form-heading">Continue as Guest</h3>}


                            <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitSocialSignUpNew}>
                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control  required value={this.props.showSocialLoginPopUp&&this.props.socialUserInfo&&this.props.socialUserInfo.firstName} name={"firstName"} type="text" placeholder="" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control required  value={this.props.showSocialLoginPopUp&&this.props.socialUserInfo&&this.props.socialUserInfo.lastName} name={"lastName"} type="text" placeholder="" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control value={this.props.showSocialLoginPopUp&&this.props.socialUserInfo&&this.props.socialUserInfo.email}  required name={"email"} type="email" placeholder="" />
                                        {this.state.emailLoad &&    <Spinner
                                            className={"email-input-spinner"}
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} className=""  controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 12 }}>
                                        <Form.Label>Phone Number</Form.Label>


                                        <PhoneInput
                                            onChange={this.handleChangeSocial.bind(this, "phoneNumber")}
                                            inputClass={this.state.phoneNumberInValidSocial?"is-invalid":""}
                                            inputProps={{
                                                name: 'phoneNumber',
                                                // required: true,

                                                defaultErrorMessage:"Invalid",
                                                // minLength:9,
                                            }}
                                            country={'ae'}
                                        />


                                        {/*<PhoneInputIntl />*/}


                                        {/*<Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                        {/*<Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>*/}
                                        {/*{this.state.phoneNumberInValid && <div  style={{display:"block"}} className="invalid-feedback">Phone No. must be vaild</div>}*/}
                                        <span className={"invalid-feedback-phone"}>{this.state.errorsSocial["phoneNumber"]}</span>


                                    </Col>
                                </Form.Group>


                                {!this.state.userExists &&
                                <Form.Group as={Row}  className={"justify-content-md-center"} controlId="exampleForm.ControlInput1">
                                    <Col sm={{ span: 1 }}/>

                                    <Col sm={{ span: 5 }}>
                                        <div className="d-flex btn-group">



                                            <Button  type={"submit"}
                                                     className="btn btn-no-radius"
                                                     type={"submit"}  >
                                                { this.props.loading && <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> }

                                                { this.props.loading  ? "Wait.." : "Submit"}

                                            </Button>



                                        </div>

                                    </Col>
                                    <Col sm={{ span: 1 }}/>
                                </Form.Group> }



                            </Form>
                        </div>

                        }







                   </div>
                </div>
            </Modal>

        )
    }
}



const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showSocialLoginPopUp: state.showSocialLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        userDetail: state.userDetail,
        abondonCartItem : state.abondonCartItem,
        socialUserInfo: state.socialUserInfo,



    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        setSocialUserInfo: (item) => dispatch(actionCreator.setSocialUserInfo(item)),
        socialLoginPopUp : (item) => dispatch(actionCreator.socialLoginPopUp(item)),
        setUserDetail: (user) => dispatch(actionCreator.setUserDetail(user)),




    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(LoginPopUp);


