import React, { Component } from 'react';
import { Spinner,Col, Form, Button} from 'react-bootstrap';
import {baseUrl} from "../Util/Constants";
import axios from "axios/index";
import MetaTags from 'react-meta-tags';

class  ContactUs extends Component{

    constructor() {
        super();
        this.state = {
            validatedForm: false,
            isChecked: false,
            passwordError : false,
            btnLoading : false,
            successSubmit : false,


        }
        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
    }




    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const email = data.get("email")
            const name = data.get("name")
            const message = data.get("message")




            axios.post(baseUrl+"contact_uses",{"email" : email,"message" : message,"name" : name})
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        btnLoading : false,
                        successSubmit : true
                    })

                })
        }


    }


    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render (){
        return(
            <div className="bg-gray">


                <MetaTags>

                    <title>Contact Us for an incredible weekend | Happy Weekends</title>
                    <meta name="title" content="Contact Us for an incredible weekend | Happy Weekends" />
                    <meta name="description" content="If you want us to help you with something, get in touch with us on care@happy-weekends.com. Happy Weekends will plan a unique and amazing weekend for you. Book your favourite experiences today. Contact us to know more." />

                    <link rel="canonical" href="https://www.happy-weekends.com/" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.happy-weekends.com/" />
                    <meta property="og:title" content="Contact Us for an incredible weekend | Happy Weekends" />
                    <meta property="og:description" content="If you want us to help you with something, get in touch with us on care@happy-weekends.com. Happy Weekends will plan a unique and amazing weekend for you. Book your favourite experiences today. Contact us to know more." />
                    <meta property="og:image" content="/cover-image.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                    <meta property="twitter:title" content=" Contact Us for an incredible weekend | Happy Weekends" />
                    <meta property="twitter:description" content="If you want us to help you with something, get in touch with us on care@happy-weekends.com. Happy Weekends will plan a unique and amazing weekend for you. Book your favourite experiences today. Contact us to know more." />
                    <meta property="twitter:image" content="/cover-image.jpg" />


                </MetaTags>


                <section className="signup-banner contact-banner">
                    <div class="contact-blue-banner">
                        <div class="col align-items-center">
                            <h2>Contact Us</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-9">
                        <div className="border-div">
                            <div className="contact-form">
                                <div className="row">
                                <div className="col-md-6 pt-0 pl-0 pb-0">
                                    <div className="help-desk-col">
                                        <h2>Hello! What can we help you with today?</h2>
                                        <p  >Please email us at <a style={{fontWeight: "600"}}  href="mailto:care@happy-weekends.com">care@happy-weekends.com</a> or submit the form and our team would contact you soon.</p>
                                    </div>
                                    <div className="help-desk-bottom for-web-custom">
                                        <h2>Got a great idea/suggestion?</h2>
                                        <p>If you've got an interesting idea/suggestion or have come across some hidden gems experiences in the UAE that you would like others in the community to experience it, then do share with us. Our team would love to review and consider it. Let's make weekends happier! </p>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="contact-from-right">
                                    <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label className="text-white">Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder=""
                                                    defaultValue=""
                                                    name={"name"}
                                                />

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Form.Label className="text-white">Email Id</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="email"
                                                    placeholder=""
                                                    defaultValue=""
                                                    name={"email"}
                                                />

                                                <Form.Control.Feedback type="invalid">Email Id must be valid</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Form.Label className="text-white">Message</Form.Label>
                                                <Form.Control as="textarea" rows="4"
                                                    required
                                                    type="email"
                                                    placeholder=""
                                                    defaultValue=""
                                                              name={"message"}
                                                />

                                            </Form.Group>
                                        </Form.Row>



                                        <Button  className={"contact-us-submit"}
                                                type={"submit"}

                                        >
                                            { this.state.btnLoading && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> }

                                            {!this.state.successSubmit &&
                                            <>
                                                {this.state.btnLoading ?  "Wait" : "Submit" }
                                            </>

                                            }

                                            { this.state.successSubmit &&  "Request Submitted Successfully" }

                                        </Button>



                                    </Form>
                                    </div>
                                     </div>
                            </div>
                                <div className={"row for-mobile-custom"}>
                                    <div className="help-desk-bottom  ">
                                        <h2>Got a great idea/suggestion?</h2>
                                        <p>If you've got an interesting idea/suggestion or have come across some hidden gems experiences in the UAE that you would like others in the community to experience it, then do share with us. Our team would love to review and consider it. Let's make weekends happier! </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default ContactUs;