import React, { Component } from 'react';
import './NewsLetter.css';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel,Form, CarouselItem, Modal, ModalFooter,ModalDialog, ModalTitle, ModalBody} from 'react-bootstrap'
import {baseUrl,baseImgUrl} from  '../Util/Constants'



class  NewsLetter extends Component{


    constructor() {
        super();

        this.state ={

            btnLoading : false,
            successSubmit : false,
            emailExist : false,

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);


    }





    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const username = data.get("email")



            axios.post(baseUrl+"newsletters",{"email" : data.get("email")})
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        btnLoading : false,
                        successSubmit : true
                    })

                })
                .catch(error => {
                    console.log("Bad Response")

                    console.log(error.response)


                    this.setState({
                        btnLoading : false,
                        emailExist : true,
                        successSubmit : true

                    })

                });
        }


    }


    handleButtonClick = () => {

        // this.setState({
        //     btnLoading : true
        // })

    };


    render (){


        return(
            <section className="newsletter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">

                            <h2>Sign up for our newsletter</h2>
                            <p>Be the first one to receive handpicked weekends experiences in your mailbox.</p>

                        </div>
                        <div className="col-lg-4 ml-auto text-right">
                            {/*<form className="newsletter-form" onSubmit={this.handleSubmit}>*/}
                                <Form className={"newsletter-form"}  noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>


                                {/*<input name={"email"} type="email" required placeholder="Your Email Address" />*/}
                                    <Form.Control disabled ={this.state.successSubmit} value={this.state.email} onChange={this.checkIfUserExist}  required name={"email"} type="email" placeholder="Your Email Address" />


                                    <Button variant={"light"} className={"btn-newsletter-submit"}
                                          type={"submit"}>

                                    { this.state.btnLoading && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> }

                                    {!this.state.successSubmit &&
                                    <>
                                    {this.state.btnLoading ?  "Wait" : "Submit" }
                                    </>

                                    }

                                        { this.state.successSubmit &&!this.state.emailExist&&  "Subscribed Successfully" }
                                        { this.state.successSubmit &&this.state.emailExist &&  "Already Subscribed" }


                                    </Button>


                                </Form>
                        </div>
                    </div>
                </div>
            </section>
         )
    }



}





export default NewsLetter;