import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import GoogleLogin from 'react-google-login';
import { FacebookProvider, Login  } from 'react-facebook';
import queryString from 'query-string'
import popUpImage from '../img/newsletter-img.png';
import popUpImageDesktop from '../img/news-letter-desktop.jpg';
import popUpImageMobile  from '../img/newsletter-img.png';
import {saveKey,getKey} from '../LocalStorage/user'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

class  NewsletterPopUpNew extends Component{

    componentDidMount(){


        this.showAfterTimeInterVal()




    }


    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,
            email : null,
            userExists : false,
            socialUserExists : false,
            emailLoad : false,
            showPopUp : true,
            btnLoading : false,
            successSubmit : false,
            timerEnd : false,
            emailExist : false,



        }


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
        this.handleSubmitSignUp = this.handleSubmitSignUp.bind(this);
        this.showSignUp = this.showSignUp.bind(this);
        this.showGuestSignUp = this.showGuestSignUp.bind(this);
        this.showLogin = this.showLogin.bind(this);
        this.hidePopUp = this.hidePopUp.bind(this);
        this.checkIfUserExist = this.checkIfUserExist.bind(this);
        this.responseGoogle=this.responseGoogle.bind(this)
        this.createUser=this.createUser.bind(this)
        this.responseGoogle=this.responseGoogle.bind(this)
        this.checkIfUserExist=this.checkIfUserExist.bind(this)
        this.checkIfSocialUserExist=this.checkIfSocialUserExist.bind(this)
        this.hideAfterTimeInterVal=this.hideAfterTimeInterVal.bind(this)
        this.showAfterTimeInterVal=this.showAfterTimeInterVal.bind(this)


    }



    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const username = data.get("email")



            axios.post(baseUrl+"newsletters",{"email" : data.get("email")})
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        btnLoading : false,
                        successSubmit : true
                    })
               this.hideAfterTimeInterVal()

                })
                .catch(error => {
                    console.log("Bad Response")

                    console.log(error.response)


                    this.setState({
                        btnLoading : false,
                        emailExist : true,
                        successSubmit : true

                    })

                });
        }


    }


    showAfterTimeInterVal(){

        setInterval(() => {

            this.setState({
                timerEnd: true
            })


            var getPopUpCount= getKey("newsletter_popup")


            console.log("newsletter count"+ getPopUpCount)

            if (!getPopUpCount){


                var newsletterPopUp = saveKey("newsletter_popup",1)
                console.log("newsletter count saved 1")


            } else {

                var newsletterPopUp = saveKey("newsletter_popup",2)
                console.log("newsletter count saved 2")

            }

        }, 60000);

    }

    hideAfterTimeInterVal(){

        setInterval(() => {

            this.hidePopUp()

        }, 1000);

    }

    createUser(user){

        this.props.signUp({

            "firstName": user.name.trim().split(" ")[0], "lastName": user.name.trim().split(" ")[1],
            "email": user.email,
            "password": "qwerty",
            "socialLogin": true

        })

    }




    responseGoogle(response){

        console.log(response)

        if (response&&response.profileObj)

            this.checkIfUserExist({"name":response.profileObj.name,"email": response.profileObj.email})



    }


    checkIfUserExist(user){

        // this.setState({
        //
        //     email : event.target.value,
        //     emailLoad : true
        // })


        var  url = baseUrl+"customers.json?groups[]=customer&email="+user.email;

        console.log(url)



        axios.get(url)
            .then(res => {

                if (res.data.length>0){


                    console.log("user exists ")
                    console.log(res.data)

                    if (res.data[0].socialLogin){

                        console.log("social user exists ")

                        this.props.logIn({"username":user.email,"password": "qwerty"})


                        console.log("already user exists ")

                        this.setState({

                            userExists: false
                        })

                    } else {


                        console.log("already user exists ")

                        this.setState({

                            userExists: true
                        })
                    }



                } else {

                    this.setState({

                        userExists : false,
                    })


                    this.createUser(user)

                }




            }).catch(error => {

            console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }

    checkIfSocialUserExist(event){

        this.setState({

            email : event.target.value,
            emailLoad : true
        })


        var    url = baseUrl+"customers.json?groups[]=customer&email="+event.target.value;

        console.log(url)



        axios.get(url)
            .then(res => {


                this.setState({

                    emailLoad : false
                })

                this.setState({

                    loadingRefresh : false,
                })


                console.log("user data exits")

                console.log(res.data)


                if (res.data.length>0){


                    this.setState({

                        socialUserExists : true,
                    })

                } else {

                    this.setState({

                        socialUserExists : false,
                    })


                }




            }).catch(error => {

            console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }




    handleSubmitGuestSignUp = event => {


        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            console.log(data)
            //
            // if (data.get("password")==="qwerty"){
            //
            //     // alert("hello")
            //
            //     this.setState({
            //
            //         passwordError : true
            //     })
            //
            //     event.stopPropagation();
            //
            //
            // } else {

                this.setState({

                    passwordError : false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")

                // const confirmPassword = data.get("confirmPassword")
                this.props.signUpWithCartItem(

                    {

                    "abondonCartItem" : this.props.abondonCartItem,
                   "user" : {"firstName":firstName, "lastName":lastName,
                    "email":email,"phoneNumber":phoneNumber,
                    "isGuest":true }

                }
                )




                // if user was trying to access checkout
                if (this.props.showLoginCheckoutPopUp) {

                    history.push("/checkout")


                }
        }



    }



    handleSubmitSignUp = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            console.log(data)

            // if (data.get("password")==="qwerty"){
            //
            //     // alert("hello")
            //
            //     this.setState({
            //
            //         passwordError : true
            //     })
            //
            //     event.stopPropagation();
            //
            //
            // } else {

                this.setState({

                    passwordError : false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")
                const password = data.get("password")



            this.props.signUpWithCartItem(

                {

                    "abondonCartItem" : this.props.abondonCartItem,
                    "user" : {"firstName":firstName, "lastName":lastName,
                        "email":email,"phoneNumber":phoneNumber,
                        "isGuest":false, "password" : password }

                }
            )





            // }

        }
    }




    handleSubmitLogin = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const username = data.get("email")
            const password = data.get("password")

            this.props.logIn({"username": username, "password": password})

            // history.push("/")



        }

    }



    hidePopUp(){



        this.setState({
            showPopUp: false
        })

        this.props.showNewsletter(false)


    }


    responseFacebook(response) {

        console.log(response)


        if (response&&response.profile) {

            console.log("check if exists")

            this.checkIfSocialUserExist(
                {
                    "name": response.profile.name,
                    "email": response.profile.email,
                    "image": response.profile.picture.data.url
                }
            )


        }


    }



    showLogin(){

        this.setState({

            showSignUp: false,
            showGuestSignUp: false,
            showLogin: true,

        })

    }

    showSignUp(){

        this.setState({
            showSignUp: true,
            showGuestSignUp: false,
            showLogin: false,
        })


    }

    showGuestSignUp(){

        this.setState({

            showSignUp: false,
            showGuestSignUp: true,
            showLogin: false,

        })
    }




    render (){

        return(

            <Modal  centered show={this.state.showPopUp && this.state.timerEnd}  onHide={false}
                     className={isMobile?"subscribe_modal":"news-letter-website"} >
                <button onClick={this.hidePopUp} className="close" data-dismiss="modal" aria-label="Close">&times;</button>

                {!isMobile ?
                    <div className="modal-body desktop-newsletter-content">
                        <h2>Have a great weekend every weekend!</h2>
                        <p>Subscribe us and receive handpicked experiences to make your weekends happier.
                        </p>
                        <Form className={""} noValidate validated={this.state.validatedForm}
                              onSubmit={this.handleSubmit}>

                            <div className="form-group">

                                <Form.Control disabled={this.state.successSubmit} value={this.state.email}
                                              onChange={this.checkIfUserExist} required name={"email"} type="email"
                                              placeholder="Your Email Address"/>

                            </div>
                            <Button variant={"light"} className={"btn btn-default"}
                                    type={"submit"}>

                                {this.state.btnLoading && <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}

                                {!this.state.successSubmit &&
                                <>
                                    {this.state.btnLoading ? "Wait" : "Subscribe"}
                                </>

                                }

                                { this.state.successSubmit &&!this.state.emailExist&&  "Subscribed Successfully" }
                                { this.state.successSubmit &&this.state.emailExist &&  "Already Subscribed" }

                            </Button>
                            <button onClick={this.hidePopUp} className="skip_now">No thanks, skip for now.</button>
                        </Form>
                    </div>
                    :
                    <div className="modal-body mobile-newsletter-content">
                        <h2>Have a great weekend every weekend!</h2>
                        <p>Subscribe us and receive handpicked experiences to make your weekends happier.
                        </p>

                        <Form className={""} noValidate validated={this.state.validatedForm}
                              onSubmit={this.handleSubmit}>

                            <div className="form-group">
                                <Form.Control disabled={this.state.successSubmit} value={this.state.email}
                                              onChange={this.checkIfUserExist} required name={"email"} type="email"
                                              placeholder="Your Email Address"/>

                            </div>

                            <Button variant={"light"} className={"btn btn-default"}
                                    type={"submit"}>

                                {this.state.btnLoading && <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}

                                {!this.state.successSubmit &&
                                <>
                                    {this.state.btnLoading ? "Wait" : "Subscribe"}
                                </>

                                }

                                { this.state.successSubmit &&!this.state.emailExist&&  "Subscribed Successfully" }
                                { this.state.successSubmit &&this.state.emailExist &&  "Already Subscribed" }

                            </Button>
                            <button onClick={this.hidePopUp} className="skip_now">No thanks, skip for now.</button>

                        </Form>

                    </div>
                }

            </Modal>



        )
    }
}



const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed,
        showLoginPopUp: state.showLoginPopUp,
        showLoginCheckoutPopUp: state.showLoginCheckoutPopUp,
        userDetail: state.userDetail,
        abondonCartItem : state.abondonCartItem,
        showNewsletter: state.showNewsletter




    };
};

const mapDispachToProps = dispatch => {
    return {


        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        signUpWithCartItem: (item) => dispatch(actionCreator.signUpWithCartItem(item)),
        showNewsletter: (item) => dispatch(actionCreator.showNewsletter(item)),



    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(NewsletterPopUpNew);


