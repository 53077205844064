import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";

const CheckoutRoute = ({ component: Component,isLoggedIn ,userDetail,isGuest, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {


            console.log("checkout route")
            console.log(isGuest)
            console.log(userDetail)

            //
            // if (isGuest){
            //
            //     if (guestDetail&&guestDetail.id) {
            //
            //
            //       return  <Redirect to={"/checkout/" + guestDetail.id}/>
            //     }else{
            //
            //       return  <Component {...props} />
            //     }
            //     }
            // else {

                if (userDetail&&userDetail.id) {


                  return   <Redirect to={"/checkout/" + userDetail.id}/>
                }else  {

                   return <Component {...props} />
                }



            // }


            // switch (status) {
            //
            //
            //
            //     case 1:
            //         return <Redirect to={"/checkout/" + userDetail.id}/>;
            //
            //     case 2:
            //         return <Redirect to={"/checkout/" + userDetail.id}/>;
            //
            //     default:
            //         return <Component {...props} />;
            //
            //         }



            }
        }
    />
);



const mapStateToProps = state => {
    return {
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        guestDetail: state.guestDetail,
        isGuest: state.isGuest,



    };
};

const mapDispachToProps = dispatch => {
    return {
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CheckoutRoute);


