import React, { Component, Fragment, useState, useEffect,useRef } from 'react';
import { Spinner,Button, Col, Row, Carousel, CarouselItem,ButtonToolbar, InputGroup, ModalFooter,Modal,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from "react-bootstrap"
import ReactHtml from 'raw-html-react';
import MapIcon from '../img/map.png';
import DetailIcon1 from '../img/detail-icon-1.png';
import DetailIcon2 from '../img/detail-icon-2.png';
import DetailIcon5 from '../img/detail-icon-5.png';
import DetailIcon7 from '../img/detail-icon-7.png';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import TabsMaterial from '@material-ui/core/Tabs';
import TabMaterial from '@material-ui/core/Tab';
import ViewArrowDown from '../img/red-arrow-down.png';
import ViewArrowUp from '../img/red-arrow-up.png';
import ArrowDown from '../img/arrow-down.png';
import {MapContainer} from "./MapContainer";
import  {Star} from '@material-ui/icons';
import Zoom from '@material-ui/core/Zoom';
import reviewtick from '../img/review-tick.png';
import inspireprofile from '../img/inspire-profile.png';
import emailfriend from '../img/email-friend.png';
import CrossBlack from '../img/cross-black.png';
import facebook from '../img/f-round.png';
import insta from '../img/in-round.png';
import LinkDin from '../img/lin-round.png';
import Mail from '../img/mail-round.png';
import Moment from 'react-moment';

import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import Rating from '@material-ui/lab/Rating';
import axios from "axios/index";
import {baseUrl,baseImgUrl} from  '../Util/Constants'


class  CustomerReviewBoxNew extends Component {

    description;
    importantInformation;
    constructor(props){

        super(props)

        // this.description = props.activity.description
        // this.importantInformation = props.activity.importantInformation

        this.state = {

            show : false,
            rating: "",
            reviews:[],
            successMessage : false

        };

        this.showReviewBox= this.showReviewBox.bind(this)
        this.handleReviewSubmit= this.handleReviewSubmit.bind(this)
        this.loadReviews= this.loadReviews.bind(this)

    }



    loadReviews(){

        var    url = baseUrl+"activity_reviews.json?groups[]=reviews&activity="+this.props.activity.id;

        console.log(url)


        axios.get(url)
            .then(res => {



                console.log(res.data)

                this.setState({

                    reviews : res.data,
                })





            }).catch(error => {

            // dispatch(stopLoading())

            // dispatch(loginFailed())

            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });

    }



    handleReviewSubmit = event => {


        event.preventDefault();
        const form = event.currentTarget;
        console.log(form.checkValidity())
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                validatedForm : true
            });
        }else {


            var    url = baseUrl+"activity_reviews";

            console.log(url)

            const formData = new FormData(event.target);
            const title = formData.get("title")
            const content = formData.get("content")
            const rating  = this.state.rating



            var data = {

                title: title,
                content: content,
                rating: rating,
                customer : "/api/customers/"+this.props.userDetail.id,
                activity : "/api/activities/"+this.props.activity.id,



            }

            console.log(data)
            axios.post(url, data)
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        show: !this.state.show,
                        successMessage: !this.state.successMessage

                    })

                    // this.loadReviews()



                }).catch(error => {


                console.log(error)



            });

        }


    }


    showReviewBox(){

        if (this.props.isLoggedIn) {

            this.setState({

                show: !this.state.show,

            })
        }else {

            this.props.loginPopUp(true)

        }

    }



    changeRating(event,newValue){

        this.setState({
            rating : newValue

        })

    }


    componentDidMount(){



this.loadReviews()

    }

    render() {




        return(

            <div id="reviews" className="review-section ">
                <div className="row">
                    {this.state.reviews.length > 0 && <div className="col-lg-12  ">
                        <h3>Customer Reviews</h3>
                        <div className="rating d-none">
                            <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"}/>
                            <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"}/>
                            <Star style={{color: "#29abe2"}} name="size-small" value={2} fontSize={"small"}/>
                            <Star color="disabled" name="size-small" value={2} fontSize={"small"}/>
                            <Star color="disabled" name="size-small" value={2} fontSize={"small"}/>
                            <p>4.0 (98 ratings)</p>
                        </div>
                        <div className="review-verify d-none">
                            <img src={reviewtick} alt=""/>
                            <div className="verifycontent d-none">
                                <h5>100% Verified Reviews </h5>
                                <p>All reviews are from people who have redeemed deals with this merchant.</p>
                            </div>
                        </div>





                        <div className="reviews ">


                            {this.state.reviews.map((item)=>
                                <div className="block">
                                    {/* <img className="user" src={inspireprofile} alt="" /> */}
                                    <i class="far fa-user user"></i>
                                    <div className="reviewcontent">
                                        <h5>{item.customer.firstName}</h5>
                                        <p className="small-text">   <Moment local fromNow>
                                            {item.publishedAt}
                                        </Moment></p>
                                        <p className="ratings">
                                            <span className="rating-total">{item.rating}/5</span>


                                            <Rating
                                                readOnly
                                                value={item.rating}
                                                icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                                emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                                name="size-large"  size="large"
                                            />

                                        </p>
                                        <h5 className="mb-0">{item.title}</h5>
                                        <p>{item.content} </p>
                                    </div>
                                </div>
                            )}


                        </div>




                    </div>
                    }
                    <div className="col-lg-12 mt-3 d-none">
                        <a href="javascripit" className="see-reviews">See all reviews</a>
                    </div>

                    <div className="col-lg-12 mt-3">
                        <div className="text-block">
                            {(!this.state.reviews.length > 0) &&
                            <><h3>Customer Reviews</h3>
                            <p className="mb-2">There aren't any reviews for this activity yet. Be the first to write one!</p>
                            </>
                            }

                            <button onClick={this.showReviewBox} className="blue-btn">Submit A Review</button>

                            {this.state.show && <div className="review-box">
                                <div className="form-row">
                                    <div className="form-group col-lg-12 pl-4 mb-0">
                                        <label>Overall Rating</label>
                                        <Rating

                                            value={this.state.rating}
                                            onChange={this.changeRating.bind(this)}
                                            icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                            emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                            name="size-large"  size="large"
                                        />


                                    </div>
                                </div>


                                <Form noValidate validated={this.state.validatedForm}
                                      onSubmit={this.handleReviewSubmit.bind(this)} className="border-0">
                                    <Form.Group as={Col} md="5" controlId="validationCustom01">
                                        <Form.Label>Add a headine<em class="text-danger">*</em></Form.Label>
                                        <Form.Control
                                            name={"title"}
                                            required
                                            type="text"
                                            placeholder="Add a headine"
                                            defaultValue=""
                                        />
                                    </Form.Group>
                                    <Form.Group  as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Write your review<em class="text-danger">*</em></Form.Label>

                                        <Form.Control
                                            name={"content"}
                                            as={"textarea"} rows={4}
                                            type="text"
                                                      required
                                            defaultValue=""
                                            placeholder="Write your review"

                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Button type={"submit"} className=" btn">Submit</Button>

                                    </Form.Group>
                                </Form>



                                   </div>
                            }


                            {this.state.successMessage &&

                            <Alert key={"success"} variant={"info"}>
                                Your review has been successfully submitted for approval.
                            </Alert>
                            }


                        </div>
                    </div>
                    <div className="col-lg-12 wl_share">
                        <p className="float-left">Share on: </p>
                        <a className="float-left" href="https://www.facebook.com/Happy-Weekends-113793993335534/"><img src={facebook} alt="fb-imge" /></a>
                        <a className="float-left" href="https://www.instagram.com/happyweekendsuae/"><img src={insta} alt="fb-imge" /></a>
                        <a className="float-left" href="https://twitter.com/WeekendsHappy"><img src={LinkDin} alt="fb-imge" /></a>
                        <a className="float-left" href="mailto:info@happy-weekends.com"><img src={Mail} alt="fb-imge" /></a>
                    </div>
                </div>
            </div>

        );
    }

}








const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        userDetail: state.userDetail,
        isLoggedIn:state.isLoggedIn,
        isGuest:state.isGuest,


    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
        // addCartItemGuest: (item) => dispatch(actionCreator.addCartItemGuest(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CustomerReviewBoxNew);

