import React, { Component ,useState} from 'react';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import {Switch, Link} from 'react-router-dom'
import { Formik } from 'formik';
import {baseUrl} from "../Util/Constants";
import axios from "axios/index";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

import { makeStyles } from '@material-ui/core/styles';


class  Register extends Component{


     email
    password
    componentDidMount() {

        window.scrollTo(0, 0)


    }

    constructor() {

        super();

        this.state = {
            validatedForm: false,
            isChecked: false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            email : null,
            userExists : false,
            emailLoad : false,
            showInfoPopUp : false,
            existingUserInfo: {},
            phone: "",
            invalidPhone:false,
            fields: {},
            errors: {},
            phoneNumberInValid: false,



        }


        this.passwordRef = React.createRef()

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitNew = this.handleSubmitNew.bind(this);

        this.checkIfUserExist= this.checkIfUserExist.bind(this)
        this.captchaResponse=this.captchaResponse.bind(this)
        this.showInfo=this.showInfo.bind(this)
        this.handlePhoneInputChange=this.handlePhoneInputChange.bind()
        this.handleValidation=this.handleValidation.bind(this)
        this.onChange=this.onChange.bind(this)

    }



    handleChange(field, e){

         // console.log(field)
        // console.log(e)

        let fields = this.state.fields;
        fields[field] = e;
        this.setState({fields});
    }

    onChange(field, e){

        // console.log(field)
        // console.log(e)

        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }


     handlePhoneInputChange(e) {
    //
    //      // console.log("phone inpuut")
    //      // console.log(e)
    //      // console.log(e.length)
    //
    //     this.setState({
    //         phone: e
    //     });
    //
    //
    }


    // handleInputChange(event) {
    //     const target = event.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }


    showInfo(){

        this.setState({

            showInfoPopUp: !this.state.showInfoPopUp
        })


    }

    captchaResponse(response){

         // console.log(response)


    }





    checkIfUserExist(event){



        this.setState({

            email : event.target.value,
            emailLoad : true
        })


         var    url = baseUrl+"customers.json?groups[]=customer&email="+event.target.value;

        // console.log(url)

        axios.get(url)
            .then(res => {


                this.setState({

                    emailLoad : false
                })

                this.setState({

                    loadingRefresh : false,
                })


                // console.log("user data exits")

                // console.log(res.data)


                if (res.data.length>0){






                    this.setState({

                        existingUserInfo: res.data[0]
                    })




                    if (this.state.existingUserInfo.isGuest||this.state.existingUserInfo.socialLogin>0){


                      // alert("social user "+ this.state.existingUserInfo.isGuest +" "+this.state.existingUserInfo.socialLogin)

                        this.setState({

                            userExists : false,
                        })
                    }else {


                        this.setState({

                            userExists : true,
                        })
                    }




                } else {

                    this.setState({

                        userExists : false,
                    })


                }




            }).catch(error => {

            // console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }



    mergeAccount(user){


        //  this.updateAccountInfoMerge(user)
        // this.updatePasswordAccountMerge(user)


        var url = baseUrl + "merge_accounts";

        const firstName = user.firstName
        const lastName = user.lastName
        const email = user.email
        const phoneNumber = user.phoneNumber
        const password = user.password

        axios.post(url,
            { firstName: firstName,lastName: lastName,  email: email,
                phoneNumber : phoneNumber, customer : this.state.existingUserInfo.id,
                password : password, socialLogin : 0, isGuest : false
            })
            .then(res => {

                // console.log(res.data)

                this.setState({

                    loading : false
                })


                this.props.logIn({

                    username : user.email,
                    password : user.password
                })


            }).catch(error => {

            this.setState({

                loading : false
            })

        });


    }





    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())


        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }



        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            // // console.log("phone number length "+this.state.phone.length)
            //
            // if (this.state.phone.length<12){
            //
            //     this.setState({
            //
            //         phoneNumberInValid: true,
            //     });
            //
            //     event.stopPropagation();
            //
            // }else {
            //
            //     this.setState({
            //
            //         phoneNumberInValid: false,
            //     });
            //
            // }


            if (data.get("password") != data.get("confirmPassword")) {

                // alert("hello")

                this.setState({

                    confirmPasswordError: true
                })


                event.stopPropagation();


            }

            // else if(this.state.phoneNumberInValid){
            //
            //
            //     alert("invalid")
            //     event.stopPropagation();
            // }

            else{


                this.setState({

                    confirmPasswordError: false
                })

                this.setState({

                    passwordError: false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")
                const password = data.get("password")
                const confirmPassword = data.get("confirmPassword")


         // alert("submt")
                if (this.state.existingUserInfo&&this.state.existingUserInfo.isGuest||this.state.existingUserInfo.socialLogin>0){

                    // this.props.signUp({
                    //     "firstName": firstName, "lastName": lastName,
                    //     "email": email, "phoneNumber": phoneNumber,
                    //     "password": password
                    // })
                    //


                    // this.props.logIn({"username":email,"password": "qwerty"})


                    //
                    this.mergeAccount({
                        "firstName": firstName, "lastName": lastName,
                        "email": email, "phoneNumber": phoneNumber,
                        "password": password
                    })


                }else {

                    this.props.signUp({
                        "firstName": firstName, "lastName": lastName,
                        "email": email, "phoneNumber": phoneNumber,
                        "password": password
                    })



                }





            }
        }
    }




    handleValidation(){

        // alert("called")
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            errors["phoneNumber"] = "Required";
            this.setState({

                "phoneNumberInValid" : true
            })
        }


        if(!fields["password"]){
            // alert("invalid ")
            formIsValid = false;
            errors["password"] = "Required";
        }

        if(!fields["confirmPassword"]){
            // alert("invalid ")
            formIsValid = false;
            // errors["confirmPassword"] = "Required";

        }


        if (typeof fields["password"] !== "undefined"){


            // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            // const strongRegex = new RegExp("^(?=.*[A-Z])");

            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{6,})");


            // console.log(strongRegex.test(fields["password"]))

            if (!strongRegex.test(fields["password"])){

                formIsValid = false;
                errors["password"] = "The password field must be at least 6 characters, one upper case, one numerical charactor, one special character!";

            }


            if (fields["password"].length<6){

                formIsValid = false;
                errors["password"] = "The password field must be at least 6 characters long, must contain one upper case, one numerical characters";

            }


            // // console.log(strongRegex.test(fields["password"]))




        }



        if (typeof fields["password"] !== "undefined" && typeof fields["confirmPassword"] !== "undefined") {

            // console.log(fields["password"])
            // console.log(fields["confirmPassword"])

            if (fields["password"]!==(fields["confirmPassword"])) {

                // alert("hello")

                this.setState({

                    confirmPasswordError: true
                })


                errors["confirmPassword"] = "Confirm password do not match";

                // alert(" not matched")

            }

        else{

            // formIsValid = false;
            // alert("matched")
            // this.setState({
            //
            //     confirmPasswordError: true
            // })
            //
            //
            // errors["confirmPassword"] = "Confirm password do not match";


        }


        }




        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValid" : true
            })


        }
        // else {
        //
        //     this.setState({
        //
        //         "phoneNumberInValid" : false
        //     })
        // }


        this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmitNew = event => {

        event.preventDefault();

        const form = event.currentTarget;

        // // console.log(form.checkValidity())


        if (!this.handleValidation() ) {



            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }



        if (this.handleValidation()&&form.checkValidity()) {



                this.setState({
                    btnLoading: true
                })

                const data = new FormData(event.target);


                // // console.log("phone number length "+this.state.phone.length)
                //
                // if (this.state.phone.length<12){
                //
                //     this.setState({
                //
                //         phoneNumberInValid: true,
                //     });
                //
                //     event.stopPropagation();
                //
                // }else {
                //
                //     this.setState({
                //
                //         phoneNumberInValid: false,
                //     });
                //
                // }

                //
                // if (data.get("password") != data.get("confirmPassword")) {
                //
                //     // alert("hello")
                //
                //     this.setState({
                //
                //         confirmPasswordError: true
                //     })
                //
                //
                //     event.stopPropagation();
                //
                //
                // }

                // else if(this.state.phoneNumberInValid){
                //
                //
                //     alert("invalid")
                //     event.stopPropagation();
                // }

                // else{


                    this.setState({

                        confirmPasswordError: false
                    })

                    this.setState({

                        passwordError: false
                    })

                    const firstName = data.get("firstName")
                    const lastName = data.get("lastName")
                    const email = data.get("email")
                    const phoneNumber = data.get("phoneNumber")
                    const password = data.get("password")
                    const confirmPassword = data.get("confirmPassword")


                    // alert("submt")
                    if (this.state.existingUserInfo&&this.state.existingUserInfo.isGuest||this.state.existingUserInfo.socialLogin>0){

                        // this.props.signUp({
                        //     "firstName": firstName, "lastName": lastName,
                        //     "email": email, "phoneNumber": phoneNumber,
                        //     "password": password
                        // })
                        //


                        // this.props.logIn({"username":email,"password": "qwerty"})


                        //
                        this.mergeAccount({
                            "firstName": firstName, "lastName": lastName,
                            "email": email, "phoneNumber": phoneNumber,
                            "password": password
                        })


                    }else {

                        this.props.signUp({
                            "firstName": firstName, "lastName": lastName,
                            "email": email, "phoneNumber": phoneNumber,
                            "password": password
                        })



                    }





                // }
            }


    }



    render (){


        return(
            <section className="login signup-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                        <div className="signup-formtext-center">
                            <div className="signup-form-col text-left">
                            <form method="post">
                                <div className="res-row d-flex justify-content-between">
                                <div className="form-col-banner">
                                </div>
                                <div className="form-col-left">
                                    <h3 className="form-heading">Sign Up</h3>

                                    <Form    noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitNew}>
                                        <Form.Group as={Row}  >
                                            <Col sm={{ span: 12 }}>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control  required  name={"firstName"} type="text" placeholder="" />
                                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>

                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} >
                                            <Col sm={{ span: 12 }}>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required  name={"lastName"} type="text" placeholder="" />
                                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}  >
                                            <Col sm={{ span: 12 }}>
                                            <Form.Label>Email</Form.Label>


                                            <Form.Control   value={this.state.email} onChange={this.checkIfUserExist}  required name={"email"} type="email" placeholder="" />
                                                {this.state.emailLoad &&    <Spinner
                                                    className={"email-input-spinner"}
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}


                                            <Form.Control.Feedback type="invalid">Email Id must be vaild</Form.Control.Feedback>
                                            </Col>

                                        </Form.Group>


                                        {this.state.userExists &&
                                        <Form.Group as={Row} >
                                            <Col sm={{span: 12}}>


                                                <div  style={{color : "#EC1C24"}} className="email-exists-block">An account with this email already exists.</div>

                                                <div className="email-exists-block">Go to <Link  style={{color : "#007dc4", fontWeight: "600"}} to={"/login?email="+this.state.email} className="">Log In</Link> page</div>



                                            </Col>
                                        </Form.Group>
                                        }


                                        <Form.Group as={Row} className=""  controlId="exampleForm.ControlInput1">
                                            <Col sm={{ span: 12 }}>
                                                <Form.Label>Phone Number</Form.Label>


                                                <PhoneInput
                                                    onChange={this.handleChange.bind(this, "phoneNumber")}
                                                    inputClass={this.state.phoneNumberInValid?"is-invalid":""}
                                                    inputProps={{
                                                        name: 'phoneNumber',
                                                        // required: true,

                                                        defaultErrorMessage:"Invalid",
                                                        // minLength:9,
                                                    }}
                                                    country={'ae'}
                                                />


                                                    {/*<PhoneInputIntl />*/}


                                                    {/*<Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                                    {/*<Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>*/}
                                                {/*{this.state.phoneNumberInValid && <div  style={{display:"block"}} className="invalid-feedback">Phone No. must be vaild</div>}*/}
                                                 <span className={"invalid-feedback-phone"}>{this.state.errors["phoneNumber"]}</span>


                                            </Col>
                                        </Form.Group>
                                        {/*<Form.Group as={Row} className=""  controlId="exampleForm.ControlInput1">*/}
                                            {/*<Col sm={{ span: 12 }}>*/}
                                            {/*<Form.Label>Phone Number</Form.Label>*/}
                                                {/*<InputGroup className="phone_no">*/}
                                                    {/*<InputGroup.Prepend>*/}
                                                        {/*<InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>*/}
                                                    {/*</InputGroup.Prepend>*/}

                                                {/*<Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                                {/*<Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>*/}
                                                {/*</InputGroup>*/}
                                                {/**/}
                                            {/*</Col>*/}
                                        {/*</Form.Group>*/}


                                        <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                            <Col sm={{ span: 12 }}>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control  required onChange={this.onChange.bind(this, "password")} minLength={6}  name={"password"} type="password" placeholder="" />
                                            {/*<Form.Control.Feedback type="invalid">The password field must be at least 6 characters.*/}
                                             {/*</Form.Control.Feedback>*/}

                                                <span className={"invalid-feedback-phone"}>{this.state.errors["password"]}</span>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                                            <Col sm={{ span: 12 }}>
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control onChange={this.onChange.bind(this, "confirmPassword")} fminLength={6} required  name={"confirmPassword"} type="password" placeholder="" />

                                                {/*{this.state.confirmPasswordError &&  <Form.Control.Feedback type="invalid">Passwords do not match.*/}
                                                {/*</Form.Control.Feedback>}*/}

                                                <span className={"invalid-feedback-phone"}>{this.state.errors["confirmPassword"]}</span>

                                            </Col>
                                        </Form.Group>


                                            <InfoPopOver />



                                        <Form.Group as={Row}  controlId="exampleForm.ControlInput1">

                                                <Col sm={{ span: 2, offset: 0 }}>

                                            <Form.Check inline
                                                        required
                                                name={"ambassador"}
                                                custom
                                                type={"radio"}
                                                id={"yes"}
                                                label="Yes"
                                            />

                                                </Col>
                                                    <Col sm={{ span: 2, offset: 0 }}>


                                                    <Form.Check inline
                                                                required
                                                name={"ambassador"}

                                                custom
                                                type={"radio"}
                                                id={"no"}
                                                label="No"
                                            />
                                            </Col>

                                        </Form.Group>


                                        {!this.state.userExists && <Form.Group as={Row}  className={"justify-content-md-center"} controlId="exampleForm.ControlInput1">


                                            <Col sm={{ span: 12 }}>
                                                <div className="d-flex btn-group">

                                                    <button type={"submit"} className="btn btn-signup" >Sign Up</button>

                                                </div>

                                            </Col>
                                        </Form.Group> }

                                        <Form.Group className={"d-none"}  as={Row}  controlId="exampleForm.ControlInput1">
                                            <Col sm={{ span: 12, offset: 0 }}>

                                            <div className="forgot-col mt-3 d-flex aligns-center">
                                                <Link href="/register">Forgot Password?</Link>
                                            </div>
                                            </Col>

                                        </Form.Group>

                                    </Form>


                                </div>
                                </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


function InfoPopOver() {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <div>

            <Form.Group as={Row}  controlId="exampleForm.ControlInput1">
                <Col sm={{ span: 12, offset: 0 }}>
                    <label className="required">Opt to be the happiness ambassador*</label>
                    <div onClick={handleClick} className="tooltips"> <i style={{fontSize: '16px', cursor :"pointer"}} className="fa fa-info-circle  " />

                    </div>
                </Col>

            </Form.Group>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{maxWidth:"400px"}} className={classes.typography}>Be the happiness ambassador and help us realize the vision of UAE leadership to make the UAE as the happiest place on earth. Simply share the best weekends picture on the #happyweekends hashtag. The best pictures get qualified for the annual happy weekends awards and winners will be awarded during the ceremony.</Typography>
            </Popover>
        </div>
    );





}


function PhoneInputIntl() {

    const [value, setValue] = useState()
    return (

        <PhoneInput

            inputProps={{
                name: 'phoneNumber',
                required: true,
                autoFocus: true,
                defaultErrorMessage:"Invalid",

            }}
            // enableAreaCodes={true}


            value={this.state.phone}
            country={'ae'}
            onChange={ value =>  this.setValue({value}) }
            required = {true}

        />

    )

}

const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn

    };
};

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item))

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(Register);
