import React, { Component } from 'react';
import { Button, Col, Spinner, NavItem,InputGroup, Form, FormCheck, FormControl, Container} from 'react-bootstrap';
import CorTogether from '../img/corporate-together.png';
import CorTogether3 from '../img/corporate-3.jpg';
import CorTogether4 from '../img/corporate-4.jpg';
import BestPlace from '../img/best-place.png';
import Becomepar from '../img/become-partner.png';
import Youearn from '../img/you-earn.png';
import Invovat from '../img/invoating.png';
import YourOwn from '../img/your-own.png';
import LetsSpreed from '../img/lets-spread.png';
import {baseUrl} from "../Util/Constants";
import axios from "axios/index";
import PhoneInput from 'react-phone-input-2'
import MetaTags from 'react-meta-tags';


class  Corporate extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    constructor() {
        super();
        this.state = {
            validatedForm: false,
            isChecked: false,
            passwordError : false,
            btnLoading : false,
            successSubmit : false,
            fields: {},
            errors: {},
            phoneNumberInValid: false,


        }
        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitNew = this.handleSubmitNew.bind(this);

        this.handleValidation=this.handleValidation.bind(this)
        this.handleChange=this.handleChange.bind(this)
    }
    handleChange(field, e){

        console.log(field)
        console.log(e)

        let fields = this.state.fields;
        fields[field] = e;
        this.setState({fields});
    }


    handleValidation(){

        // alert("called")
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            // errors["phoneNumber"] = "Invalid phone number!";
            this.setState({

                "phoneNumberInValid" : true
            })
        }



        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValid" : true
            })


        }


        this.setState({errors: errors});
        return formIsValid;
    }



    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const email = data.get("email")
            const designation = data.get("designation")
            const phoneNumber = data.get("phoneNumber")
            const comment = data.get("comment")
            const firstName = data.get("firstName")
            const lastName = data.get("lastName")
            const landLine = data.get("landLine")
            const companyName = data.get("company")





            axios.post(baseUrl+"corporate_contact_forms",
                {
                    "email" : email,"comment" : comment,"firstName" : firstName,"lastName": lastName,
                    "phoneNumber":phoneNumber,
                    "landLine":landLine, "designation": designation,
                    "companyName":companyName


            })
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        btnLoading : false,
                        successSubmit : true
                    })

                })
        }


    }
    handleSubmitNew = event => {

        event.preventDefault();

        const form = event.currentTarget;

        console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if (!this.handleValidation()) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }





        if(this.handleValidation()&&form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);


            const email = data.get("email")
            const designation = data.get("designation")
            const phoneNumber = data.get("phoneNumber")
            const comment = data.get("comment")
            const firstName = data.get("firstName")
            const lastName = data.get("lastName")
            const landLine = data.get("landLine")
            const companyName = data.get("company")





            axios.post(baseUrl+"corporate_contact_forms",
                {
                    "email" : email,"comment" : comment,"firstName" : firstName,"lastName": lastName,
                    "phoneNumber":phoneNumber,
                    "landLine":landLine, "designation": designation,
                    "companyName":companyName


                })
                .then(res => {

                    console.log(res.data)

                    this.setState({
                        btnLoading : false,
                        successSubmit : true
                    })

                })
        }


    }



    render (){
        return(
            
        <div className="bg-gray">


            <MetaTags>

                <title>Recharge your Office Employees This Weekend | Happy Weekends</title>
                <meta name="title" content={"Recharge your Office Employees This Weekend | Happy Weekends"} />

                <meta name="description" content="Happy Weekends believes that an office that has fun together, stays together. We curate mind blowing experience over the weekends for your corporate employees so that when they get back to work, they are able to give their 200%. We have special corporate deals for our valued customers. Check out!" />

                <link rel="canonical" href="https://www.happy-weekends.com/" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.happy-weekends.com/" />
                <meta property="og:title" content="Recharge your Office Employees This Weekend | Happy Weekends" />
                <meta property="og:description" content="Happy Weekends believes that an office that has fun together, stays together. We curate mind blowing experience over the weekends for your corporate employees so that when they get back to work, they are able to give their 200%. We have special corporate deals for our valued customers. Check out!" />
                <meta property="og:image" content="/cover-image.jpg" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.happy-weekends.com/" />
                <meta property="twitter:title" content="Recharge your Office Employees This Weekend | Happy Weekends" />
                <meta property="twitter:description" content="Happy Weekends believes that an office that has fun together, stays together. We curate mind blowing experience over the weekends for your corporate employees so that when they get back to work, they are able to give their 200%. We have special corporate deals for our valued customers. Check out!" />
                <meta property="twitter:image" content="/cover-image.jpg" />


            </MetaTags>

                <section className="corporate-stay-together">
                    <div className="container-fluid  pr-0">
                    <div className="row align-items-center no-gutters">
                        <div className="col-lg-2" />
                        <div className="col-lg-4">
                        <div className="corporate-stay-content">
                            <h3>An office that<br /> plays together,<br /> stays together.</h3>
                            <p>Ample studies have shown that employee engagement goes a long way in keeping the employee happy. So much so in fact, that Brian Scudamore from Forbes says ‘Despite its reputation for being, well, lame, team building is the most important investment you can make for your people.</p>
                            <p>And it’s true. Professional connections can only take us so far. But personal connections, on the other hand can last a lifetime.</p>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <img className="img-fluid" src={CorTogether} alt="" />
                        </div>
                    </div>
                    </div>
                </section>
                <section className="corporate-landing-2">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="corporate-what">
                            <div className="block-img mb-4">
                            <img className="img-fluid" src={CorTogether3} alt="" />
                            </div>
                            <h3>What’s more personal than a weekend?</h3>
                            <p>It’s one thing to care about employees on the weekdays. But a company that takes care of its employees on the weekends? Now that’s priceless.</p>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="corporate-what">
                            <div className="block-img mb-4">
                            <img className="img-fluid" src={CorTogether4} alt="" />
                            </div>
                            <h3>How good the week will go, depends on how great the weekend was.</h3>
                            <p>The more rejuvenated and relaxed we feel over the weekend, the more excited we are, to return to work. The more excited we are, the more productive we become. Turns out, encouraging employees to have a happy weekend has positive business impact which is measurable.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="corporate-welcome">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                        <h3>Welcome to Happy Weekends.</h3>
                        <p>Happy Weekends is a happy mobile platform that’s obsessed with making weekends as great as they possibly can be. We do that by curating exciting mini-experiences with our partners, that your employees can be a part of, no matter if they’re outdoorsy or indoorsy. The way we see it, there are fifty two weekends in a year that employees have to themselves. It’s time to start making them count.</p>
                        </div>
                    </div>
                    </div>
                    <div className="corporate-welcome-bg" />
                </section>
                <section className="keep-emp-happy">
                    <div className="container-fluid">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-6">
                        <h3>Keeping Employers Happy.</h3>
                        <p>What’s in it for you, the employer? Fair question, and one we hope we’ve answered below.</p>
                        </div>
                    </div>
                    <div className="row d-flex flex-nowrap">
                        <div className="col">
                        <div className="keep-emp-block">
                            <img className="img-fluid" src={BestPlace} alt="" />
                            <h4>Best place to work</h4>
                            <p>When you bond with employees in a personal way, by encouraging them to spend a happy weekend, you not only drastically improve the culture of the organization, but you become one of the best places to work at.</p>
                        </div>
                        </div>
                        <div className="col">
                        <div className="keep-emp-block">
                            <img className="img-fluid" src={Becomepar} alt="" />
                            <h4>Become a pioneer</h4>
                            <p>You join the community of organizations that go beyond pre-historic ways of engaging and motivating employees, and become a pioneer instead.</p>
                        </div>
                        </div>
                        <div className="col">
                        <div className="keep-emp-block">
                            <img className="img-fluid" src={Youearn} alt="" />
                            <h4>You earn respect</h4>
                            <p>Respect, as we’re sure you’ll agree, cannot be bought. It’s earned when you get personal with your employees and invest yourself in their happiness.</p>
                        </div>
                        </div>
                        <div className="col">
                        <div className="keep-emp-block">
                            <img className="img-fluid" src={Invovat} alt="" />
                            <h4>Innovative award system</h4>
                            <p>Experiences outweigh material gifts. And Happy Weekends gives you the unique opportunity to use the packages on the platform to reward your employees.</p>
                        </div>
                        </div>
                        <div className="col">
                        <div className="keep-emp-block">
                            <img className="img-fluid" src={YourOwn} alt="" />
                            <h4>Your own dashboard</h4>
                            <p>When you partner with Happy Weekends, you have access to your very own dashboard. This is a place where you have all the statistics right on your fingertips. How much money you’ve saved, the number of awards given out, who your star performers are, everything to run your business more effectively.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="lets-spread">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                        <div className="lets-spread-content">
                            <h3>Let’s spread happiness.</h3>
                            <p>As Forbes points out, it turns out that&nbsp;happiness and learning are tied very closely together. Trying new things with your staff can generate good vibes among employees, which in turn benefits the business itself. Choosing something unique and slightly outside of people’s comfort zones can encourage them to come together in new ways. Let’s get you started on the journey. Click the button below to view our corporate packages.</p>
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <img className="img-fluid" src={LetsSpreed} alt="" />
                        </div>
                    </div>
                    </div>
                </section>
                <section className="corporate-touch">
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                        <h3>Get in touch</h3>
                        <p>If you’re as excited by this as we are, and if you’d like to know more about it, please fill in your details in the contact form below, and we’ll get in touch sooner than you can say hippopotamus.</p>
                        </div>
                        <div className="col-lg-8">
                            <div className="get-touch">
                            <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmitNew}>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            name={"firstName"}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""
                                        name={"lastName"}
                                    />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            name={"company"}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            name={"designation"}
                                        />
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Email id</Form.Label>
                                        <Form.Control
                                            required
                                            type="Email"
                                            placeholder=""
                                            name={"email"}
                                        />
                                        <Form.Control.Feedback type="invalid">Email Id must be vaild</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone</Form.Label>


                                            <PhoneInput
                                                onChange={this.handleChange.bind(this, "phoneNumber")}
                                                inputClass={this.state.phoneNumberInValid?"is-invalid":""}
                                                inputProps={{
                                                    name: 'phoneNumber',
                                                    // required: true,
                                                    defaultErrorMessage:"Invalid",
                                                    // minLength:9,
                                                }}
                                                country={'ae'}
                                            />


                                            <span className={"invalid-feedback-phone"}>{this.state.errors["phoneNumber"]}</span>

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Landline</Form.Label>

                                        {/*<InputGroup className="phone_no">*/}
                                            {/*<InputGroup.Prepend>*/}
                                                {/*<InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>*/}
                                            {/*</InputGroup.Prepend>*/}

                                            {/*<Form.Control minLength={8} maxLength={8} required  name={"phone"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                            {/*<Form.Control.Feedback type="invalid">Landlime Number must be 8 digit</Form.Control.Feedback>*/}
                                        {/*</InputGroup>*/}
                                        <PhoneInput
                                            onChange={this.handleChange.bind(this, "phone")}
                                            inputClass={this.state.phoneNumberInValid?"is-invalid":""}
                                            inputProps={{
                                                name: 'phone',
                                                // required: true,
                                                defaultErrorMessage:"Invalid",
                                                // minLength:9,
                                            }}
                                            country={'ae'}
                                        />


                                        <span className={"invalid-feedback-phone"}>{this.state.errors["phone"]}</span>



                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Comment</Form.Label>
                                        <Form.Control as="textarea" rows="10"
                                            required
                                            type="number"
                                            placeholder=""
                                                      name={"comment"}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Button  className={"contact-us-submit"}
                                         type={"submit"}

                                >
                                    { this.state.btnLoading && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> }

                                    {!this.state.successSubmit &&
                                    <>
                                        {this.state.btnLoading ?  "Wait" : "Submit" }
                                    </>

                                    }

                                    { this.state.successSubmit &&  "Request Submitted Successfully" }

                                </Button>





                            </Form>
                            </div>   
                        </div>
                    </div>
                    </div>
                </section>    
         </div>
          )
    }
}

export default Corporate;