import React, {Component, Fragment} from 'react';
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import { Spinner,Button, Col, Row, Carousel, CarouselItem,ButtonToolbar,
    InputGroup, ModalFooter,Modal,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from "react-bootstrap"

import Grow from '@material-ui/core/Grow';

import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import history from "../History/history";
import axios from "axios/index";
import AddIconColor from '../img/plus-color.png';
import MinIconColor from '../img/min-color.png';
import {Link} from 'react-router-dom';
import {loginFailed} from "../store/actions/actions";
import {saveUserData} from "../LocalStorage/user";
import GoSellElementsDemo from "./GoSellElementsDemo";
import {baseUrl,baseImgUrl} from  '../Util/Constants'


class  Address extends Component{


    constructor(props) {
        super(props)

        this.state = {

            showNewAddress: false,
            cities : [],

            addresses : [],
            address: null,
            addressIdSelected : null

        }

        this.showNewAddressForm=this.showNewAddressForm.bind(this)
        this.makePaymentRequestDummy=this.makePaymentRequestDummy.bind(this)
        this.makePaymentRequest=this.makePaymentRequest.bind(this)
        this.loadAddress=this.loadAddress.bind(this)
        this.selectAddress=this.selectAddress.bind(this)
        this.loadCities=this.loadCities.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);


    }


    handleInputChange(event) {


        const target = event.target;
        const value = target.type === 'radio' ? target.checked : target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });
        // console.log(event.target.value)

        this.selectAddress(event.target.value)

        // alert(value);
    }


    componentWillMount(){

        this.loadCities()

        if(this.props.isLoggedIn||this.props.isGuest) {

            this.loadAddress()

        }


        // this.setState({
        //
        //     addressIdSelected: this.props.salesQuote.address.id
        // })
    }


    selectAddress(addressId){

        this.props.loading()

        var url = baseUrl + "address_sales_quotes"
        axios.post(url, {customer: parseInt(this.props.userDetail.id),address: parseInt(addressId)})
            .then(res => {

                // console.log(res.data)

                // this.loadAddress()
                // this.loadAddress()
                this.props.loadSalesQuote({id: this.props.userDetail.id})

                this.props.stopLoading()

            }).catch(error => {


            // console.log(error)



        });


    }


    loadCities(){

        axios.get(baseUrl+"cities.json?groups[]=detail&isActive=1")
            .then(res => {
                this.setState({

                    cities: res.data,

                });


            })

    }


        loadAddress(){


            this.props.loading()


            var    url = baseUrl+"customers/"+this.props.userDetail.id+".json?groups[]=addresses";

        // console.log(url)


        axios.get(url)
            .then(res => {

                // console.log(res.data)



                this.props.stopLoading()

                this.setState({

                    addresses : res.data["addresses"],
                    showNewAddress: false,
                })


                if (res.data["addresses"].length>0){

                    this.props.addressExist(true)
                    this.props.stayAtHomeAddress(res.data["addresses"][0])



                } else {
                    this.props.addressExist(false)
                    this.props.stayAtHomeAddress(null)


                }



            }).catch(error => {

            // dispatch(stopLoading())

            // dispatch(loginFailed())

            this.props.stopLoading()

            // console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });

    }


    showNewAddressForm(){


        this.setState({

            showNewAddress: !this.state.showNewAddress

        })

    }



    handleAddressDelete = event => {




        var    url = baseUrl+"addresses/"+event.currentTarget.dataset.id;


        // console.log(url)
        axios.delete(url)
            .then(res => {

                // console.log(res.data)

                this.loadAddress()


            }).catch(error => {


            // console.log(error)



        });





    }


        handleAddressSubmit = event => {


        event.preventDefault();
        const form = event.currentTarget;
        // console.log(form.checkValidity())
        if (form.checkValidity() === false) {
            event.stopPropagation();
            this.setState({
                validatedForm : true
            });
        }else {


            this.props.loading()


            var    url = baseUrl+"addresses";

            // console.log(url)


            const formData = new FormData(event.target);


            const firstName = formData.get("firstName")
            const lastName = formData.get("lastName")
            const email  = formData.get("email")
            const street  = formData.get("street")

            const phoneNumber  = formData.get("phoneNumber")

            const address  = formData.get("address")

            const poBoxNo  = formData.get("poBoxNo")
            const city  = formData.get("city")


            var data = {

                firstName: this.props.userDetail.firstName,
                lastName: this.props.userDetail.lastName,
                address: address,
                poBoxNo: poBoxNo,
                city : "/api/cities/"+city,
                customer : "/api/customers/"+this.props.userDetail.id,
                phoneNumber : phoneNumber,
                email : email,
                street : street,

            }

            // console.log(data)
            axios.post(url, data)
                .then(res => {

                    // console.log(res.data)

      this.loadAddress()

                }).catch(error => {


                // console.log(error)



            });

        }


    }

    cancelNewAddressForm(){


        this.setState({

            showNewAddress: false

        })

    }

    makePaymentRequestDummy() {


        history.push("booking/success/"+this.props.userDetail.id)

    }


    makePaymentRequest(){


        var    url = baseUrl+"customer_payments";



        var data = {

            customer : (this.props.userDetail.id)

        }

        axios.post(url, data)
            .then(res => {

                // console.log(res.data)


                if (res.data.payment && res.data.payment.order) {

                    window.location.replace(res.data.payment.order.url)

                }

                else {


                }


            }).catch(error => {


            // console.log(error)
            return { type: "ERROR_REQUEST", value: error };


        });





    }



    render (){
        return(
            <Fragment>
              <div className="address-userinfo">
                <h5>{this.props.userDetail.firstName+" "+this.props.userDetail.lastName}</h5>
                <div><i class="far fa-envelope"></i>{this.props.userDetail.email}</div>
                <div><i class="fas fa-phone"></i>{this.props.userDetail.phoneNumber}</div>
              </div>

                {this.props.addressInput && <div className="address">
                    <div className="form-group">
                        <div class="checkbox d-none">
                            <input id="check1" type="checkbox" name="check" value="check1"/>
                            <label for="check1">Add address, if the chosen experience is to be delivered at your
                                home.</label>
                        </div>

                        <button onClick={this.showNewAddressForm.bind(this)}
                                style={{backgroundColor: "transparent", border: "none"}} className="add-address">
                            {this.state.showNewAddress ? <img src={MinIconColor} alt=""/> :
                                <img src={AddIconColor} alt=""/>}
                            Add New Address
                        </button>


                        <Zoom direction="down" in={this.state.showNewAddress} mountOnEnter unmountOnExit>

                            <div className="newaddress">
                                <Form noValidate validated={this.state.validatedForm}
                                      onSubmit={this.handleAddressSubmit} className="border-0">

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Address<em class="text-danger">*</em></Form.Label>
                                        <Form.Control
                                            name={"address"}
                                            required
                                            type="text"

                                            defaultValue=""
                                            placeholder="Address Details line 1"
                                        />
                                    </Form.Group>


                                    <Form.Group className={"d-none"} as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control

                                            type="text"
                                            defaultValue=""
                                            placeholder="Address Details line 2"

                                        />
                                    </Form.Group>


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            name={"street"}
                                            required
                                            type="text"

                                            defaultValue=""
                                            placeholder="Area"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control name={"city"} required as="select" className="custom-select">

                                            {this.state.cities.map((item)=>
                                                <option value={item.id}>{item.name}</option>
                                            )}


                                        </Form.Control>
                                    </Form.Group>


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            name={"poBoxNo"}
                                            required
                                            type="text"

                                            defaultValue=""
                                            placeholder="Post Box No."
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">


                                        <Button type={"submit"} className="blue-btn">Add New</Button>
                                        <Button type={"reset"} onClick={this.cancelNewAddressForm.bind(this)}
                                                className="gray-btn ml-2">Cancel</Button>

                                    </Form.Group>

                                </Form>
                            </div>
                        </Zoom>


                    </div>
                    {this.state.addresses.length > 0 && <h5 className="font-16">Address</h5>}

                    { this.state.addresses.map((item,index) =>

                        <div className="address-info mt-2">
                            <button data-id={item.id} onClick={this.handleAddressDelete.bind(this)} className="address-delete"><i class="far fa-trash-alt"></i></button>
                        <div class="form-check">
                            <input
                                onChange={this.handleInputChange}
                                checked={(this.props.salesQuote.address&&this.props.salesQuote.address.id==item.id)?true:false}

                                class="form-check-input" type="radio" name="address" id={item.id}
                                   value={item.id}/>
                            <label class="form-check-label" for={item.id}>
                                <p className="mb-0">{item.address} </p>
                                <p className="mb-0">{item.street}, {item.city.name},</p>
                                <p className="mb-0"> United Arab Emirates</p>
                            </label>
                        </div>
                       </div>
                    )
                    }


                </div>
                }

            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        isGuest: state.isGuest,

        userDetail: state.userDetail,
        addressInput : state.addressInput,
        salesQuote : state.salesQuote

    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item)),
        stayAtHomeAddress: (item) => dispatch(actionCreator.stayAtHomeAddress(item)),
        addressExist: (item) => dispatch(actionCreator.addressExist(item)),
        loadSalesQuote: (item) => dispatch(actionCreator.loadSalesQuote(item)),
        loading: () => dispatch(actionCreator.loading()),
        stopLoading: () => dispatch(actionCreator.stopLoading()),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(Address);

