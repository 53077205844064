import React, { Component } from 'react';
import { Button, Col, Row, NavItem,InputGroup, Form, FormCheck, FormControl, Container} from 'react-bootstrap';
import facebook from '../img/f-round.png';
import insta from '../img/in-round.png';
import LinkDin from '../img/lin-round.png';
import Mail from '../img/mail-round.png';
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'

class  CareerForm extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }
    render (){
        return(
            <div className="bg-gray">
                <section className="career-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 how-work-txt text-center">
                                <h2 className="text-center">Careers at Happy Weekends</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="career-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <h3>Manager-Visual Design</h3>
                                <p>
                                Location: Abu Dhabi<br/>
                                Type of job: Permanent<br/>
                                Date published: 11-Sep-2019<br/>
                                Domain: Customer Experience & Design<br/>
                                Job ID: 58916
                                </p>
                            </div>
                            <div className="col-lg-3 text-right"><Link className="backlistlink" to="/career">Back to job list</Link></div>
                        </div>
                        <div className="row justify-content-center mt-3">
                        <div className="col-lg-8">
                            <div className="careerform">
                            <Form   noValidate >
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            defaultValue=""
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="bordergroup">
                                    <h4>Your Profile</h4>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Resume</Form.Label>
                                    <Form.Control
                                        required
                                        type="file"
                                        placeholder=""
                                        defaultValue=""
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="bordergroup">
                                    <h4>Application Details</h4>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Cover Letter</Form.Label>
                                    <Form.Control as="textarea" rows="3"/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="check-career bordergroup">
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Check type="checkbox" label="* I have read, understand and accept the content of the Privacy Notice and consent to the processing of my data
as part of this application." />
                                    </Form.Group>
                                </Form.Row>
                                <Button className="red-btn" type="submit">Submit Your Application</Button>
                            </Form>
                            </div>   
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default CareerForm;