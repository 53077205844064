import React, {Component, Fragment} from 'react';
import axios from "axios/index";
import {Link} from 'react-router-dom'
import ReactHtml from 'raw-html-react';
import TrendingScrollSections from "./TrendingScrollSections";
import Moment from 'react-moment';


import {baseImgUrl, baseUrl} from '../Util/Constants'


class  RelatingBlog extends Component{


    constructor(props) {

        super(props)
        this.state = {

            blogItems: [],


        }

    }


    componentDidMount (){
        window.scrollTo(0, 0)

        axios.get(baseUrl+"posts.json?groups[]=posts&isActive=1")
            .then(res => {

                this.setState({

                    blogItems : res.data,

                });
                console.log(res.data)
            })

    }

    render() {
        return (
            <div>
           <section className="related-blog-list">
               <div className="container">
                   <div className="row">
                        <div className="col-lg-8">
                            <div className="related-experience">
                                <h5>Most Recent</h5>
                                <div className="row">
                                    {this.state.blogItems.filter((itemFilter)=> itemFilter.id!=this.props.blog.id).map((item,index)=>
                                        <Fragment>

                                            {index<2 &&
                                                <div className="col-lg-6">
                                                    <div className="card">

                                                        <Link to={"/blog-detail/"+item.slug}>
                                                            <img className="card-img-top" src={ baseImgUrl+ item.image } alt="" />
                                                        <div className="card-body">
                                                            <p className="card-text"> <Moment local format="D MMM YYYY">
                                                                {item.publishedAt}
                                                            </Moment></p>
                                                            <h5 className="card-title">

                                                                    <ReactHtml html={item.title}/>
                                                            </h5>
                                                        </div>
                                                        </Link>

                                                    </div>

                                                </div>
                                            }

                                        </Fragment>

                                    )
                                            }


                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="most-popular">
                                <h5>Most Popular</h5>
                                <div className="popular-lisitng">

                                    {this.state.blogItems.map((item,index)=>
                                        <Fragment>

                                            {item.id != this.props.blog.id &&
                                            <div className="card mb-3">
                                                <Link to={"/blog-detail/" + item.slug}>

                                                <div className="row no-gutters">

                                                    <div className="col-lg-3">

                                                        <img className="card-img" src={ baseImgUrl+ item.image } alt="" />

                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="card-body">
                                                            <p className="card-text"> <Moment local format="D MMM YYYY">
                                                                {item.publishedAt}
                                                            </Moment></p>
                                                            <h5 className="card-title">
                                                                    <ReactHtml html={item.title}/>

                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Link>

                                            </div>
                                            }
                                        </Fragment>

                                                )}



                                </div>
                            </div>
                        </div>
                   </div>
               </div>
            </section>
            <section className="blue-bg blog-detail-related py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                    <TrendingScrollSections/>
</div>
                    </div>
                </div>
            </section>
            </div>

        )
    }
}

export default RelatingBlog;
