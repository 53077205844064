import React, { Component } from 'react';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import { Spinner,Button, Col, Row, Carousel,FormFile, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import {Switch, Link} from 'react-router-dom'

import  {CloudUpload} from '@material-ui/icons';


import './BecomePartner.css';
import {baseUrl} from "../Util/Constants";
import axios from "axios/index";

class  BecomePartner extends Component{



    constructor(props) {
        super(props);
        this.state = {
            validatedForm: false,
            isChecked: false,
            file: null,
            userExists : false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            cities : [],
            areas:[],
            formHasError: false,
            fileBase64 : null,
            customerButNotHost: false,
            emailEntered: null,
            hideSubmit: false,
            hidePasswordInput: false,
            fields: {},
            errors: {},
            phoneNumberInValid: false,

        }
        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkIfUserExist = this.checkIfUserExist.bind(this)
        this.fetchCities=this.fetchCities.bind(this)
        this.fetchArea=this.fetchArea.bind(this)
        this.loginPopUp=this.loginPopUp.bind(this)
        this.handleValidation=this.handleValidation.bind(this)

    }




    loginPopUp(){


        this.props.loginCheckoutPopUp(true)
    }


    fetchCities() {

        axios.get(baseUrl + "cities.json?groups[]=detail&isActive=1")
            .then(res => {

                this.setState({

                    cities: res.data,

                });

            })
    }

    fetchArea(e) {


        console.log(e.target.value)
        var cityId = e.target.value

        var url = baseUrl + "areas.json?groups[]=detail&city="+cityId

        console.log(url)

        axios.get(url)
            .then(res => {

                console.log("areas")
                console.log(res.data)

                this.setState({

                    areas: res.data,

                });


            })


    }


    handleValidation(){

        // alert("called")
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        // //Name
        if(!fields["phoneNumber"]){
            // alert("empty ")
            formIsValid = false;
            // errors["phoneNumber"] = "Invalid phone number!";
            this.setState({

                "phoneNumberInValid" : true
            })
        }



        if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

            formIsValid = false;
            errors["phoneNumber"] = "Invalid phone number!";

            this.setState({

                "phoneNumberInValid" : true
            })


        }


        this.setState({errors: errors});
        return formIsValid;
    }

    checkIfUserExist(event){



        this.setState({

            email : event.target.value,
            emailLoad : true
        })


        var    url = baseUrl+"customers.json?groups[]=customer&email="+event.target.value;

        console.log(url)






            this.setState({

                emailEntered : event.target.value,

            })







        axios.get(url)
            .then(res => {


                this.setState({

                    emailLoad : false
                })

                this.setState({

                    loadingRefresh : false,
                })


                console.log("user data exits")

                console.log(res.data)


                if (res.data.length>0){

                    if (res.data[0].isHost){

                        this.setState({

                            userExists : true,
                            customerButNotHost : false,
                            hideSubmit : true
                        })

                    }else {

                        this.setState({

                            customerButNotHost : true,
                            userExists : false,
                            hideSubmit : false
                        })
                    }






                } else {

                    this.setState({

                        userExists : false,
                    })


                }




            }).catch(error => {

            console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }



    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;



        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,
                formHasError : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true,
                formHasError : false
            })

            const data = new FormData(event.target);


            console.log("host form data")
            console.log(data)


            if (data.get("password") != data.get("confirmPassword")) {

                // alert("hello")

                this.setState({

                    confirmPasswordError: true
                })


                event.stopPropagation();


            } else {


                this.setState({

                    confirmPasswordError: false
                })

                this.setState({

                    passwordError: false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")
                const password = data.get("password")
                const confirmPassword = data.get("confirmPassword")



                if (!this.props.isLoggedIn) {

                    this.props.signUpHost({

                        "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "isHost": true,
                        "phoneNumber": phoneNumber,
                        "landline": data.get("landline"),
                        "designation": data.get("designation"),
                        "password": password,


                        "firstName2": data.get("firstName2"),
                        "lastName2": data.get("lastName2"),
                        "mobile2": data.get("mobile2"),
                        "landline2": data.get("landline2"),
                        "designation2": data.get("designation2"),

                        "companyName": data.get("company_name"),
                        "tradeLicenseId": data.get("trade_license_id"),
                        "businessDescription": data.get("businessDescription"),
                        "tradeLicenseImageHolder": this.state.fileBase64,

                        "address": data.get("address"),
                        "streetName": data.get("streetName"),
                        "buildingName": data.get("buildingName"),
                        "poBox": data.get("poBox"),

                        "city": "/api/cities/" + data.get("city"),
                        "area": "/api/areas/" + data.get("area"),

                        "companyWebsiteLink": data.get("companyWebsiteLink"),
                        "youtubeLink": data.get("youtubeLink"),
                        "linkedInLink": data.get("linkedInLink"),
                        "instagramLink": data.get("instagramLink"),

                        "facebookLink": data.get("facebookLink"),
                        "twitterLink": data.get("twitterLink"),


                    })
                }else {


                    this.props.signUpHost({

                        "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "isHost": true,
                        "phoneNumber": phoneNumber,
                        "landline": data.get("landline"),
                        "designation": data.get("designation"),

                        "firstName2": data.get("firstName2"),
                        "lastName2": data.get("lastName2"),
                        "mobile2": data.get("mobile2"),
                        "landline2": data.get("landline2"),
                        "designation2": data.get("designation2"),

                        "companyName": data.get("company_name"),
                        "tradeLicenseId": data.get("trade_license_id"),
                        "businessDescription": data.get("businessDescription"),
                        "tradeLicenseImageHolder": this.state.fileBase64,

                        "address": data.get("address"),
                        "streetName": data.get("streetName"),
                        "buildingName": data.get("buildingName"),
                        "poBox": data.get("poBox"),

                        "city": "/api/cities/" + data.get("city"),
                        "area": "/api/areas/" + data.get("area"),

                        "companyWebsiteLink": data.get("companyWebsiteLink"),
                        "youtubeLink": data.get("youtubeLink"),
                        "linkedInLink": data.get("linkedInLink"),
                        "instagramLink": data.get("instagramLink"),

                        "facebookLink": data.get("facebookLink"),
                        "twitterLink": data.get("twitterLink"),


                    })


                }

            }
        }
    }


    componentDidMount(){


        this.fetchCities()
    }

    onChange(e) {

        var files = e.target.files;

        console.log(files);


        var filesArr = Array.prototype.slice.call(files);

        console.log(filesArr);

        this.setState({ file: files[0]});

        console.log(fileToBase64(files[0]))

        this.setState({

            fileBase64: fileToBase64(files[0])
        })



        // var reader = new FileReader();
        // // Closure to capture the file information.
        //
        // reader.onload = (function(theFile) {
        //     return function(e) {
        //         var binaryData = e.target.result;
        //         //Converting Binary Data to base 64
        //         var base64String = window.btoa(binaryData);
        //         //showing file converted to base64
        //
        //         console.log(base64String)
        //
        //         alert('File converted to base64 successfuly!\nCheck in Textarea');
        //     };
        // })(files[0]);
        // // Read in the image file as a data URL.
        // reader.readAsBinaryString(files[0]);





    }

    render (){
        return(
            <div className="bg-gray">
                <section className="signup-banner host-new">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="signup-form w-100">
                                <h3 className="form-heading text-center">Business (Host)'s Sign Up </h3>
                                <div className="signup-form-col">
                                <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}  enctype="multipart/form-data">
                                <div className="form-block">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <h4>First Contact Person Details</h4>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"firstName"}

                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"lastName"}
                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>

                                        <Form.Group as={Col} md="6"  controlId="exampleForm.ControlInput1">

                                                <Form.Label>Email </Form.Label>


                                            {!this.props.isLoggedIn ?
                                                <Form.Control
                                                    value={this.state.email} onChange={this.checkIfUserExist}
                                                    required name={"email"}
                                                    type="email" placeholder=""/> :


                                                <Form.Control
                                                    value={this.props.userDetail&&this.props.userDetail.email} onChange={this.checkIfUserExist}
                                                    required name={"email"}
                                                    type="email" placeholder=""/>

                                            }

                                                {this.state.emailLoad &&  <Spinner
                                                    className={"email-input-spinner"}
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}


                                                <Form.Control.Feedback type="invalid">Email Id must be vaild</Form.Control.Feedback>


                                            {this.state.userExists &&

                                                <Col sm={{span: 12}}>


                                                    <div  style={{color : "#EC1C24"}} className="email-exists-block">An account with this email already exists.</div>

                                                    <div className="email-exists-block">

                                                        <Link  style={{color : "#007dc4", fontWeight: "600"}}  onClick={this.loginPopUp} className="">Log In</Link> now.</div>


                                                </Col>

                                            }


                                            {((!this.props.isLoggedIn&&this.state.customerButNotHost))&&

                                            <Col sm={{span: 12}}>


                                                {/*<div  style={{color : "#EC1C24"}} className="email-exists-block">An customer account with this email already exists.</div>*/}

                                                <div className="email-exists-block">An customer account with this email already exists. Click <Link onClick={this.loginPopUp} style={{color : "#007dc4", fontWeight: "600"}}  className="">Log In</Link> to continue Host Sign Up. </div>



                                            </Col>

                                            }


                                        </Form.Group>


                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Designation</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"designation"}
                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Landline</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                maxLength={9} minLength={9} type="tel" name={"landline"}

                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Phone number</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                maxLength={9} minLength={9} name={"phoneNumber"} type="tel"
                                                required

                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <small class="opecity-text small-text">Please do not include prefix +971</small>

                                        </Form.Group>
                                    </Form.Row>


                                    {!this.props.isLoggedIn && <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control minLength={6} required name={"password"} type="password"
                                                          placeholder=""/>
                                            <Form.Control.Feedback type="invalid">The password field must be at least 6
                                                characters.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control isInvalid={this.state.confirmPasswordError} minLength={6}
                                                          required name={"confirmPassword"} type="password"
                                                          placeholder=""/>

                                            {this.state.confirmPasswordError &&
                                            <Form.Control.Feedback type="invalid">Passwords do not match.
                                            </Form.Control.Feedback>}

                                        </Form.Group>
                                    </Form.Row>

                                    }



                                </div>
                                <div className="form-block mt-4">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <h4>Second Contact Person:</h4>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                name={"firstName2"}

                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                name={"lastName2"}

                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>

                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Designation</Form.Label>
                                            <Form.Control

                                                name={"desgination2"}
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Contact Number(Mobile)</Form.Label>
                                            <InputGroup className="phone_no">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    name={"mobile2"}


                                                    placeholder=""
                                                    defaultValue=""
                                                    maxLength={9} minLength={9} type="tel"
                                                />
                                            </InputGroup>
                                            <small class="opecity-text small-text">Please do not include prefix +971</small>

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Contact Number(landline)</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control

                                                name={"landline2"}
                                                maxLength={9} minLength={9} type="tel"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>

                                        </Form.Group>

                                    </Form.Row>
                                </div>
                                <div className="form-block mt-4">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <h4>Business (Host) Information</h4>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Company name</Form.Label>
                                            <Form.Control
                                                name={"company_name"}
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Trade license id</Form.Label>
                                            <Form.Control
                                                name={"trade_license_id"}
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Business description</Form.Label>
                                            <Form.Control as="textarea" rows="3"
                                                 name={"businessDescription"}
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                        </Form.Group>
                                        <Form.Group  as={Col} md="6" controlId="validationCustom01">

                                            <Form.Label className={""}>Trade License Image</Form.Label>

                                      <p/>
                                        <Form.Label for="uploadBtn" className={"custom-file-upload"}>

                                                <Form.Control accept="image/jpg, image/jpeg" id={"uploadBtn"} onChange={this.onChange} className="custom-file-input-control"

                                                type="file"
                                                placeholder=""
                                                defaultValue=""
                                                 custom
                                                              name={"tradeLicenseImage"}

                                            />
                                               <CloudUpload/> Click To Upload
                                            </Form.Label>
                                            {this.state.file && <img className={"upload-img-thumbnail"} src={URL.createObjectURL(this.state.file)} />}


                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"address"}
                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Street Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"streetName"}
                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Building </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"buildingName"}
                                            />

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>PO Box No.</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                                name={"poBox"}
                                            />

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label >City</Form.Label>
                                            <Form.Control   required as="select" className=""  onChange={this.fetchArea} name={"city"}>
                                                <option value={""} >Choose...</option>
                                                {this.state.cities.map((item)=>
                                                    <option value={item.id}>{item.name}</option>

                                                )
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Area</Form.Label>
                                            <Form.Control    required  as="select" className="" name={"area"}>
                                                <option value={""}>Choose...</option>
                                                {this.state.areas.map((item)=>
                                                    <option value={item.id}>{item.name}</option>

                                                )
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                </div>
                                <div className="form-block mt-4">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12">
                                            <h4>Website and Social Media Profiles</h4>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Website</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend"><i class="fas fa-globe"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name={"companyWebsiteLink"}

                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <p class="small-text">For eg: www.yourwebsite.com</p>

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Linkedin</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-linkedin-in"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name={"linkedInLink"}
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <p class="small-text">For eg: www.yourwebsite.com</p>

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Facebook</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-facebook-f"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name={"facebookPageLink"}
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <p class="small-text">For eg: www.facebook.com</p>

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Twitter</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-twitter"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name={"twitterLink"}
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <p class="small-text">For eg: www.twitter.com</p>

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Instagram</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-instagram"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control

                                                name={"instagramLink"}
                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <p class="small-text">For eg: www.instagram.com</p>

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>youtube</Form.Label>
                                            <InputGroup className="phone_no">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrepend"><i class="fab fa-youtube"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name={"youtubeLink"}

                                                type="text"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            </InputGroup>
                                            <p class="small-text">For eg: www.youtube.com</p>

                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} md="12" className="text-center mt-4">
                                        {(!this.state.userExists && !this.state.customerButNotHost) &&
                                        <Button className="btn " type="submit">


                                            {this.props.loading
                                            && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            }

                                            {this.props.loading ? "Wait.." : "SignUp"}

                                        </Button>
                                        }
                                        {this.state.formHasError && <Alert className={"mt-2"} variant={"danger"}>
                                            Information provided is not valid or missing!
                                        </Alert>
                                        }
                                    </Form.Group>
                                    </Form.Row>
                                </div>
                                </Form>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}



export const fileToBase64 = (fileToConvert) => {


    return new Promise(resolve => {


        var file = fileToConvert;
        var reader = new FileReader();    // Read file content on file loaded event
        reader.onload = function(event) {
            resolve(event.target.result);
        };

        // Convert data to base64
        reader.readAsDataURL(file);
    });



};

const mapStateToProps = state => {
    return {
        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,

    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        signUpHost: (data) => dispatch(actionCreator.signUpHost(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),



    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(BecomePartner);

// export default BecomePartner;