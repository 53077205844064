import React, { Component } from 'react';
import axios from "axios/index";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {Switch, Link} from 'react-router-dom'
import {Captcha} from 'primereact/captcha';
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import queryString from 'query-string'
import config from "./social/config";
import Recaptcha from 'react-google-invisible-recaptcha';
import GoogleLogin from 'react-google-login';

import { FacebookProvider, Login  } from 'react-facebook';


class  LoginNew extends Component{



    userFormData;


    // componentDidUpdate(prevProps) {
    //
    //     if (this.props.location !== prevProps.location) {
    //         // console.log('Route Updated');
    //
    //         const urlParams = queryString.parse(this.props.location.search)
    //
    //         // console.log(urlParams)
    //
    //
    //    this.checkIfUserExist(urlParams)
    //
    //     }
    // }

        constructor() {

        super()

        this.state = {

            validatedForm: false,
            isChecked: false,
            showForgotForm: false,
            email: null,
            formData: null,
            googleLoginError: false,
            facebookLogin: false,
            userExists: false,
            socialUserInfo: null,
            existingUserInfo: null


        }


        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleChangeChecked = this.handleChangeChecked.bind(this);
        this.toggleForgotScreen=this.toggleForgotScreen.bind(this)
        this.emailInput=this.emailInput.bind(this)

       this.socialUserPhoneInput= this.socialUserPhoneInput.bind(this)


       this.createUser=this.createUser.bind(this)

            this.onResolved=this.onResolved.bind(this)
          this.handleLogin=this.handleLogin.bind(this)


            this.responseGoogle=this.responseGoogle.bind(this)

            this.responseFacebook=this.responseFacebook.bind(this)
            this.checkIfUserExist=this.checkIfUserExist.bind(this)



        }






    checkIfUserExist(user){



        var  url = baseUrl+"customers.json?groups[]=customer&email="+user.email;

        // console.log(url)



        axios.get(url)
            .then(res => {



                if (res.data.length>0){


                    this.setState({

                        userExists: true,
                        existingUserInfo:res.data[0],

                    })



                    // console.log("user exists ")
                    // console.log(res.data)

                    if (res.data[0].socialLogin>0){

                        // console.log("social user exists ")

                        this.props.logIn({"username":user.email,"password": "qwerty"})




                    } else {


                        // console.log("social user doenst  exists ")

                        this.setState({

                            userExists: true
                        })
                    }



                } else {

                    this.setState({

                        userExists : false,
                    })


                    this.createUser(user)

                }




            }).catch(error => {

            // console.log(error)


            this.setState({

                emailLoad : false

            })


        });


    }



    socialUserPhoneInput(){


            // this.setState({
            //
            //     socialUserInfo:   {
            //                 "firstName": "Dua",
            //                 "lastName":"Arun",
            //                 "email": "email@gmail.com",
            //                 "image": "",
            //                  "socialLogin":1
            //     }
            //
            // })

            this.props.setSocialUserInfo(this.state.socialUserInfo)

        // this.props.socialLogin(true)
        this.props.socialLoginPopUp(true)


    }


    responseFacebook(response) {




            // console.log("error facebok")
            // this.socialUserPhoneInput()


        // console.log(response)



        // this.checkIfUserExist(
        //     {
        //         "name":"My name",
        //         "email": "riyaarora080492@gmail.com",
        //         "image": "img",
        //         "socialLogin": 1
        //     }
        // )

        if (response&&response.profile) {

            // console.log("check if exists")

            this.checkIfUserExist(
                {
                "name": response.profile.name,
                "email": response.profile.email,
                "image": response.profile.picture.data.url,
                    "socialLogin": 1
            }
            )


        }


    }

        responseGoogle(response){

        // console.log(response)

        if (response&&response.profileObj) {



            this.checkIfUserExist({"name": response.profileObj.name, "email": response.profileObj.email,"socialLogin":2})


        }


    }

    checkRecaptcha = () => {

        this.recaptcha.execute();
    }

    onResolved = (response) => {


        // console.log( 'Recaptcha resolved with response: ' + this.recaptcha.getResponse() );
        axios.post(baseUrl+"captcha_forms" ,{ token: this.recaptcha.getResponse()  }).then(res => {


           // console.log(res.data)


            if (res.data.response.success){
                // console.log("captach success")

                this.handleLogin()


            } else {

               // console.log("bot detected"+res.data.response.success)
            }




        }).catch(error => {

            // console.log("Captach response error found "+error)


        });




        // this.handleLogin()


    }


    emailInput(event){

        this.setState({

            email : event.target.value,
        })


    }





    handleLogin(){

        // console.log(this.userFormData)
        this.props.logIn(this.userFormData)

    }



    createUser(user){



        this.setState({

            socialUserInfo:   {
                   "firstName": user.name.trim().split(" ")[0],
                    "lastName": user.name.trim().split(" ")[1],
                    "email": user.email,
                    "password": "qwerty",
                    "image": "",
                    "socialLogin":user.socialLogin
            }

        })



        this.socialUserPhoneInput()


    }




    componentDidMount(){

        // this.initFacebookLogin()

        window.scrollTo(0, 0)

        const urlParams = queryString.parse(this.props.location.search)


        if (urlParams.email&&urlParams.name&&urlParams.type){


            this.createUser({"name": urlParams.name, "email": urlParams.email})

        }

        this.setState({

            email : urlParams.email
        });


    }


    toggleForgotScreen(){
        window.scrollTo(0, 0)


        this.setState({

            showForgotForm : !this.state.showForgotForm
        });


    }




    handleSubmit = event => {


        event.preventDefault();

        const form = event.currentTarget;

        // console.log(form.checkValidity())
        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true
            })

            const data = new FormData(event.target);

            const username = data.get("email")
            const password = data.get("password")


            this.userFormData= {"username": username, "password": password}
            // this.props.logIn({"username": username, "password": password})


            this.checkRecaptcha()

        }




    }


    render (){


        return(
            <section className="login signin-banner">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="signup-formtext-center">
                                <div className="signup-form-col text-left">
                                        <div className="res-row d-flex justify-content-between">
                                            <div className="form-col-banner">
                                            </div>


                                                <div className="form-col-left">
                                                    <h3 className="form-heading">Sign In </h3>

                                                    {this.props.loginFailed &&
                                                    <Alert key={"alert"} variant={"danger"}>
                                                        Invalid Credentials, Try again!
                                                    </Alert>}


                                                    <Form noValidate validated={this.state.validatedForm}
                                                          onSubmit={this.handleSubmit}>
                                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                                            <Form.Label for column sm={10}>
                                                                Email
                                                            </Form.Label>
                                                            <Col sm={12}>
                                                                <Form.Control value={this.state.email} onChange={this.emailInput} required name={"email"} type="email"
                                                                              placeholder="Email"/>
                                                                <Form.Control.Feedback type="invalid">Email Id must be
                                                                    vaild</Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formHorizontalPassword">
                                                            <Form.Label column sm={12}>
                                                                Password
                                                            </Form.Label>
                                                            <Col sm={12}>
                                                                <Form.Control minLength={5} required name={"password"}
                                                                              type="password" placeholder="Password"/>
                                                                <Form.Control.Feedback type="invalid">
                                                                    The password field must be at least 5 characters.
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formHorizontalCheck">
                                                            <Col sm={{span: 12}}>
                                                                <Form.Check label="Remember me" size="lg"/>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="exampleForm.ControlInput1">
                                                            <Col className={"d-flex"}>

                                                                <Recaptcha
                                                                    ref={ ref => this.recaptcha = ref }
                                                                    sitekey="6LeT490UAAAAAG_gyj1PQAYYQK-_Cyqo6WxheSqx"
                                                                    onResolved={ this.onResolved } />

                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className={"justify-content-md-center"}
                                                                    controlId="exampleForm.ControlInput1">


                                                            <Col sm={{span: 12}}>
                                                                <div className="d-flex btn-group">

                                                                    <button type={"submit"}
                                                                            className="btn btn-no-radius">Sign In
                                                                    </button>


                                                                </div>

                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row}>
                                                            <Col sm={{span: 12, offset: 0}}>

                                                                <div className="forgot-col mt-3 d-flex aligns-center">
                                                                    <Link to={"/forgot-password"}  >Forgot
                                                                        Password?</Link>
                                                                    <span className="or w-100">or</span>
                                                                </div>

                                                                <div>

                                                                    {this.state.userExists &&
                                                                    <Alert key={"alert"} variant={"danger"}>
                                                                        An account already exists linked with this email address. Thanks!
                                                                    </Alert>}

                                                                </div>




                                                                <div>

                                                                    <FacebookProvider appId="860677251005804">

                                                                        <Login
                                                                            scope="email"
                                                                            onCompleted={this.responseFacebook}
                                                                            onError={this.responseFacebook}
                                                                        >

                                                                            {({ loading, handleClick, error, data }) => (

                                                                                <div onClick={handleClick}
                                                                                className="fb-login-btn d-flex w-100 justify-content-center">

                                                                                <div id="fb-btn" className="btn fb-btn ">Login
                                                                                with
                                                                                Facebook
                                                                                </div>
                                                                                </div>


                                                                            )}

                                                                        </Login>



                                                                    </FacebookProvider>

                                                                </div>

                                                                <div>




                                                                    <GoogleLogin
                                                                        clientId="178867397101-br3ke941t0iaensupo3bh5b5ct1oklha.apps.googleusercontent.com"
                                                                        render={renderProps => (

                                                                            <div onClick={renderProps.onClick} disabled={renderProps.disabled} id="g-btn"
                                                                            className="fb-login-btn d-flex w-100 justify-content-center">
                                                                            <div href="#"
                                                                            className="loginBtn loginBtn--google">
                                                                            Login with Google
                                                                            </div>
                                                                            </div>

                                                                        )}
                                                                        buttonText="Login"
                                                                        onSuccess={this.responseGoogle}
                                                                        onFailure={this.responseGoogle}
                                                                        cookiePolicy={'single_host_origin'}
                                                                    />


                                                                </div>

                                                            </Col>
                                                        </Form.Group>

                                                    </Form>

                                                </div>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}



const mapStateToProps = state => {
    return {

        age: state.age,
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        loginFailed: state.loginFailed

    };
};

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        logIn: (data) => dispatch(actionCreator.logIn(data)),
        signUp: (data) => dispatch(actionCreator.signUp(data)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        socialLoginPopUp : (item) => dispatch(actionCreator.socialLoginPopUp(item)),
        setSocialUserInfo: (item) => dispatch(actionCreator.setSocialUserInfo(item)),



    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(LoginNew);


