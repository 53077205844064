import React, {Component, Fragment, useState} from 'react';
import Address from './Address';
import Delete from '../img/delete.png';
import Edit from '../img/edit.png';
import Check from '../img/check.png';
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import axios from "axios/index";
import {Alert, Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {GoSell} from "@tap-payments/gosell";
import {baseImgUrl, baseUrl} from '../Util/Constants'
import Map from '../img/map.png';
import Calander from '../img/calander.png';
import Duration from '../img/duration.png';
import Date from '../img/day.png';
import Ticket from '../img/ticket.png';
import BundleDetailItem from './BundleDetailItem';
import {getKey} from "../LocalStorage/user";

var webUrl="https://www.happy-weekends.com/checkout/";

var publicKeyLive = "pk_live_W1rkGbLVzjD4iCd59wQx0pov"
var publicKeyTest = "pk_test_m0nVBSPpFtQawqgO2XA7Gz9L"


class  Checkout extends Component{


    constructor(props){

        super(props)

        this.state = {

            total: 0,
            cartTotal :0,
            subTotal: 0,
            cartDiscount :0,
            couponDiscount : 0,
            couponPercentage : 0,
            couponDiscountVisible : false,
            couponCheckloading : false,
            couponApplied : false,
            couponCode : "",
            confirmUserPopUp : false,
            removeItemId : {},
            stayAtHome : false,
            showMobileDetail : false,
            showEmptyMsg : false,
            randomOrderId : null,
            bundle : null,
            bundleItems : null,
            priceOptionsBundle : null,
            addAddressAlert: false

        }

        this.removeCartItem= this.removeCartItem.bind(this)
        this.handleCouponSubmit= this.handleCouponSubmit.bind(this)
        this.hideUserConfirmPopUp= this.hideUserConfirmPopUp.bind(this)
        this.handleCouponRemove= this.handleCouponRemove.bind(this)
        this.getOrderItems= this.getOrderItems.bind(this)
        this.callbackFunc= this.callbackFunc.bind(this)
        this.handleGuest= this.handleGuest.bind(this)
        this.toggleMobileDetail=this.toggleMobileDetail.bind(this)
        this.showEditCartPopUp= this.showEditCartPopUp.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.callAddressRequired=this.callAddressRequired.bind(this)

    }



    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    handleGuest(){

        GoSell.openLightBox()

    }



    showEditCartPopUp(e){

        this.props.setEditCartPopUp(e.currentTarget.dataset.id)
        // this.props.editCartPopUp(true)

    }

    showBundleEditCartPopUp(e){

        // this.props.setEditBundleCartPopUp(e.currentTarget.dataset.id)

        var bundle = getKey("bundleItems").filter((item)=> item.id==e.currentTarget.dataset.id)[0]

        this.props.setEditBundleCartPopUp(bundle)

        // console.log("edit bundle selected")
        // console.log(bundle)

        this.props.editBundleCartPopUp(true)

    }



    callAddressRequired(){

    // alert("address required")


        this.setState({

            addAddressAlert : true
        })

    }




    callbackFunc(response){

        // console.log("booking callback");


        // console.log(response);


        this.props.setBookingResponse(response)

        if (response.callback.status==="CAPTURED"){

            // console.log("success");
            window.location.href=("/booking/"+this.props.userDetail.id)

        }
        else if (response.callback.status==="CANCELLED"){


        }
        else if (response.callback.status==="DECLINED"){


        }
        else if (response.callback.status==="TIMEDOUT"){


        }else {


        }

    }

    getOrderItems(){

           var items = this.props.cartItems;
           var cartItems = []

        if (items.length > 0) {


                    for (var i = 0; i < items.length; i++) {

                        if(items.itemType==0) {

                            var itemTotal = 0;
                            var itemQuantity = 0;
                            var priceOptions = items[i].cartPriceOptions;


                            for (var j = 0; j < priceOptions.length; j++) {

                                // console.log(priceOptions[j])

                                if (priceOptions[j].quantity > 0) {


                                    if (priceOptions[j].discountedPrice) {

                                        itemTotal = itemTotal + (priceOptions[j].discountedPrice * priceOptions[j].quantity)
                                        itemQuantity = itemQuantity + priceOptions[j].quantity;

                                    } else {

                                        itemTotal = itemTotal + (priceOptions[j].price * priceOptions[j].quantity)
                                        itemQuantity = itemQuantity + priceOptions[j].quantity;

                                    }


                                }
                            }


                            if (this.props.couponDiscountVisible) {

                                itemTotal = itemTotal - itemTotal * this.props.couponDiscountPercentage / 100
                                itemTotal = Math.round(itemTotal)

                            }

                            var cartItem = {
                                id: items[i].activity.id,
                                name: items[i].activity.title,
                                description: '',
                                quantity: itemQuantity,
                                total_amount: itemTotal
                            }


                            cartItems.push(cartItem)
                        }

                    }


                    // console.log("payment details")

                    // console.log(items)

                    return cartItems

                }

    }

    componentWillMount(){

        // this.calculateTotal()


    }

    componentDidMount () {

        window.scrollTo(0, 0)

        this.genRandomOrderId()


        // var bundleItems=this.props.bundleItems



        // this.setState({
        //
        //     bundleItems : bundleItems
        // })

        // // console.log(bundleItems)
        //
        // // console.log("bunlde items saved")
        // // console.log(this.state.bundleItems)



    }


    genRandomOrderId() {


        this.setState({

            randomOrderId: Math.random()

        })
    }


    handleCouponRemove(){


        var postData = {

            customer : this.props.userDetail.id,
            code : this.props.couponCode,
            addCoupon : false,

        }


        this.props.removeCoupon(postData)





    }

    toggleMobileDetail() {

        this.setState({


            showMobileDetail : !this.state.showMobileDetail
        })

    }



    hideUserConfirmPopUp() {

        this.setState({


            confirmUserPopUp : false
        })

    }


    showUserConfirmPopUp(e) {



        this.setState({

            removeItemId : e.currentTarget.dataset.id

        })

        // alert(e.currentTarget.dataset.id)

        this.setState({

            confirmUserPopUp : true
        })

    }

        removeCartItem() {


            this.hideUserConfirmPopUp()

            // console.log(this.state.removeItemId+"   remove" )

            var selectedId =   this.state.removeItemId;

            this.props.removeCartItem(
                {
                    "activity":selectedId,
                    "user":this.props.userDetail.id,
                "itemType":0
                }
                )


    }


    handleCouponSubmit = event => {



        event.preventDefault();

        if (this.props.isLoggedIn) {




            const form = event.currentTarget;

            // console.log(form.checkValidity())

            if (form.checkValidity() === false) {

                event.stopPropagation();

                this.setState({

                    validatedForm: true,

                });
            }


            if (form.checkValidity()) {


                this.setState({
                    couponCheckloading: true,
                    couponCode: event.currentTarget.coupon.value


                })

                const data = new FormData(event.target);

                // console.log("coupon data")
                // console.log(data)


                var url = baseUrl + "customer_discount_codes";

                var postData = {

                    customer: this.props.userDetail.id,
                    code: event.currentTarget.coupon.value,
                    addCoupon: true,

                }


                this.props.addCoupon(postData)

            }

        }else{


            this.props.loginPopUp(true)
        }

    }


    render (){
        return(
            <Fragment>

                {this.props.salesQuote &&
                this.props.userDetail &&
                <GoSell
                    gateway={{
                        publicKey:publicKeyLive,
                        language:"en",
                        contactInfo:true,
                        supportedCurrencies:"all",
                        supportedPaymentMethods:"all",
                        saveCardOption:true,
                        customerCards: true,
                        notifications:'standard',
                        backgroundImg: {
                            url: '',
                            opacity: '1'
                        },
                        callback: this.callbackFunc,
                        labels:{
                            cardNumber:"Card Number",
                            expirationDate:"MM/YY",
                            cvv:"CVV",
                            cardHolder:"Name On Card",
                            actionButton:"Pay"
                        },
                        style: {
                            base: {
                                zIndex:"1000",
                                color: '#535353',
                                lineHeight: '18px',
                                fontFamily: 'sans-serif',
                                fontSmoothing: 'antialiased',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.26)',
                                    fontSize:'15px'
                                }
                            },
                            invalid: {
                                color: 'red',
                                iconColor: '#fa755a '
                            }
                        }
                    }}

                    customer={{
                        first_name: this.props.userDetail.firstName,
                        middle_name: "",
                        last_name: this.props.userDetail.lastName,
                        email: this.props.userDetail.email,
                        phone: {
                            country_code: "971",
                            number: this.props.userDetail.phoneNumber
                        }
                    }}

                    order={{
                        amount: this.props.total,
                        currency:"AED",
                        items: this.getOrderItems(),
                        shipping:null,
                        taxes: null
                    }}
                    transaction= {{
                        mode: 'charge',
                        charge:{
                            saveCard: false,
                            threeDSecure: true,
                            description: "Happy Weekends Order",
                            statement_descriptor: "",
                            reference:{
                                transaction: "txn_"+this.props.userDetail.id+"-"+this.state.randomOrderId,
                                order: "ord_"+this.props.userDetail.id+"-"+this.state.randomOrderId
                            },
                            metadata:{},
                            receipt:{
                                email: true,
                                sms: true
                            },
                            redirect: webUrl+this.props.userDetail.id,
                            post: null,
                        }

                    }}
                />

                }
                <section className="checkout-section bg-gray py-5">
                <div className="container">

                    { this.props.isCartItemsLoadingDone?
                        <>

                    {  this.props.cartItems.length > 0 ?
                        <Fragment>

                            <div className="row mb-4">

                                <div className="col-lg-12">

                                    <h2>Booking Details</h2>
                                </div>
                            </div>
                        <div className="row">


                            <div className="col-lg-8">
                                <div className="white-box p-3 rounded">


                                    {this.props.salesQuote&&this.props.salesQuote.cartItems.map((item) =>

                                        <>
                                            {item.itemType==0?

                                        <div className="row  border-bottom mb-3 pb-2 product-img-block">
                                            <div className="col-5 col-lg-3 col-md-3   width-for-mobile">
                                                <div className="product-img mb-3">
                                                    <Link to={"/activity/"+item.activity.slug}>
                                                    <img src={baseImgUrl + item.activity.images[0].image} className="img-fluid"
                                                         alt="Product detail"/>
                                                    </Link>
                                                </div>
                                                <div className="actions for-web">
                                                    <button data-id={item.id} style={{border: "none",backgroundColor: "transparent"}} onClick={this.showEditCartPopUp.bind(this)} className="pr-2 text-dark click-edit" >
                                                        <img src={Edit}
                                                                                                          alt=""/></button>
                                                    <button  data-id={item.id}  data-price={item.activity.id}
                                                             onClick={this.showUserConfirmPopUp.bind(this)}   style={{border: "none",backgroundColor: "transparent"}} className="text-dark" href="javascripit">
                                                        <img src={Delete} alt=""/></button>
                                                </div>
                                            </div>

                                            {/*<ProductDis item={item.activity} priceOptions={item.cartPriceOptions}/>*/}
                                            {/*<ActivityTotal item={item.activity} priceOptions={item.cartPriceOptions} />*/}


                                            <div className="col-lg-9 col-md-9 col-7 ">
                                                <div className="row">
                                                    <div className="col-lg-12 ">
                                                        <div className="row product-title-booking">
                                                            <div className="col-lg-8">
                                                                <h2>{item.activity.title}</h2>
                                                                {item.activity.address && item.activity.address.city && <p><img src={Map} alt="" /> {item.activity.address.city.name} </p> }
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="price">AED {item.total}</div>


                                                                { (item.total !=item.cartTotal) && <div className="or-price">
                                                        <span className="line-through">AED {item.cartTotal}</span> {item.discount}%
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row for-web">
                                                            <div className="col-lg-12">
                                                                <div className="border-top pt-3 pb-5 product-dis-list">
                                                                    {item.activity.dateInput && <div className="row mb-1">
                                                                        <div className="col-lg-4"><img src={Calander} alt="" /><span>Date</span></div>
                                                                        <div className="col-lg-6"><p>Dec 20, 2019</p></div>
                                                                    </div>}
                                                                    {item.activity.duration&& <div className="row mb-1">
                                                                        <div className="col-lg-4"><img src={Duration} alt=""/><span>Duration</span>
                                                                        </div>
                                                                        <div className="col-lg-6"><p>1 Hours</p></div>
                                                                    </div>
                                                                    }



                                                                    {item.activity.day &&item.activity.day.length>0 && <div className="row mb-1">
                                                                        <div className="col-lg-4"><img src={Date} alt=""/><span>Available Days</span>
                                                                        </div>
                                                                        <div className="col-lg-6"><p> {item.activity.day.map((itemDay, indexDay) =>
                                                                            <>
                                                                                {indexDay > 0 && ", "}{itemDay.name}
                                                                            </>
                                                                        )}</p></div>
                                                                    </div>
                                                                    }
                                                                    <div className="row">
                                                                        <div className="col-lg-4"><img src={Ticket} alt="" /><span>Tickets</span></div>
                                                                        <div className="col-lg-6">

                                                                            {item.cartPriceOptions.map((option)=>

                                                                                <p>{option.quantity} x AED {option.total}</p>

                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-12 for-mobile">


                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className=" product-dis-list">
                                                            {item.activity.dateInput && <div className="row mb-1">
                                                                <div className="col-lg-12"><img src={Calander} alt="" /><span>Date: Dec 20, 2019</span></div>

                                                            </div>}
                                                            {item.activity.duration&& <div className="row mb-1">
                                                                <div className="col-12"><img src={Duration} alt=""/><span>Duration: 1 Hours</span>
                                                                </div>

                                                            </div>
                                                            }



                                                            {item.activity.day &&item.activity.day.length>0 && <div className="row mb-1">

                                                                <div className="col-12"><img src={Date} alt=""/><span>Available Days: {item.activity.day.map((itemDay, indexDay) =>
                                                                    <>
                                                                        {indexDay > 0 && ", "}{itemDay.name}
                                                                    </>
                                                                )}</span></div>
                                                            </div>
                                                            }
                                                            <div className="row">

                                                                <div className="col-12"><img src={Ticket} alt="" /><span>Tickets:

                                                                    { item.cartPriceOptions.map((option,indexOption)=>


                                                                        <>
                                                                            {indexOption > 0 && ", "}{option.quantity} x AED {option.total }

                                                                        </>

                                                                    )}
</span>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className={"col-12 for-mobile"}>
                                                <div className="actions">
                                                    <button data-id={item.activity.id} style={{border: "none",backgroundColor: "transparent"}} onClick={this.showEditCartPopUp.bind(this)} className="pr-2 text-dark click-edit" >
                                                        <img src={Edit}
                                                             alt=""/></button>
                                                    <button  data-id={item.id}  data-price={item.activity.id}
                                                             onClick={this.showUserConfirmPopUp.bind(this)}   style={{border: "none",backgroundColor: "transparent"}} className="text-dark" href="javascripit">
                                                        <img src={Delete} alt=""/></button>
                                                </div>
                                            </div>

                                        </div>:

                                                //weekend pass cart item

                                                <div className="row  border-bottom mb-3 pb-2 product-img-block package-column">

                                                    {/*main Item*/}
                                                    <div className={"col-12"}>
                                                        <div className={"row"}>

                                                            <div className="col-6 col-lg-4 col-md-4 ">
                                                                <div className="product-img mb-3"><a
                                                                    href={"/weekend-detail/"+item.bundle.slug}>
                                                                    <img
                                                                        src={baseImgUrl + item.bundle.listingCoverImage}
                                                                        className="img-fluid" alt="Product detail" /></a></div>

                                                            </div>
                                                            <div className="col-6  col-lg-8 col-md-8 bundle-right-column">
                                                                <div className="row">
                                                                    <div className="col-lg-12 ">
                                                                        <div className="row product-title-booking">
                                                                            <div className="col-lg-8"><h2>{item.bundle.name}</h2></div>
                                                                            <div className="col-lg-4">
                                                                                <div className="price ">
                                                                                    AED {item.total}
                                                                                    {/*<GetBundleTotalAfterDiscount item={item} />*/}

                                                                                </div>

                                                                                <div className="or-price bundle-or-price"><span className="line-through">
                                                                                    AED {item.cartTotal}
                                                                                    {/*<GetBundleCartTotal item={item} />*/}
                                                                                </span>
                                                                                    &nbsp;
                                                                                    {item.discount}
                                                                                    %
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                {/*main item end*/}

                                                                {/*sub items */}
                                                             <BundleDetailItem  item={item} />
                                                                {/*sub items  ends*/}

                                                            </div>



                                                        </div>

                                                    </div>
                                                    <div className="col-12 ">
                                                        <div className="actions">
                                                            <button data-id={item.id} onClick={this.showEditCartPopUp.bind(this)}
                                                                    style={{border: "none",backgroundColor: "transparent"}}
                                                                    className="pr-2 text-dark click-edit"><img
                                                                src={Edit}
                                                                alt="" /></button>
                                                            <button data-id={item.id} data-price="1041" onClick={this.showUserConfirmPopUp.bind(this)}
                                                                    style={{border: "none",backgroundColor: "transparent"}}
                                                                    className="text-dark" href="javascripit"><img
                                                                src={Delete}
                                                                alt="" /></button>
                                                        </div>

                                                    </div>

                                                </div>
                                            }

                                        </>
                                    )

                                    }


                                    <div className="row mt-4 border-bottom promobox-block">
                                        <div className="col-lg-12">


                                            { this.props.couponDiscountVisible &&
                                            <div className="promobox justify-content-between promobox-apply mb-4">
                                                <p className="text-dark mb-0">Promo code Applied : {this.props.couponCode}<img src={Check} alt=""/>
                                                </p>
                                                <p className="text-danger mb-0"><button onClick={this.handleCouponRemove} type="button" class="remove">Remove</button></p>
                                            </div>

                                            }


                                            { !this.props.couponDiscountVisible &&
                                            <div className="promobox ">
                                                <p className="text-blue">Have a promo code?</p>


                                                <Form onSubmit={this.handleCouponSubmit.bind(this)}>
                                                <Form.Group className="mb-4">
                                                            <div className="p-relative">
                                                                <Form.Control   autoComplete="off" style={{display : "inline"}} required  name={"coupon"} type="text" placeholder="" />

                                                                { this.props.couponError &&
                                                                    <>
                                                                <div style={{width: "100%",
                                                                    marginTop: ".25rem",
                                                                    fontSize: "80%",
                                                                    color: "#dc3545" }} className="">Invalid Promo Code
                                                                </div>
                                                                <div className="invalid-promocode" type="invalid">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                                 </div>
                                                                    </>
                                                                    }
                                                            </div>


                                                            <Button   type={"submit"}  >
                                                                    { this.props.couponCheckloading && <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    /> }

                                                                    { this.props.couponCheckloading ? "Wait.." : "Apply"}

                                                                </Button>

                                                        </Form.Group>
                                                </Form>
                                            </div>
                                            }


                                        </div>
                                    </div>
                                    <div className="row mt-4 final-price">
                                        <div className="col-lg-12 for-mobile">
                                            <div className="block w-100 d-flex justify-content-between"><p
                                                className="text-dark font-21">Total</p><p
                                                className="text-dark font-21">AED {this.props.total}</p></div>
                                        </div>
                                        <div className="col-lg-12 for-mobile">
                                            <button onClick={this.toggleMobileDetail} className="view-cart-button-mobile">View Detail</button>
                                        </div>

                                        <div className="col-lg-12 for-web">
                                            <div className="final-price">
                                                <div className="block d-flex justify-content-between">
                                                    <p>Cart Total</p>
                                                    <p className="text-dark">AED {this.props.cartTotal}</p>
                                                </div>
                                                <div className="block d-flex justify-content-between">
                                                    <p>Cart Discount</p>
                                                    <p className="text-blue">AED {this.props.cartDiscount}</p>
                                                </div>
                                                {/*{ this.props.couponDiscountVisible && */}
                                                <div className="block d-flex justify-content-between">
                                                    <p>Coupon Discount<span className={"text-blue"} >({this.props.couponDiscountPercentage}%)</span></p>
                                                    <p className="text-blue">AED {this.props.couponDiscount}</p>
                                                </div>
                                                {/*}*/}
                                                <div className="block d-flex justify-content-between">
                                                    <p>Subtotal</p>
                                                    <p className="text-dark">AED {this.props.subTotal}</p>
                                                </div>

                                                <div className="block d-flex justify-content-between">
                                                    <p className="text-dark font-21">Total <span style={{fontWeight:"400", color:"#858688", fontSize:"15px"}} >(Inclusive of VAT)</span></p>
                                                    <p className="text-dark font-21">AED {this.props.total}</p>
                                                </div>
                                            </div>
                                        </div>


                                        { this.state.showMobileDetail &&
                                        <div className="col-lg-12 view-cart-detail-mobile">
                                            <div className="final-price">
                                                <div className="block d-flex justify-content-between">
                                                    <p>Cart Total</p>
                                                    <p className="text-dark">AED {this.props.cartTotal}</p>
                                                </div>
                                                <div className="block d-flex justify-content-between">
                                                    <p>Cart Discount</p>
                                                    <p className="text-blue">AED {this.props.cartDiscount}</p>
                                                </div>
                                                { this.props.couponDiscountVisible && <div className="block d-flex justify-content-between">
                                                    <p>Coupon Discount<span className={"text-blue"} >({this.props.couponDiscountPercentage}%)</span></p>
                                                    <p className="text-blue">AED {this.props.couponDiscount}</p>
                                                </div>
                                                }
                                                <div className="block d-flex justify-content-between">
                                                    <p>Subtotal</p>
                                                    <p className="text-dark">AED {this.props.subTotal}</p>
                                                </div>

                                                <div className="block d-flex justify-content-between">
                                                    <p className="text-dark font-21">Total <span style={{fontWeight:"400", color:"#858688", fontSize:"15px"}} >(Inclusive of VAT)</span></p>
                                                    <p className="text-dark font-21">AED {this.props.total}</p>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="primaryguestform white-box p-0 rounded">
                                    <h4>Payment Details</h4>
                                    <div className="row justify-content-center">



                                        <div className="col-lg-12">



                                        {(this.props.isLoggedIn || this.props.isGuest) &&
                                        <Address addressRequired={this.props.addressInput} total={this.state.total} />}


                                            <div className="order-total">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <p className="mb-0">Total </p>
                                                    <p className="small-text mb-0">(Inclusive of VAT)</p>
                                                </div>
                                                <div className="col-md-5 text-right">
                                                    <h5>AED <span id="totalPrice">{this.props.total}</span></h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div  className={this.props.addressRequired?"procedd-block web-price":"procedd-block border-none web-price"}>

                                            <div className="form-group ">


                                                {!this.props.addressInput &&  <button  onClick={() => GoSell.openLightBox()} className="red-btn w-100" type="submit">Proceed to Payment</button>}


                                                {this.props.addressInput&& !this.props.addressExist &&  <button  onClick={this.callAddressRequired} className="red-btn w-100" type="submit">Proceed to Payment</button>}


                                                {this.props.addressInput&& this.props.addressExist &&  <button  onClick={() => GoSell.openLightBox()} className="red-btn w-100" type="submit">Proceed to Payment</button>}

                                             </div>


                                        </div>
                                        </div>

                                        { (this.state.addAddressAlert&&!this.props.addressExist )&&
                                        <div className="col-11">
                                            <Alert style={{textAlign:"center"}} key={"alert"} variant={"danger"}>Add Address*</Alert>

                                        </div>}


                                    </div>
                                </div>
                            </div>
                        </div>

                            <Modal
                                size="sm"
                                show={this.state.confirmUserPopUp}
                                onHide={this.hideUserConfirmPopUp}
                                aria-labelledby="example-modal-sizes-title-sm">

                                <Modal.Header closeButton className="delete-item-cart">
                                    <Modal.Title id="example-modal-sizes-title-sm">
                                        Delete
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to remove it from your cart ?</Modal.Body>
                                <Modal.Footer>


                                    <Button variant="primary"   onClick={this.hideUserConfirmPopUp}>
                                        Cancel
                                    </Button>

                                    <Button variant="danger"
                                            onClick={this.removeCartItem} >
                                        Remove
                                    </Button>

                                </Modal.Footer>

                            </Modal>



                        </Fragment>
                        :
                        <Row className="row justify-content-md-center ">
                            <Col className="col-lg-12 mb-3">
                                <div className="alert alert-secondary" role="alert">
                                    Your Shopping Cart is empty!
                                </div>

                            </Col>
                            <Col md="auto" className="mb-3" md="auto">

                                <Link className="red-btn btn btn-primary" to={"/"}>
                                    Return To Home
                                </Link>
                            </Col>

                        </Row>
                    }
                    </>:<></>

                    }
                </div>
                <div className="mobile-price" onClick={this.toggleMobilePriceOptions}>
                         <div className="Show Option options-show">


                             {!this.props.addressInput &&  <button  onClick={() => GoSell.openLightBox()} className="red-btn w-100" type="submit">Proceed to Payment - AED <span id="totalPrice">{this.props.total}</span></button>}


                             {this.props.addressInput&& !this.props.addressExist &&  <button  onClick={this.callAddressRequired} className="red-btn w-100" type="submit">Proceed to Payment - AED <span id="totalPrice">{this.props.total}</span></button>}


                             {this.props.addressInput&& this.props.addressExist &&  <button  onClick={() => GoSell.openLightBox()} className="red-btn w-100" type="submit">Proceed to Payment - AED <span id="totalPrice">{this.props.total}</span></button>}

                             {/*{!this.props.isGuest &&*/}
                             {/*<button  onClick={() => GoSell.openLightBox()} className="red-btn w-100" type="submit">Proceed to Payment - AED <span id="totalPrice">{this.props.total}</span></button>}*/}

                             {/*{this.props.isGuest && <button  onClick={() => this.handleGuest()}*/}
                                                              {/*className="red-btn w-100"*/}
                                                              {/*type="submit">Proceed to Payment</button>}*/}


                        </div>
                   </div>
            </section>
            </Fragment>
        )
    }
}




function  ActivityTotal(props) {




           var total = 0
         var   cartTotal = 0
        var    totalDiscountPercent = 0




        var item = props.item;

        var cartTotal = 0;
        var cartTotalDiscountedPrice = 0;
        var priceOptions = props.priceOptions;

        var itemTotalDiscountPercent = 0

        for (var j = 0; j < priceOptions.length; j++) {

            // console.log(priceOptions[j])

            if (priceOptions[j].quantity > 0) {

                cartTotal = cartTotal + (priceOptions[j].price * priceOptions[j].quantity)

                if (priceOptions[j].discountedPrice) {

                    cartTotalDiscountedPrice = cartTotalDiscountedPrice + (priceOptions[j].discountedPrice * priceOptions[j].quantity)

                } else {

                    cartTotalDiscountedPrice = cartTotalDiscountedPrice + (priceOptions[j].price * priceOptions[j].quantity)

                }


            }
        }


           itemTotalDiscountPercent = (cartTotal - cartTotalDiscountedPrice)*100/cartTotal


                total = Math.round(cartTotalDiscountedPrice)
                cartTotal=Math.round(cartTotal)
                totalDiscountPercent= Math.round(itemTotalDiscountPercent)



        return(
            <>


            <div className="col-lg-9 col-md-9 col-7 ">
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="row product-title-booking">
                            <div className="col-lg-8">
                                <h2>{props.item.title}</h2>
                                {props.item.address && props.item.address.city && <p><img src={Map} alt="" /> {props.item.address.city.name} </p> }
                            </div>
                            <div className="col-lg-4">
                                <div className="price">AED {total}</div>


                                { (total != cartTotal) && <div className="or-price">
                                <span
                                    className="line-through">AED {cartTotal}</span> {totalDiscountPercent}%
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row for-web">
                            <div className="col-lg-12">
                                <div className="border-top pt-3 pb-5 product-dis-list">
                                    {props.item.dateInput && <div className="row mb-1">
                                        <div className="col-lg-4"><img src={Calander} alt="" /><span>Date</span></div>
                                        <div className="col-lg-6"><p>Dec 20, 2019</p></div>
                                    </div>}
                                    {props.item.duration&& <div className="row mb-1">
                                        <div className="col-lg-4"><img src={Duration} alt=""/><span>Duration</span>
                                        </div>
                                        <div className="col-lg-6"><p>1 Hours</p></div>
                                    </div>
                                    }



                                    {props.item.day &&props.item.day.length>0 && <div className="row mb-1">
                                        <div className="col-lg-4"><img src={Date} alt=""/><span>Available Days</span>
                                        </div>
                                        <div className="col-lg-6"><p> {props.item.day.map((item, index) =>
                                            <>
                                                {index > 0 && ", "}{item.name}
                                            </>
                                        )}</p></div>
                                    </div>
                                    }
                                    <div className="row">
                                        <div className="col-lg-4"><img src={Ticket} alt="" /><span>Tickets</span></div>
                                        <div className="col-lg-6">

                                            { props.item.priceOption.map((option)=>

                                                <p>{option.quantity} x AED {option.discountedPrice ? option.discountedPrice : option.price }</p>

                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
           <div className="col-lg-12 for-mobile">


                            <div className="row">
                                <div className="col-12">
                                    <div className=" product-dis-list">
                                        {props.item.dateInput && <div className="row mb-1">
                                            <div className="col-lg-12"><img src={Calander} alt="" /><span>Date: Dec 20, 2019</span></div>

                                        </div>}
                                        {props.item.duration&& <div className="row mb-1">
                                            <div className="col-12"><img src={Duration} alt=""/><span>Duration: 1 Hours</span>
                                            </div>

                                        </div>
                                        }



                                        {props.item.day &&props.item.day.length>0 && <div className="row mb-1">

                                            <div className="col-12"><img src={Date} alt=""/><span>Available Days: {props.item.day.map((item, index) =>
                                                <>
                                                    {index > 0 && ", "}{item.name}
                                                </>
                                            )}</span></div>
                                        </div>
                                        }
                                        <div className="row">

                                            <div className="col-12"><img src={Ticket} alt="" /><span>Tickets:

                                                { props.item.priceOption.map((option,index)=>
                                                    <>

                                                        {index > 0 && ", "}{option.quantity} x AED {option.discountedPrice ? option.discountedPrice : option.price }
                                                    </>

                                                )}
</span>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                </div>

                </>
        )

}


function GetBundleTotalAfterDiscount(props) {


    var total=0;

    for (let i = 0; i < props.item.bundleItems.length; i++) {

        for (let j = 0; j < props.item.bundleItems[i].activity.priceOption.length; j++) {

            if (props.item.bundleItems[i].activity.priceOption[j].quantity>0) {

                if (props.item.bundleItems[i].activity.priceOption.length > 0) {
                    if (props.item.bundleItems[i].activity.priceOption[j].discountedPrice && props.item.bundleItems[i].activity.priceOption[j].discountedPrice > 0) {

                        total = total + props.item.bundleItems[i].activity.priceOption[j].quantity*props.item.bundleItems[i].activity.priceOption[j].discountedPrice * (100 - props.item.bundleItems[i].discount) / 100

                    } else {

                        total = total + props.item.bundleItems[i].activity.priceOption[j].quantity*props.item.bundleItems[i].activity.priceOption[j].price * (100 - props.item.bundleItems[i].discount) / 100

                    }
                }
            }
        }
    }

    total = Math.round(total)


    return (

        <>
            {total}
        </>
    );

}
function GetTicketInfo(props) {


    var total="";
    var count = 0
    for (let i = 0; i < props.item.activity.priceOption.length; i++) {



        if (props.item.activity.priceOption[i].quantity>0){

            count++;

            if (count>1){
                total= total +", "
            }

            if (props.item.activity.priceOption[i].discountedPrice && props.item.activity.priceOption[i].discountedPrice > 0) {

                total = total + props.item.activity.priceOption[i].quantity+" x "+props.item.activity.priceOption[i].discountedPrice



            } else {

                total = total + props.item.activity.priceOption[i].quantity+" x "+props.item.activity.priceOption[i].price

            }
        }
    }


    return (

        <>
            {total}
        </>
    );

}
function GetBundleCartTotal(props) {


    var cartTotal=0;

    for (let i = 0; i < props.item.bundleItems.length; i++) {


        for (let j = 0; j < props.item.bundleItems[i].activity.priceOption.length; j++) {

            if (props.item.bundleItems[i].activity.priceOption[j].quantity>0){


            if (props.item.bundleItems[i].activity.priceOption[j].discountedPrice && props.item.bundleItems[i].activity.priceOption[j].discountedPrice > 0) {

                cartTotal = cartTotal + props.item.bundleItems[i].activity.priceOption[j].quantity * props.item.bundleItems[i].activity.priceOption[j].discountedPrice

            } else {

                cartTotal = cartTotal + props.item.bundleItems[i].activity.priceOption[j].quantity * props.item.bundleItems[i].activity.priceOption[j].price

            }

        }

        }
    }


    return (

        <>
            {cartTotal}
        </>
    );

}

function GetBundleDiscount(props) {


    var total=0;

    for (let i = 0; i < props.item.bundleItems.length; i++) {

        if (props.item.bundleItems[i].activity.priceOption.length > 0){
            if (props.item.bundleItems[i].activity.priceOption[0].discountedPrice && props.item.bundleItems[i].activity.priceOption[0].discountedPrice > 0) {

                total = total + props.item.bundleItems[i].activity.priceOption[0].discountedPrice*(100 - props.item.bundleItems[i].discount)/100

            } else {

                total = total + props.item.bundleItems[i].activity.priceOption[0].price*(100 - props.item.bundleItems[i].discount)/100

            }
        }
    }

    total = Math.round(total)

    var cartTotal=0;

    for (let i = 0; i < props.item.bundleItems.length; i++) {


        // cartTotal= cartTotal + props.item.bundleItems[i].activity.priceOption[0].price

        if (props.item.bundleItems[i].activity.priceOption[0].discountedPrice && props.item.bundleItems[i].activity.priceOption[0].discountedPrice > 0) {

            cartTotal = cartTotal + props.item.bundleItems[i].activity.priceOption[0].discountedPrice

        } else {

            cartTotal = cartTotal + props.item.bundleItems[i].activity.priceOption[0].price

        }

    }




    var discount = Math.round(100*(cartTotal - total)/cartTotal)

    return (

        <>
            {discount}
        </>
    );



}

function GetItemTotal(props) {


    var total=0;

    for (let i = 0; i < props.item.activity.priceOption.length; i++) {



        if (props.item.activity.priceOption[i].quantity>0){

            if (props.item.activity.priceOption[i].discountedPrice && props.item.activity.priceOption[i].discountedPrice > 0) {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].discountedPrice*(100-props.item.discount)/100



            } else {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].price*(100-props.item.discount)/100

            }
        }
    }



    total = Math.round(total)


    return (

        <>
            {total}
        </>
    );

}
function GetItemCartTotal(props) {


    var total=0;

    for (let i = 0; i < props.item.activity.priceOption.length; i++) {



        if (props.item.activity.priceOption[i].quantity>0){

            if (props.item.activity.priceOption[i].discountedPrice && props.item.activity.priceOption[i].discountedPrice > 0) {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].discountedPrice



            } else {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].price

            }
        }
    }



    total = Math.round(total)


    return (

        <>
            {total}
        </>
    );

}




function GetBundleItems(props) {



    const [bundle, setBundle] = useState(null);

    var priceOptions;

    axios.get(baseUrl+"bundles.json?groups[]=activities&slug="+props.item.bundle.slug)
        .then(res => {

            const persons = res.data;
            // console.log(res.data)

            // this.setState({
            //
            //     bundle : persons[0],
            //     priceOptions : persons[0].priceOption
            //
            // });

            // console.log("loaded bundle items")
            // console.log(res.data)
            setBundle (persons[0])

            priceOptions = persons[0].priceOption



            // this.setPriceOptions()
            //
            // this.loadInitialSelection()


        })



    return (

        <>
            {bundle&&bundle.bundleItems&&bundle.bundleItems.map((bundleItem,index) =>
                <>

                    <div  className={(index+1)==bundle.bundleItems.length?"row bundle-items bottom-border-none":"row bundle-items"}>

                        <div className="col-lg-3 col-3 col-md-3  ">
                            <div className="product-img mb-3"><a
                                href={"activity/"+bundleItem.activity.slug}>
                                <img
                                    src={baseImgUrl + bundleItem.activity.images[0].image}
                                    className="img-fluid" alt="Product detail" /></a></div>

                        </div>
                        <div className="col-9 col-lg-9  col-md-9 ">
                            <div className="row product-title-booking">
                                <div className="col-lg-8">
                                    <h6>{bundleItem.activity.title}</h6>

                                    <div className="row for-web">
                                        <div className="col-lg-12">
                                            <div className="product-dis-list">
                                                <div className="row mb-1">
                                                    <div className="col-lg-12">
                                                        <img
                                                            src={Calander}
                                                            alt="" /> Available Days: {bundleItem.activity.day.map((item, index) => <>{index > 0 && ","}{item.name} </>)}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12"><img
                                                        src={Ticket}
                                                        alt="" /> Tickets:  <GetTicketInfo item={bundleItem} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 ">
                                    <div className="price"><h6>AED <GetItemTotal  item={bundleItem} /></h6></div>
                                    <div className="or-price"><span className="line-through">AED <GetItemCartTotal  item={bundleItem} /></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 for-mobile">
                            <div className="row">
                                <div className="col-lg-12 ">

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="product-dis-list">
                                                <div className="row mb-1">
                                                    <div className="col-lg-12">
                                                        <img
                                                            src={Calander}
                                                            alt="" /> Available Days:  Thursday, Friday,
                                                        Saturday
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12"><img
                                                        src={Ticket}
                                                        alt="" /> Tickets: 2 x AED 170</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </>

            )}

        </>

    );

}



const mapStateToProps = state => {
    return {

        cartItems: state.cartItems,

        // cartBundleItems: state.cartBundleItems,
        loading: state.loading,
        userDetail: state.userDetail,
        salesQuote: state.salesQuote,
        cartTotal: state.cartTotal,
        couponDiscountVisible : state.couponDiscountVisible,
        total: state.total,
        subTotal: state.subTotal,
        cartDiscount: state.cartDiscount,
        couponDiscount: state.couponDiscount,
        couponCode: state.couponCode,
        couponDiscountPercentage: state.couponDiscountPercentage,
        couponCheckloading: state.couponCheckloading,
        couponError : state.couponError,
        addressInput : state.addressInput,
        isGuest : state.isGuest,
        isLoggedIn : state.isLoggedIn,
        bundleItem :  state.bundleItem,
        bundleItems :  state.bundleItems,
        isCartItemsLoadingDone :  state.isCartItemsLoadingDone,
        addressExist :  state.addressExist,
        stayAtHomeAddress :  state.stayAtHomeAddress


    };
};



const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item)),
        addCoupon : (item)=> dispatch(actionCreator.addCoupon(item)),
        setBookingResponse  : (item)=> dispatch(actionCreator.setBookingResponse(item)),
        loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
        removeCoupon : (item) => dispatch(actionCreator.removeCoupon(item)),
        checkGuestCartItems : (item) => dispatch(actionCreator.checkGuestCartItems(item)),
        editCartPopUp : (item) => dispatch(actionCreator.editCartPopUp(item)),
        setEditCartPopUp : (item) => dispatch(actionCreator.setEditCartPopUp(item)),
        editBundleCartPopUp : (item) => dispatch(actionCreator.editBundleCartPopUp(item)),
        setEditBundleCartPopUp : (item) => dispatch(actionCreator.setEditBundleCartPopUp(item)),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(Checkout);
