import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import axios from "axios/index";
import {baseUrl} from "../Util/Constants";
import {connect} from "react-redux";
import {loadSalesQuote, loginFailed} from "../store/actions/actions";
import {saveUserData} from "../LocalStorage/user";
import { Spinner,Button, Col, Row, Carousel,FormFile, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import {Switch, Link} from 'react-router-dom'

import  {CloudUpload} from '@material-ui/icons';


class  PersonalInformation extends Component{





    constructor(props) {
        super(props);
        this.state = {


            validatedForm: false,
            isChecked: false,
            file: null,
            loading: false,
            userExists : false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            cities : [],
            areas:[],
            formHasError: false,
            host : this.props.host,

            firstName:null,
            lastName:null,
            email:null,
            designation:null,
            landline:null,
            phoneNumber:null,

            firstName2:null,
            lastName2:null,
            landline2:null,
            mobile2:null,
            designation2:null



        }
        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);



    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;



        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,
                formHasError : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true,
                formHasError : false
            })

            const data = new FormData(event.target);


            console.log("host form data")
            console.log(data)


            this.setState({

                loading: true
            })
                this.setState({

                    confirmPasswordError: false
                })

                this.setState({

                    passwordError: false
                })

                const firstName = data.get("firstName")
                const lastName = data.get("lastName")
                const email = data.get("email")
                const phoneNumber = data.get("phoneNumber")


                let hostData = {

                    "host": this.state.host.id,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "phoneNumber": phoneNumber,
                    "landline": data.get("landline"),
                    "designation": data.get("designation"),

                    "firstName2": data.get("firstName2"),
                    "lastName2": data.get("lastName2"),
                    "mobile2": data.get("mobile2"),
                    "landline2": data.get("landline2"),
                    "designation2": data.get("designation2"),

                };



            var urlHost = baseUrl + "edit_hosts";

            var urlCustomer = baseUrl + "edit_accounts";

            console.log(urlCustomer)
                axios.post(urlCustomer,
                    { firstName: firstName,lastName: lastName,  email: email,
                        phoneNumber : phoneNumber, customer : this.props.userDetail.id
                    })
                    .then(res => {

                        console.log(res.data)

                        this.setState({

                            loading : false
                        })


                    }).catch(error => {
                    console.log("customer")
                        console.log(error)


                    this.setState({

                        loading : false
                    })

                });


            axios.post(urlHost, hostData )
                .then(res => {

                    this.setState({

                        loading : false
                    })


                }).catch(error => {
                console.log("host error")
                console.log(hostData)
                console.log(error)


                this.setState({

                    loading : false
                })

            });




        }
    }


    componentDidMount(){




        this.setHostInfo(this.state.host)



    }

    getHostInformation(){


        var    url = baseUrl+"hosts.json?groups[]=customer&email="+this.props.userDetail.email;

        console.log(url)


        axios.get(url)
            .then(res => {


                console.log("host data exits")
                console.log(res.data[0])

                // saveUserData(res.data[0])

                // this.setHostInfo(res.data[0])

                this.setState({

                    host: res.data[0]
                })




            }).catch(error => {


            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }




    setHostInfo(host){




        this.setState({

            firstName: host.firstName,
            lastName: host.lastName,
            email: host.email,
            designation: host.designation,
            landline: host.landline,
            phoneNumber: host.phoneNumber,

            firstName2: host.firstName2,
            lastName2:  host.lastName2,
            landline2:  host.landline2,
            mobile2:  host.mobile2,
            designation2: host.designation2
            

        })

    }


    onChange(e) {

        var files = e.target.files;

        console.log(files);


        var filesArr = Array.prototype.slice.call(files);

        console.log(filesArr);

        this.setState({ file: files[0]});
    }





    render (){
        return(
            <div className="tab-content" id="pills-tabContent">

            <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                 aria-labelledby="pills-personal-tab">


                {this.state.host &&
                <Form noValidate validated={this.state.validatedForm} onSubmit={this.handleSubmit}>
                    <div className="form-block">
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <h4>First Contact Person Details</h4>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    defaultValue=""
                                    name={"firstName"}
                                    value={this.state.firstName}
                                    onChange={this.handleInputChange}

                                />

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    defaultValue=""
                                    name={"lastName"}
                                    value={this.state.lastName}
                                    onChange={this.handleInputChange}
                                />

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            {/*<Form.Group as={Col} md="6" controlId="validationCustom01">*/}
                            {/*<Form.Label>Email</Form.Label>*/}
                            {/*<Form.Control*/}
                            {/*required*/}
                            {/*type="email"*/}
                            {/*placeholder=""*/}
                            {/*defaultValue=""*/}
                            {/*/>*/}
                            {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                            {/*</Form.Group>*/}

                            <Form.Group as={Col} md="6" controlId="exampleForm.ControlInput1">

                                <Form.Label>Email </Form.Label>


                                <Form.Control
                                    value={this.state.email}
                                    onChange={this.handleInputChange}

                                    required name={"email"}
                                    type="email" placeholder=""/>


                                <Form.Control.Feedback type="invalid">Email Id must be vaild</Form.Control.Feedback>


                            </Form.Group>


                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder=""
                                    defaultValue=""
                                    name={"designation"}
                                    value={this.state.designation}
                                    onChange={this.handleInputChange}
                                />

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Landline</Form.Label>
                                <InputGroup className="phone_no">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        maxLength={9} minLength={9} name={"landline"} type="tel"

                                        placeholder=""

                                        value={this.state.landline}
                                        onChange={this.handleInputChange}
                                    />
                                </InputGroup>

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Phone number</Form.Label>
                                <InputGroup className="phone_no">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        maxLength={9} minLength={9}
                                        name={"phoneNumber"} type="tel"
                                        required
                                        value={this.state.phoneNumber}
                                        onChange={this.handleInputChange}
                                        placeholder=""
                                        defaultValue=""
                                    />
                                </InputGroup>
                                <small class="opecity-text small-text">Please do not include prefix +971</small>

                            </Form.Group>
                        </Form.Row>

                    </div>
                    <div className="form-block mt-4">
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <h4>Second Contact Person:</h4>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    name={"firstName2"}

                                    value={this.state.firstName2}
                                    onChange={this.handleInputChange}

                                    type="text"
                                    placeholder=""
                                    defaultValue=""
                                />

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    name={"lastName2"}

                                    type="text"
                                    placeholder=""
                                    defaultValue=""
                                    value={this.state.lastName2}
                                    onChange={this.handleInputChange}
                                />

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>

                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control

                                    name={"designation2"}
                                    type="text"
                                    placeholder=""
                                    defaultValue=""
                                    value={this.state.designation2}
                                    onChange={this.handleInputChange}
                                />

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Contact Number(Mobile)</Form.Label>
                                <InputGroup className="phone_no">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        name={"mobile2"}

                                        value={this.state.mobile2}
                                        onChange={this.handleInputChange}
                                        type="tel"
                                        placeholder=""
                                        defaultValue=""
                                    />
                                </InputGroup>
                                <small class="opecity-text small-text">Please do not include prefix +971</small>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Contact Number(landline)</Form.Label>
                                <InputGroup className="phone_no">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">+971</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control

                                        name={"landline2"}
                                        type="tel"
                                        placeholder=""
                                        defaultValue=""
                                        value={this.state.landline2}
                                        onChange={this.handleInputChange}
                                    />
                                </InputGroup>

                            </Form.Group>

                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="12" className="text-center mt-4">
                                <Button className="btn btn-red" type="submit">

                                    { this.state.loading && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> }

                                    { this.state.loading ? "Wait.." : "Save"}
                                </Button>
                                {this.state.formHasError && <Alert className={"mt-2"} variant={"danger"}>
                                    Information provided is not valid or missing!
                                </Alert>
                                }
                            </Form.Group>
                        </Form.Row>
                    </div>

                </Form>

                }


            </div>
            </div>

        )
    }
}



const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(PersonalInformation);


// export default PersonalInformation;