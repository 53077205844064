import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import axios from "axios/index";

import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import {baseUrl,baseImgUrl} from  '../Util/Constants'


class  CustomerFavorite extends Component{


    constructor(props){

        super(props)

        this.state = {
            activities : [],
        };
    }

    componentDidMount (){

        var url =baseUrl+"customers/"+this.props.userDetail.id+".json?groups[]=favorites";

        axios.get(url)
            .then(res => {

                this.setState({

                    activities : res.data.favoriteActivities,

                });
                console.log(res.data)

            })

    }

    render (){
        return(

            <div className="tab-pane fade show active" id="pills-myfavorite" role="tabpanel"
                 aria-labelledby="pills-myfavorite-tab">


                {this.props.favorites.length>0 && this.props.favorites.map((item) =>

                    <FavoriteItem item={item} />

                )}


            </div>
        )
    }
}


function FavoriteItem(props) {


return (
    <div className="row mt-4">
        <div className="col-md-12 col-lg-4 title-goes">
            <img className="border-raidus" src={ baseImgUrl +props.item.images[0].image} alt="" width="100%"/>
        </div>
        <div className="col-md-12 col-lg-8">
            <div className="title_here">
                <h5 className="title_pirce">{props.item.title}</h5>
                <div className="rating rating-admin">
                    <img src={Star} alt=""/>
                    <img src={Star} alt=""/>
                    <img src={Star} alt=""/>
                    <img src={Star} alt=""/>
                    <img src={Star} alt=""/>
                    <p>4.0 (98 ratings)</p>
                </div>
                <p className="title_domi"> </p>
            </div>
            <div className="bottom-block">
                <p className="title_price2">

                    AED {props.item.priceOption[0]&&props.item.priceOption[0].price}

                </p>
                <Link   to={"/activity/"+props.item.slug} className="button_edit">View
                    Details</Link>
            </div>
        </div>
    </div>
)

}


const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        favorites: state.favorites


    };
};

const mapDispachToProps = dispatch => {
    return {

        addFavoriteItem: (data) => dispatch(actionCreator.addFavoriteItem(data)),
        removeFavoriteItem: (data) => dispatch(actionCreator.removeFavoriteItem(data))
    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CustomerFavorite);