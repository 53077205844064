import React, {Component, Fragment} from 'react';

import {Link} from 'react-router-dom'
import FavoriteButton from '../Util/FavoriteButton'
import {Star} from '@material-ui/icons'
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import Rating from '@material-ui/lab/Rating';
import twi from '../img/twi.png';
import fb from '../img/fb.png';
import {baseImgUrl} from '../Util/Constants'
import LinkDin from '../img/linkedin-2.svg';


const geolib = require('geolib');


class  TrendingSectionItem extends Component{

    item;
    constructor(props){

        super(props)
        this.item = props
        this.state = {
        };

        this.addFavorite= this.addFavorite.bind(this)

        this.removeFavorite= this.removeFavorite.bind(this)
        this.getDistance= this.getDistance.bind(this)

        this.whatsAppShare= this.whatsAppShare.bind(this)
        this.facebookShare= this.facebookShare.bind(this)
        this.twitterAppShare= this.twitterAppShare.bind(this)
        this.linkedInShare = this.linkedInShare.bind(this)

        // console.log(this.props)
    }



    linkedInShare(e){

        e.preventDefault()

        window.open("https://www.linkedin.com/shareArticle?mini=true&url=www.happy-weekends.com/activity/"+this.props.item.slug+"&title="+this.props.item.title,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    facebookShare(e){

        e.preventDefault()
//         Link.open(`whatsapp://send?text=${text}`);
// Link.open("/")

        // window.location.href = "https://www.facebook.com/sharer.php?u=www.happy-weekends.com/activity/"+this.item.slug



        // window.location.href = "https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.item.slug+"&redirect_uri="+window.location.href


        window.open("https://www.facebook.com/sharer/sharer.php?display=popup&u=https://www.happy-weekends.com/activity/"+this.item.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    twitterAppShare(e){


        e.preventDefault()

        // window.location.href = ("whatsapp://send?text=https://www.happy-weekends.com/activity/"+this.item.slug)



        window.open("https://twitter.com/intent/tweet?url=https://www.happy-weekends.com/activity/"+this.props.item.slug+"&text="+this.props.item.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    whatsAppShare(e){

        e.preventDefault()

        // window.location.href = ("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.item.slug)


        window.open("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.props.item.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


    }

    getDistance(){

        var distance =null;
        if (this.props.item.address&&this.props.item.address.latitude &&this.props.item.address.latitude) {


            if (this.props.location.latitude&this.props.location.longitude) {

                distance = geolib.getDistance(
                    {latitude: this.props.item.address.latitude, longitude: this.props.item.address.longitude},
                    this.props.location
                );

            }else {
                distance = null

            }


        }
        else {

            distance = null
        }


        return Math.round((distance/100))/10;

    }


    removeFavorite(){


    }
    addFavorite(e){


        // e.currentTarget.dataset.id)
        e.preventDefault()
        alert("hello "+e.currentTarget.dataset.id)


    }


    render (){

        return(

            <Fragment>
                <div className="col-lg-4">
                    <Link className="trend-item" to={"/activity/"+this.props.item.slug}>

                        <FavoriteButton item={this.props.item} />
                        <div className="item-share" id="favorite-share-923">
                                    <button ><i><svg style={{height: "28px" ,width: "28px", display: "block",
                        overflow: "visible"}}
                 viewBox="0 0 512 512" fill="#ffffff" fillOpacity="1" stroke="#ffffff"
                 strokeWidth="1" focusable="false" aria-label="Add listing to a list"
                 role="img" strokeLinecap="round" strokeLinejoin="round">

                <path d="M376.677,171.954h-71.967c-9.35,0-17,7.65-17,17s7.65,17,17,17h54.683v201.167H81.727V205.954h60.35c9.35,0,17-7.65,17-17
                s-7.65-17-17-17H64.444c-9.35,0-16.717,8.5-16.717,17.85v235.733c0,9.35,7.367,15.583,16.717,15.583h312.233
                c9.35,0,16.717-6.233,16.717-15.583V189.804C393.394,180.454,386.027,171.954,376.677,171.954z"/>
                <path d="M217.727,299.454c9.35,0,17-7.65,17-17V52.104l72.533,64.6c3.117,2.833,7.367,4.25,11.333,4.25
                c4.817,0,9.35-1.983,12.75-5.667c6.233-7.083,5.667-17.85-1.417-24.083l-97.75-86.983c-6.233-5.383-15.3-5.667-21.817-0.567
                c-3.117,1.417-5.667,3.967-7.367,6.8l-84.717,81.317c-6.8,6.517-7.083,17.283-0.567,24.083c6.517,6.8,17.283,7.083,24.083,0.567
                l58.933-56.667v222.7C200.727,291.804,208.377,299.454,217.727,299.454z"/>

            </svg></i>

                                        <div style={{position: "absolute"}} className="share-hover">
                                            <ul>

                                                <li><button onClick={this.facebookShare.bind(this)}>
                                                    <img src={fb} alt="iconshare" /></button>
                                                </li>
                                                <li>
                                                    <button  onClick={this.linkedInShare.bind()}>
                                                        <img src={LinkDin} alt="iconshare" />
                                                    </button>
                                                </li>
                                                <li><button onClick={this.twitterAppShare.bind(this)}>
                                                    <img src={twi} alt="iconshare" /></button>
                                                </li>

                                            </ul>
                                        </div>

                                    </button>

                            </div>
                        <div className="image w-100">
                            <img className="img-fluid" src={this.props.item.images[0]&&baseImgUrl+this.props.item.images[0].image} alt=""/>
                        </div>
                        <div className="wl-info-box">
                            <ul>
                                {this.props.item.isOnline&&<li>
                                    <span className="item-online">
                                        ONLINE
                                    </span>
                                </li>
                                }
                                <li>
                                    <p className="item-heading">
                                        {this.props.item.title}
                                        </p>
                                </li>
                                <li>
                                    <fieldset className="rating">
                                    <Rating className="detail-head-r"
                                                readOnly
                                                value={this.props.item.rating}
                                                icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                                emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                                name="size-large"  size="large"
                                    />
                                    </fieldset>
                                </li>
                                <li>
                                    <p className="item-subtext">
                                        <i className="fa fa-location-arrow"></i>
                                        {this.props.item.address && this.props.item.address.city &&" "+ this.props.item.address.city.name}

                                        {this.getDistance()?" "+this.getDistance()+" km":"" }



                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="wl-price-box">
                            <ul>
                                {this.props.item.priceOption && this.props.item.priceOption.length>0 && this.props.item.priceOption[0].discountedPrice &&

                                    <Fragment>

                                <li><p className="price-main"><span><strike>AED {this.props.item.priceOption[0].price}</strike></span></p></li>
                                 <li><p className="price-discount"><span>AED {this.props.item.priceOption[0].discountedPrice }</span></p></li>

                                    </Fragment>
                                }
                                {this.props.item.priceOption && (this.props.item.priceOption.length>0) && (!this.props.item.priceOption[0].discountedPrice) &&

                                <Fragment>
                                    <li><p className="price-discount"><span>AED {this.props.item.priceOption[0].price }</span></p></li>
                                </Fragment>
                                }



                            </ul>
                        </div>
                        <span className="lastleft">Last 3 Left</span>
                    </Link>
                </div>

            </Fragment>
        )
    }
}







const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail,
        location: state.location

    };
};


const mapDispachToProps = dispatch => {
    return {

        loadFavorites: (user) => dispatch(actionCreator.loadFavorites(user)),
        setLocation: (data) => dispatch(actionCreator.setLocation(data)),
        loadSalesQuote: (user) => dispatch(actionCreator.loadSalesQuote(user)),
        setRememberUserDetail: (user) => dispatch(actionCreator.setRememberUserDetail(user)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(TrendingSectionItem);



