import React, {Component, Fragment} from 'react';
import axios from "axios/index";
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DashboardHeader from "./DashboardHeader";
import '../admin.css';
import ChangePassword from "./ChangePassword";
import PersonalInformation from "./PersonalInformation";
import CustomerBookings from "./CustomerBookings";
import CustomerFavorite from "./CustomerFavorite";
import CustomerReviews from "./CustomerReviews";
import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import SearchGray from '@material-ui/icons/Search';

import {baseUrl,baseImgUrl} from  '../Util/Constants'



class  CustomerDashbaord extends Component{


    slug

    constructor(props){

        super(props)

        this.state = {
            homeSections : [],
            modal: false,
        };


        this.slug = props.match.params.id


        if (this.slug=="profile"){

            this.page = 0

        }


        if (this.slug=="favorite"){

            this.page = 1

        }


        if (this.slug=="reviews"){

            this.page = 3

        }

        if (this.slug=="bookings"){

            this.page = 2

        }



        // this.handleTabChange=this.handleTabChange.bind(this)
    }



    componentWillReceiveProps(newProps) {

        if (newProps.match.params.id !== this.props.match.params.id) {

            console.log("Dashboard Customer")

            console.log(newProps)



            this.slug = newProps.match.params.id


            if (this.slug=="profile"){

                this.page = 0

            }


            if (this.slug=="bookings"){

                this.page = 2

            }



            if (this.slug=="favorite"){

                this.page = 1

            }


            if (this.slug=="reviews"){

                this.page = 3

            }



        }


    }

    componentDidMount (){



    }




    //  handleTabChange = (newValue) => {
    //
    //     this.setState({
    //
    //         page : newValue
    //     })
    //
    //
    // };





    render() {




        return (

            <div>

           <DashboardHeader/>



                <MainTabs    page={this.page} />



            </div>

        )
    }
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

  function MainTabs(props) {



    let  selectedValue = props.page

    const classes = useStyles();
    const [value, setValue] = React.useState(props.page);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        selectedValue = newValue


        if (newValue=="0")
            history.push("profile")
        if (newValue=="1")
            history.push("favorite")
        if (newValue=="2")
            history.push("bookings")
        if (newValue=="3")
            history.push("reviews")

    };

    return (

        <div className={classes.root}>
            <AppBar position="static">
                <Tabs

                    className="custom-color"
                       centered value={selectedValue}
                       onChange={handleChange.bind(this)}
                       aria-label="simple tabs example" >

                    <Tab label="Account Settings" {...a11yProps(0)} />
                    <Tab label="My Favorite" {...a11yProps(1)} />
                    <Tab label="Booked Activities" {...a11yProps(2)} />
                    <Tab label="Reviews/Ratings" {...a11yProps(3)} />

                </Tabs>
            </AppBar>

            <TabPanel value={selectedValue} index={0}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                                    <AccountTabs />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </TabPanel>

            <TabPanel value={selectedValue} index={1}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                <FavouriteTabs />
                        </div>
                            </div>
                        </div>
                    </div>
                </section>

            </TabPanel>

            <TabPanel value={selectedValue} index={2}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                <BookedActivities />
                        </div>
                            </div>
                        </div>
                    </div>
                </section>

            </TabPanel>
            <TabPanel value={selectedValue} index={3}>
                <section className="tab-detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tile tile-bg">
                <ReviewsTabs />
                        </div>
                            </div>
                        </div>
                    </div>
                </section>
            </TabPanel>

        </div>


    );
}

function AccountTabs() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>
                <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                    <Tab label="Personal Information" {...a11yProps(0)} />
                    <Tab label="Change Password" {...a11yProps(1)} />

                </Tabs>

            <TabPanel value={value} index={0}>

                <PersonalInformation/>

            </TabPanel>

            <TabPanel value={value} index={1}>

                <ChangePassword/>

            </TabPanel>

        </div>


    );
}


function FavouriteTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                <Tab label="My Favorite" {...a11yProps(0)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <CustomerFavorite/>
            </TabPanel>


        </div>


    );
}
function BookedActivities() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.root}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

                <Tab label="Booked Activities" {...a11yProps(0)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <CustomerBookings/>
            </TabPanel>



        </div>


    );
}


function ReviewsTabs() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>

            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">

           <Tab label="Reviews/Ratings" {...a11yProps(0)} />

            </Tabs>

            <TabPanel value={value} index={0}>
                <CustomerReviews/>
            </TabPanel>

        </div>


    );
}

const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CustomerDashbaord);
