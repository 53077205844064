import React, { Component } from 'react';
import './AdminPartner.css';
import AdminPartnerHeader from './AdminPartnerHeader';
import AdminPartnerTab from './AdminPartnerTab';
import Header from '../Header/index';
import Footer from '../Footer/index';
//import BollyoodImage1 from '../img/bollywood-1.png';

class  AdminPartner extends Component{ 
    render (){
        return(
            <section>
                <Header/>
                <AdminPartnerHeader/>
                <AdminPartnerTab/>
                <Footer/>
            </section>      
        )
    }
}

export default AdminPartner;