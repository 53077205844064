import React, {Component, Fragment} from 'react';
import * as actionCreator from "../store/actions/actions";
import {connect} from "react-redux";
import { Spinner,Button, Col, Row, Carousel, CarouselItem,ButtonToolbar,
    InputGroup, ModalFooter,Modal,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from "react-bootstrap"

import Grow from '@material-ui/core/Grow';

import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Zoom from '@material-ui/core/Zoom';
import history from "../History/history";
import axios from "axios/index";
import AddIconColor from '../img/plus-color.png';
import MinIconColor from '../img/min-color.png';
import {Link} from 'react-router-dom';
import {loginFailed} from "../store/actions/actions";
import {saveUserData} from "../LocalStorage/user";
import GoSellElementsDemo from "./GoSellElementsDemo";
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import Ticket from '../img/ticket.png';
import Calander from '../img/calander.png';


class  BundleDetailItem extends Component{


    constructor(props) {
        super(props)

        this.state = {
            bundle:null,
            cartItem: null,
            bundleItems: null
        }

        this.loadInitialSelection=this.loadInitialSelection.bind(this)


        }



    componentDidMount(){

        this.setState({

            cartItem: this.props.item
        })

        axios.get(baseUrl+"bundles.json?groups[]=activities&slug="+this.props.item.bundle.slug)
            .then(res => {

                const persons = res.data;
                console.log(res.data)

                // this.setState({
                //
                //     bundle : persons[0],
                //     priceOptions : persons[0].priceOption
                //
                // });

                console.log("loaded bundle items")
                console.log(res.data)
                // setBundle (persons[0])

                this.setState({

                    bundle : persons[0],

                })


                // this.setPriceOptions()
                //
                this.loadInitialSelection()


            })

    }



    loadInitialSelection(){



        console.log("cart item checkout")
        console.log(this.state.cartItem)

            let bundle = this.state.bundle

        var total = 0;

            var bundleItems = bundle.bundleItems

            for (var i = 0; i < bundleItems.length; i++) {

                bundleItems[i].total = bundleItems[i].total


                for (var j = 0; j < bundleItems[i].activity.priceOption.length; j++) {

                    for (var k = 0; k < this.state.cartItem.cartPriceOptions.length; k++) {

                        if (bundleItems[i].activity.priceOption[j].id == this.state.cartItem.cartPriceOptions[k].priceOptionId) {

                            // alert(this.state.cartItem.cartPriceOptions[k].quantity)


                            bundleItems[i].activity.priceOption[j].quantity = this.state.cartItem.cartPriceOptions[k].quantity

                            // alert(this.state.cartItem.cartPriceOptions[k].quantity)


                        }
                    }


                    if (bundleItems[i].activity.priceOption[j].quantity > 0) {

                        if (bundleItems[i].activity.priceOption[j].discountedPrice && bundleItems[i].activity.priceOption[j].discountedPrice > 0) {

                            total = total + bundleItems[i].activity.priceOption[j].quantity * bundleItems[i].activity.priceOption[j].discountedPrice


                            bundleItems[i].total= total

                        } else {

                            total = total + bundleItems[i].activity.priceOption[j].quantity * bundleItems[i].activity.priceOption[j].price

                            bundleItems[i].total= total
                        }
                    }

                }


                this.setState({

                    total: Math.floor(total*(100 - bundleItems[i].discount) / 100),
                    cartTotal: total

                });

            }


            bundle.bundleItems= bundleItems

            this.setState({

                bundleItems: bundleItems,
                bundle: bundle,
                total : this.props.item.total

            })






    }





    render (){
        return(
            <>
                {this.state.bundleItems&&this.state.bundleItems.map((bundleItem,index) =>
                    <>

                        <div  className={(index+1)==this.state.bundleItems.length?"row bundle-items bottom-border-none":"row bundle-items"}>

                            <div className="col-lg-3  col-md-3  col-5 ">
                                <div className="product-img mb-3"><a
                                    href={"activity/"+bundleItem.activity.slug}>
                                    <img
                                        src={baseImgUrl + bundleItem.activity.images[0].image}
                                        className="img-fluid" alt="Product detail" /></a></div>

                            </div>
                            <div className="col-7 col-lg-9  col-md-9 ">
                                <div className="row product-title-booking">
                                    <div className="col-lg-8">
                                        <h6>{bundleItem.activity.title}</h6>

                                        <div className="row for-web">
                                            <div className="col-lg-12">
                                                <div className="product-dis-list">
                                                    <div className="row mb-1">
                                                        <div className="col-lg-12">
                                                            <img
                                                                src={Calander}
                                                                alt="" /> Available Days: {bundleItem.activity.day.map((item, index) => <>{index > 0 && ","}{item.name} </>)}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12"><img
                                                            src={Ticket}
                                                            alt="" /> Tickets:  <GetTicketInfo item={bundleItem} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <div className="price"><h6>AED <GetItemCartTotal cartItem={this.state.cartItem} item={bundleItem} /></h6></div>
                                        {/*<div className="or-price"><span className="line-through">AED <GetItemCartTotal  item={bundleItem} /></span>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 for-mobile">
                                <div className="row">
                                    <div className="col-lg-12 ">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-dis-list">
                                                    <div className="row mb-1">
                                                        <div className="col-lg-12">
                                                            <img
                                                                src={Calander}
                                                                alt="" /> Available Days:  {bundleItem.activity.day.map((item, index) => <>{index > 0 && ","}{item.name} </>)}
                                                            Saturday
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12"><img
                                                            src={Ticket}
                                                            alt="" /> Tickets: <GetTicketInfo item={bundleItem} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </>

                )}

            </>
        )
    }
}

function GetItemTotal(props) {


    console.log(props.item)

    var total=0;

    for (let i = 0; i < props.item.activity.priceOption.length; i++) {



        for (let j=0 ;j< props.cartItem.cartPriceOptions.length;j++){

            if (props.item.activity.priceOption[i].id=props.cartItem.cartPriceOptions[j].priceOptionId){

                props.item.activity.priceOption[i].quantity = props.cartItem.cartPriceOptions[j].quantity

            }


        }



        if (props.item.activity.priceOption[i].quantity>0){

            if (props.item.activity.priceOption[i].discountedPrice && props.item.activity.priceOption[i].discountedPrice > 0) {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].discountedPrice*(100-props.item.discount)/100


            } else {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].price*(100-props.item.discount)/100

            }
        }
    }



    total = Math.floor(total)


    return (

        <>
            {total}
        </>
    );

}
function GetItemCartTotal(props) {


    var total=0;

    for (let i = 0; i < props.item.activity.priceOption.length; i++) {



        if (props.item.activity.priceOption[i].quantity>0){

            // if (props.item.activity.priceOption[i].discountedPrice && props.item.activity.priceOption[i].discountedPrice > 0) {
            //
            //     total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].discountedPrice
            //
            //
            //
            // } else {

                total = total + props.item.activity.priceOption[i].quantity*props.item.activity.priceOption[i].price

            // }
        }
    }



    total = Math.floor(total)


    return (

        <>
            {total}
        </>
    );

}


const mapStateToProps = state => {
    return {
        cartItems: state.cartItems,
        loading: state.loading,
        isLoggedIn: state.isLoggedIn,
        isGuest: state.isGuest,

        userDetail: state.userDetail,
        addressInput : state.addressInput

    };
};

function GetTicketInfo(props) {


    var total="";
    var count = 0
    for (let i = 0; i < props.item.activity.priceOption.length; i++) {


        if (props.item.activity.priceOption[i].quantity>0){

            count++;

            if (count>1){
                total= total +", "
            }

            // if (props.item.activity.priceOption[i].discountedPrice && props.item.activity.priceOption[i].discountedPrice > 0) {
            //
            //     total = total + props.item.activity.priceOption[i].quantity+" x "+props.item.activity.priceOption[i].discountedPrice
            //
            //
            //
            // } else {

                total = total + props.item.activity.priceOption[i].quantity+" x "+props.item.activity.priceOption[i].price

            // }
        }
    }


    return (

        <>
            {total}
        </>
    );

}

const mapDispachToProps = dispatch => {
    return {

        onAgeUp: () => dispatch(actionCreator.ageUp(1)),
        onAgeDown: () => dispatch(actionCreator.ageDown(1)),
        addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
        removeCartItem: (item) => dispatch(actionCreator.removeCartItem(item)),
        stayAtHomeAddress: (item) => dispatch(actionCreator.stayAtHomeAddress(item)),
        addressExist: (item) => dispatch(actionCreator.addressExist(item)),


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(BundleDetailItem);

