import React, { Component } from 'react';
import AboutMaking from '../img/about-page-making.jpg';


class  GiveBack extends Component{

    componentDidMount () {
        window.scrollTo(0, 0)

    }

    render (){
        return(
            <div className="bg-gray">
                <section className="giveback-banner">
                    <div className="container">
                        <div className="row">
                        <div className="col">
                        </div>
                        </div>
                    </div>
                </section>
                <section className="giveback-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="giveback-listing">
                                <ul>
                                    <li>
                                    <span>THE ISSUE </span>
                                    It’s no news that the world is divided between the haves and the have-nots. And no matter which part of the world we’re in, we’ll ﬁnd a large part of the population who are less fortunate 
                                    nobody to care for them, children who don’t have the same luxur ies our children have. And while most of us contribute whatever little we can in terms of money, we’re all acutely aware that money is just not enough. Actions speak louder. But here comes the other problem. Because while the intent to physically do so mething is there, not everyone knows how. Add to that, there’s the little thing about ﬁnding time – because let’s face it, the only available time are the weekends that we have. 
                                    THE 
                                    </li>
                                    <li>
                                    <span>THE SOLUTION </span>
                                    Say hello to Return Gift Weekend. A few hours over the weekend where you can spend time with someone who’s not as fortunate as yourself. Because we all know the greatest gift we can ever receive, is the gift of giving. And it’s a great value to teach to our children as well. 
                                    </li>
                                    <li>
                                    <span>HOW IT WORKS </span>
                                    We’re in constant touch with various NGO’s around the city, who tell us what their   need is.  It could range from providing them meals, books, clothes, or just spending   time. You let us know what interests you in the comments section below, and   depending upon the need, we’ll send you details of where you have to go.  
                                    It’s nice to celebrate our special occasions with the ones who aren’t as fortunate   as us. So if you’d like to do something really special on your birthday, anniversary,   work promotion, or just celebrating the beginning of a new relationship, let us know,   and we’ll make it happen for you
                                    </li>
                                    <li>
                                    <span>BE A PART OF IT </span>
                                    <ol>
                                        <li>You start by nominating yourself. To do that, you’ll just need to ﬁll out a simple form,  outlining what it is you’d like to do, and how you’d like to be involved. </li>
                                        <li>Once the nominations are in, we’ll make groups of people who are interested in the same  way of contributing as you are. </li>
                                        <li>We’ll send you an email seeking your conﬁrmation of the event. Typically, you’ll have  a few weeks to conﬁrm your presence.</li>
                                        <li>Once you conﬁrm, we’ll send you a brieﬁng package that’ll outline the event in detail,  and give you every other information that you’d need. </li>
                                        <li>Because we’ll be hosting this once every few weeks, make sure your forms reach  us  well on time.</li>
                                        <li>Your commitment would be take out just a couple of hours over the weekend to be a  part of the Return Gift Weekend.</li>
                                    </ol>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
        <div className="giveback-form">
          <div className="give-form">
            <h4>NOMINATION FORM</h4>
            <form className="for-web">
              <div className="form-row">
                <div className="form-group col-lg-7">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">name</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
                <div className="form-group col-lg-5">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Age</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-7">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Emailid</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
                <div className="form-group col-lg-5">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Gender</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-7">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Phone</label>
                    <div className="col-sm-9">
                      <div className="input-group phone_no">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+971</div>
                        </div>
                        <input type="text" className="field field_full_width" id="inlineFormInputGroup" placeholder />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row" style={{marginTop: '20px'}}>
                <div className="form-group col-lg-12">
                  <div className="row">
                    <label className="col-sm-7 col-form-label">HOW WOULD YOU LIKE TO CONTRIBUTE</label>
                    <label style={{textAlign: 'center'}} className="col-sm-3 col-form-label">SELECT</label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                    <label className="col-sm-7 col-form-label">TIME </label>
                    <div className="col-sm-3">
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                    <label className="col-sm-7 col-form-label">MONEY</label>
                    <div className="col-sm-3">
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                    <label className="col-sm-7 col-form-label">BOTH</label>
                    <div className="col-sm-3">
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                    <div style={{paddingLeft: '0px!important'}} className="col-sm-12">
                      <a className="invite-friend" href="javascripit">Invite a Friend</a>
                      <a className="invite-friend ml-2" href="javascripit">Submit</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form className="for-mobile">
              <div className="form-row">
                <div className="form-group col-lg-7">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">name</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
                <div className="form-group col-lg-5">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Age</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-7">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Emailid</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
                <div className="form-group col-lg-5">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Gender</label>
                    <div className="col-sm-9"><input type="email" className="form-control" id="inputEmail4" placeholder /></div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-7">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Phone</label>
                    <div className="col-sm-9">
                      <div className="input-group phone_no">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+971</div>
                        </div>
                        <input type="text" className="field field_full_width" id="inlineFormInputGroup" placeholder />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                    
                    <div className="col-lg-12">
                      <label className="col-form-label">TIME</label>
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                  <div className="col-lg-12">
                      <label className="col-form-label">MONEY</label>
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                  <div className="col-lg-12">
                      <label className="col-form-label">BOTH</label>
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="row">
                    <div style={{paddingLeft: '0px!important'}} className="col-sm-12">
                      <a className="invite-friend" href="javascripit">Invite a Friend</a>
                      <a className="invite-friend ml-2" href="javascripit">Submit</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
 
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default GiveBack;