   import React, { Component, Fragment, useState } from 'react';
    import './ActivityDetail.css';

    import CrossBlack from '../img/cross-black.png';
    import InfoTool from '../img/info-tooltip.png';

    import BottomTabsNew from './BottomTabsNew';
    import axios from "axios/index";
    import ImageGallery from 'react-image-gallery';
    import { Spinner,Button, Col, Row, Carousel, CarouselItem, Modal, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText, InputGroup} from 'react-bootstrap'
   import what from '../img/what.png';
   import twi from '../img/twi.png';
   import fb from '../img/fb.png';
   import * as actionCreator from "../store/actions/actions";
    import { connect } from "react-redux";
    import RightArrow from '../img/owl-arrow-right.png';
    import DetailIcon1 from '../img/detail-icon-1.png';
    import DetailIcon2 from '../img/detail-icon-2.png';
    import DetailIcon3 from '../img/detail-icon-3.png';
    import DetailIcon4 from '../img/detail-icon-4.png';
    import DetailIcon5 from '../img/detail-icon-5.png';
    import DetailIcon6 from '../img/detail-icon-6.png';
    import DetailIcon7 from '../img/detail-icon-7.png';
   import PhoneInput from 'react-phone-input-2'

   import calenderSVG from '../img/calendar.svg';
   import calenderCheckedSVG from '../img/calendar-checked.svg';

   import scedhuleSVG from '../img/schedule.svg';
   import timeSVG from '../img/time.svg';
   import durationSVG from '../img/duration.svg';
   import redeemSVG from '../img/redeem.svg';
   import PlusIcon from '../img/plus.png';
    import MinIcon from '../img/min.png';
    import FavoriteButton from '../Util/FavoriteButton';
    import  {Star} from '@material-ui/icons';
   import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom';
   import Scrollchor from 'react-scrollchor';
   import DateFnsUtils from '@date-io/date-fns';
   import Rating from '@material-ui/lab/Rating';
   // import MetaTags from 'react-meta-tags';
   import {Helmet} from "react-helmet";
   import MetaTags from 'react-meta-tags';


   import {
       MuiPickersUtilsProvider,
       DatePicker,
       KeyboardTicmePicker

   } from '@material-ui/pickers';
   import history from "../History/history";
   import LinkDinSquare from '../img/linkedin-2.svg';
   import {baseUrl,baseImgUrl,mainUrl} from  '../Util/Constants'

   class  ActivityDetail extends Component{

        slug;

        constructor(props){
            super(props)


            this.state = {

                activity: {},
                images: [],
                total: 0,
                priceOptions: [],
                showBuyNowError: false,
                isSoldOut: false,
                isSubmitInterest: false,
                dateSelected : new Date(),
                displayMobilePriceOptions:false,
                showSubmitRequestPopUp : false,
                showSubmitRequestSuccess : false,
                submitRequestLoading : false,
                fields: {},
                errors: {},
                phoneNumberInValid: false,


            }

            this.slug = props.match.params.id
            this.buyNow = this.buyNow.bind(this)

            this.handleDateChange = this.handleDateChange.bind(this)
            this.checkCartItems = this.checkCartItems.bind(this)

            this.disableRandomDates = this.disableRandomDates.bind(this)
            this.disableWeekends = this.disableWeekends.bind(this)
            this.disableWeekDays = this.disableWeekDays.bind(this)
            this.toggleMobilePriceOptions = this.toggleMobilePriceOptions.bind(this)
            this.updateViews = this.updateViews.bind(this)
            this.showReviewBox = this.showReviewBox.bind(this)
            this.toggleSubmitRequestPopUp=this.toggleSubmitRequestPopUp.bind(this)
            this.whatsAppShare= this.whatsAppShare.bind(this)
            this.facebookShare= this.facebookShare.bind(this)
            this.twitterAppShare= this.twitterAppShare.bind(this)
            this.linkedInShare = this.linkedInShare.bind(this)
            this.loadActivity = this.loadActivity.bind(this)
            this.loadInitialSection = this.loadInitialSection.bind(this)
            this.handleSubmitRequestPopUpNew=this.handleSubmitRequestPopUpNew.bind(this)


        }


       handleChange(field, e){

           console.log(field)
           console.log(e)

           let fields = this.state.fields;
           fields[field] = e;
           this.setState({fields});
       }

       onChange(field, e){

           console.log(field)
           console.log(e)

           let fields = this.state.fields;
           fields[field] = e.target.value;
           this.setState({fields});

       }

       handleValidation(){

           // alert("called")
           let fields = this.state.fields;
           let errors = {};
           let formIsValid = true;

           // //Name
           if(!fields["phoneNumber"]){
               // alert("empty ")
               formIsValid = false;
               // errors["phoneNumber"] = "Invalid phone number!";
               this.setState({
                   "phoneNumberInValid" : true
               })
           }


           if(fields["phoneNumber"]&&fields["phoneNumber"].length<12){

               formIsValid = false;
               errors["phoneNumber"] = "Invalid phone number!";

               this.setState({

                   "phoneNumberInValid" : true
               })

           }





           this.setState({errors: errors});
           return formIsValid;
       }

       componentWillReceiveProps(newProps){

           if (newProps.match.params.id !== this.props.match.params.id) {

               // console.log(newProps)
               //
               this.setState({


                   activity: {},
                   images: [],
                   total: 0,
                   priceOptions: [],
                   showBuyNowError: false,
                   isSoldOut: false,
                   isSubmitInterest: false,
                   dateSelected : new Date(),
                   displayMobilePriceOptions:false,
                   showSubmitRequestPopUp : false,
                   showSubmitRequestSuccess : false,
                   submitRequestLoading : false


               })
               //
               // this.page = 1


               this.slug= newProps.match.params.id

               this.loadActivity()



           }

       }




       linkedInShare(e){

           e.preventDefault()

           window.open("https://www.linkedin.com/shareArticle?mini=true&url=www.happy-weekends.com/activity/"+this.state.activity.slug+"&title="+this.state.activity.title,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


       }

       toggleSubmitRequestPopUp(){



            this.setState({

                showSubmitRequestPopUp : !this.state.showSubmitRequestPopUp
            })


       }



       showSubmitRequestSuccess(){

           this.setState({

               showSubmitRequestSuccess : !this.state.showSubmitRequestSuccess
           })


       }

        updateViews(){

            axios.post(baseUrl+"activity_views.json",
            {"activity": "/api/activities/"+this.state.activity.id})
                .then(res => {

                    const persons = res.data;
                    console.log(res.data)

                })

        }



       facebookShare(e){

           e.preventDefault()


//         Link.open(`whatsapp://send?text=${text}`);
// Link.open("/")

           // window.location.href = "https://www.facebook.com/sharer.php?u=www.happy-weekends.com/activity/"+this.item.slug



           // window.location.href = "https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.item.slug+"&redirect_uri="+window.location.href


           // window.open("https://www.facebook.com/dialog/share?app_id=400403577525251&display=popup&href=www.happy-weekends.com/activity/"+this.state.activity.slug+"&redirect_uri="+window.location.href,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')
           window.open("https://www.facebook.com/sharer/sharer.php?display=popup&u=https://www.happy-weekends.com/activity/"+this.state.activity.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


       }

       twitterAppShare(e){


           e.preventDefault()

           // window.location.href = ("whatsapp://send?text=https://www.happy-weekends.com/activity/"+this.item.slug)



           window.open("https://twitter.com/intent/tweet?url=https://www.happy-weekends.com/activity/"+this.state.activity.slug+"&text="+this.state.activity.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


       }

       whatsAppShare(e){

           e.preventDefault()

           // window.location.href = ("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.item.slug)


           window.open("https://api.whatsapp.com//send?text=Hey, This might interest you! https://www.happy-weekends.com/activity/"+this.state.activity.slug+"&text="+this.state.activity.slug,'popUpWindow','height=500,width=500,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes')


       }



       showReviewBox(){


           this.props.setReviewBoxOpen(true)


       }

    toggleMobilePriceOptions(){


            if (!this.state.displayMobilePriceOptions){

                document.body.classList.add('body-overflow');

            } else {

                document.body.classList.remove('body-overflow');


            }
        this.setState({
            displayMobilePriceOptions : !this.state.displayMobilePriceOptions
        })



    }




       handleSubmitRequestPopUp = event => {



           event.preventDefault();

           const form = event.currentTarget;

           console.log(form.checkValidity())

           if (form.checkValidity() === false) {

               event.stopPropagation();

               this.setState({

                   validatedForm : true
               });
           }


           if(form.checkValidity()) {


               this.setState({
                   btnLoading: true
               })

               const data = new FormData(event.target);

               console.log(data)


               this.setState({

                   passwordError : false,
                   submitRequestLoading : true
               })

               const firstName = data.get("firstName")
               const lastName = data.get("lastName")
               const email = data.get("email")
               const phoneNumber = data.get("phoneNumber")



               axios.post(baseUrl+"submit_interests" ,{ "activity" : this.state.activity.id, "firstName": firstName, "lastName": lastName,
                   "email": email, "phoneNumber": phoneNumber  }).then(res => {


                   console.log("confirmation success ")
                   console.log( res.data)


                   this.setState({

                       success : true,
                       showSubmitRequestSuccess: true,
                       submitRequestLoading : false
                   })





               }).catch(error => {

                   console.log("submit request error found "+error)


               });







           }



       }
       handleSubmitRequestPopUpNew = event => {



           event.preventDefault();

           const form = event.currentTarget;

           console.log(form.checkValidity())

           if (this.handleValidation()) {

               event.stopPropagation();

               this.setState({

                   validatedForm : true
               });
           }

           if (form.checkValidity() === false) {

               event.stopPropagation();

               this.setState({

                   validatedForm : true
               });
           }


           if(this.handleValidation()&&form.checkValidity()) {


               this.setState({
                   btnLoading: true
               })

               const data = new FormData(event.target);

               console.log(data)


               this.setState({

                   passwordError : false,
                   submitRequestLoading : true
               })

               const firstName = data.get("firstName")
               const lastName = data.get("lastName")
               const email = data.get("email")
               const phoneNumber = data.get("phoneNumber")



               axios.post(baseUrl+"submit_interests" ,{ "activity" : this.state.activity.id, "firstName": firstName, "lastName": lastName,
                   "email": email, "phoneNumber": phoneNumber  }).then(res => {


                   console.log("confirmation success ")
                   console.log( res.data)


                   this.setState({

                       success : true,
                       showSubmitRequestSuccess: true,
                       submitRequestLoading : false
                   })





               }).catch(error => {

                   console.log("submit request error found "+error)


               });







           }



       }

       togglefilter(){


        this.setState({
            displayFilterMenu : !this.state.displayFilterMenu
        })
    }

        checkCartItems(){

         let activity = this.state.activity


            let priceOptionNew = this.state.priceOptions.slice();


            for (var i=0;i<priceOptionNew.length;i++){

                priceOptionNew[i].quantity=0

            }


            for(var i=0; i < this.props.cartItems.length;i++ ){
                // alert("loop")

                if (this.props.cartItems[i].itemType==0&&this.props.cartItems[i].activity.id==activity.id){

                    // alert("machted")


                    for(var j=0; j < this.props.cartItems[i].cartPriceOptions.length;j++ ) {


                        for (var k=0;k<priceOptionNew.length;k++) {

                            if (priceOptionNew[k].id ===this.props.cartItems[i].cartPriceOptions[j].id ){

                                priceOptionNew[k].quantity = this.props.cartItems[i].cartPriceOptions[j].quantity


                                var price = 0
                                if(priceOptionNew[k].discountedPrice){

                                    price = priceOptionNew[k].discountedPrice*this.props.cartItems[i].cartPriceOptions[j].quantity
                                }
                                else {

                                    price = priceOptionNew[k].price*this.props.cartItems[i].cartPriceOptions[j].quantity

                                }

                                this.setState({ total: this.state.total + price });

                            }

                        }

                        }

                }else {
                    // alert("no matche")
                }


            }


            this.setState({ priceOptions: priceOptionNew });
}


     loadInitialSection(){


        let activity = this.state.activity


        let priceOptionNew = this.state.priceOptions.slice();


        for (var i=0;i<priceOptionNew.length;i++){

            priceOptionNew[i].quantity=0

        }
        
        console.log("salea Quote")

    for (var i = 0; i < this.props.cartItems.length; i++) {
        // alert("loop")

        if (this.props.cartItems[i].itemType == 0 && this.props.cartItems[i].activity.id == activity.id) {

            // alert("machted")


            for (var j = 0; j < this.props.cartItems[i].cartPriceOptions.length; j++) {


                for (var k = 0; k < priceOptionNew.length; k++) {

                    if (priceOptionNew[k].id === this.props.cartItems[i].cartPriceOptions[j].id) {

                        priceOptionNew[k].quantity = this.props.cartItems[i].cartPriceOptions[j].quantity


                        var price = 0
                        if (priceOptionNew[k].discountedPrice) {

                            price = priceOptionNew[k].discountedPrice * this.props.cartItems[i].cartPriceOptions[j].quantity
                        }
                        else {

                            price = priceOptionNew[k].price * this.props.cartItems[i].cartPriceOptions[j].quantity

                        }

                        this.setState({total: this.state.total + price});

                    }

                }

            }

        } else {
            // alert("no matche")
        }


    }


    this.setState({priceOptions: priceOptionNew});


}


        disableWeekDays(date) {

            var daysArray=[0,1,2,3,4,5,6]// 0- sunday, 6 saturday


            if (this.state.activity.day && this.state.activity.day.length>0 ){
                var days = this.state.activity.day

                for (var i=0;i<days.length;i++) {


                    if (days[i].name==="Thursday"){

                        daysArray=daysArray.filter((value) => value!=4)


                        // if (date.getDay()===4)
                        //     return false

                    }

                   else if (days[i].name==="Friday"){

                        daysArray=daysArray.filter((value) => value!=5)
                        // if (date.getDay()===5)
                        //     return false


                    }

                   else if (days[i].name==="Saturday"){

                        daysArray=daysArray.filter((value) => value!=6)


                        // if (date.getDay()===6)
                        //     return false

                    }



                   }


            }


            return (daysArray.indexOf(date.getDay())>-1)

            // return date.getDay() === 0||date.getDay() === 1 || date.getDay() === 2||date.getDay() === 3;


        }

        enableWeekends(date) {

            return date.getDay() === 0 || date.getDay() === 6;
        }

         disableWeekends(date) {
            return date.getDay() === 0 || date.getDay() === 6;
        }

         disableRandomDates() {
            return Math.random() > 0.7;
        }

     buyNow(){


            if (this.state.total>0){

                this.setState({ showBuyNowError: false });

                var activity =  this.state.activity
                activity.priceOption = this.state.priceOptions.filter((option)=> option.quantity > 0)
                var activityExists = false


                for(var i=0; i < this.props.cartItems.length;i++ ){


                    if (this.props.cartItems[i].itemType==0&&this.props.cartItems[i].activity.id===activity.id){

                        activityExists = true
                        this.props.cartItems[i].activity=activity
                        this.props.cartItems[i].cartPriceOptions=activity.priceOption

                    }

                }


                // if(!activityExists) {


                        this.props.addCartItem(

                            {
                                "user":this.props.userDetail,
                                "cartItem":{
                                 "itemType":0,
                                    "activity": this.state.activity,
                                    'cartPriceOptions': this.state.activity.priceOption

                                }
                            }

                            )


                    // }






                if ((this.props.isLoggedIn|| this.props.isGuest )&& this.props.userDetail.id) {


                    history.push("/checkout")


                }
                else{

                    // no login

                            console.log("detail page user/guest deosnt exists")

                            this.props.addCartItem(

                                {

                                    "cartItem":{
                                       "itemType":0,
                                        "activity": this.state.activity,
                                        'cartPriceOptions': this.state.activity.priceOption

                                    }
                                }
                            )

                            this.props.loginCheckoutPopUp(true)

                        }

            }else {

                this.setState({ showBuyNowError: true });


            }

        }



        loadActivity(){

            this.props.reviewSuccess(false)

            console.log("detail slug")
            console.log(this.slug)

            axios.get(baseUrl+"activities.json?groups[]=activities&slug="+this.slug)
                .then(res => {
                    const persons = res.data;
                    console.log(res.data)

                    this.setState({

                        activity : persons[0],
                        images : persons[0].images,
                        priceOptions : persons[0].priceOption

                    });

                    // this.checkCartItems()
                    
                    
                    this.loadInitialSection()


                    this.fetchDetail(res.data[0].id)

                    this.updateViews()


                })

        }

        componentDidMount (){


            window.scrollTo(0, 0)

          this.loadActivity(this.slug)

        }




        fetchDetail(id){

            console.log(id)


            axios.get(baseUrl+"activities/"+id+".json?groups[]=activity-detail")
                .then(res => {
                    const persons = res.data;
                    // console.log(res.data)
                    this.setState({

                        activityDetail: persons

                    });
                })
        }

        incrementQuantity(e){

            this.setState({ showBuyNowError: false });

            let priceOptionNew = this.state.priceOptions.slice();

            for (var i=0;i<priceOptionNew.length;i++) {


                if (priceOptionNew[i].id == e.currentTarget.dataset.id) {


                    priceOptionNew[i].quantity = priceOptionNew[i].quantity + 1

                    var price = 0
                    if(priceOptionNew[i].discountedPrice){

                        price = priceOptionNew[i].discountedPrice
                    }else {

                        price = priceOptionNew[i].price


                    }

                    this.setState({ total: this.state.total + price });


                }
            }




            this.setState({ priceOptions: priceOptionNew });



        }
       decrementQuantity(e){


           let priceOptionNew = this.state.priceOptions.slice();

           for (var i=0;i<priceOptionNew.length;i++) {


               if ((priceOptionNew[i].id == e.currentTarget.dataset.id)&&(priceOptionNew[i].quantity>0)) {


                   priceOptionNew[i].quantity = priceOptionNew[i].quantity - 1

                   var price = 0

                   if(priceOptionNew[i].discountedPrice){

                       price = priceOptionNew[i].discountedPrice

                   }else {

                       price = priceOptionNew[i].price


                   }

                   this.setState({ total: this.state.total - price });


               }else {

               }
           }

           this.setState({ priceOptions: priceOptionNew });

       }


         handleDateChange = date => {


            this.setState({

                dateSelected : date

            })

        };



        render (){


            return(
               <div>


                   {this.state.activity.title &&
                   <MetaTags>

                       <title>{this.state.activity.title}</title>
                       <meta name="title" content={this.state.activity.metaTitle?this.state.activity.metaTitle:this.state.activity.title} />
                       <meta property="og:type" content="website" />
                       <meta property="og:url" content={"https://www.happy-weekends.com/activity/"+this.state.activity.slug} />
                       <link rel="canonical" href={"https://www.happy-weekends.com/activity/"+this.state.activity.slug} />
                       <meta property="og:title" content={this.state.activity.metaTitle?this.state.activity.metaTitle:this.state.activity.title} />
                       <meta property="og:description" content={this.state.activity.metaDescription?this.state.activity.metaDescription:this.state.activity.description} />
                       <meta property="og:image" content={this.state.activity.images&&this.state.activity.images.length>0 && baseImgUrl + this.state.activity.images[0].image} />
                       <meta property="twitter:card" content="summary_large_image" />
                       <meta property="twitter:url" content={"https://www.happy-weekends.com/activity/"+this.state.activity.slug} />
                       <meta property="twitter:title" content={this.state.activity.metaTitle?this.state.activity.metaTitle:this.state.activity.title} />
                       <meta property="twitter:description" content={this.state.activity.metaDescription?this.state.activity.metaDescription:this.state.activity.description} />
                       <meta property="twitter:image" content={this.state.activity.images&&this.state.activity.images.length>0 && baseImgUrl + this.state.activity.images[0].image} />
                       <meta name="description" content={this.state.activity.metaDescription?this.state.activity.metaDescription:this.state.activity.description} />
                       <meta property="og:title" content={this.state.activity.metaTitle?this.state.activity.metaTitle:this.state.activity.title} />
                       <meta property="og:image" content={this.state.activity.images&&this.state.activity.images.length>0 && baseImgUrl + this.state.activity.images[0].image} />

                   </MetaTags>}


                   {/*<MetaTags>*/}

                   {/*<Helmet>*/}
                       {/*<title>{this.state.activity &&this.state.activity.title}</title>*/}
                       {/*<meta id="meta-description" name="description" content={this.state.activity.description} />*/}
                       {/*<meta id="meta-title" property="og:title" content={this.state.activity && this.state.activity.title} />*/}
                       {/*<meta id="meta-url" property="og:url" content={mainUrl+"activity/"+this.state.activity && this.state.activity.slug} />*/}
                       {/*<meta id="meta-img" property="og:image" content={this.state.activity &&this.state.activity.images && this.state.activity.images.length>0 && baseImgUrl + this.state.activity.images[0].image} />*/}
                   
                   {/*</Helmet>*/}


                   {/*</MetaTags>*/}

                   <section className="pro-detail">
                       <div className="container">
                           <div className="row">
                               <div className="col-lg-8">

                                   <div className="product-head">
                                       <div className="row for-web">
                                           <div className="col-lg-12">
                                               <ul className="breadcum">
                                                   <li><Link to="/">Home</Link></li>
                                                   {this.state.activityDetail && this.state.activityDetail.category && this.state.activityDetail && this.state.activityDetail.category[0] &&
                                                   <>
                                                       <li><img src={RightArrow} alt=""/></li>
                                                       <li>{this.state.activityDetail.category[0].name}</li>
                                                   </>
                                                   }
                                               </ul>

                                               {this.state.activity.isOnline &&
                                               <div>
                                               <span className="item-online">
                                                   ONLINE
                                               </span>
                                               </div>
                                               }


                                               <h1> {this.state.activity.title}</h1>

                                               {this.state.activity.address && this.state.activity.address.city &&
                                               <p>{this.state.activity.address.city.name}</p>}

                                           </div>
                                       </div>
                                       <div className="row for-mobile">
                                           <div className="col-sm-12">
                                               <ul className="breadcum">
                                                   <li><Link to="/">Home</Link></li>

                                                   {this.state.activityDetail && this.state.activityDetail.category && this.state.activityDetail && this.state.activityDetail.category[0] &&
                                                   <>
                                                       <li><img src={RightArrow} alt=""/></li>
                                                       <li>{this.state.activityDetail.category[0].name}</li>
                                                   </>
                                                   }


                                               </ul>
                                           </div>

                                           <div className="col-sm-12 col-xs-12">
                                               <h1>{this.state.activity.title}</h1>

                                               {this.state.activity.address && this.state.activity.address.city &&
                                               <p> {this.state.activity.address.city && this.state.activity.address.city.name}</p>}

                                           </div>

                                       </div>
                                       <div className="row">
                                           {this.state.activity.rating && <div className="col-lg-12">
                                               <div className="rating">
                                                   <Rating className="detail-head-r"
                                                           readOnly
                                                           value={this.state.activity.rating}
                                                           icon={<Star style={{color: "#29abe2"}} name="size-small"
                                                                       fontSize={"small"}/>}
                                                           emptyIcon={<Star color="disabled" name="size-small"
                                                                            fontSize={"small"}/>}
                                                           name="size-large" size="large"
                                                   />
                                                   {this.state.activity.rating > 0 &&
                                                   <p className="rating-p ">{this.state.activity.rating}
                                                   ({this.state.activity.reviewsCount} ratings) <span
                                                       className={"d-none"}>|  100+ Booked</span></p>}
                                                   <p class="rating-p"><Scrollchor to="#reviews"><a
                                                       onClick={this.showReviewBox}>Add Review </a><i
                                                       class="fas fa-chevron-right"></i></Scrollchor></p>


                                               </div>
                                           </div>
                                           }
                                       </div>
                                   </div>

                                   {this.state.activity.id &&

                                   <div className="productinfo mb-4">

                                       <div style={{marginTop: "15px"}} id="pills-tabContent" className="tab-content">
                                           <div className="tab-pane fade show active" id="pills-photos" role="tabpanel"
                                                aria-labelledby="pills-home-tab">
                                               <div className="row">
                                                   <div className="col-lg-12">
                                                       <div className="slide-link">
                                                           <button className="detail-share-icon share-icon" href="javascripit"><ShareIcon/>

                                                               <div className="detail-share-hover share-hover">
                                                                   <ul>

                                                                       <li><button onClick={this.facebookShare.bind(this)}>
                                                                           <img src={fb} alt="iconshare" /></button>
                                                                       </li>

                                                                       <li>
                                                                           <button  onClick={this.linkedInShare.bind()}>
                                                                               <img src={LinkDinSquare} alt="iconshare" />
                                                                           </button>
                                                                       </li>
                                                                       <li><button onClick={this.twitterAppShare.bind(this)}>
                                                                           <img src={twi} alt="iconshare" /></button>
                                                                       </li>

                                                                   </ul>
                                                               </div>
                                                           </button>

                                                           {  this.state.activity && this.state.activity.id && <FavoriteButton item={this.state.activity} /> }


                                                       </div>
                                                       {  <ImageSlider  images={this.state.activity.images}/> }
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>}


                                   {this.state.activityDetail && <Fragment>
                                       <div className="detail-info">
                                           {this.state.activityDetail && this.state.activityDetail.activityTopInformation &&
                                           <div className="row">
                                               <div className="col-lg-12">
                                                   <ul>
                                                       {this.state.activityDetail.day &&
                                                       <li><img src={calenderSVG} alt=""/> Available Days: {this.state.activityDetail.day.map((item, index) =>
                                                               <>
                                                                   {index > 0 && ", "}{item.name}
                                                               </>
                                                           )}</li>
                                                       }
                                                       {this.state.activityDetail.activityTopInformation.duration &&

                                                       <li><img src={durationSVG}
                                                                alt=""/> Duration: {this.state.activityDetail.activityTopInformation.duration}
                                                       </li>
                                                       }
                                                       {this.state.activityDetail.activityTopInformation.workingHours &&

                                                       <li><img src={timeSVG} alt=""/> Working
                                                           hours: {this.state.activityDetail.activityTopInformation.workingHours}
                                                       </li>
                                                       }

                                                       {this.state.activityDetail.activityTopInformation.howToRedeeem &&

                                                       <li><img src={redeemSVG} alt=""/> How to
                                                           redeem: {this.state.activityDetail.activityTopInformation.howToRedeeem}
                                                       </li>
                                                       }


                                                       {/*{this.state.activityDetail.activityPreference && this.state.activityDetail.activityPreference.name &&*/}

                                                       {/*<li><img src={DetailIcon5} alt=""/> Experience*/}
                                                           {/*Delivered: {this.state.activityDetail.activityPreference.name}*/}
                                                       {/*</li>*/}
                                                       {/*}*/}


                                                       {this.state.activity.validityPeriod &&

                                                       <li><img src={timeSVG}
                                                                alt=""/> Validity: {this.state.activity.validityPeriod} Weekends
                                                       </li>
                                                       }
                                                       {this.state.activityDetail.activityTopInformation.whatsNext &&

                                                       <li><img src={scedhuleSVG} alt=""/> What’s
                                                           next: {this.state.activityDetail.activityTopInformation.whatsNext}
                                                       </li>
                                                       }
                                                   </ul>
                                               </div>
                                           </div>
                                           }
                                       </div>


                                       <BottomTabsNew activityDetail={this.state.activityDetail}
                                                      activity={this.state.activity}/>
                                   </Fragment>

                                   }


                               </div>
                               <div id="rightColrightCol" className="col-lg-4">
                                   <div id="sticky-box">
                                       <div id="priceOptionForm">

                                           <div id="offers" className="right-section row display-sm-none">
                                               <div style={{border: " 1px solid #f5f5f5"}}
                                                    className={this.state.showBuyNowError ? " col-12 offers wl_scroll_fix_detail  buy-now-error" : " col-12 offers wl_scroll_fix_detail "}>
                                                   {this.state.activity.dateInput &&
                                                   <div className="block border-bottom ">
                                                       <Form className="w-100">
                                                           <Form.Group as={Row} className={"d-"}
                                                                       controlId="formPlaintextEmail">
                                                               <Form.Label column sm="4" className="date-label pl-0 ">
                                                                   <img src={DetailIcon1} alt=""/> Date
                                                               </Form.Label>


                                                               <Col sm="8" className={"custom-select"}>
                                                                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                       <DatePicker

                                                                           shouldDisableDate={this.disableWeekDays}
                                                                           minDate={new Date()}
                                                                           autoOk={true}
                                                                           disableToolbar
                                                                           variant="inline"
                                                                           format="dd/MM/yyyy"
                                                                           value={this.state.dateSelected}
                                                                           id="date-picker-inline"
                                                                           onChange={this.handleDateChange}
                                                                           KeyboardButtonProps={{
                                                                               'aria-label': 'change date',
                                                                           }}
                                                                       />

                                                                   </MuiPickersUtilsProvider>

                                                               </Col>
                                                           </Form.Group>
                                                       </Form>
                                                   </div>
                                                   }

                                                   {
                                                       this.state.priceOptions && this.state.priceOptions.map((item) =>


                                                           <div className="block border-bottom">
                                                               <div className="top-block">
                                                                   <p className="mb-0 d-none">0 bought</p>
                                                                   <h4>{item.description}</h4>
                                                               </div>
                                                               <div className="left-side">

                                                                   <div className="offer-data-left">
                                                                       <div>
                                                                           <button className="value-button"
                                                                                   value="Decrease Value"
                                                                                   data-id={item.id}
                                                                                   data-price={item.id}
                                                                                   onClick={this.decrementQuantity.bind(this)}
                                                                           ><img src={MinIcon} alt=""/>
                                                                           </button>
                                                                           <input type="hidden" multiple
                                                                                  name="priceOption[]"
                                                                                  className="number" value={item.id}/>
                                                                           <input dataprice={175} multiple
                                                                                  name="priceOptionQuantity[]"
                                                                                  className="number exists"
                                                                                  readOnly type="text" id="number-1845"
                                                                                  value={item.quantity}/>
                                                                           <button data-id={item.id}
                                                                                   data-price={item.id}
                                                                                   onClick={this.incrementQuantity.bind(this)}
                                                                                   className="value-button"
                                                                                   value="Increase Value"><img
                                                                               src={PlusIcon} alt=""/>
                                                                           </button>
                                                                       </div>
                                                                   </div>

                                                               </div>
                                                               <div className="right-side">

                                                                   {item.discountedPrice &&
                                                                   <p class="totaloff">{Math.floor(((item.price - item.discountedPrice) / item.price) * 100)}%
                                                                       OFF</p>}

                                                                   <div className="offer-data-right">


                                                                       {!item.discountedPrice &&
                                                                       <h4>AED <span id="price-1845">{item.price}</span>
                                                                       </h4>}
                                                                       {item.discountedPrice && <h4>AED <span
                                                                           id="price-1845">{item.discountedPrice}</span>
                                                                       </h4>}
                                                                       {item.discountedPrice &&
                                                                       <p className="text-line">AED {item.price}  </p>}

                                                                   </div>

                                                               </div>
                                                           </div>
                                                       )
                                                   }

                                                   <div className="order-total">
                                                       <div className="row">
                                                           <div className="col-md-7">
                                                               <p className="mb-0">Total</p>
                                                               <p className="small-text mb-0">(Inclusive of VAT)</p>
                                                           </div>
                                                           <div className="col-md-5 text-right">
                                                               <h5>AED <span id="totalPrice">{this.state.total}</span>
                                                               </h5>
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <div className="promo-block">
                                                       {!this.state.activity.submitRequest && !this.state.activity.soldOut &&
                                                       <button onClick={this.buyNow} className="buy-now">BOOK
                                                           NOW</button>}


                                                       {!this.state.activity.submitRequest && this.state.activity.soldOut &&
                                                       <button className="sold-out">Sold Out</button>}

                                                       {this.state.activity.submitRequest &&
                                                       <button onClick={this.toggleSubmitRequestPopUp}
                                                               className="submit-interest">Submit
                                                           Interest
                                                           <div class="interest-tooltip ">
                                                               <p>Due to Covid-19, this experience is currently unavailable. If you want to be notified when it gets available, please submit interest.</p>
                                                               <img src={CrossBlack} alt=""/>
                                                           </div>
                                                       </button>}
                                                   </div>
                                               </div>
                                               {/* <div className="promo-box">
                                                    <span className="newOffersIcon"/>
                                                    <p>Get 5% extra off use PROMO CODE<span className="text-red"> Accenture10</span>
                                                        <span className="static-tnc">
                                                        <a href="JavaScript:void(0)" data-toggle="popover"
                                                           title="Popover title"
                                                           data-content="And here's some amazing content. It's very engaging. Right?">...</a>
                                                    </span></p>
                                                </div> */}


                                           </div>


                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>


                       {!this.state.displayMobilePriceOptions && this.state.activity.priceOption && this.state.activity.priceOption.length > 0 &&
                       <div className="mobile-price" onClick={this.toggleMobilePriceOptions}>
                           <div className="Show Option">
                               <h1>AED {this.state.activity.priceOption[0].discountedPrice ? this.state.activity.priceOption[0].discountedPrice : this.state.activity.priceOption[0].price}</h1>
                               <button className="options-show">Book Now</button>
                           </div>
                       </div>
                       }

                       {this.state.displayMobilePriceOptions &&
                       <div className="mobile-pricing-view">

                           <div
                               className={this.state.showBuyNowError ? " col-12 offers wl_scroll_fix_detail  buy-now-error" : " col-12 offers wl_scroll_fix_detail "}>

                               <div className="">

                                   <b style={{color:"#00adee",fontSize:"20px"}}>Select number of experiences</b>
                                   <button onClick={this.toggleMobilePriceOptions} className="cross-btn"><i
                                       class="fas fa-times"></i></button>
                               </div>
                               {this.state.activity.dateInput && <div className="block border-bottom">
                                   <Form className="w-100">
                                       <Form.Group className="mb-0  d-flex" controlId="formPlaintextEmail">

                                           <Form.Label column sm="4" className="date-label pl-0 ">
                                               <img src={DetailIcon1} alt=""/> Date
                                           </Form.Label>


                                           <Col sm="8" className={"custom-select"}>
                                               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                   <DatePicker

                                                       shouldDisableDate={this.disableWeekDays}


                                                       minDate={new Date()}
                                                       autoOk={true}
                                                       disableToolbar
                                                       variant="inline"
                                                       format="dd/MM/yyyy"
                                                       value={this.state.dateSelected}
                                                       id="date-picker-inline"
                                                       onChange={this.handleDateChange}
                                                       KeyboardButtonProps={{
                                                           'aria-label': 'change date',
                                                       }}
                                                   />

                                               </MuiPickersUtilsProvider>

                                           </Col>
                                       </Form.Group>
                                   </Form>
                               </div>}

                               {
                                   this.state.priceOptions && this.state.priceOptions.map((item) =>


                                       <div className="block border-bottom">
                                           <p className="mb-0 d-one bought-total">0 bought</p>
                                           <div className="left-side">
                                               <div className="top-block">

                                                   <h4>{item.description}</h4>
                                               </div>
                                               <div className="offer-data-left">
                                                   <div>
                                                       <button className="value-button"
                                                               value="Decrease Value"
                                                               data-id={item.id} data-price={item.id}
                                                               onClick={this.decrementQuantity.bind(this)}
                                                       ><img src={MinIcon} alt=""/>
                                                       </button>
                                                       <input type="hidden" multiple name="priceOption[]"
                                                              className="number" value={item.id}/>
                                                       <input dataprice={175} multiple name="priceOptionQuantity[]"
                                                              className="number exists"
                                                              readOnly type="text" id="number-1845"
                                                              value={item.quantity}/>
                                                       <button data-id={item.id} data-price={item.id}
                                                               onClick={this.incrementQuantity.bind(this)}
                                                               className="value-button"
                                                               value="Increase Value"><img src={PlusIcon} alt=""/>
                                                       </button>
                                                   </div>
                                               </div>

                                           </div>
                                           <div className="right-side">
                                               {item.discountedPrice &&
                                               <p class="totaloff">{Math.floor(((item.price - item.discountedPrice) / item.price) * 100)}%
                                                   OFF</p>}

                                               <div className="offer-data-right">

                                                   {!item.discountedPrice &&
                                                   <h4>AED <span id="price-1845">{item.price}</span></h4>}
                                                   {item.discountedPrice &&
                                                   <h4>AED <span id="price-1845">{item.discountedPrice}</span></h4>}
                                                   {item.discountedPrice &&
                                                   <p className="text-line">AED {item.price}  </p>}

                                               </div>
                                           </div>
                                       </div>
                                   )


                               }


                               <div className="order-total">
                                   <div className="row">
                                       <div className="col-md-7">
                                           <p className="mb-0">Total</p>
                                           <p className="small-text mb-0">(Inclusive of VAT)</p>
                                       </div>
                                       <div className="col-md-5 text-right">
                                           <h5>AED <span id="totalPrice">{this.state.total}</span></h5>
                                       </div>
                                   </div>
                               </div>
                               <div className="promo-block">

                                   {!this.state.activity.submitRequest&&!this.state.activity.soldOut &&
                                   <button onClick={this.buyNow} className="buy-now">BOOK NOW</button>}


                                   {!this.state.activity.submitRequest&&this.state.activity.soldOut &&
                                   <button className="sold-out" href="javascripit">Sold Out</button>
                                   }

                                   {this.state.activity.submitRequest &&
                                   <button onClick={this.toggleSubmitRequestPopUp} className="submit-interest">
                                       Submit Interest <img src={InfoTool} alt=""/>
                                       <div class="interest-tooltip">
                                           <p>Due to Covid-19, this experience is currently unavailable. If you want to be notified when it gets available, please submit interest.</p>
                                           <img src={CrossBlack} alt=""/>
                                       </div>
                                   </button>
                                   }

                               </div>
                           </div>

                       </div>}
                   </section>


                   {this.state.activity &&
                   <Modal show={this.state.showSubmitRequestPopUp}

                                                  onHide={this.toggleSubmitRequestPopUp}
                                                  className={"custom-modal-popup popup-form"}
                   >
                       <button onClick={this.toggleSubmitRequestPopUp} className="close" data-dismiss="modal"
                               aria-label="Close"><i class="fas fa-times"></i></button>
                       <div className="row py-3 justify-content-center mobile-menu-row">
                           {this.state.activity &&
                           <div className="mobile-menu edit-cart-block col-lg-12">
                               <div className="form-col-left pl-3 pr-3">
                                   <h3 class="form-heading">Submit Your Request</h3>

                                   <div className="offers wl_scroll_fix_detail ">
                                       <div className="block border-bottom pt-0">
                                           <div className="product-img-block">
                                               <div className="product-img">
                                                   {this.state.activity.images && this.state.activity.images.length > 0 && <img src={baseImgUrl + this.state.activity.images[0].image}
                                                        className="img-fluid"
                                                        alt="Product detail"/> }
                                               </div>
                                               <h2>{this.state.activity.title}</h2>
                                           </div>
                                       </div>
                                   </div>

                                           {!this.state.showSubmitRequestSuccess &&
                                               <>
                                               <p>{this.state.activity.submitRequestText}</p>

                                               <Form noValidate validated={this.state.validatedForm}
                                                     onSubmit={this.handleSubmitRequestPopUpNew}>
                                                   <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                                       <Col sm={{span: 6}}>
                                                           <Form.Label>First Name</Form.Label>
                                                           <Form.Control required name={"firstName"} type="text"
                                                                         placeholder=""/>
                                                       </Col>
                                                       <Col sm={{span: 6}}>
                                                           <Form.Label>Last Name</Form.Label>
                                                           <Form.Control required name={"lastName"} type="text"
                                                                         placeholder=""/>
                                                       </Col>
                                                   </Form.Group>

                                                   <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                                       <Col sm={{span: 12}}>
                                                           <Form.Label>Email</Form.Label>
                                                           <Form.Control required name={"email"} type="email"
                                                                         placeholder=""/>
                                                       </Col>
                                                   </Form.Group>


                                                   <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                                       <Col sm={{span: 12}}>
                                                           <Form.Label>Phone Number</Form.Label>
                                                           <PhoneInput
                                                               onChange={this.handleChange.bind(this, "phoneNumber")}
                                                               inputClass={this.state.phoneNumberInValid?"is-invalid":""}
                                                               inputProps={{
                                                                   name: 'phoneNumber',
                                                                   // required: true,
                                                                   autoFocus: true,
                                                                   defaultErrorMessage:"Invalid",
                                                                   // minLength:9,
                                                               }}
                                                               country={'ae'}
                                                           />


                                                           {/*<PhoneInputIntl />*/}


                                                           {/*<Form.Control required  maxLength={9} minLength={9} name={"phoneNumber"} type="tel" placeholder=""   aria-describedby="inputGroupPrepend"/>*/}
                                                           {/*<Form.Control.Feedback type="invalid">Invalid Phone Number</Form.Control.Feedback>*/}
                                                           {/*{this.state.phoneNumberInValid && <div  style={{display:"block"}} className="invalid-feedback">Phone No. must be vaild</div>}*/}
                                                           <span className={"invalid-feedback-phone"}>{this.state.errors["phoneNumber"]}</span>

                                                       </Col>
                                                   </Form.Group>


                                                   <Form.Group className="row" controlId="exampleForm.ControlInput1">
                                                       <Col sm={{span: 12}}>
                                                           <Form.Label>Comments</Form.Label>
                                                           <Form.Control as="textarea" rows="2" required name={"comment"}
                                                                         type="text"
                                                                         placeholder=""/>
                                                       </Col>
                                                   </Form.Group>


                                                   <Form.Group className={"justify-content-md-center row"}
                                                               controlId="exampleForm.ControlInput1">
                                                       <Col sm={{span: 1}}/>

                                                       <Col sm={{span: 12}}>
                                                           <div className="d-flex btn-group">

                                                               <Button  type={"submit"}
                                                                        className="btn btn-no-radius"
                                                                        type={"submit"}  >
                                                                   { this.state.submitRequestLoading && <Spinner
                                                                       as="span"
                                                                       animation="grow"
                                                                       size="sm"
                                                                       role="status"
                                                                       aria-hidden="true"
                                                                   /> }

                                                                   { this.state.submitRequestLoading  ? "Wait.." : "Submit Interest Request"}

                                                               </Button>


                                                           </div>

                                                       </Col>
                                                       <Col sm={{span: 1}}/>
                                                   </Form.Group>


                                               </Form>
                                               </>
                                           }


                                   {this.state.showSubmitRequestSuccess &&

                                   <div className="text-center" style={{marginTop:"10px"}}>
                                       <h1 style={{color: "#00adee"}}><i className="fas fa-check-circle"></i></h1>
                                       <h6 style={{color: "#34383b", fontWeight:"500!important"}}>Thank you for your interest. Our team will get
                                           back to you when we got interest from required number of people.
                                       </h6>
                                       <button onClick={this.toggleSubmitRequestPopUp} className="btn">Close
                                       </button>


                                   </div>
                                   }
                               </div>
                           </div>
                           }
                       </div>
                   </Modal>
                   }
               </div>
             )
        }
    }


    function ImageSlider(props) {

        var imagesArray = []



        for (let i = 0; i < props.images.length; i++) {


            // console.log(props.images[i])
            imagesArray.push(
                {
                    original: baseImgUrl + props.images[i].image,
                    thumbnail: baseImgUrl + props.images[i].image,

                }
            )

        }


        return (

             <ImageGallery infinite={true}  autoPlay={true} items={imagesArray}  />
        );

    }

    function ActivityItemInfo(props) {

        console.log(props)

            return(

                <div className="productinfo mb-4">

                    <div style={{marginTop: "15px"}} id="pills-tabContent" className="tab-content">
                        <div className="tab-pane fade show active" id="pills-photos" role="tabpanel"
                             aria-labelledby="pills-home-tab">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slide-link">
                                    <button className="share-icon" href="javascripit"><ShareIcon/>

                                        <div className="share-hover">
                                            <ul>
                                                <li>
                                                    <button  onClick={this.whatsAppShare.bind()}>
                                                        <img src={what} alt="iconshare" />
                                                    </button>
                                                </li>
                                                <li><button onClick={this.twitterAppShare.bind(this)}>
                                                    <img src={twi} alt="iconshare" /></button>
                                                </li>
                                                <li><button onClick={this.facebookShare.bind(this)}>
                                                    <img src={fb} alt="iconshare" /></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </button>

                                        {  props.activity && props.activity.id && <FavoriteButton item={props.activity} /> }


                                    </div>
                                    {  <ImageSlider  images={props.images}/> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }



    function ShareIcon() {


        return (
    
            <svg style={{height: "28px" ,width: "28px", display: "block",
                        overflow: "visible"}}
                 viewBox="0 0 512 512" fill="#ffffff" fillOpacity="1" stroke="#ffffff"
                 strokeWidth="1" focusable="false" aria-label="Add listing to a list"
                 role="img" strokeLinecap="round" strokeLinejoin="round">
    
                <path d="M376.677,171.954h-71.967c-9.35,0-17,7.65-17,17s7.65,17,17,17h54.683v201.167H81.727V205.954h60.35c9.35,0,17-7.65,17-17
                s-7.65-17-17-17H64.444c-9.35,0-16.717,8.5-16.717,17.85v235.733c0,9.35,7.367,15.583,16.717,15.583h312.233
                c9.35,0,16.717-6.233,16.717-15.583V189.804C393.394,180.454,386.027,171.954,376.677,171.954z"/>
                <path d="M217.727,299.454c9.35,0,17-7.65,17-17V52.104l72.533,64.6c3.117,2.833,7.367,4.25,11.333,4.25
                c4.817,0,9.35-1.983,12.75-5.667c6.233-7.083,5.667-17.85-1.417-24.083l-97.75-86.983c-6.233-5.383-15.3-5.667-21.817-0.567
                c-3.117,1.417-5.667,3.967-7.367,6.8l-84.717,81.317c-6.8,6.517-7.083,17.283-0.567,24.083c6.517,6.8,17.283,7.083,24.083,0.567
                l58.933-56.667v222.7C200.727,291.804,208.377,299.454,217.727,299.454z"/>
    
            </svg>
        )
    
    }




    const mapStateToProps = state => {
        return {

            age: state.age,
            cartItems: state.cartItems,
            loading: state.loading,
            userDetail: state.userDetail,
            isLoggedIn:state.isLoggedIn,
            isGuest:state.isGuest,
            salesQuote: state.salesQuote,


        };
    };

    const mapDispachToProps = dispatch => {
        return {

            onAgeUp: () => dispatch(actionCreator.ageUp(1)),
            onAgeDown: () => dispatch(actionCreator.ageDown(1)),
            addCartItem: (item) => dispatch(actionCreator.addCartItem(item)),
            loginPopUp : (item) => dispatch(actionCreator.loginPopUp(item)),
            loginCheckoutPopUp : (item) => dispatch(actionCreator.loginCheckoutPopUp(item)),
            setReviewBoxOpen: (item) => dispatch(actionCreator.setReviewBoxOpen(item)),
            reviewSuccess: (item) => dispatch(actionCreator.reviewSuccess(item)),



        };
    };
    export default connect(
        mapStateToProps,
        mapDispachToProps
    )(ActivityDetail);

