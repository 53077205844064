import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import * as actionCreator from "../store/actions/actions";
import { connect } from "react-redux";
import  history from '../History/history'
import {BrowserRouter as Router, Route , Switch, Link} from 'react-router-dom'
import {baseUrl,baseImgUrl} from  '../Util/Constants'
import axios from "axios/index";
import Moment from 'react-moment';
import Rating from '@material-ui/lab/Rating';
import  {Star} from '@material-ui/icons';


class  CustomerReviews extends Component{



    constructor(props){

        super(props)

        // this.description = props.activity.description
        // this.importantInformation = props.activity.importantInformation

        this.state = {

            reviews:[],

        };
        this.loadReviews= this.loadReviews.bind(this)

    }


    componentDidMount(){


        this.loadReviews()
    }


    loadReviews(){



        if (this.props.userDetail && this.props.userDetail.id) {
            var url = baseUrl + "activity_reviews.json?groups[]=customer-reviews&isPublished=1&customer=" + this.props.userDetail.id;

            console.log(url)


            axios.get(url)
                .then(res => {

                    console.log("reviews customer")

                    console.log(res.data)

                    this.setState({

                        reviews: res.data,
                    })


                }).catch(error => {

                // dispatch(stopLoading())

                // dispatch(loginFailed())

                console.log(error)
                // dispatch({ type: AUTH_FAILED });
                // dispatch({ type: ERROR, payload: error.data.error.message });


            });
        }

    }


    render (){
        return(


            <div className="tab-pane fade show active" id="pills-myreview" role="tabpanel"
                 aria-labelledby="pills-myreview-tab">

                { this.state.reviews.length>0 &&
                this.state.reviews.map((item)=>

                    <>
                    <div className="row margin-top">
                    <div className="col-md-12 col-lg-3 title-goes">
                        <img className="border-raidus" src={baseImgUrl+item.activity.images[0].image} alt="" width="100%"/>
                    </div>
                    <div className="col-md-12 col-lg-9">
                        <div className="title_here">
                            <h5 className="title_pirce mb-0 mt-0">{item.activity.title}</h5>
                            <p className="title_date mb-2"> <Moment local fromNow>
                                {item.publishedAt}
                            </Moment></p>
                            <div className="rating reviewtab">
                                <Rating
                                    readOnly
                                    value={item.rating}
                                    icon={<Star style={{color: "#29abe2"}} name="size-small" fontSize={"small"} />}
                                    emptyIcon={<Star color="disabled" name="size-small"  fontSize={"small"} />}
                                    name="size-large"  size="large"
                                />

                            </div>
                            <h6 className="mb-0 mt-0">{item.title}</h6>

                            <p className="title_domi mb-0">{item.content}</p>
                            <div className="bottom-block mt-3">
                                <Link style={{width:"auto!important"}} to={"/activity/"+item.activity.slug} className="readmore-red mb-0">View Experience</Link>
                            </div>
                        </div>
                    </div>
                </div>
                   </>
                )}



            </div>

        )
    }
}


const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {


    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(CustomerReviews);
