import React, { Component } from 'react';
import './PersonalisedWeekends.css';
import BollywoodWeekend from '../BollywoodWeekend/index';
import SportsWeekend from '../SportsWeekend/index';
import FamilyWeekend from '../FamilyWeekend/index';

class  PersonalisedWeekends extends Component{ 
    render (){

        return(

                    <div className="row">
                        <div className="col-lg-12 block-title text-center">
                            <h2>Personalised Weekends For You</h2>
                            <p>Curating Collection of Hand-Picked Experiences to Make Your Every Weekend Special</p>
                        </div>
                    </div>

        )
    }
}

export default PersonalisedWeekends;