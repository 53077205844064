import React, { Component } from 'react';
import BollyoodImage1 from '../img/corporate-4.jpg';
import Star from '../img/star.png';
import axios from "axios/index";
import {baseUrl} from "../Util/Constants";
import {connect} from "react-redux";
import { Spinner,Button, Col, Row, Carousel,FormFile, CarouselItem, InputGroup, ModalFooter,ModalDialog, Alert, ModalBody, Form, FormControl, FormCheck, FormGroup, FormLabel, FormText} from 'react-bootstrap'


class  PaymentSettingsNew extends Component{




    constructor(props) {
        super(props);

        this.state = {
            validatedForm: false,
            isChecked: false,
            file: null,
            userExists : false,
            passwordError : false,
            oldPasswordError : false,
            confirmPasswordError : false,
            cities : [],
            areas:[],
            formHasError: false,
            host: this.props.host,
            hostBankInfo:{},
            loading: false,

        }

        this.passwordRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }




    handleSubmit = event => {

        event.preventDefault();

        const form = event.currentTarget;


        console.log(form.checkValidity())

        if (form.checkValidity() === false) {

            event.stopPropagation();

            this.setState({

                validatedForm : true,
                formHasError : true
            });
        }


        if(form.checkValidity()) {

            this.setState({
                btnLoading: true,
                formHasError : false
            })

            this.setState({

                loading: true
            })

            const data = new FormData(event.target);


            console.log("host form data")
            console.log(data)



            this.setState({

                confirmPasswordError: false
            })

            this.setState({

                passwordError: false
            })



            let hostData = {

                "host": this.state.host.id,

                "iban": data.get("iban"),
                "accountHolder": data.get("accountHolder"),
                "accountNumber": data.get("accountNumber"),
                "swiftCode": data.get("swiftCode"),
                "bankName": data.get("bankName"),

            };


            var urlHost = baseUrl + "edit_banks";

            axios.post(urlHost, hostData )
                .then(res => {

                    this.setState({

                        loading : false
                    })


                }).catch(error => {
                console.log("host business error")
                console.log(hostData)
                console.log(error)


                this.setState({

                    loading : false
                })

            });




        }
    }


    componentDidMount(){



        this.getHostInformation()

    }



    getHostInformation(){


        var    url = baseUrl+"hosts.json?groups[]=bankinfo&email="+this.props.userDetail.email;

        console.log(url)


        axios.get(url)
            .then(res => {


                console.log("host bank data exits")
                console.log(res.data[0])

                this.setHostInfo(res.data[0])





            }).catch(error => {


            console.log(error)
            // dispatch({ type: AUTH_FAILED });
            // dispatch({ type: ERROR, payload: error.data.error.message });


        });




    }

    setHostInfo(hostBankInfo){


        this.setState({

            bankName: hostBankInfo.bankName,
            accountHolder: hostBankInfo.accountHolder,
            swiftCode: hostBankInfo.swiftCode,
            iban: hostBankInfo.iban,
            accountNumber: hostBankInfo.accountNumber,

        })

    }


    onChange(e) {

        var files = e.target.files;

        console.log(files);


        var filesArr = Array.prototype.slice.call(files);

        console.log(filesArr);

        this.setState({ file: files[0]});
    }



    render (){
        return(

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-personal" role="tabpanel"
                     aria-labelledby="pills-personal-tab">
                    <Form   noValidate validated={this.state.validatedForm}  onSubmit={this.handleSubmit}>
                        <div className="form-block mt-4">
                            <Form.Row>
                                <Form.Group as={Col} md="12">
                                    <h4>Business (Host) Bank Account Information</h4>
                                    <p>Payee Details (Person / Institution that the fund will be sent to)</p>
                                </Form.Group>
                            </Form.Row>


                            <Form.Row>
                              
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Account Holder Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            value={this.state.accountHolder}
                                            onChange={this.handleInputChange}

                                        />
                                    
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Bank Account Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder=""
                                        value={this.state.accountNumber}
                                        onChange={this.handleInputChange}
                                    />

                                </Form.Group>

                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Bank Name & Address</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            value={this.state.bankName}
                                            onChange={this.handleInputChange}
                                        />
                                    
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>IBAN</Form.Label>
                                        <Form.Control
                                            name={"iban"}
                                            required
                                            type="text"
                                            placeholder=""
                                            value={this.state.iban}
                                            onChange={this.handleInputChange}
                                        />
                                    
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Swift Code</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder=""
                                            value={this.state.swiftCode}
                                            onChange={this.handleInputChange}
                                        />
                                    
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md="12" className="text-center mt-4">
                                    <Button className="btn btn-red" type="submit">

                                        { this.state.loading && <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> }

                                        { this.state.loading ? "Wait.." : "Save"}
                                    </Button>
                                    {this.state.formHasError && <Alert className={"mt-2"} variant={"danger"}>
                                        Information provided is not valid or missing!
                                    </Alert>
                                    }
                                </Form.Group>
                            </Form.Row>
                        </div>                
                    </Form>
                </div>
            </div>


        )
    }
}


const mapStateToProps = state => {
    return {

        isLoggedIn: state.isLoggedIn,
        userDetail: state.userDetail

    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(PaymentSettingsNew);

