import React, {Component, Fragment} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios/index";
import TrendingScrollSection from "./TrendingScrollSection";
import {baseUrl,baseImgUrl} from  '../Util/Constants'

class  TrendingScrollSections extends Component{


    constructor(props){

        super(props)
        this.state = {

            items : [],

        };

    }

     componentDidMount(){



         var  url =  baseUrl+"activities.json?groups[]=activities&isActive=1";




             url = url + "&order[lifeTimeSales]=desc&order[views]=desc&address.city[]=11&&address.city[]=12";



         axios.get(url)
             .then(res => {

                 console.log("trending items recieved")
                 this.setState({

                     items : res.data

                 });




             })

     }



    render (){

        return(
           <Fragment>

                    { this.state.items.length>0&&


                            <TrendingScrollSection key={"category-section-"} items={this.state.items}/>
                        }


           </Fragment>

        )
    }
}





export default TrendingScrollSections;